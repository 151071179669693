<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/financial/records"></ion-back-button>
                    </ion-buttons>
          <ion-title>{{ $t("Register") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content-items" >
          <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="empresaSelecionada">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
          <!-- <ion-item fill="outline">
            <ion-label position="stacked">Record id</ion-label>
            <ion-input id="invoiceInputt" readonly="invoiceInputt"
              placeholder="This field will be automatically filled"></ion-input>
          </ion-item> -->
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="registro.nome" ></ion-input>
          </ion-item>

          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Registration") }} id <span style="color: red;">*</span></ion-label>
            <ion-input v-model="registro.cadastro_id" type="number"></ion-input>
          </ion-item>
          <IonItem fill="outline">
            <ion-label position="floating">{{ $t("Supplier") }}</ion-label>
            <ion-select position="floating" aria-label="Fruit" class="select" side="top"
              v-model="registro.fornecedor">
              <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in fornecedores" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
            </ion-select>
          </IonItem>
          <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Register") }} {{ $t("Supplier") }}</ion-label>
                        <ion-input v-model="nomeFornecedor"
                            ></ion-input>
                    </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Address") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="registro.endereco" ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Number") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="registro.numero" type="number" ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Neighborhood") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="registro.vizinhanca" ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Details") }}</ion-label>
            <ion-input v-model="registro.detalhes" ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("City") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="registro.cidade" ></ion-input>
          </ion-item>
          <div class="country">
            <ion-item fill="outline">
              <ion-label position="floating">{{ $t("Country") }} <span style="color: red;">*</span></ion-label>
              <ion-input id="paisInput" v-model="registro.pais" 
                @ionFocus="showCountryList = true"></ion-input>
            </ion-item>
            <ion-list class="listaPaíses" v-if="showCountryList" style="padding-top: 0px; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);">
              <ion-content scrool="y">
                <ion-list lines="full">
                  <ion-item button v-for="country in filteredCountries" :key="country.id" @click="selectCountry(country)">
                    <ion-label slot="start">{{ country }}</ion-label>
                  </ion-item>
                </ion-list>
              </ion-content>
            </ion-list>
          </div>
          <ion-item fill="outline">
            <ion-label position="stacked">{{ $t("Zip") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="arquivoInput" type="file" @change="onFileSelected"
               accept="*" aria-label="Choose file"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Telephone") }} 1 <span style="color: red;">*</span></ion-label>
            <ion-input type="tel" @blur="removerMascara" @input="formatarTelefone1" v-model="registro.telefone_1"
              ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Telephone") }} 2</ion-label>
            <ion-input type="tel" @blur="removerMascara" @input="formatarTelefone2" v-model="registro.telefone_2"
              ></ion-input>
          </ion-item>
          <ion-item fill="outline" ref="item">
            <ion-label position="floating">Email <span style="color: red;">*</span></ion-label>
            <ion-input id="emailInput" ref="email" v-model="registro.email" ></ion-input>
          </ion-item>

          <ion-button size="large" expand="block" @click="cadastrarRegistro">{{ $t("Register") }}</ion-button>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>
    
<script>
import { IonBackButton ,IonSelect, IonList, IonSelectOption, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"

export default {
  components: {
    IonBackButton ,IonSelect, IonList, IonSelectOption, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
    return {
      registro: {
        nome: '',
        fornecedor: '',
        detalhes: '',
        numero: '',
        telefone_1: '',
        telefone_2: '',
        vizinhanca: '',
        cadastro_id: '',
        pais: '',
        cidade: '',
        email: '',
        endereco: '',
        empresa:''
      }
      ,
      especies: "",
      empresas:'',
      showCountryList: false,
      empresaSelecionada:'',
      funcionalidades:'',
            master: '',
            fornecedores: '',
            nomeFornecedor: ''
    }
  },
  computed: {
    filteredCountries() {
      return this.countriesNames.filter((country) =>
        country.toLowerCase().includes(this.registro.pais.toLowerCase())
      );
    },
  },
  mounted() {
    this.getPermissoes();
    this.getCountries()
    this.fetchEmpresas()
        this.fetchUser()
        this.fetchFornecedores()
  },
  methods: {
    
    async fetchFornecedores() {
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/fornecedores`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.fornecedores = response.data.items;
            } catch (error) {
                console.log(error);
            }
        },
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(29)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
            } catch (error) {
                console.log(error);
            }
        },
      async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresa = parseInt(localStorage.getItem('idEmpresa'));
        this.master = response.data.master
      } catch (error) {
        console.log(error);
      }
    },
    removerMascara(event) {
      if (event && event.target) {
        const input = event.target.value;
        const digitsOnly = input.replace(/\D/g, '');
        const masked = `(${digitsOnly.substring(0, 2)}) ${digitsOnly.substring(2, 7)}-${digitsOnly.substring(7, 11)}`;
        this.registro.telefone_1 = masked;
      }
    },

    formatarTelefone1(event) {
      const isDeleting = event.data == null;
      if (!isDeleting && this.registro.telefone_1) {
        const whats = event.target.value.replace(/\D/g, '');
        const whatsLength = whats.length;

        if (whatsLength <= 2) {
          this.registro.telefone_1 = `(${whats})`;
        } else if (whatsLength <= 6) {
          this.registro.telefone_1 = `(${whats.substring(0, 2)}) ${whats.substring(2)}`;
        } else if (whatsLength <= 10) {
          this.registro.telefone_1 = `(${whats.substring(0, 2)}) ${whats.substring(2, 6)}-${whats.substring(6)}`;
        } else {
          this.registro.telefone_1 = `(${whats.substring(0, 2)}) ${whats.substring(2, 7)}-${whats.substring(7, 11)}`;
        }
      }
      this.removerMascara()
    },
    formatarTelefone2(event) {
      const isDeleting = event.data == null;
      if (!isDeleting && this.registro.telefone_2) {
        const whats = event.target.value.replace(/\D/g, '');
        const whatsLength = whats.length;

        if (whatsLength <= 2) {
          this.registro.telefone_2 = `(${whats})`;
        } else if (whatsLength <= 6) {
          this.registro.telefone_2 = `(${whats.substring(0, 2)}) ${whats.substring(2)}`;
        } else if (whatsLength <= 10) {
          this.registro.telefone_2 = `(${whats.substring(0, 2)}) ${whats.substring(2, 6)}-${whats.substring(6)}`;
        } else {
          this.registro.telefone_2 = `(${whats.substring(0, 2)}) ${whats.substring(2, 7)}-${whats.substring(7, 11)}`;
        }
      }
      this.removerMascara()
    },
    validateEmail(email) {
      const validTLDs = ["com", "net", "org", "io", "br"];
      return email.match(`^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\\.(${validTLDs.join("|")})$`);
    },
    getCountries() {
      axios.get('https://restcountries.com/v3.1/all')
        .then(response => {
          this.countries = response.data;
          this.countriesNames = this.countries.map(country => country.name.common)
          console.log(this.countries);
        })
        .catch(error => {
          console.log(error);
        });
    },
    onFileSelected(event) {
      this.fileSelected = event.target.files[0]

    },
    selectCountry(country) {
      this.registro.pais = country;
      this.showCountryList = false;
    },
    async cadastrarRegistro() {

      if (!this.registro.nome) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.registro.cadastro_id) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Registration") + " id",
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.registro.endereco) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Address"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      if (!this.registro.numero) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Number"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.registro.vizinhanca) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Neighborhood"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.registro.cidade) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("City"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      if (!this.fileSelected) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("file"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      if (!this.registro.pais) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Country"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }


      if (!this.registro.telefone_1) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Telephone") + " 1",
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      if (!this.registro.email || !this.validateEmail(this.registro.email)) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+" Email",
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      const token = localStorage.getItem('token');
      if(!this.master){
          this.empresaSelecionada = ''
        }

      if (this.nomeFornecedor !== "") {
                const data2 = {
                    nome: this.nomeFornecedor,
                }

                axios.post(`${config.apiUrl}/fornecedores/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                    .then(response => {
                        console.log(response.data.id);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            }

            this.registro.empresa = this.empresaSelecionada ? this.empresaSelecionada : this.empresa;
        this.registro.fornecedor = this.nomeFornecedor ? this.nomeFornecedor : this.registro.fornecedor

      const file = this.fileSelected;

        const registros = new FormData();

        for (let index in this.registro) {
            registros.append(index, this.registro[index]);
        }

        registros.append('zip', file);
        console.log(registros);

        axios.post(`${config.apiUrl}/financial/records/create`, registros, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            console.log(response.data.id);

            const alert = alertController.create({
              header: this.$t("successfully_registered!")
            });
            alert.then(_alert => {
              _alert.present();

              window.location.replace("/financial/records")
            });
          })
          .catch(error => {
            console.log(error);
            const alert = alertController.create({
              header: this.$t("Error_registering"),
              buttons: ['OK']
            });
            alert.then(_alert => {
              _alert.present();
            });
          });
    }

  }
};
</script>
    
<style scoped>
#arquivoInput {
  font-size: 12px;
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  max-height: max-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.country{
  height: max-content;
  max-width: 450px;
}

.listaPaíses{
  height: 250px;
}
</style>