import storageService from "../services/storageService";
import { i18n } from "@/i18n";

const languageService = {
    locale: storageService.get('locale') || i18n.global.locale,
    set(locale) {
        this.locale = locale;
        storageService.set('locale', locale);
        i18n.global.locale = locale;
    },
    get() {
        return this.locale;
    },
    list() {
        return {
            en: 'English',
            af: 'Afrikaans',
            am: 'አማርኛ (Amarəñña)',
            ar: 'العربية (Al-ʿarabiyyah)',
            az: 'Azərbaycanca',
            be: 'Беларуская (Belaruskaya)',
            bg: 'Български (Bǎlgarski)',
            bn: 'বাংলা (Bangla)',
            bs: 'Bosanski',
            ca: 'Català',
            // ceb: 'Cebuano',
            // co: 'Corsu',
            cs: 'Čeština',
            // cy: 'Cymraeg',
            da: 'Dansk',
            de: 'Deutsch',
            // el: 'Ελληνικά (Elliniká)',
            // eo: 'Esperanto',
            es: 'Español',
            et: 'Eesti',
            eu: 'Euskara',
            // fa: 'فارسی (Fārsi)',
            fi: 'Suomi',
            fr: 'Français',
            // fy: 'Frysk',
            ga: 'Gaeilge',
            // gd: 'Gàidhlig',
            gl: 'Galego',
            // gu: 'ગુજરાતી (Gujarātī)',
            // ha: 'هَوُسَ (Hausa)',
            // haw: 'Hawaiʻi',
            // he: 'עִבְרִית (Ivrit)',
            hi: 'हिन्दी (Hindi)',
            // hmn: 'Hmoob',
            hr: 'Hrvatski',
            // ht: 'Kreyòl Ayisyen',
            // hu: 'Magyar',
            hy: 'Հայերեն (Hayeren)',
            id: 'Bahasa Indonesia',
            // ig: 'Igbo',
            is: 'Íslenska',
            it: 'Italiano',
            ja: '日本語 (Nihongo)',
            // jv: 'Basa Jawa',
            ka: 'ქართული (Kartuli)',
            kk: 'Қазақ тілі (Qazaq tili)',
            km: 'ភាសាខ្មែរ (Phéasa Khmêr)',
            kn: 'ಕನ್ನಡ (Kannada)',
            ko: '한국어 (Hangugeo)',
            // ku: 'Kurdî',
            ky: 'Кыргызча (Kyrgyzcha)',
            // la: 'Latina',
            // lb: 'Lëtzebuergesch',
            lo: 'ລາວ (Lao)',
            lt: 'Lietuvių',
            lv: 'Latviešu',
            mg: 'Malagasy',
            // mi: 'Māori',
            mk: 'Македонски (Makedonski)',
            // ml: 'മലയാളം (Malayalam)',
            // mn: 'Монгол (Mongol)',
            // mr: 'मराठी (Marāṭhī)',
            // ms: 'Bahasa Melayu',
            // mt: 'Malti',
            // my: 'ဗမာ (Myanma)',
            // ne: 'नेपाली (Nepālī)',
            nl: 'Nederlands',
            // no: 'Norsk',
            // ny: 'ChiCheŵa',
            // or: 'ଓଡ଼ିଆ (Odia)',
            // pa: 'ਪੰਜਾਬੀ (Panjābī)',
            // pl: 'Polski',
            // ps: 'پښتو (Paštō)',
            pt: 'Português',
            // ro: 'Română',
            ru: 'Русский (Russkiy)',
            // rw: 'Kinyarwanda',
            // sd: 'سنڌي (Sindhi)',
            // si: 'සිංහල (Sinhala)',
            // sk: 'Slovenčina',
            // sl: 'Slovenščina',
            // sm: 'Gagana Sāmoa',
            // sn: 'Chishona',
            // so: 'Soomaali',
            sq: 'Shqip',
            // sr: 'Српски (Srpski)',
            // st: 'Sesotho',
            // su: 'Basa Sunda',
            // sv: 'Svenska',
            // sw: 'Kiswahili',
            // ta: 'தமிழ் (Tamiḻ)',
            // te: 'తెలుగు (Telugu)',
            // tg: 'Тоҷикӣ (Tojikī)',
            // th: 'ไทย (Thai)',
            // tk: 'Türkmençe',
            // tl: 'Tagalog',
            tr: 'Türkçe',
            // tt: 'Татарча (Tatarça)',
            // ug: 'ئۇيغۇرچە (Uyghurche)',
            // uk: "Українська (Ukrayins'ka)",
            // ur: 'اردو (Urdu)',
            // uz: 'Oʻzbekcha',
            vi: 'Tiếng Việt',
            // xh: 'IsiXhosa',
            // yi: 'ייִדיש (Yidiš)',
            // yo: 'Yorùbá',
            zh: '中文 (Zhōngwén)',
            // zu: 'IsiZulu'
        };
    }
}

export {
    languageService
};