<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button
                            :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Aquaculture") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Batch") }}</ion-label>
                        <ion-input v-model="lote" type="number"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarLote">{{ $t("Send") }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Fishing") }}</ion-label>
                        <ion-input v-model="pescaria" @input="pescaria = formatUn($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarPescaria">{{ $t("Send") }}</ion-button>
                    <br>
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Specie") }}</ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="especie">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="especie in especies" :key="especie.id">
                                    <ion-select-option :value=especie.nome>{{ especie.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Unit") }}</ion-label>
                        <ion-input v-model="qntd_especie" @input="qntd_especie = formatUn($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarEspecie">{{ $t("Send") }}</ion-button>
                    <br>
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("transfer_to") }}</ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="transferir">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="lago in lagos" :key="lago.id">
                                    <ion-select-option :value=lago.nome>{{ lago.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Unit") }}</ion-label>
                        <ion-input v-model="qntd_Transferir"
                            @input="qntd_Transferir = formatUn($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarLago">{{ $t("Send") }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Mortality_entry") }}</ion-label>
                        <ion-input v-model="mortalidade" @input="mortalidade = formatUn($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button size="large" id="causa_modal" expand="block">{{ $t("Send") }}</ion-button>
                    <ion-modal ref="Doismodal" trigger="causa_modal" @ionModalDidPresent="onModalDidPresent">
                        <ion-content>
                            <ion-toolbar>
                                <ion-buttons slot="end">
                                    <ion-button color="light" @click="dismiss()">{{ $t("Close") }}</ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                            <ion-content class="ion-padding">
                                <div class="main">
                                    <div>
                                        <ion-item lines="none">
                                            <ion-label style="margin: 0px;">{{ $t("The_cause") }}</ion-label>
                                        </ion-item>
                                        <ion-item style="margin-bottom: 20px;" fill="outline" lines="none">
                                            <div class="rechargeDiv">
                                                <ion-radio-group v-model="causa">
                                                    <ion-item lines="full">
                                                        <ion-label>{{ $t("Human_or_machine_failure") }}</ion-label>
                                                        <ion-radio value="Human or machine failure"></ion-radio>
                                                    </ion-item>
                                                    <ion-item lines="full">
                                                        <ion-label>{{ $t("Natural_death") }}</ion-label>
                                                        <ion-radio value="Natural death"></ion-radio>
                                                    </ion-item>
                                                    <ion-item lines="full">
                                                        <ion-label>{{ $t("Disease") }}</ion-label>
                                                        <ion-radio value="Disease"></ion-radio>
                                                    </ion-item>
                                                </ion-radio-group>

                                                <IonItem v-if="causa == 'Disease'">
                                                    <ion-label position="floating">{{ $t("Disease") }}</ion-label>
                                                    <ion-select position="floating" aria-label="Fruit" class="select"
                                                        v-model="doenca">
                                                        <ion-content class="ion-padding" id="content-lista">
                                                            <ion-list v-for="doenca in doencas" :key="doenca.id">
                                                                <ion-select-option :value='doenca.nome'>{{ doenca.nome
                                                                }}</ion-select-option>
                                                            </ion-list>
                                                        </ion-content>
                                                    </ion-select>
                                                </IonItem>
                                            </div>
                                        </ion-item>

                                        <ion-button size="large" expand="block" @click="cadastrarMortalidade"
                                            style="margin-bottom: 100px;">{{ $t("Send") }}</ion-button>
                                    </div>
                                </div>
                            </ion-content>

                        </ion-content>
                    </ion-modal>
                    <br>
                    <IonItem fill="outline" v-if=" especies">
                        <ion-label position="floating">{{ $t("Specie") }}</ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="especie_amostragem">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="especie in especies" :key="especie.id">
                                    <ion-select-option :value=especie.id disabled>{{ especie.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Average_Weight") }}</ion-label>
                        <ion-input v-model="pesoMedio" @input="pesoMedio = formatG($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button  size="large" expand="block" @click="cadastrarPesoMedio">{{
                        $t("Send") }}</ion-button>
                    <ion-button size="large" expand="block" id="open_amostragem_modal">{{
                        $t("Sampling") }}</ion-button>
                    <ion-modal ref="modal" trigger="open_amostragem_modal" @ionModalDidPresent="onModalDidPresent">
                        <ion-content>
                            <ion-toolbar>
                                <ion-buttons slot="end">
                                    <ion-button color="light" @click="dismiss()">{{ $t("Close") }}</ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                            <ion-content class="ion-padding">
                                <div class="main">
                                    <div class="content-items">
                                        <IonItem fill="outline" v-if="this.especiePM">
                                            <ion-label position="floating">{{ $t("Specie") }}</ion-label>
                                            <ion-select position="floating" aria-label="Fruit" class="select"
                                                v-model="especie_amostragem">
                                                <ion-content class="ion-padding" id="content-lista">
                                                    <ion-list v-for="especie in especies" :key="especie.id">
                                                        <ion-select-option :value=especie.nome>{{ especie.nome
                                                        }}</ion-select-option>
                                                    </ion-list>
                                                </ion-content>
                                            </ion-select>
                                        </IonItem>

                                        <div class="pesos" v-for="peso of pesos" :key="peso.id">
                                            <ion-item fill="outline">
                                                <ion-label position="floating">{{ $t("Weight") }}</ion-label>
                                                <ion-input v-model="peso.peso"
                                                    @input="peso.peso = formatG($event.target.value)"
                                                    @ionChange="calcularTotal()"></ion-input>
                                            </ion-item>
                                        </div>
                                        <div class="botoes">
                                            <button class="addProduct" slot="start" @click="deleteInput()"><ion-icon
                                                    class="plusIcon" :ios="removeOutline" :md="removeSharp"
                                                    @click="exibirOpcoes"></ion-icon>
                                            </button>
                                            <button class="addProduct" slot="start" @click="addInput()"><ion-icon
                                                    class="plusIcon" :ios="addOutline" :md="addSharp"
                                                    @click="exibirOpcoes"></ion-icon>
                                            </button>
                                        </div>
                                        <ion-item fill="outline">
                                            <ion-label position="floating">{{ $t("Average_Weight") }}</ion-label>
                                            <ion-input v-model="pesoMedio_amostragem" readonly></ion-input>
                                        </ion-item>
                                        <ion-button size="large" expand="block" @click="cadastrarAmostragem"
                                            style="margin-bottom: 100px;">{{ $t("Send") }}</ion-button>
                                    </div>
                                </div>
                            </ion-content>

                        </ion-content>
                    </ion-modal>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Full_update") }}</ion-label>
                        <ion-input v-model="atualizacaoTotal"
                            @input="atualizacaoTotal = formatUn($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button size="large" id="2_causa_modal" expand="block">{{ $t("Send") }}</ion-button>
                    <ion-modal ref="Tresmodal" trigger="2_causa_modal" @ionModalDidPresent="onModalDidPresent">
                        <ion-content>
                            <ion-toolbar>
                                <ion-buttons slot="end">
                                    <ion-button color="light" @click="dismiss()">{{ $t("Close") }}</ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                            <ion-content class="ion-padding">
                                <div class="main">
                                    <div>
                                        <ion-item lines="none">
                                            <ion-label style="margin: 0px;">{{ $t("The cause") }}</ion-label>
                                        </ion-item>
                                        <ion-item style="margin-bottom: 20px;" fill="outline" lines="none">
                                            <div class="rechargeDiv">
                                                <ion-radio-group v-model="causa">
                                                    <ion-item lines="full">
                                                        <ion-label>{{ $t("Human_or_machine_failure") }}</ion-label>
                                                        <ion-radio value="Human or machine failure"></ion-radio>
                                                    </ion-item>
                                                    <ion-item lines="full">
                                                        <ion-label>{{ $t("Natural_death") }}</ion-label>
                                                        <ion-radio value="Natural death"></ion-radio>
                                                    </ion-item>
                                                    <ion-item lines="full">
                                                        <ion-label>{{ $t("Recount") }}</ion-label>
                                                        <ion-radio value="Recount"></ion-radio>
                                                    </ion-item>
                                                    <ion-item lines="full">
                                                        <ion-label>{{ $t("Disease") }}</ion-label>
                                                        <ion-radio value="Disease"></ion-radio>
                                                    </ion-item>
                                                </ion-radio-group>

                                                <IonItem v-if="causa == 'Disease'">
                                                    <ion-label position="floating">{{ $t("Disease") }}</ion-label>
                                                    <ion-select position="floating" aria-label="Fruit" class="select"
                                                        v-model="doenca">
                                                        <ion-content class="ion-padding" id="content-lista">
                                                            <ion-list v-for="doenca in doencas" :key="doenca.id">
                                                                <ion-select-option :value='doenca.nome'>{{ doenca.nome
                                                                }}</ion-select-option>
                                                            </ion-list>
                                                        </ion-content>
                                                    </ion-select>
                                                </IonItem>
                                            </div>
                                        </ion-item>
                                        <ion-item style="margin-bottom: 20px;" fill="outline">
                                            <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date")
                                            }}</ion-label>
                                            <ion-input v-model="dataAtt" type="date"></ion-input>
                                        </ion-item>
                                        <ion-button size="large" expand="block" @click="cadastrarAttTotal"
                                            style="margin-bottom: 100px;">{{ $t("Send") }}</ion-button>
                                    </div>
                                </div>
                            </ion-content>

                        </ion-content>
                    </ion-modal>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Instant_death") }}</ion-label>
                        <ion-input v-model="morteInstantanea"
                            @input="morteInstantanea = formatPorcent($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date")
                        }}</ion-label>
                        <ion-input v-model="dataMI" type="date"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarMorteInstantanea">{{ $t("Send") }}</ion-button>
                    <br>
                </div>

            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonRadio, IonRadioGroup, IonIcon, IonBackButton, IonModal, IonSelect, IonList, IonSelectOption, IonButton, IonLabel, alertController, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"
import { addOutline, addSharp, removeOutline, removeSharp } from 'ionicons/icons';

export default {
    components: {
        IonRadio, IonRadioGroup, IonIcon, IonBackButton, IonModal, IonSelect, IonList, IonSelectOption, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            pesos: [
                {
                    peso: ""
                }
            ]
            ,
            especie_amostragem: '',
            pesoMedio_amostragem: '',
            qntd_Transferir: '',
            qntd_especie: '',
            atualizacaoTotal: '',
            morteInstantanea: '',
            pesoMedio: '',
            mortalidade: '',
            transferir: '',
            lote: '',
            pescaria: '',
            especie: '',
            especies: '',
            lagos: '',
            causa: '',
            addOutline,
            addSharp,
            removeOutline,
            removeSharp,
            doenca: '',
            doencas: '',
            dataAtt: '',
            dataMI: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.obterDataAtual()
        this.fetchEspecies()
        this.fetchBatch()
        this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
        this.fetchLagos(this.idEmpresa)
        this.fetchDoencas()
    },
    methods: {
        formatDataForDatabase(date) {
            const parts = date.split('-');
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            return formattedDate;
        },
        async fetchDoencas() {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.apiUrl}/configs/diseases`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log(response)
            const items = response.data.items.map((item) => {
                return {
                    nome: item.nome,
                    id: item.id,
                    empresa: item.empresa
                };
            });
            this.itens = items;
            const idEmpresa = localStorage.getItem('idEmpresaCentro')
            this.doencas = this.itens.filter(item => item.empresa == parseInt(idEmpresa));
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        calcularTotal() {
            let total = 0;
            let count = 0;
            for (const peso of this.pesos) {
                const numericPeso = parseFloat(peso.peso);
                if (!isNaN(numericPeso)) {
                    total += numericPeso;
                    count++;
                }
            }
            console.log("Total:", total);
            const average = count > 0 ? total / count : 0;
            this.pesoMedio_amostragem = average.toFixed(2) + " g";
        },
        formatG(value) {
            return value.replace(/[^\d.,]/g, '') + " g";
        },
        formatPorcent(value) {
            return value.replace(/[^\d.,]/g, '') + " %";
        },
        formatUn(value) {
            return value.replace(/[^\d.,]/g, '') + " Un";
        },
        addInput() {
            this.pesos.push({
                peso: ""
            });
        },
        async deleteInput(peso) {
            if (this.pesos.length > 1) {
                const index = this.pesos.indexOf(peso);
                this.pesos.splice(index, 1);
            } else {
                const alert = await alertController.create({
                    header: this.$t("Invalid_action"),
                    message: this.$t("You_can_not_delete_all"),
                    buttons: ['OK']
                });
                await alert.present();
            }
        },
        dismiss() {
            this.$refs.modal.$el.dismiss();
            this.$refs.Doismodal.$el.dismiss();
            this.$refs.Tresmodal.$el.dismiss();
        },
        async fetchLagos() {
            const token = localStorage.getItem('token');
            const data = {
                idEmpresa: this.idEmpresa
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getLagosByEmpresa`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                console.log(response)
                const lagos = response.data.items.map((lago) => {
                    return {
                        nome: lago.nome
                    };
                });
                this.lagos = lagos;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchEspecies() {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);

            try {
                const response = await axios.get(`${config.apiUrl}/configs/diseases/${userId}/species`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.data = response.data.items;
                this.especies = this.data.filter(item => item.empresa == idEmpresa);
                console.log(response)
            } catch (error) {
                console.log(error);
            }
        },
        async fetchBatch() {
            const pondId = this.$route.params.id
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/getLoteByPondId/${pondId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.especie_amostragem = response.data.lotes.especie;
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEspecie(event) {
            this.valueEspecie = event.target.value
        },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        cadastrarCausa() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.causa);

                    if (objetoEncontrado.length > 0 && this.causa) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.causa = ''
                                    const alert = alertController.create({
                                        header: this.$t("Success"),
                                        message: this.$t("successfully_registered!"),
                                        buttons: ['OK']
                                    });
                                    alert.then(_alert => {
                                        _alert.present();
                                    });
                                    this.dismiss()
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data = {};

                                    data.id_lago = this.$route.params.id
                                    data.causa = this.doenca ? this.doenca : this.causa
                                    data.data_registro = this.dataAtual

                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);
                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });
                                            this.dismiss()
                                            this.causa = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data = {};

                        data.id_lago = this.$route.params.id
                        data.causa = this.doenca ? this.doenca : this.causa
                        data.data_registro = this.dataAtual
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);
                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });
                                this.dismiss()
                                this.causa = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarLote() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.lote);

                    if (objetoEncontrado.length > 0 && this.lote) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.lote = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data = {};

                                    if (this.lote) {
                                        data.id_lago = this.$route.params.id
                                        data.lote = this.lote
                                        data.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.lote = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data = {};

                        if (this.lote) {
                            data.id_lago = this.$route.params.id
                            data.lote = this.lote
                            data.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.lote = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarPescaria() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.pescaria);

                    if (objetoEncontrado.length > 0 && this.pescaria) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.pescaria = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data2 = {};
                                    if (this.pescaria) {
                                        data2.id_lago = this.$route.params.id
                                        data2.pescaria = this.pescaria;
                                        data2.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data2)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data2, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.pescaria = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data2 = {};
                        if (this.pescaria) {
                            data2.id_lago = this.$route.params.id
                            data2.pescaria = this.pescaria;
                            data2.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data2)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data2, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.pescaria = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarEspecie() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.especie);

                    if (objetoEncontrado.length > 0 && (this.qntd_especie && this.especie)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.qntd_especie = ''
                                    this.especie = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data3 = {};
                                    if (this.qntd_especie && this.especie) {
                                        data3.id_lago = this.$route.params.id
                                        data3.qntd_especie = this.qntd_especie;
                                        data3.especie = this.especie
                                        data3.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data3)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data3, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.qntd_especie = ''
                                            this.especie = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data3 = {};
                        if (this.qntd_especie && this.especie) {
                            data3.id_lago = this.$route.params.id
                            data3.qntd_especie = this.qntd_especie;
                            data3.especie = this.especie
                            data3.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data3)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data3, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.qntd_especie = ''
                                this.especie = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarLago() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.transferir);

                    if (objetoEncontrado.length > 0 && (this.transferir && this.qntd_Transferir)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.transferir = ''
                                    this.qntd_Transferir = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data4 = {};
                                    if (this.transferir && this.qntd_Transferir) {
                                        data4.id_lago = this.$route.params.id
                                        data4.transferir = this.transferir;
                                        data4.qntd_Transferir = this.qntd_Transferir
                                        data4.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data4)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data4, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.transferir = ''
                                            this.qntd_Transferir = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data4 = {};
                        if (this.transferir && this.qntd_Transferir) {
                            data4.id_lago = this.$route.params.id
                            data4.transferir = this.transferir;
                            data4.qntd_Transferir = this.qntd_Transferir
                            data4.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data4)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data4, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.transferir = ''
                                this.qntd_Transferir = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarMortalidade() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.mortalidade);

                    if (objetoEncontrado.length > 0 && this.mortalidade) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {

                                    if (this.causa == 'Disease' && !this.doenca) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    const data5 = {};
                                    if (this.mortalidade && this.causa) {
                                        data5.id_lago = this.$route.params.id
                                        data5.mortalidade = this.mortalidade;
                                        data5.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data5)
                                    this.cadastrarCausa()
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data5, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);
                                            this.mortalidade = ''
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {

                        if (this.causa == 'Disease' && !this.doenca) {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        const data5 = {};
                        if (this.mortalidade && this.causa) {
                            data5.id_lago = this.$route.params.id
                            data5.mortalidade = this.mortalidade;
                            data5.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data5)
                        this.cadastrarCausa()
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data5, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);
                                this.mortalidade = ''
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarPesoMedio() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAmostrasAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.especie == this.especie_amostragem);

                    if (objetoEncontrado.length > 0 && this.pesoMedio) {
                        const alert = alertController.create({
                            header: this.$t("A_record_of_this_specie"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.pesoMedio = ''
                                    this.especie_amostragem = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data6 = {};
                                    if (this.pesoMedio && this.especie_amostragem) {
                                        data6.especie_amostragem = this.especie_amostragem
                                        data6.id_lago = this.$route.params.id
                                        data6.pesoMedio = this.pesoMedio;
                                        data6.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data6)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data6, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.pesoMedio = ''
                                            this.especie_amostragem = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data6 = {};
                        if (this.pesoMedio && this.especie_amostragem) {
                            data6.especie_amostragem = this.especie_amostragem
                            data6.id_lago = this.$route.params.id
                            data6.pesoMedio = this.pesoMedio;
                            data6.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data6)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data6, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.pesoMedio = ''
                                this.especie_amostragem = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarAmostragem() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAmostrasAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.especie == this.especie_amostragem);

                    if (objetoEncontrado.length > 0 && (this.especie_amostragem && this.pesoMedio_amostragem)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_of_this_specie"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {

                                    this.especie_amostragem = ''
                                    for (var i = 0; i < this.pesos.length; i++) {
                                        var objeto = this.pesos[i];
                                        objeto.peso = ''
                                        this.deleteInput()
                                    }
                                    this.pesoMedio_amostragem = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data6 = {};
                                    if (this.pesoMedio_amostragem && this.especie_amostragem) {
                                        data6.especie_amostragem = this.especie_amostragem
                                        data6.id_lago = this.$route.params.id
                                        data6.pesoMedio = this.pesoMedio_amostragem;
                                        data6.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data6)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data6, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });


                                            this.especie_amostragem = ''
                                            for (var i = 0; i < this.pesos.length; i++) {
                                                var objeto = this.pesos[i];
                                                objeto.peso = ''
                                                this.deleteInput()
                                            }
                                            this.pesoMedio_amostragem = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data6 = {};
                        if (this.pesoMedio_amostragem && this.especie_amostragem) {
                            data6.especie_amostragem = this.especie_amostragem
                            data6.id_lago = this.$route.params.id
                            data6.pesoMedio = this.pesoMedio_amostragem;
                            data6.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data6)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data6, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });


                                this.especie_amostragem = ''
                                for (var i = 0; i < this.pesos.length; i++) {
                                    var objeto = this.pesos[i];
                                    objeto.peso = ''
                                    this.deleteInput()
                                }
                                this.pesoMedio_amostragem = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarAttTotal() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.atualizacaoTotal);

                    if (objetoEncontrado.length > 0 && (this.atualizacaoTotal)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    if (this.causa == 'Disease' && !this.doenca) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    const data8 = {};
                                    if (this.atualizacaoTotal && this.causa && this.dataAtt) {
                                        data8.id_lago = this.$route.params.id
                                        data8.atualizacaoTotal = this.atualizacaoTotal;
                                        data8.data_registro = this.dataAtual
                                        data8.dataAtt = this.formatDataForDatabase(this.dataAtt)
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data8)
                                    this.cadastrarCausa()
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data8, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            this.atualizacaoTotal = ''
                                            this.dataAtt = ''
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        if (this.causa == 'Disease' && !this.doenca) {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        const data8 = {};
                        if (this.atualizacaoTotal && this.causa && this.dataAtt) {
                            data8.id_lago = this.$route.params.id
                            data8.atualizacaoTotal = this.atualizacaoTotal;
                            data8.data_registro = this.dataAtual
                            data8.dataAtt = this.formatDataForDatabase(this.dataAtt)
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data8)
                        this.cadastrarCausa()
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data8, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);
                                this.atualizacaoTotal = ''
                                this.dataAtt = ''
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarMorteInstantanea() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAgricultura`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.morteInstantanea);

                    if (objetoEncontrado.length > 0 && (this.morteInstantanea)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.morteInstantanea = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data8 = {};
                                    if (this.morteInstantanea && this.dataMI) {
                                        data8.id_lago = this.$route.params.id
                                        data8.morteInstantanea = this.morteInstantanea;
                                        data8.data_registro = this.dataAtual
                                        data8.dataMI = this.formatDataForDatabase(this.dataMI)
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data8)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data8, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.morteInstantanea = ''
                                            this.dataMI = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data8 = {};
                        if (this.morteInstantanea && this.dataMI) {
                            data8.id_lago = this.$route.params.id
                            data8.morteInstantanea = this.morteInstantanea;
                            data8.data_registro = this.dataAtual
                            data8.dataMI = this.formatDataForDatabase(this.dataMI)
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data8)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAgricultura/create`, data8, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.morteInstantanea = ''
                                this.dataMI = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
    }
};
</script>
    
<style scoped>
.rechargeDiv {
    display: flex;
    flex-direction: column;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    width: 100%;
}

ion-button.commercialButton {
    margin-top: 10px;
    --border-radius: 150px !important;
    width: 35px;
    height: 35px;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 0px !important;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

#open_amostragem_modal {
    --background: transparent;
    border: #0049b1 2px solid;
    --color: #0049b1;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
}

.botoes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
}

.addProduct {
    width: 49.5%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    background: #0049b1;
    color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addProduct h2 {
    margin: 0px;
    font-size: 16px;
}

.plusIcon {
    font-size: 35px;
    color: white;

}

.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>