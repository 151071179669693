import router from "../router/index"

let authGuard =  () => {
    const user = localStorage.getItem('user');

    if (user) {
        return true
    }
    
    router.push( 'login' );
}

export {
    authGuard
}