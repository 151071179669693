<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button default-href="/users/users-groups"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ $t("Register") }} {{ $t("Users_Group") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding" >
        <div class="content-items">
          <IonItem fill="outline" v-if="this.master" style="margin-bottom: 20px;">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="empresaSelecionada">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="nomeInputgrupo"></ion-input>
          </ion-item>
          <ion-label class="ion-margin">{{ $t("Functionalities") }} <span style="color: red;">*</span></ion-label>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput22" class="groups" slot="start" @click="selecionarTodas2"></ion-checkbox>
                <ion-label>{{ $t("Users_Group") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="groups" id="funcionalidadeInput2" @click="todasSelecionadas2">
                  <ion-checkbox  :value="5" slot="start"></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Group") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="groups" id="funcionalidadeInput2" @click="todasSelecionadas2">
                  <ion-checkbox  :value="4" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Group") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="groups" id="funcionalidadeInput2" @click="todasSelecionadas2">
                  <ion-checkbox  :value="6" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Group") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput33" class="users" slot="start" @click="selecionarTodas3"></ion-checkbox>
                <ion-label>{{ $t("Users") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="users" id="funcionalidadeInput3" @click="todasSelecionadas3">
                  <ion-checkbox  :value="8" slot="start"></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Users") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="users" id="funcionalidadeInput3" @click="todasSelecionadas3">
                  <ion-checkbox  :value="7" slot="start"></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Users") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="users" id="funcionalidadeInput3" @click="todasSelecionadas3">
                  <ion-checkbox :value="9" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Users") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput44" class="species" slot="start" @click="selecionarTodas4"></ion-checkbox>
                <ion-label>{{ $t("Species") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="species" id="funcionalidadeInput4" @click="todasSelecionadas4">
                  <ion-checkbox  :value="14" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Species") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="species" id="funcionalidadeInput4" @click="todasSelecionadas4"> 
                  <ion-checkbox  :value="13" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Species") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="species" id="funcionalidadeInput4" @click="todasSelecionadas4">
                  <ion-checkbox  :value="15" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Species") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1616" class="batches" slot="start" @click="selecionarTodas15"></ion-checkbox>
                <ion-label>{{ $t("Batches") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="batches" id="funcionalidadeInput16" @click="todasSelecionadas15">
                  <ion-checkbox  :value="45" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Batches") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="batches" id="funcionalidadeInput16" @click="todasSelecionadas15"> 
                  <ion-checkbox  :value="46" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Batches") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="batches" id="funcionalidadeInput16" @click="todasSelecionadas15">
                  <ion-checkbox  :value="47" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Batches") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1717" class="reasonsForFasting" slot="start" @click="selecionarTodas16"></ion-checkbox>
                <ion-label>{{ $t("Reasons_For_Fasting") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="reasonsForFasting" id="funcionalidadeInput17" @click="todasSelecionadas16">
                  <ion-checkbox  :value="48" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Reasons_For_Fasting") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="reasonsForFasting" id="funcionalidadeInput17" @click="todasSelecionadas16"> 
                  <ion-checkbox  :value="49" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Reasons_For_Fasting") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="reasonsForFasting" id="funcionalidadeInput17" @click="todasSelecionadas16">
                  <ion-checkbox  :value="50" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Reasons_For_Fasting") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1818" class="manufactures" slot="start" @click="selecionarTodas17"></ion-checkbox>
                <ion-label>{{ $t("Manufacturers") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="manufactures" id="funcionalidadeInput18" @click="todasSelecionadas17">
                  <ion-checkbox  :value="51" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Manufacturer") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="manufactures" id="funcionalidadeInput18" @click="todasSelecionadas17"> 
                  <ion-checkbox  :value="52" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Manufacturer") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="manufactures" id="funcionalidadeInput18" @click="todasSelecionadas17">
                  <ion-checkbox  :value="53" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Manufacturer") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1919" class="clients" slot="start" @click="selecionarTodas18"></ion-checkbox>
                <ion-label>{{ $t("Clients") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="clients" id="funcionalidadeInput19" @click="todasSelecionadas18">
                  <ion-checkbox  :value="54" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Client") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="clients" id="funcionalidadeInput19" @click="todasSelecionadas18"> 
                  <ion-checkbox  :value="55" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Client") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="clients" id="funcionalidadeInput19" @click="todasSelecionadas18">
                  <ion-checkbox  :value="56" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Client") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput55" class="centros" slot="start" @click="selecionarTodas5"></ion-checkbox>
                <ion-label>{{ $t("Production_centers") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="centros" id="funcionalidadeInput5" @click="todasSelecionadas5">
                  <ion-checkbox  :value="11" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Production_Center") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="centros" id="funcionalidadeInput5" @click="todasSelecionadas5"> 
                  <ion-checkbox  :value="10" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Production_Center") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="centros" id="funcionalidadeInput5" @click="todasSelecionadas5">
                  <ion-checkbox  :value="12" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Production_Center") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput66" class="racoes" slot="start" @click="selecionarTodas6"></ion-checkbox>
                <ion-label>{{ $t("Feed") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="racoes" id="funcionalidadeInput6" @click="todasSelecionadas6">
                  <ion-checkbox  :value="17" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Feed") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="racoes" id="funcionalidadeInput6" @click="todasSelecionadas6"> 
                  <ion-checkbox  :value="16" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Feed") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="racoes" id="funcionalidadeInput6" @click="todasSelecionadas6">
                  <ion-checkbox  :value="18" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Feed") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput77" class="doencas" slot="start" @click="selecionarTodas7"></ion-checkbox>
                <ion-label>{{ $t("Diseases") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="doencas" id="funcionalidadeInput7" @click="todasSelecionadas7">
                  <ion-checkbox  :value="20" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Disease") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="doencas" id="funcionalidadeInput7" @click="todasSelecionadas7"> 
                  <ion-checkbox  :value="19" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Disease") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="doencas" id="funcionalidadeInput7" @click="todasSelecionadas7">
                  <ion-checkbox  :value="21" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Disease") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput88" class="armazem" slot="start" @click="selecionarTodas8"></ion-checkbox>
                <ion-label>{{ $t("Warehouses") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="armazem" id="funcionalidadeInput8" @click="todasSelecionadas8">
                  <ion-checkbox  :value="23" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Warehouse") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="armazem" id="funcionalidadeInput8" @click="todasSelecionadas8"> 
                  <ion-checkbox  :value="22" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Warehouse") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="armazem" id="funcionalidadeInput8" @click="todasSelecionadas8">
                  <ion-checkbox  :value="24" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Warehouse") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput99" class="compras" slot="start" @click="selecionarTodas9"></ion-checkbox>
                <ion-label>{{ $t("Shopping") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="compras" id="funcionalidadeInput9" @click="todasSelecionadas9">
                  <ion-checkbox  :value="26" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Purchase") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="compras" id="funcionalidadeInput9" @click="todasSelecionadas9"> 
                  <ion-checkbox  :value="25" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Purchase") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="compras" id="funcionalidadeInput9" @click="todasSelecionadas9">
                  <ion-checkbox  :value="27" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Purchase") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1010" class="registros" slot="start" @click="selecionarTodas10"></ion-checkbox>
                <ion-label>{{ $t("Records") }} {{ $t("Financial") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="registros" id="funcionalidadeInput10" @click="todasSelecionadas10">
                  <ion-checkbox  :value="29" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="registros" id="funcionalidadeInput10" @click="todasSelecionadas10"> 
                  <ion-checkbox  :value="28" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Record") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="registros" id="funcionalidadeInput10" @click="todasSelecionadas10">
                  <ion-checkbox  :value="30" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Record") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1111" class="itens" slot="start" @click="selecionarTodas11"></ion-checkbox>
                <ion-label>{{ $t("Item") }} {{ $t("Financial") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="itens" id="funcionalidadeInput11" @click="todasSelecionadas11">
                  <ion-checkbox  :value="32" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Item") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="itens" id="funcionalidadeInput11" @click="todasSelecionadas11"> 
                  <ion-checkbox  :value="31" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Item") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="itens" id="funcionalidadeInput11" @click="todasSelecionadas11">
                  <ion-checkbox  :value="33" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Item") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1414" class="atualizacoes" slot="start" @click="selecionarTodas14"></ion-checkbox>
                <ion-label>{{ $t("Updates") }} {{ $t("Financial") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="atualizacoes" id="funcionalidadeInput14" @click="todasSelecionadas14">
                  <ion-checkbox  :value="42" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Updates") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="atualizacoes" id="funcionalidadeInput14" @click="todasSelecionadas14"> 
                  <ion-checkbox  :value="43" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Updates") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="atualizacoes" id="funcionalidadeInput14" @click="todasSelecionadas14">
                  <ion-checkbox  :value="44" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Updates") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1212" class="vendas" slot="start" @click="selecionarTodas12"></ion-checkbox>
                <ion-label>{{ $t("Sales") }} {{ $t("Financial") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="vendas" id="funcionalidadeInput12" @click="todasSelecionadas12">
                  <ion-checkbox  :value="35" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("Sales") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="vendas" id="funcionalidadeInput12" @click="todasSelecionadas12"> 
                  <ion-checkbox  :value="34" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("Sales") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="vendas" id="funcionalidadeInput12" @click="todasSelecionadas12">
                  <ion-checkbox  :value="36" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("Sales") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox id="funcionalidadeInput1313" class="infraestrutura" slot="start" @click="selecionarTodas13"></ion-checkbox>
                <ion-label>{{ $t("infrastructures") }}</ion-label>
              </ion-item>
              <div class="left-padding">
                <ion-item lines="none" class="infraestrutura" id="funcionalidadeInput13" @click="todasSelecionadas13">
                  <ion-checkbox  :value="37" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Register") }} {{ $t("infrastructures") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="infraestrutura" id="funcionalidadeInput13" @click="todasSelecionadas13"> 
                  <ion-checkbox  :value="38" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Edit") }} {{ $t("infrastructures") }}</ion-label>
                </ion-item>
                <ion-item lines="none" class="infraestrutura" id="funcionalidadeInput13" @click="todasSelecionadas13">
                  <ion-checkbox  :value="39" slot="start" ></ion-checkbox>
                  <ion-label>{{ $t("Delete") }} {{ $t("infrastructures") }}</ion-label>
                </ion-item>
              </div>
              <ion-item lines="none">
                <ion-checkbox :value="40" id="funcionalidadeInput14" slot="start"></ion-checkbox>
                <ion-label>{{ $t("License_Information") }}</ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-checkbox :value="41" id="funcionalidadeInput15" slot="start"></ion-checkbox>
                <ion-label>{{ $t("Importation/Exportation") }}</ion-label>
              </ion-item>
            <br>
          <ion-button size="large" expand="block" class="ion-margin" @click="cadastrarGrupoDeUsuario">{{ $t("Register") }}</ion-button>
        </div>
          
      </ion-content>
        
    </ion-page>
  </ion-page>
</template>

<script>
import { IonSelect, IonList, IonSelectOption,IonBackButton,alertController, IonButton, IonCheckbox, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"

export default {
  components: {
    IonSelect, IonList, IonSelectOption,IonBackButton,IonButton, IonCheckbox, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
      return {
        empresas: "",
            master: '',
            empresaSelecionada:''
      }
    },
    mounted() {
      this.getPermissoes();
    this.fetchEmpresas()
    this.fetchUser()
  },
  methods: {
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(5)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
            } catch (error) {
                console.log(error);
            }
        },
      async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresa = parseInt(localStorage.getItem('idEmpresa'));
        this.master = response.data.master
      } catch (error) {
        console.log(error);
      }
    },
todasSelecionadas2() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput2 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput22');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas3() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput3 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput33');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas4() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput4 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput44');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas5() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput5 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput55');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas6() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput6 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput66');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas7() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput7 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput77');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas8() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput8 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput88');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas9() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput9 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput99');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas10() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput10 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1010');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas11() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput11 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1111');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas12() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput12 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1212');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas13() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput13 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1313');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas14() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput14 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1414');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas15() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput16 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1616');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas16() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput17 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1717');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas17() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput18 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1818');

  checkboxTodos.checked = todosSelecionados;
},
todasSelecionadas18() {
  const checkboxes = document.querySelectorAll('#funcionalidadeInput19 ion-checkbox');
  let todosSelecionados = true;

  checkboxes.forEach((checkbox) => {
    if (!checkbox.checked) {
      todosSelecionados = false;
    }
  });

  const checkboxTodos = document.querySelector('#funcionalidadeInput1919');

  checkboxTodos.checked = todosSelecionados;
},
    selecionarTodas2(event) {
      const checkboxes = document.querySelectorAll('.groups ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas3(event) {
      const checkboxes = document.querySelectorAll('.users ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas4(event) {
      const checkboxes = document.querySelectorAll('.species ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas5(event) {
      const checkboxes = document.querySelectorAll('.centros ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas6(event) {
      const checkboxes = document.querySelectorAll('.racoes ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas7(event) {
      const checkboxes = document.querySelectorAll('.doencas ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas8(event) {
      const checkboxes = document.querySelectorAll('.armazem ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas9(event) {
      const checkboxes = document.querySelectorAll('.compras ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas10(event) {
      const checkboxes = document.querySelectorAll('.registros ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas11(event) {
      const checkboxes = document.querySelectorAll('.itens ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas12(event) {
      const checkboxes = document.querySelectorAll('.vendas ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas13(event) {
      const checkboxes = document.querySelectorAll('.infraestrutura ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas14(event) {
      const checkboxes = document.querySelectorAll('.atualizacoes ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas15(event) {
      const checkboxes = document.querySelectorAll('.batches ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas16(event) {
      const checkboxes = document.querySelectorAll('.reasonsForFasting ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas17(event) {
      const checkboxes = document.querySelectorAll('.manufacturers ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    selecionarTodas18(event) {
      const checkboxes = document.querySelectorAll('.clients ion-checkbox');
      const selecionado = event.target.checked;
      checkboxes.forEach((checkbox) => {
        checkbox.checked = !selecionado;
      });
    },
    async cadastrarGrupoDeUsuario() {

      const nome = document.getElementById("nomeInputgrupo").value;
      const checkboxes = document.querySelectorAll('ion-checkbox');
      const funcionalidades = [];

      checkboxes.forEach(checkbox => {
        if (checkbox.checked) {
          const value = parseInt(checkbox.value);
          if (!isNaN(value)) {
            funcionalidades.push(value);
          }
        }
      });

      if (!nome){
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
    
      if (funcionalidades.length == 0){
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Functionality"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      const token = localStorage.getItem('token');
      const data = {
        nome: nome,
        funcionalidades: funcionalidades,
        empresa: this.empresaSelecionada ? this.empresaSelecionada: this.empresa
      }
      console.log(funcionalidades)
      await axios.post(`${config.apiUrl}/users/groups/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          console.log(response.data.id);

          const alert = alertController.create({
            header: this.$t("successfully_registered!")
          });
          alert.then(_alert => {
            _alert.present();

            window.location.replace("/users/users-groups")
          });
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_registering"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });
    }

  },

};
</script>

<style scoped>
ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-padding{
  padding-left: 30px;
  margin-bottom: 20px;
}
</style>