<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Product") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Product") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in produtos" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Register") }} {{ $t("Product") }}</ion-label>
                        <ion-input v-model="nomeProduto"
                            ></ion-input>
                    </ion-item>
                    <IonItem fill="outline" >
                        <ion-label position="floating">{{ $t("Warehouse") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="armazem" @ionChange="selecionarArmazem($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in armazens" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Amount") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="quantidade" type="number"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarProduto">{{ $t("Confirm") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonSelect, IonList, IonSelectOption, alertController, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    components: {
        IonBackButton, IonSelect, IonList, IonSelectOption, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            produtos: '',
            produto: '',
            quantidade: '',
            nomeProduto: '',
            armazem: '',
            armazens:''
        }
    },
    mounted() {
        this.getPermissoes();
                this.nomeEmpresa = parseInt(localStorage.getItem('idEmpresa'));
                this.fetchProdutos(this.nomeEmpresa)
        this.obterDataAtual()
        this.buscarArmazens()
    },
    methods: {
        async buscarArmazens() {
            const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${config.apiUrl}/configs/warehouse?search=${this.termoPesquisa}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

            const empresaId = parseInt(localStorage.getItem('idEmpresa'), 10);

            const items = response.data.items.filter((item) => {
              const empresa = parseInt(item.empresa, 10);
              return empresa == empresaId;
            }).map((item) => {
              return {
                nome: item.nome,
                id: item.id
              };
            });

            this.armazens = items;
        } catch (error) {
          return console.error("Erro ao buscar nomes ", error);
        }
      },
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        async fetchProdutos() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/produtos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.produtos = response.data.items;
            } catch (error) {
                console.log(error);
            }
        },
        cadastrarProduto() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getProduto`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && ((this.produto || this.nomeProduto) && this.quantidade)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                            this.produto = ''
                                            this.quantidade = ''
                                            this.nomeProduto = ''
                                            this.armazem = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    if (!this.produto && !this.nomeProduto) {
                                        const alert = alertController.create({
                                            header: 'Error',
                                            message: 'Select an product',
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    if (!this.armazem) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    if (!this.quantidade) {
                                        const alert = alertController.create({
                                            header: 'Error',
                                            message: 'Enter amount',
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    if (this.nomeProduto !== "") {
                                        const data2 = {
                                            nome: this.nomeProduto,
                                        }

                                        axios.post(`${config.apiUrl}/produtos/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                            .then(response => {
                                                console.log(response.data.id);

                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }

                                    const data = {};

                                    data.id_lago = this.$route.params.id
                                    data.produto = this.nomeProduto ? this.nomeProduto : this.produto;
                                    data.quantidade = this.quantidade;
                                    data.data_registro = this.dataAtual
                                    data.armazem = this.armazem

                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarProduto/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.produto = ''
                                            this.quantidade = ''
                                            this.nomeProduto = ''
                                            this.armazem = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        if (!this.produto && !this.nomeProduto) {
                                        const alert = alertController.create({
                                            header: 'Error',
                                            message: 'Select an product',
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                        if (!this.quantidade) {
                            const alert = alertController.create({
                                header: 'Error',
                                message: 'Enter amount',
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }

                        if (!this.armazem) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                        if (this.nomeProduto !== "") {
                                        const data2 = {
                                            nome: this.nomeProduto,
                                        }

                                        axios.post(`${config.apiUrl}/produtos/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                            .then(response => {
                                                console.log(response.data.id);

                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }

                        const data = {};

                        data.id_lago = this.$route.params.id
                        data.produto = this.nomeProduto ? this.nomeProduto : this.produto;
                        data.quantidade = this.quantidade;
                        data.data_registro = this.dataAtual
                        data.armazem = this.armazem

                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarProduto/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.produto = ''
                                this.quantidade = ''
                                this.nomeProduto = ''
                                this.armazem = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        }
    }
};
</script>
    
<style scoped>
ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>