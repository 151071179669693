<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Work") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <ion-item fill="outline" lines="none">
                        <div class="rechargeDiv">
                            <ion-item lines="full">
                                <ion-label position="floating">{{ $t("Cleaning") }}</ion-label>
                                <ion-input type="number" v-model="limpeza"></ion-input>
                            </ion-item>
                            <ion-radio-group v-model="limpezaUnits">
                                <ion-item lines="full">
                                    <ion-label>{{ $t("Hours") }}</ion-label>
                                    <ion-radio value="Horas"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>€</ion-label>
                                    <ion-radio value="€"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </div>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarLimpeza">{{ $t("Confirm") }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Excavation") }}</ion-label>
                        <ion-input v-model="escavacao" @input="escavacao = formatM3($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarEscavacao">{{ $t("Confirm") }}</ion-button>
                    <br>
                    <ion-item fill="outline" lines="none">
                        <div class="rechargeDiv">
                            <ion-item lines="full">
                                <ion-label position="floating">{{ $t("Specific_Labor") }}</ion-label>
                                <ion-input type="number" v-model="maoObra"></ion-input>
                            </ion-item>
                            <ion-radio-group v-model="maoObraUnits">
                                <ion-item lines="full">
                                    <ion-label>{{ $t("Hours") }}</ion-label>
                                    <ion-radio value="Horas"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>€</ion-label>
                                    <ion-radio value="€"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </div>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarMaoObra">{{ $t("Confirm") }}</ion-button>
                    <br>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonRadio, IonRadioGroup, IonButton, IonLabel, alertController, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    components: {
        IonBackButton, IonRadio, IonRadioGroup, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            escavacao: '',
            limpeza: '',
            limpezaUnits: [],
            maoObra: '',
            maoObraUnits: []
        }
    },
    mounted() {
        this.getPermissoes();
        this.obterDataAtual()
    },
    methods: {
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        formatM3(value) {
            return value.replace(/[^\d.,]/g, '') + " m³";
        },
        cadastrarLimpeza() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getTrabalho`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.limpeza);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && (this.limpeza && this.limpezaUnits.length !== 0)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.limpezaUnits = []
                                    this.limpeza = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data = {};
                                    if (this.limpeza && this.limpezaUnits.length !== 0) {
                                        data.id_lago = this.$route.params.id
                                        data.tipoLimpeza = this.limpezaUnits;
                                        data.limpeza = this.limpeza
                                        data.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarTrabalho/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.limpezaUnits = []
                                            this.limpeza = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data = {};
                        if (this.limpeza && this.limpezaUnits.length !== 0) {
                            data.id_lago = this.$route.params.id
                            data.tipoLimpeza = this.limpezaUnits;
                            data.limpeza = this.limpeza
                            data.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarTrabalho/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.limpezaUnits = []
                                this.limpeza = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarEscavacao() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getTrabalho`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.escavacao);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.escavacao) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.escavacao = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data2 = {};
                                    if (this.escavacao) {
                                        data2.id_lago = this.$route.params.id
                                        data2.escavacao = this.escavacao;
                                        data2.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data2)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarTrabalho/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.escavacao = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data2 = {};
                        if (this.escavacao) {
                            data2.id_lago = this.$route.params.id
                            data2.escavacao = this.escavacao;
                            data2.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data2)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarTrabalho/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.escavacao = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarMaoObra() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getTrabalho`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.maoObra);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && (this.maoObra && this.maoObraUnits.length !== 0)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                            this.maoObraUnits = []
                                            this.maoObra = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data3 = {};
                                    if (this.maoObra && this.maoObraUnits.length !== 0) {
                                        data3.id_lago = this.$route.params.id
                                        data3.tipo_maoObra = this.maoObraUnits;
                                        data3.maoObra = this.maoObra
                                        data3.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data3)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarTrabalho/create`, data3, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.maoObraUnits = []
                                            this.maoObra = ''

                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data3 = {};
                        if (this.maoObra && this.maoObraUnits.length !== 0) {
                            data3.id_lago = this.$route.params.id
                            data3.tipo_maoObra = this.maoObraUnits;
                            data3.maoObra = this.maoObra
                            data3.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data3)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarTrabalho/create`, data3, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.maoObraUnits = []
                                this.maoObra = ''

                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        },
    }
};
</script>
    
<style scoped>
.rechargeDiv {
    display: flex;
    flex-direction: column;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    width: 100%;
}


ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>