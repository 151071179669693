<template>
    <ion-page>
  
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/financial/items"></ion-back-button>
                    </ion-buttons>
            <ion-title>{{ $t("Register") }} {{ $t("Item") }}</ion-title>
          </ion-toolbar>
        </ion-header>
  
        <ion-content class="ion-padding">
          <div class="content-items" >
            <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="empresaSelecionada">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
            <ion-item fill="outline" lines="none" class="item">
                <ion-label >{{ $t("Chemistry") }}</ion-label>
                <ion-input  readonly="item"></ion-input>
                <ion-item class="singleCheckBox" lines="none" >
                    <ion-label style="font-size: 14px;">{{ $t("Needs_Special_Control") }}</ion-label>
                    <ion-checkbox class="check" v-model="item.quimica" justify="start"></ion-checkbox>
                </ion-item>
            </ion-item>
            <ion-item fill="outline" lines="none" class="item">
                <ion-label >{{ $t("Products") }}</ion-label>
                <ion-input  readonly="item"></ion-input>
                <ion-item class="singleCheckBox" lines="none">
                    <ion-label>{{ $t("Unusual_product") }}</ion-label>
                    <ion-checkbox class="check" v-model="item.produtos" justify="start"></ion-checkbox>
                </ion-item>
            </ion-item>
            <ion-item fill="outline">
              <ion-label position="floating">{{ $t("Unit") }}</ion-label>
              <ion-input placeholder="m3/kg" v-model="item.unidade"></ion-input>
            </ion-item>
            <ion-item fill="outline" lines="none" class="item">
                <ion-label >{{ $t("Service") }}</ion-label>
                <ion-input  readonly="item"></ion-input>
                <ion-item class="singleCheckBox" lines="none">
                    <ion-label>{{ $t("Asset_cushioning") }}</ion-label>
                    <ion-checkbox class="check" v-model="item.servico" justify="start" ></ion-checkbox>
                </ion-item>
            </ion-item>
            <ion-item fill="outline">
              <ion-label position="floating">{{ $t("Description") }} <span style="color: red;">*</span></ion-label>
              <ion-input v-model="item.descricao"></ion-input>
            </ion-item>
            <IonItem fill="outline">
                <ion-label position="floating">{{ $t("Unit_of_measurement") }} <span style="color: red;">*</span></ion-label>
                <ion-select position="floating" aria-label="Fruit" class="select" side="top" v-model="item.unidade_medida"
                >
                <ion-content class="ion-padding" id="content-lista">
                    <ion-list>
                    <ion-select-option value="Kg">Kg</ion-select-option>
                    <ion-select-option value="m³">m³</ion-select-option>
                    <ion-select-option value="Hour">{{ $t("Hour") }}</ion-select-option>
                    <ion-select-option value="Day">{{ $t("Day") }}</ion-select-option>
                    <ion-select-option value="N/d">N/d</ion-select-option>
                    </ion-list>
                </ion-content>
                </ion-select>
            </IonItem>
            <IonItem fill="outline">
                <ion-label position="Stacked">{{ $t("Unit_Price") }} <span style="color: red;">*</span></ion-label>
                <ion-input placeholder="Euro €" v-model="item.preco" @input="item.preco = formatEuro($event.target.value)"></ion-input>
                <div class="selects">
                    <IonItem class="select">
                      <span style="color: red;">*</span>
                    <ion-select position="floating" aria-label="Fruit"  side="top" v-model="item.config_1"
                     >
                    <ion-content class="ion-padding" id="content-lista">
                        <ion-list>
                        <ion-select-option value="directCost">{{ $t("Direct_cost") }}</ion-select-option>
                        <ion-select-option value="generalCost">{{ $t("General_Cost") }}</ion-select-option>
                        </ion-list>
                    </ion-content>
                    </ion-select>
                    </IonItem>
                    <IonItem class="select">
                      <span style="color: red;">*</span>
                    <ion-select position="floating" aria-label="Fruit" class="select" side="top" v-model="item.config_2"
                     >
                    <ion-content class="ion-padding" id="content-lista">
                        <ion-list>
                        <ion-select-option value="activPont">{{ $t("Activ_Pont") }}</ion-select-option>
                        <ion-select-option value="activeBiomass">{{ $t("Active_Biomass") }}</ion-select-option>
                        </ion-list>
                    </ion-content>
                    </ion-select>
                    </IonItem>
                    <IonItem class="select">
                      <span style="color: red;">*</span>
                    <ion-select position="floating" aria-label="Fruit" class="select" side="top" v-model="item.config_3"
                     >
                    <ion-content class="ion-padding" id="content-lista">
                        <ion-list>
                        <ion-select-option value="withMedicine">{{ $t("With_Medicine") }}</ion-select-option>
                        <ion-select-option value="specialControl">{{ $t("Special_Control") }}</ion-select-option>
                        </ion-list>
                    </ion-content>
                    </ion-select>
                    </IonItem>
                </div>
            </IonItem>
            <ion-button size="large" expand="block" @click="cadastrarRegistro">{{ $t("Register") }}</ion-button>
          </div>
        </ion-content>
      </ion-page>
    </ion-page>
  </template>
      
  <script>
  import { IonBackButton, IonCheckbox,IonSelect,IonList,IonSelectOption ,alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
  import axios from 'axios';
  import { config } from "../../config"

  export default {
    components: {
      IonBackButton, IonCheckbox,IonSelect,IonList,IonSelectOption,IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
      return {
        item: {
          quimica: false,
          produtos: false,
          unidade: '',
          servico: false,
          descricao: '',
          unidade_medida: '',
          preco: '',
          config_1: '',
          config_2: '',
          config_3: '',
          empresa:''
        },
        empresas:'',
            empresaSelecionada:'',
            funcionalidades:'',
            master: ''
      }
    },
    mounted() {
      this.getPermissoes();
    this.fetchEmpresas()
        this.fetchUser()
  },
    methods: {
      
      async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(32)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
      async fetchEmpresas() {
        const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
            } catch (error) {
                console.log(error);
            }
        },
      async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresa = parseInt(localStorage.getItem('idEmpresa'));
        this.master = response.data.master
      } catch (error) {
        console.log(error);
      }
    },
      formatEuro(value) {
    return value.replace(/[^\d.,]/g, '')+ " €";
  },
      async cadastrarRegistro() {
  
        if (!this.item.unidade_medida) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Unit_of_measurement"),
            buttons: ['OK']
          });
          await alert.present();
          return;
        }

        if (!this.item.preco) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Unit_price"),
            buttons: ['OK']
          });
          await alert.present();
          return;
        }

        if (!this.item.config_1 || !this.item.config_2 || !this.item.config_3) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Unit_price"),
            buttons: ['OK']
          });
          await alert.present();
          return;
        }

        if(!this.master){
          this.empresaSelecionada = ''
        }
        
        const item = {
          item: {
            quimica: this.item.quimica,
            produtos: this.item.produtos,
            unidade: this.item.unidade,
            servico: this.item.servico,
            descricao: this.item.descricao,
            unidade_medida: this.item.unidade_medida,
            preco: this.item.preco,
            config_1:this.item.config_1,
            config_2: this.item.config_2,
            config_3: this.item.config_3,
            empresa: this.empresaSelecionada ? this.empresaSelecionada : this.empresa
          }
        };
        const token = localStorage.getItem('token');
          axios.post(`${config.apiUrl}/financial/items/create`, item, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
            .then(response => {
              console.log(response.data.id);
  
              const alert = alertController.create({
                header:this.$t("successfully_registered!")
              });
              alert.then(_alert => {
                _alert.present();
  
                window.location.replace("/financial/items")
              });
            })
            .catch(error => {
              console.log(error);
              const alert = alertController.create({
                header: this.$t("Error_registering"),
                buttons: ['OK']
              });
              alert.then(_alert => {
                _alert.present();
              });
            });
      }
  
    }
  };
  </script>
      
  <style scoped>
  .select{
    --inner-padding-end: 0px;
    width: 100%;
  }
  .selects{
    display: flex;
    flex-direction: column;
  }
.item{
    padding: 0px;
}
  .singleCheckBox .check{
    margin-left: 10px;
  }
  ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
  }
  
  ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  }
  
  
  .content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  
  </style>