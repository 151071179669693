<template>
  <ion-modal :is-open="showTutorial && isLoged && !tutorialCompleted">
    <ion-header>
      <ion-toolbar>
        <ion-title>Tutorial</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="tutorial">
        <div v-for="(step, index) in filteredSteps" :key="index" class="tutorialContent">
          <div class="step-content">
            <div class="step-number" :class="getStepClass(index)">{{ index + 1 }}</div>
            <div class="step-info">
              <div class="step-description">{{ step.description }}</div>
            </div>
          </div>
          <div v-if="showGallery && index == currentStep" class="step-gallery">
            <div v-for="(image, index) in step.images" :key="index" class="gallery-item">
              <img :src="image">
            </div>
          </div>
          <ion-button v-if="step.buttonText && index == currentStep" @click="onStepComplete(index)" size="large"
            expand="block" class="botaoConcluir">{{ step.buttonText }}</ion-button>
        </div>
        <button v-if="!isLastStep" class="skip-button" @click="onSkip">
          <h2 style="font-size: 20px; font-weight: 500;">Skip</h2>
        </button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import { IonContent, IonModal, IonHeader, IonToolbar, IonTitle, IonButton } from '@ionic/vue';
import p1s8 from '../static/p1s8.png';
import p2s8 from '../static/p2s8.png';
import p3s8 from '../static/p3s8.png';
import p3s9 from '../static/p3s9.png';
import p3s7 from '../static/p3s7.png';

export default {
  components: { IonContent, IonModal, IonHeader, IonToolbar, IonTitle, IonButton },
  data() {
    return {
      steps: [
        { description: this.$t("Create_your_first_Production_Center"), buttonText: this.$t("Register_now"), url: '/users/production-center/create', permissionRequired:[11] },
        { description: this.$t("Create_your_first_Zone"), buttonText: this.$t("Register_now"), url: '/config/infrastructure/create/zone', permissionRequired: [37]},
        { description: this.$t("Create_your_first_Pond"), buttonText: this.$t("Register_now"), url: '/config/infrastructure/create/lake', permissionRequired: [37]},
        { description: this.$t("Register_your_first_Specie"), buttonText: this.$t("Register_now"), url: '/config/species/create', permissionRequired: [14]},
        { description: this.$t("Register_your_first_Warehouse"), buttonText: this.$t("Register_now"), url: '/config/warehouse/create', permissionRequired: [23]},
        { description: this.$t("Register_your_first_Feed"), buttonText: this.$t("Register_now"), url: '/config/feed/create', permissionRequired: [17]},
        { description: this.$t("Register_your_first_Disease"), buttonText: this.$t("Register_now"), url: '/config/disease/create', permissionRequired: [20]},
        { description: this.$t("Configure_your_Pond_with_your_registered_Feed"), buttonText: this.$t("Configure_now"), url: '/config/infrastructure', permissionRequired: [37], images: [p1s8, p2s8, p3s7]},
        { description: this.$t("Configure_your_Pond_with_your_registered_Disease"), buttonText: this.$t("Configure_now"), url: '/config/infrastructure', permissionRequired: [37], images: [p1s8, p2s8, p3s8] },
        { description: this.$t("You_finished_the_tutorial"), buttonText: 'OK', url: '/config/infrastructure',  permissionRequired: [37, 100], images: [p1s8, p2s8, p3s9] }
      ],
      currentStep: 0,
      previousStep: -1,
      showTutorial: false,
      permissoes: [],
      tutorialCompleted: localStorage.getItem('tutorialCompleted') ?? false
    };
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.onPageUnload);
  },
  created() {
    const tutorialCompleted = localStorage.getItem('tutorialCompleted');
    const permissoes = localStorage.getItem('permissoes');
    this.permissoes = permissoes ? JSON.parse(permissoes) : [];
    this.permissoes.push(100);
    this.showTutorial = !tutorialCompleted;
    if(permissoes){
      this.isLoged = true
    }

    const inTutorial = localStorage.getItem('inTutorial');
    if (inTutorial) {
      this.showTutorial = true;
      const savedCurrentStep = localStorage.getItem('currentStep');
      if (savedCurrentStep) {
        this.currentStep = parseInt(savedCurrentStep);
      }
      localStorage.setItem('inTutorial', false);
    }

    window.addEventListener('beforeunload', this.onPageUnload);
  },
  methods: {
    onPageUnload() {
      this.showTutorial = false;
    },
    onModalDismiss() {
      localStorage.setItem('inTutorial', true);
    },
    onStepComplete(stepIndex) {
      if (this.currentStep > 0) {
        this.previousStep = this.currentStep - 1;
      }

      if (stepIndex == this.currentStep) {
        this.filteredSteps[stepIndex].completed = true;
        this.currentStep = stepIndex + 1;
      }

      if (this.currentStep !== this.filteredSteps.length) {
        const lastStep = this.filteredSteps[this.currentStep - 1];
        if (lastStep.url) {
          this.$router.push(lastStep.url);
          localStorage.setItem('inTutorial', true);
          localStorage.setItem('currentStep', this.currentStep.toString());
          this.showTutorial = false;
        }
      }else{
        const lastStep = this.filteredSteps[this.currentStep - 1];
        localStorage.setItem('tutorialCompleted', true);
        this.tutorialCompleted = true
        localStorage.setItem('inTutorial', false);
        setTimeout(() => {
          this.showTutorial = false;
        }, 300);

        this.previousStep = -1;
        this.$router.push(lastStep.url);
      }
    },
    onSkip() {
      this.skipStep();
    },
    getStepClass(stepIndex) {
      return {
        active: stepIndex == this.currentStep,
        completed: stepIndex < this.currentStep || stepIndex == this.previousStep,
      };
    },
    skipStep() {
      if (this.currentStep < this.filteredSteps.length - 1) {
        this.currentStep++;
        localStorage.setItem('currentStep', this.currentStep.toString());
      } else {
        localStorage.setItem('tutorialCompleted', true);
        localStorage.setItem('inTutorial', false);
        localStorage.removeItem('currentStep');
        this.showTutorial = false;
      }
    },
  },
  computed: {
    filteredSteps() {
      return this.steps.filter(step => {
        return step.permissionRequired.some(permission => this.permissoes.includes(permission));
      });
    },
    isLastStep() {
      return this.currentStep == this.filteredSteps.length - 1;
    },
    showGallery() {
      return this.currentStep >= 0 && this.currentStep < this.filteredSteps.length;
    },
  },
};
</script>

<style>
.tutorial {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 150px;
  gap: 50px;
}

.tutorialContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px;
  border: none;
}

.step-content {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 20%;
  gap: 15px;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

.step-info {
  display: flex;
  flex-direction: column;
}

.step-description {
  flex: 1;
  width: 225px;
  line-height: 23px;
}

.botaoConcluir {
  min-width: 50%;
  font-size: 80%;
  margin-top: 25px;
  width: max-content;
  --background: transparent;
  border: #0049b1 2px solid;
  --color: #0049b1;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  height: 52px;
  font-size: 85%;
}

.completed {
  background-color: #0049b1;
}

.active {
  border: #0049b1 2px solid;
  background: transparent;
  color: #0049b1;
}

.skip-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
}

.center-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.step-gallery {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
  margin: auto;
}

.gallery-item {
  margin-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  height: 100%;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 12px black;
}
</style>