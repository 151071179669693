const storageService = {
    get(key) {
      const item = localStorage.getItem(key);
  
      if (item == null) return null;
  
      try {
        return JSON.parse(item);
      } catch (e) {
        return item;
      }
    },
  
    set(key, obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    },
  
    remove(key) {
      localStorage.removeItem(key);
    }
  };
  
  export default storageService;