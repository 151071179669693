<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button default-href="/financial/sales"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ $t("Edit") }} {{ $t("sale") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content-items" v-if="venda">
          <IonItem fill="outline" v-if="this.master && empresas">
            <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
            <ion-select position="floating" aria-label="Fruit" class="select" v-model="venda.empresa">
              <ion-content class="ion-padding" id="content-lista">
                <ion-list v-for="item in empresas" :key="item.id">
                  <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                </ion-list>
              </ion-content>
            </ion-select>
          </IonItem>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Delivery_note") }} </ion-label>
            <ion-input v-model="venda.fatura" readonly></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date") }} <span
                style="color: red;">*</span></ion-label>
            <ion-input id="dateInput" v-model="venda.data" type="date"></ion-input>
          </ion-item>
          <IonItem fill="outline">
            <ion-label position="floating">{{ $t("Client_Name") }} <span style="color: red;">*</span></ion-label>
            <ion-select position="floating" aria-label="Fruit" class="select" v-model="venda.nomeCliente"
              @ionChange="selecionarCliente($event)">
              <ion-content class="ion-padding" id="content-lista">
                <ion-list v-for="item in clientes" :key="item.id">
                  <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                </ion-list>
                <ion-select-option value="novoC">{{ $t("New") }}</ion-select-option>
              </ion-content>
            </ion-select>
          </IonItem>
          <ion-item fill="outline" v-if="venda.nomeCliente == 'novoC'">
            <ion-label position="floating">{{ $t("Register") }} {{ $t("Client_Name") }} </ion-label>
            <ion-input v-model="TypeNomeCliente"></ion-input>
          </ion-item>
          <div class="products">
            <ion-label slot="end">{{ $t("Products") }}</ion-label>
          </div>
          <ion-accordion-group :value="expandedAccordion" class="produto">
            <ion-accordion :value="index" v-for="(produto, index) of venda.produtos" :key="index"
              toggle-icon-slot="start">
              <ion-item slot="header" color="light">
                <ion-label>{{ $t("Product") }} {{ index + 1 }}</ion-label>
                <div class="iconDelete" style="width: 30px;display: flex; align-items: center;">
                  <ion-icon @click="removeProduto(venda, index)" :ios="closeOutline" :md="closeSharp"
                    style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                </div>
              </ion-item>
              <ion-card slot="content" style="margin: 0px; padding: 0px; box-shadow: none;">
                <IonItem fill="outline" class="ion-margin" v-if="produtos">
                  <ion-label position="floating">{{ $t("Product") }} <span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto.produto"
                    @ionChange="selecionarProduto($event, produto, index)">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="item in produtos" :key="item.id">
                        <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <IonItem fill="outline" class="ion-margin" v-if="armazens && (produto.showArmazens || produto.armazem)">
                  <ion-label position="floating">{{ $t("Warehouse") }} <span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto.armazem"
                    @ionChange="selecionarArmazem($event)">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="item in armazens" :key="item.id">
                        <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <IonItem fill="outline" class="lagoList ion-margin" v-if="lagos && (produto.showLagos || produto.lago)">
                  <ion-label position="floating">{{ $t("Pond") }} <span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" :disabled="disableButton"
                    @ionChange="selecionarLago($event)" v-model="produto.lago">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="lago in lagos" :key="lago.id">
                        <ion-select-option :value=lago.id>{{ lago.nome }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("Quantity_in") }} Kg <span style="color: red;">*</span></ion-label>
                  <ion-input v-model="produto.qntd_kg"
                    @input="produto.qntd_kg = formatKg($event.target.value)"></ion-input>
                  <ion-icon slot="end" :ios="closeOutline" :md="closeSharp" @click="clearInput1(produto)"></ion-icon>
                </ion-item>
                <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("Quantity_in") }} Un</ion-label>
                  <ion-input v-model="produto.qntd_un"
                    @input="produto.qntd_un = formatUn($event.target.value)"></ion-input>
                  <ion-icon slot="end" :ios="closeOutline" :md="closeSharp" @click="clearInput2(produto)"></ion-icon>
                </ion-item>
                <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("Price") }} €/Kg <span style="color: red;">*</span></ion-label>
                  <ion-input v-model="produto.priceKg"
                    @input="produto.priceKg = formatKg2($event.target.value)"></ion-input>
                  <ion-icon slot="end" :ios="closeOutline" :md="closeSharp" @click="clearInput3(produto)"></ion-icon>
                </ion-item>
                <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("Price") }} Un <span style="color: red;">*</span></ion-label>
                  <ion-input v-model="produto.priceUn"
                    @input="produto.priceUn = formatUn($event.target.value)"></ion-input>
                  <ion-icon slot="end" :ios="closeOutline" :md="closeSharp" @click="clearInput4(produto)"></ion-icon>
                </ion-item>
                <div class="contentCommercial" v-if="produto.showTm1">
                  <ion-label slot="end">{{ $t("Commercial_sizes") }}</ion-label>
                  <div class="commercialSizes" v-for="(tm, indexT) in produto.tm1" :key="indexT">
                    <ion-checkbox v-model="tm.escolhido" @click="handleCheckboxChange(indexT, index)" justify="start" class="ion-margin "></ion-checkbox>
                    <ion-item fill="outline" class="ion-margin fixTm1Margin">
                      <ion-input class="commercial-input" v-model="tm.a" type="number" :placeholder="$t('From')"
                        disabled></ion-input>
                    </ion-item>
                    <ion-item fill="outline" class="ion-margin fixTm1Margin">
                      <ion-input class="commercial-input" v-model="tm.b" type="number" :placeholder="$t('To')"
                        disabled></ion-input>
                    </ion-item>
                  </div>
                </div>
                <ion-item fill="outline" lines="none" class="ion-margin">
                  <ion-checkbox slot="end" v-model="produto.naoSemeado"></ion-checkbox>
                  <ion-label slot="start">{{ $t("Not sown") }}</ion-label>
                </ion-item>
              </ion-card>
            </ion-accordion>
          </ion-accordion-group>
          <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
            <ion-icon @click="addProduto(venda)" :ios="addOutline" :md="addSharp"
              style="font-size: 8px; width: 30px !important;margin-right: 15px; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
          </div>
          <ion-button size="large" expand="block" @click="cadastrarRegistro">{{ $t("Register") }}</ion-button>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>
    
<script>
import { IonAccordionGroup, IonAccordion, IonBackButton, IonCheckbox, IonIcon, IonSelect, IonList, IonSelectOption, IonCard, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { addOutline, addSharp, closeOutline, closeSharp, chevronDownOutline, chevronDownSharp, chevronUpOutline, chevronUpSharp } from 'ionicons/icons';


export default {
  components: {
    IonAccordionGroup, IonAccordion, IonBackButton, IonCheckbox, IonIcon, IonSelect, IonList, IonSelectOption, IonCard, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
    return {
      expandedAccordion: 0,
      venda: {
        empresa: '',
        fatura: '',
        nomeCliente: '',
        data: '',
        produtos: [{
          empresa: '',
          produto: '',
          lago: '',
          armazem: '',
          qntd_kg: '',
          qntd_un: '',
          priceKg: '',
          priceUn: '',
          naoSemeado: false,
          tm1: '',
          showTm1: false,
          showLagos: false,
          showArmazens: false,
        }],
      },
      empresas: '',
      produtos: '',
      lagos: '',
      addOutline,
      addSharp,
      chevronDownOutline,
      chevronDownSharp,
      closeOutline,
      closeSharp,
      empresaSelecionada: '',
      funcionalidades: '',
      master: '',
      armazens: '',
      itens1: '',
      itnes2: '',
      chevronUpOutline,
      chevronUpSharp,
      TypeNomeCliente: '',
      clientes: ''
    }
  },
  mounted() {
    this.getPermissoes();
    this.fetchEmpresas()
    this.fetchUser()
    this.buscarArmazens()
    this.fetchClientes()
  },
  methods: {
    handleCheckboxChange(indexT, index) {
            this.venda.produtos[index].tm1.forEach((tm, i) => {
                if (i !== indexT) {
                    tm.escolhido = false;
                }
            });
        },
    async fetchClientes() {
      const token = localStorage.getItem('token');
      const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);
      try {
        const response = await axios.get(`${config.apiUrl}/configs/clients`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.data = response.data.items;
        console.log(response)
        this.clientes = this.data.filter(item => item.empresa == idEmpresa);
      } catch (error) {
        console.log(error);
      }
    },
    async buscarTm1(produto, id) {
      const token = localStorage.getItem('token');
      const specieId = id
      try {
        const response = await axios.get(`${config.apiUrl}/configs/species/${specieId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.venda.produtos[produto].showTm1 = true
        this.venda.produtos[produto].tm1 = response.data.especies.tm1;
        this.venda.produtos[produto].tm1.forEach((item, index) => {
            item.escolhido = index == this.venda.produtos[produto].indexTm1;
        });
      } catch (error) {
        console.log(error);
      }
    },
    selecionarProduto(event, produto, index) {
      const selectedItem = event.target.value;
      const isSpeciesItem = this.itens1.some(item => item.nome == selectedItem);
      const isProdutoItem = this.itens2.some(item => item.nome == selectedItem);
      this.venda.produtos[index].showLagos = isSpeciesItem;
      this.venda.produtos[index].showArmazens = isProdutoItem;

      if (isSpeciesItem) {
        const itemEncontrado = this.itens1.find(item => item.nome == event.target.value);

        if (itemEncontrado) {
          const idEspecie = itemEncontrado.id;
          this.buscarTm1(index, idEspecie);
        }
      } else {
        this.venda.produtos[index].showTm1 = false
      }
      this.showArmazens = isProdutoItem;
      produto.armazem = ''
      produto.lago = ''
    },
    async buscarArmazens() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/configs/warehouse?search=${this.termoPesquisa}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const empresaId = parseInt(localStorage.getItem('idEmpresa'), 10);

        const items = response.data.items.filter((item) => {
          const empresa = parseInt(item.empresa, 10);
          return empresa == empresaId;
        }).map((item) => {
          return {
            nome: item.nome,
            id: item.id
          };
        });

        this.armazens = items;
      } catch (error) {
        return console.error("Erro ao buscar nomes ", error);
      }
    },
    async getPermissoes() {
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
      await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {

          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if (!funcionalidades.includes(34)) {
            const alert = await alertController.create({
              header: "Error",
              message: this.$t("You_haven't_access")
            });
            await alert.present();
            const userId = localStorage.getItem('id');
            window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    clearInput1(produto) {
      produto.qntd_kg = '';
    },
    clearInput2(produto) {
      produto.qntd_un = '';
    },
    clearInput3(produto) {
      produto.priceKg = '';
    },
    clearInput4(produto) {
      produto.priceUn = '';
    },
    selecionarCliente(event) {
      this.valueCliente = event.target.value
    },
    addProduto(venda) {
      venda.produtos.push({
        produto: '',
        lago: '',
        armazem: '',
        qntd_kg: '',
        qntd_un: '',
        priceKg: '',
        priceUn: '',
        naoSemeado: false
      })
      this.expandedAccordion = [venda.produtos.length - 1];
    },
    formatKg(value) {
      return value.replace(/[^\d.,]/g, '') + " Kg";
    },
    formatKg2(value) {
      return value.replace(/[^\d.,]/g, '') + " €/Kg";
    },
    formatUn(value) {
      return value.replace(/[^\d.,]/g, '') + " Un";
    },
    async fetchProdutos() {
      const token = localStorage.getItem('token');
      try {
        const responseSpecies = await axios.get(`${config.apiUrl}/configs/species`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const empresaId = parseInt(localStorage.getItem('idEmpresa'));
        const speciesItems = responseSpecies.data.items.filter((item) => {
          const empresa = parseInt(item.empresa);
          return empresa == empresaId;
        }).map((item) => {
          return {
            nome: item.nome,
            id: item.id
          };
        });

        this.itens1 = speciesItems

        const responseProdutos = await axios.get(`${config.apiUrl}/produtos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const produtosItems = responseProdutos.data.items;

        this.itens2 = produtosItems

        const combinedItems = [...speciesItems, ...produtosItems];
        this.produtos = combinedItems
      } catch (error) {
        console.log(error);
      }
    },
    async removeProduto(venda, index) {
      if (venda.produtos.length > 1) {
        const produto = this.venda.produtos[index];
        if (produto.produto || produto.lago || produto.qntd_kg || produto.qntd_un || produto.priceKg || produto.priceUn) {
          const alert = await alertController.create({
            header: this.$t("Confirm_Deletion"),
            message: this.$t("Do_you_really_want_to_delete_the"),
            buttons: [
              {
                text: this.$t("Cancel"),
                role: 'cancel'
              },
              {
                text: this.$t("Yes"),
                role: "destructive",
                handler: async () => {
                  venda.produtos = venda.produtos.filter((p, i) => i !== index)
                  this.expandedAccordion = [venda.produtos.length - 1];
                }
              }
            ]
          });
          await alert.present();
        } else {
          venda.produtos = venda.produtos.filter((p, i) => i !== index)
        }
      } else {
        const alert = await alertController.create({
          header: this.$t("Invalid_action"),
          message: this.$t("You_can_not_delete_all"),
          buttons: ['OK']
        });
        await alert.present();
      }
      this.expandedAccordion = [venda.produtos.length - 1];
    },
    async fetchLagos(idEmpresa) {
      const token = localStorage.getItem('token');
      const data = {
        idEmpresa: idEmpresa
      }
      try {
        const response = await axios.put(`${config.apiUrl}/getLagosByEmpresa`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const lagos = response.data.items.map((lago) => {
          return {
            nome: lago.nome,
            id: lago.id
          };
        });
        this.lagos = lagos;

        this.getVenda()
      } catch (error) {
        console.log(error);
      }
    },
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresas = response.data.items;

      } catch (error) {
        console.log(error);
      }
    },
    async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const idEmpresa = parseInt(localStorage.getItem('idEmpresa'))
        this.master = response.data.master
        this.fetchLagos(idEmpresa)

        this.fetchProdutos()
      } catch (error) {
        console.log(error);
      }
    },
    formatEuro(value) {
      return value.replace(/[^\d.,]/g, '') + " €";
    },
    async getVenda() {
      const vendaId = this.$route.params.id
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/financial/sales/${vendaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.venda = response.data.vendas;

        this.venda.produtos.forEach((produto, index) => {

          const isSpeciesItem = this.itens1.some(item => item.nome == produto.produto);

          if (isSpeciesItem) {

            this.showTm1 = true;

            const itemEncontrado = this.itens1.find(item => item.nome == produto.produto);

            if (itemEncontrado) {
              const idEspecie = itemEncontrado.id;
              this.venda.produtos[index].showTm1 = true
              this.buscarTm1(index, idEspecie);
            }
          }
        })


      } catch (error) {
        console.log(error);
      }
    },
    async cadastrarRegistro() {

      if (!this.venda.nomeCliente && !this.TypeNomeCliente) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Client_Name"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.venda.data) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Date"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      for (let i = 0; i < this.venda.produtos.length; i++) {
        const produto = this.venda.produtos[i];
        if (!produto.produto || (!produto.lago && !produto.armazem) || !produto.qntd_kg || !produto.priceKg && !produto.priceUn) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Product"),
            buttons: ['OK']
          });
          await alert.present();
          return false;
        }
        if (produto.tm1.every(tm1 => !tm1.escolhido)) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("You_need_to_choose_at_least_one_commercial_size"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return false;
                }
        if (produto.showArmazens || produto.armazem) {
          produto.lago = ''
        }
        if (produto.showLagos || produto.lago) {
          produto.armazem = ''
        }
      }

      // for (let i = 0; i < this.venda.produtos.length; i++) {
      //   const produto = this.venda.produtos[i];
      //   if (produto.qntd_kg && produto.qntd_un) {
      //     const alert = await alertController.create({
      //       header: this.$t("Error"),
      //       message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Quantity"),
      //       buttons: ['OK']
      //     });
      //     await alert.present();
      //     return false;
      //   }
      // }

      // for (let i = 0; i < this.venda.produtos.length; i++) {
      //   const produto = this.venda.produtos[i];
      //   if (produto.priceKg && produto.priceUn) {
      //     const alert = await alertController.create({
      //       header: this.$t("Error"),
      //       message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Price"),
      //       buttons: ['OK']
      //     });
      //     await alert.present();
      //     return false;
      //   }
      // }

      for (let i = 0; i < this.venda.produtos.length; i++) {
        const produto = this.venda.produtos[i];
        produto.empresa = this.venda.empresa
      }

      if (this.TypeNomeCliente !== "") {

        const idEmpresa = localStorage.getItem('idEmpresa')
        const id = null
        const data2 = {
          nome: this.TypeNomeCliente,
          empresa: idEmpresa
        }
        const token = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/configs/client/createEdit/${id}`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            console.log(response.data.id);

          })
          .catch(error => {
            console.log(error);
          });
      }

      if (this.venda.nomeCliente == 'nomeC') {
        this.venda.nomeCliente = ''
      }

      const venda = {
        fatura: this.venda.fatura,
        nomeCliente: this.TypeNomeCliente ? this.TypeNomeCliente : this.venda.nomeCliente,
        empresa: this.venda.empresa,
        data: this.venda.data,
        produtos: this.venda.produtos.flatMap(produto => {
          return {
            produto: produto.produto,
            lago: produto.lago,
            armazem: produto.armazem,
            empresa: this.venda.empresa,
            qntd_kg: produto.qntd_kg,
            qntd_un: produto.qntd_un,
            priceKg: produto.priceKg,
            priceUn: produto.priceUn,
            naoSemeado: produto.naoSemeado,
            indexTm1: produto.tm1.findIndex(item => item.escolhido == true)
          };
        })
      };
      const token = localStorage.getItem('token');
      const vendaId = this.$route.params.id
      axios.put(`${config.apiUrl}/financial/sales/edit/${vendaId}`, venda, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          console.log(response.data.id);

          const alert = alertController.create({
            header: this.$t("successfully_updated!")
          });
          alert.then(_alert => {
            _alert.present();

            window.location.replace("/financial/sales")
          });
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_updating"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });
    }

  }
};
</script>
    
<style scoped>
.commercial-input {
  font-size: 14px;
}

.sizesTemp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.contentCommercial {
  text-align: center;
}

.commercialSizes {
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 1px;
  column-gap: 15px;
  justify-items: center;
}

.select {
  --inner-padding-end: 0px;
  width: 100%;
}

.selects {
  display: flex;
  flex-direction: column;
}

.item {
  padding: 0px;
}

.singleCheckBox .check {
  margin-left: 10px;
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
  max-width: 450px;
  max-height: max-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.botoes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.addProduct {
  width: 49.5%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  background: #0049b1;
  color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.addProduct h2 {
  margin: 0px;
  font-size: 16px;
}

.plusIcon {
  font-size: 35px;
  color: white;

}

.products {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contentCommercial {
  text-align: center;
}

.commercialSizes {
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 1px;
  justify-items: center;
    align-items: center;
  margin-bottom: 15px;
}

.fixTm1Margin {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>