import en from '../src/lang/en.json';
import pt from '../src/lang/pt.json';
import es from '../src/lang/es.json';
import it from '../src/lang/it.json';
import fr from '../src/lang/fr.json';
import de from '../src/lang/de.json';
import af from '../src/lang/af.json';
import am from '../src/lang/am.json';
import ar from '../src/lang/ar.json';
import az from '../src/lang/az.json';
import be from '../src/lang/be.json';
import bg from '../src/lang/bg.json';
import bn from '../src/lang/bn.json';
import bs from '../src/lang/bs.json';
import ca from '../src/lang/ca.json';
//import ceb from '../src/lang/ceb.json';
//import co from '../src/lang/co.json';
import cs from '../src/lang/cs.json';
//import cy from '../src/lang/cy.json';
import da from '../src/lang/da.json';
//import el from '../src/lang/el.json';
//import eo from '../src/lang/eo.json';
import et from '../src/lang/et.json';
import eu from '../src/lang/eu.json';
//import fa from '../src/lang/fa.json';
import fi from '../src/lang/fi.json';
//import fy from '../src/lang/fy.json';
import ga from '../src/lang/ga.json';
//import gd from '../src/lang/gd.json';
import gl from '../src/lang/gl.json';
//import gu from '../src/lang/gu.json';
//import ha from '../src/lang/ha.json';
//import haw from '../src/lang/haw.json';
//import he from '../src/lang/he.json';
import hi from '../src/lang/hi.json';
//import hmn from '../src/lang/hmn.json';
import hr from '../src/lang/hr.json';
//import ht from '../src/lang/ht.json';
//import hu from '../src/lang/hu.json';
import hy from '../src/lang/hy.json';
import id from '../src/lang/id.json';
//import ig from '../src/lang/ig.json';
import is from '../src/lang/is.json';
import ja from '../src/lang/ja.json';
//import jv from '../src/lang/jv.json';
import ka from '../src/lang/ka.json';
import kk from '../src/lang/kk.json';
import km from '../src/lang/km.json';
import kn from '../src/lang/kn.json';
import ko from '../src/lang/ko.json';
//import ku from '../src/lang/ku.json';
import ky from '../src/lang/ky.json';
//import la from '../src/lang/la.json';
//import lb from '../src/lang/lb.json';
import lo from '../src/lang/lo.json';
import lt from '../src/lang/lt.json';
import lv from '../src/lang/lv.json';
import mg from '../src/lang/mg.json';
//import mi from '../src/lang/mi.json';
import mk from '../src/lang/mk.json';
//import ml from '../src/lang/ml.json';
//import mn from '../src/lang/mn.json';
//import mr from '../src/lang/mr.json';
//import ms from '../src/lang/ms.json';
//import mt from '../src/lang/mt.json';
//import my from '../src/lang/my.json';
//import ne from '../src/lang/ne.json';
import nl from '../src/lang/nl.json';
//import no from '../src/lang/no.json';
//import ny from '../src/lang/ny.json';
//import or from '../src/lang/or.json';
//import pa from '../src/lang/pa.json';
//import pl from '../src/lang/pl.json';
//import ps from '../src/lang/ps.json';
//import ro from '../src/lang/ro.json';
import ru from '../src/lang/ru.json';
//import rw from '../src/lang/rw.json';
//import sd from '../src/lang/sd.json';
//import si from '../src/lang/si.json';
//import sk from '../src/lang/sk.json';
//import sl from '../src/lang/sl.json';
//import sm from '../src/lang/sm.json';
//import sn from '../src/lang/sn.json';
//import so from '../src/lang/so.json';
import sq from '../src/lang/sq.json';
//import sr from '../src/lang/sr.json';
//import st from '../src/lang/st.json';
//import su from '../src/lang/su.json';
//import sv from '../src/lang/sv.json';
//import sw from '../src/lang/sw.json';
//import ta from '../src/lang/ta.json';
//import te from '../src/lang/te.json';
//import tg from '../src/lang/tg.json';
//import th from '../src/lang/th.json';
//import tk from '../src/lang/tk.json';
//import tl from '../src/lang/tl.json';
import tr from '../src/lang/tr.json';
//import tt from '../src/lang/tt.json';
//import ug from '../src/lang/ug.json';
//import uk from '../src/lang/uk.json';
//import ur from '../src/lang/ur.json';
//import uz from '../src/lang/uz.json';
import vi from '../src/lang/vi.json';
//import xh from '../src/lang/xh.json';
//import yi from '../src/lang/yi.json';
//import yo from '../src/lang/yo.json';
import zh from '../src/lang/zh.json';
//import zu from '../src/lang/zu.json';

import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: 'en',
  messages: {
    en, 
    pt, 
    es, 
    it, 
    fr, 
    de, 
    af, 
    am, 
    ar, 
    az, 
    be, 
    bg, 
    bn, 
    bs, 
    ca, 
    //ceb, 
    //co, 
    cs, 
    //cy, 
    da, 
    //el, 
    //eo, 
    et, 
    eu, 
    //fa, 
    fi, 
    //fy, 
    ga, 
    //gd, 
    gl, 
    //gu, 
    //ha, 
    //haw, 
    //he, 
    hi, 
    //hmn, 
    hr, 
    //ht, 
    //hu, 
    hy, 
    id, 
    //ig, 
    is, 
    ja, 
    //jv, 
    ka, 
    kk, 
    km, 
    kn, 
    ko, 
    //ku, 
    ky, 
    //la, 
    //lb, 
    lo, 
    lt, 
    lv, 
    mg, 
    //mi, 
    mk, 
    //ml, 
    //mn, 
    //mr, 
    //ms, 
    //mt, 
    //my, 
    //ne, 
    nl, 
    //no, 
    //ny, 
    //or, 
    //pa, 
    //pl, 
    //ps, 
    //ro,
    ru, 
    //rw, 
    //sd, 
    //si, 
    //sk, 
    //sl, 
    //sm, 
    //sn, 
    //so, 
    sq, 
    //sr, 
    //st, 
    //su, 
    //sv, 
    //sw, 
    //ta, 
    //te, 
    //tg, 
    //th, 
    //tk, 
    //tl, 
    tr, 
    //tt, 
    //ug, 
    //uk, 
    //ur, 
    //uz, 
    vi, 
    //xh, 
    //yi, 
    //yo, 
    zh, 
    //zu
  },
});

export {
  i18n
};