<template>
  <ion-page>
    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>

                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button :default-href="`/config/infrastructure/lake/${this.$route.params.id}/fuel`"></ion-back-button>
                    </ion-buttons>
          <ion-title>{{ $t("Register") }} {{ $t("Type_of_Fuel") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content-items">
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Distributor") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="tipoCombustivel.distribuidora"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Type_of_Fuel") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="tipoCombustivel.tipoCombustivel" ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Product_trade_name") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="tipoCombustivel.nomeComercial" ></ion-input>
          </ion-item>
          <ion-button size="large" expand="block" @click="cadastrarTipo">{{ $t("Register") }}</ion-button>
        </div>
      </ion-content>

    </ion-page>
  </ion-page>
</template>
    
<script>
import {IonBackButton, 
  alertController,
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonTitle,
  IonItem,
  IonLabel
} from "@ionic/vue";
import axios from 'axios';
import { config } from "../../../config"

export default {
  name: "HomePage",
  components: {IonBackButton, 
    IonButton,
    IonInput,
    IonPage,
    IonContent,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonTitle,
    IonItem,
    IonLabel
  },
  data() {
    return {
      tipoCombustivel: {
        tipoCombustivel: '',
        nomeComercial: '',
        distribuidora: '',
        empresa: this.empresa
      }
    }
  }
  ,
  mounted() {
    this.getPermissoes();
    this.empresa = parseInt(localStorage.getItem('idEmpresa'));
  },
  methods: {
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async cadastrarTipo() {

      if (!this.tipoCombustivel.distribuidora) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Distributor"),
          buttons: ['OK']
        });
        await alert.present();
        return;

      }

      if (!this.tipoCombustivel.tipoCombustivel) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Type_of_Fuel"),
          buttons: ['OK']
        });
        await alert.present();
        return;

      }

      if (!this.tipoCombustivel.nomeComercial) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Product_trade_name"),
          buttons: ['OK']
        });
        await alert.present();
        return;

      }

      const data = {
        tipoCombustivel: {
          tipoCombustivel: this.tipoCombustivel.tipoCombustivel,
          nomeComercial: this.tipoCombustivel.nomeComercial,
          empresa: this.empresa,
          distribuidora: this.tipoCombustivel.distribuidora
        }
      };
      const token = localStorage.getItem('token');
      axios.post(`${config.apiUrl}/combustiveis/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })

        .then(response => {
          console.log(response.data.id);

          const alert = alertController.create({
            header: this.$t("Success"),
             message: this.$t("successfully_registered!"),
          });
          alert.then(_alert => {
            _alert.present();

          });
          window.location.replace(`/config/infrastructure/lake/${this.$route.params.id}/fuel`)
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_registering"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });

    }
  }
};
</script>
    
<style scoped>
.quantidade {
  width: 40%;
}

.lago {
  width: 60%;
}

.naoEdita {
  color: #838383;
}

.lagos {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
  max-width: 450px;
  max-height: max-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
</style>