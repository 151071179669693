<template>
    <ion-page>
        <ion-page id="main-content">

            <ion-header style="height: 56px;">
                <ion-toolbar>

                    <ion-buttons slot="start">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="end">
                        <language-selector></language-selector>
                    </ion-buttons>
                    <ion-title>{{ $t("Importation/Exportation") }}</ion-title>
                </ion-toolbar>
                <ion-progress-bar v-if="loading" :value="progressBarValue"></ion-progress-bar>
            </ion-header>
            <ion-content class="ion-padding">

                <div class="content-items">
                    <ion-item fill="outline" lines="none">
                        <div class="ieDiv">
                            <ion-item lines="full">
                                <ion-label>{{ $t("Import") }} {{ $t("or") }} {{ $t("Export") }}</ion-label>
                            </ion-item>
                            <ion-radio-group v-model="ImportarExportar">
                                <ion-item lines="none">
                                    <ion-label>{{ $t("Import") }}</ion-label>
                                    <ion-radio value="importar" disabled></ion-radio>
                                </ion-item>
                                <ion-item lines="none">
                                    <ion-label>{{ $t("Export") }}</ion-label>
                                    <ion-radio value="exportar"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </div>
                    </ion-item>
                    <IonItem class="selectFilter" fill="outline">
                        <ion-label position="floating">{{ $t("Production_Center") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-select label-placement="floating" @ionChange="selecionarCentro($event)">
                            <ion-select-option v-for="centro in centros" :key="centro.id" :value="centro.id">{{
                                centro.nomeLegal }}</ion-select-option>
                        </ion-select>
                    </IonItem>
                    <IonItem class="selectFilter" fill="outline">
                        <ion-label position="floating">{{ $t("Company_data") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-select label-placement="floating" @ionChange="selecionarDadosEmpresa($event)">
                            <ion-select-option value="Sim">{{ $t("Yes") }}</ion-select-option>
                            <ion-select-option value="Não">{{ $t("No") }}</ion-select-option>
                        </ion-select>
                    </IonItem>
                    <ion-button v-if="download" size="large" class="createButton" @click="exportData">{{ $t("Download")
                    }}</ion-button>
                </div>
            </ion-content>

        </ion-page>
    </ion-page>
</template>
    
<script>
import {
    IonProgressBar, IonButton, IonRadio, IonRadioGroup, alertController, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonItem, IonLabel, IonSelect, IonSelectOption
} from "@ionic/vue";
import { mapState } from 'vuex'
import axios from 'axios';
import { config } from "../../config"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
    name: "HomePage",
    components: {
        'language-selector': LanguageSelector, IonProgressBar, IonButton, IonRadio, IonRadioGroup, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonItem, IonLabel, IonSelect, IonSelectOption
    },
    data() {
        return {
            ImportarExportar: 'exportar',
            alimentos: "",
            lagos: "",
            centros: "",
            zonas: [],
            loading: false,
            progressBarValue: 0,
            download: false,
            secoes: [], // Alterado para um objeto vazio
            valueCentro: "",
            dadosEmpresa: ''
        };
    },
    computed: {
        ...mapState("user", {
            user: (m) => m.user,
        }),
    },
    mounted() {
        this.getUser();
        this.getPermissoes();
    },
    methods: {
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(41)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async exportData() {
            if (!this.dadosEmpresa) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                await alert.present();
                return
            }
            this.loading = true;

            try {
                // Crie uma planilha para "Sections, Zones, and Ponds"
                const worksheetMain = XLSX.utils.aoa_to_sheet([]);

                // Defina os dados das colunas
                const dataZones = this.zonas.map(item => [item.nome]);
                const dataSections = this.secoes.map(item => [item.texto]);
                const dataPonds = this.lagos.map(item => [item.nome]);

                // Adicione os dados das colunas à planilha principal
                XLSX.utils.sheet_add_aoa(worksheetMain, dataSections, { origin: "B2" });
                XLSX.utils.sheet_add_aoa(worksheetMain, dataZones, { origin: "A2" });
                XLSX.utils.sheet_add_aoa(worksheetMain, dataPonds, { origin: "C2" });

                // Defina o cabeçalho na planilha principal
                const headers = ["Zones", "Sections", "Ponds"];
                XLSX.utils.sheet_add_aoa(worksheetMain, [headers], { origin: "A1" });

                // Obtenha a célula do cabeçalho
                const headerCell = XLSX.utils.encode_cell({ r: 0, c: 0 });

                // Defina a formatação em negrito para o cabeçalho
                worksheetMain[headerCell].s = { font: { bold: true } };

                // Defina a largura das colunas na planilha principal
                worksheetMain["!cols"] = [
                    { wpx: 200 }, // Largura da coluna "Sections"
                    { wpx: 200 }, // Largura da coluna "Zones"
                    { wpx: 200 }, // Largura da coluna "Ponds"
                ];

                // Defina a primeira linha como congelada (fixa) na planilha principal
                worksheetMain["!freeze"] = { xSplit: 0, ySplit: 1 };

                // Crie o arquivo de Excel
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheetMain, "Sections, Zones, and Ponds");

                if (this.dadosEmpresa == 'Sim') {
                    const idempresa = this.idEmpresa;
                    const empresaData = await this.getAllCompanyData(idempresa);

                    // Create a worksheet for the alimentos
                    const worksheetAlimentos = XLSX.utils.aoa_to_sheet([]);

                    // Define the headers in the alimentos worksheet
                    const alimentosHeaders = ["Name", "Specie", "Manufacturer", "Feed type", "from", "to", "temperatures"];
                    XLSX.utils.sheet_add_aoa(worksheetAlimentos, [alimentosHeaders], { origin: "A1" });

                    let alimentosRowOffset = 1;

                    empresaData.alimentos.forEach((alimento) => {
                        const tm1Length = alimento.tm1.length;

                        let ColumnData = [];

                        const tm1ColumnData = alimento.tm1.map((tm1, index) => {
                            if (index == 0) {
                                return [alimento.nome, alimento.especie, alimento.fabricante, alimento.tipoAlimento, tm1.a, tm1.b];
                            } else {
                                return ["", "", "", "", tm1.a, tm1.b];
                            }
                        });

                        const tt1ColumnData = alimento.tt1.map((tt1, index) => {
                            if (index == 0) {
                                return [tt1.c1, tt1.c2, tt1.coeficientes.join(", ")]; // Converta o array em uma string
                            } else {
                                return [tt1.c1, tt1.c2, tt1.coeficientes.join(", ")]; // Converta o array em uma string
                            }
                        });

                        for (let i = 0; i < Math.max(tm1ColumnData.length, tt1ColumnData.length); i++) {
                            // Use o spread apenas se o índice estiver dentro do comprimento do array
                            const tm1Data = i < tm1ColumnData.length ? tm1ColumnData[i] : ["", "", "", "", "", ""]; // Preencha com valores vazios se necessário
                            const tt1Data = i < tt1ColumnData.length ? tt1ColumnData[i] : ["", "", ""]; // Preencha com valores vazios se necessário

                            ColumnData.push([...tm1Data, ...tt1Data]);
                        }

                        XLSX.utils.sheet_add_aoa(worksheetAlimentos, ColumnData, { origin: { r: alimentosRowOffset, c: 0 } });
                        console.log(ColumnData)
                        // Incrementa o deslocamento da linha com base no número de entradas em tm1
                        alimentosRowOffset += tm1Length;
                    });
                    // Set the column width in the alimentos worksheet
                    worksheetAlimentos["!cols"] = alimentosHeaders.map(() => ({ wpx: 150 }));

                    // Create a worksheet for the doenças
                    const worksheetDoencas = XLSX.utils.json_to_sheet(empresaData.doencas);

                    // Define the headers in the alimentos worksheet
                    const DoencasHeaders = ["Name", "Change", "Medicine", "Reduction", "Amount", "Specie"];
                    XLSX.utils.sheet_add_aoa(worksheetDoencas, [DoencasHeaders], { origin: "A1" });

                    // Set the column width in the alimentos worksheet
                    worksheetDoencas["!cols"] = DoencasHeaders.map(() => ({ wpx: 150 }));

                    // Create a worksheet for the espécies
                    const worksheetEspecies = XLSX.utils.aoa_to_sheet([]);

                    // Define the headers in the espécies worksheet
                    const especiesHeaders = ["Name", "Scientific name", "Decrease", "Reduction", "from", "to"];
                    XLSX.utils.sheet_add_aoa(worksheetEspecies, [especiesHeaders], { origin: "A1" });

                    let especiesRowOffset = 1;

                    empresaData.especies.forEach((especie) => {
                        const tm1Length = especie.tm1.length;
                        const tm1ColumnData = especie.tm1.map((tm1, index) => {
                            if (index == 0) {
                                return [especie.nome, especie.nomeCientifico, especie.diminuicao, especie.reducao, tm1.a, tm1.b, tm1.c];
                            } else {
                                return ["", "", "", "", tm1.a, tm1.b, tm1.c];
                            }
                        });

                        XLSX.utils.sheet_add_aoa(worksheetEspecies, tm1ColumnData, { origin: { r: especiesRowOffset, c: 0 } });

                        // Increment the row offset based on the number of tm1 entries
                        especiesRowOffset += tm1Length;
                    });

                    // Set the column width in the espécies worksheet
                    worksheetEspecies["!cols"] = especiesHeaders.map(() => ({ wpx: 150 }));

                    // Add the empresa worksheets to the workbook
                    XLSX.utils.book_append_sheet(workbook, worksheetEspecies, "Species");
                    XLSX.utils.book_append_sheet(workbook, worksheetAlimentos, "Feeds");
                    XLSX.utils.book_append_sheet(workbook, worksheetDoencas, "Diseases");
                }
                //const allArraysEmpty = Object.values(lagoData).every(arr => Array.isArray(arr) && arr.length == 0);
                for (const lago of this.lagos) {
                    const lagoData = await this.getAllPondData(lago.id);

                    // Verificar se todos os arrays estão vazios
                    const allArraysEmpty = Object.values(lagoData).every(arr => Array.isArray(arr) && arr.length == 0);

                    if (!allArraysEmpty) {
                        const worksheetLago = XLSX.utils.aoa_to_sheet(lagoData);

                        let firstArrayHeaders = [];

                        // Encontrar o primeiro array não vazio e obter seus headers
                        for (const categoria in lagoData.results) {
                            if (lagoData.results[categoria].length > 0) {
                                firstArrayHeaders = Object.keys(lagoData.results[categoria][0]);
                                break;
                            }
                        }

                        // Definir os headers na planilha do lago
                        XLSX.utils.sheet_add_aoa(worksheetLago, [firstArrayHeaders], { origin: "A1" });

                        // Substituir os números pelos headers correspondentes
                        for (let i = 0; i < firstArrayHeaders.length; i++) {
                            worksheetLago[XLSX.utils.encode_cell({ r: 0, c: i })].v = firstArrayHeaders[i];
                        }
                        // Defina a largura das colunas na planilha do lago
                        worksheetLago["!cols"] = [
                            { wpx: 150 }, // Largura da coluna "Campo 1"
                            { wpx: 150 }, // Largura da coluna "Campo 2"
                            { wpx: 150 }, // Largura da coluna "Campo 3"
                            { wpx: 150 }, // Largura da coluna "Campo 1"
                            { wpx: 150 }, // Largura da coluna "Campo 2"
                            { wpx: 150 }, // Largura da coluna "Campo 3"
                            { wpx: 150 }, // Largura da coluna "Campo 1"
                            { wpx: 150 }, // Largura da coluna "Campo 2"
                            { wpx: 150 }, // Largura da coluna "Campo 3"
                            { wpx: 150 }, // Largura da coluna "Campo 1"
                            { wpx: 150 }, // Largura da coluna "Campo 2"
                            { wpx: 150 }, // Largura da coluna "Campo 3"
                            { wpx: 150 }, // Largura da coluna "Campo 2"
                            { wpx: 150 }, // Largura da coluna "Campo 3"
                            { wpx: 150 }, // Largura da coluna "Campo 1"
                            { wpx: 150 }, // Largura da coluna "Campo 2"
                            { wpx: 150 },
                        ];

                        // Definir a primeira linha como congelada (fixa) na planilha do lago
                        worksheetLago["!freeze"] = { xSplit: 0, ySplit: 1 };

                        // Adicionar a planilha do lago ao livro de trabalho
                        XLSX.utils.book_append_sheet(workbook, worksheetLago, lago.nome);
                    } else {
                        console.error('Nenhum dado encontrado para o lago:', lago.id);
                    }
                }
                // Gere o arquivo Excel em formato binário
                const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

                // Converta o buffer para um blob
                const excelBlob = new Blob([excelBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                // Faça o download do arquivo Excel
                saveAs(excelBlob, "centerData.xlsx");

                this.loading = false;

                setTimeout(() => {
                    this.showDownloadAlert();
                }, 0);
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        async getAllCompanyData(idempresa) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/getAllCompanyData/${idempresa}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const companyData = response.data;

                const especiesData = companyData.especies.map(item => {
                    const especie = {
                        nome: item.nome || '',
                        nomeCientifico: item.nomeCientifico || '',
                        diminuicao: item.diminuicao || '',
                        reducao: item.reducao || '',
                        tm1: item.tm1 || []
                    };

                    return especie;
                });
                console.log(companyData.alimentos)
                const alimentosData = companyData.alimentos.map(item => {
                    const alimento = {
                        nome: item.nome || '',
                        especie: item.especie || '',
                        fabricante: item.fabricante || '',
                        tipoAlimento: item.tipoAlimento || '',
                        tm1: item.tm1 || [],
                        tt1: item.tt1 || []
                    };

                    return alimento;
                });

                const doencasData = companyData.doencas.map(item => ({
                    nome: item.nome || '',
                    alteracao: item.alteracao || '',
                    medicamento: item.medicamento || '',
                    reducao: item.reducao || '',
                    quantidade: item.quantidade || '',
                    especie: item.especie || '',
                }));

                return {
                    especies: especiesData,
                    alimentos: alimentosData,
                    doencas: doencasData
                };
            } catch (error) {
                console.error(error);
                return {};
            }
        },

        async getAllPondData(lagoId) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/getAllPondData/${lagoId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const pondData = response.data.results;

                const data = [];

                for (const categoria in pondData) {
                    if (Object.prototype.hasOwnProperty.call(pondData, categoria)) {
                        const categoriaData = pondData[categoria];

                        if (categoriaData.length == 0) {
                            continue;
                        }

                        const categoriaHeaders = Object.keys(categoriaData[0]);

                        const categoriaFormattedData = categoriaData.map(item => {
                            const rowData = {};
                            categoriaHeaders.forEach(header => {
                                if (header !== 'id' && header !== 'id_lago') {
                                    rowData[`${categoria}_${header}`] = item[header] || '';
                                }
                            });
                            return rowData;
                        });

                        data.push(categoriaFormattedData);
                    }
                }

                const finalData = data.flatMap(categoriaData => {
                    const headers = Object.keys(categoriaData[0]);
                    const columns = categoriaData.map(item => Object.values(item));
                    return [headers, ...columns];
                });

                return finalData;
            } catch (error) {
                console.error(error);
                return [];
            }
        }
        ,
        showDownloadAlert() {
            const alert = alertController.create({
                header: this.$t("Successfully_downloaded!"),
                buttons: ["OK"],
            });
            alert.then((result) => {
                result.present();
            });
        },
        async getUser() {
            const token = localStorage.getItem('token');
            const Id = localStorage.getItem("id");
            try {
                const response = await axios.get(`${config.apiUrl}/users/${Id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.userData = response.data;
                this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'))
                this.showCentros(this.idEmpresa);
            } catch (error) {
                console.log(error);
            }
        },
        selecionarCentro($event) {
            this.valueCentro = $event.target.value;
            this.idCentro = $event.target.value;
            this.download = true;
            this.getLagosByCentro(this.idCentro)
        },
        selecionarDadosEmpresa($event) {
            this.dadosEmpresa = $event.target.value;
            console.log(this.dadosEmpresa)
        },
        async showCentros() {
            const token = localStorage.getItem('token');
            const data = {
                empresa: this.idEmpresa,
            };
            try {
                const response = await axios.put(`${config.apiUrl}/getProductionCenterbyCompanyName`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const centros = response.data.items.map((centro) => {
                    return {
                        nomeLegal: centro.nomeLegal,
                        id: centro.id,
                    };
                });
                this.centros = centros;
            } catch (error) {
                console.log(error);
            }
        },
        async getLagosByCentro(idCentro) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/getLagosByCentro/${idCentro}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const lagos = response.data.lagos;
                this.lagos = lagos;
                this.download = true;
                this.getZonas(idCentro)
            } catch (error) {
                console.log(error);
            }
        },
        async getZonas(idCentro) {
            const token = localStorage.getItem('token');
            const data = {
                idCentro: idCentro,
            };
            try {
                const response = await axios.put(`${config.apiUrl}/getZonebyProductionCenter`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const zonas = response.data.items.map((zona) => {
                    return {
                        nome: zona.nome,
                        id: zona.id,
                    };
                });
                this.zonas = zonas;
                this.download = true;
                console.log("aqui, zonas:", response);

                this.getSecoes(idCentro);
            } catch (error) {
                console.log(error);
            }
        },
        async getSecoes(idCentro) {
            const token = localStorage.getItem('token');
            const data = {
                idCentro: idCentro,
            };
            try {
                const response = await axios.put(`${config.apiUrl}/getSecoesByCentro`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const secoes = response.data.items.map((secao) => {
                    return {
                        texto: secao.texto,
                        id: secao.id,
                    };
                });
                this.secoes = secoes;
                this.download = true;
                console.log("aqui, seceos:", response);
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>
    
<style scoped>
.loading-bar {
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
    position: relative;
}

.ieDiv {
    display: flex;
    flex-direction: column;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    width: 100%;
}

.quantidade {
    width: 40%;
}

.lago {
    width: 60%;
}

.naoEdita {
    color: #838383;
}

.lagos {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}</style>