<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button
                            :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Fuel") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <ion-item fill="outline">
                        <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date") }} <span
                            style="color: red;">*</span></ion-label>
                        <ion-input v-model="data_registro" type="date" @ionChange="onDataChange"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarTipo" style="font-size: 15px; height: 56px;">{{
                        $t("Register") }} {{ $t("Type_of_Fuel") }}</ion-button>
                    <br>
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Type_of_Fuel") }}</ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="combustivel">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in combustiveis" :key="item.id">
                                    <ion-select-option :value=item.tipoCombustivel>{{ item.tipoCombustivel
                                    }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-button v-if="!isNoLake" size="large" expand="block" @click="cadastrarTipoCombustivel">{{
                        $t("Confirm") }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="cadastrarTipoCombustivelGeral">{{ $t("Confirm")
                    }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Was_in") }}</ion-label>
                        <ion-input v-model="estavaEm" @input="estavaEm = formatKg($event.target.value)"
                            :placeholder="$t('Applies_to_tanks_only')"></ion-input>
                    </ion-item>
                    <ion-button v-if="!isNoLake" size="large" expand="block" @click="cadastrarEstavaEm">{{ $t("Confirm")
                    }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="cadastrarEstavaEmGeral">{{ $t("Confirm")
                    }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Consumption") }}</ion-label>
                        <ion-input v-model="consumo" @input="consumo = formatKg($event.target.value)"
                            :placeholder="$t('apply_to_warehouse')"></ion-input>
                    </ion-item>
                    <ion-button size="large" v-if="!isNoLake" expand="block" @click="cadastrarConsumo">{{ $t("Confirm")
                    }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="cadastrarConsumoGeral">{{ $t("Confirm")
                    }}</ion-button>
                    <br>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonList, IonSelect, IonSelectOption, alertController, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"
//import moment from 'moment';

export default {
    components: {
        IonBackButton, IonList, IonSelect, IonSelectOption, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            combustiveis: '',
            combustivel: '',
            consumo: '',
            estavaEm: '',
            isNoLake: false,
            data_registro: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.obterDataAtual()
        this.empresa = parseInt(localStorage.getItem('idEmpresa'));
        this.fetchCombustiveis(this.empresa)
        this.defineType()
    },
    methods: {
        async onDataChange() {
            this.fetchDataGeral(this.data_registro);
        },
        formatDataForDatabase(date) {
            const parts = date.split('-');
            const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
            return formattedDate;
        },
        defineType() {
            const data = localStorage.getItem('CZSL')

            if (data != 'Lake' && data) {
                this.isNoLake = true
            } else {
                this.isNoLake = false
            }

        },
        async fetchDataGeral(date) {
            try {
                this.consumo = null;
                this.estavaEm = null;
                this.combustivel = null;

                const tipo = localStorage.getItem('CZSL')
                const id = this.$route.params.id
                const token = localStorage.getItem('token');

                const data = {
                    type: tipo,
                    data_registro: this.formatDataForDatabase(date)
                }

                console.log(data)
              
                const response = await axios.get(`${config.apiUrl}/getByIdCombustivel/${id}`, {
                    params: data,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                
                const consumoCData = response.data.consumo;
                const estavaEmCData = response.data.estavaEm;
                const tipoCombustivel = response.data.tipoCombustivel;

                this.consumo = consumoCData;
                this.estavaEm = estavaEmCData;
                this.combustivel = tipoCombustivel;

            } catch (error) {
                console.log(error);
            }
        },
        cadastrarTipoCombustivelGeral() {
            const data = {};

            const token = localStorage.getItem('token');
            const dataType = localStorage.getItem('CZSL')

            if (dataType == 'Center') {
                data.idCentro = this.$route.params.id
            } else if (dataType == 'Zone') {
                data.idZona = this.$route.params.id
            } else if (dataType == 'Section') {
                data.idSecao = this.$route.params.id
            } else {
                data.id_lago = this.$route.params.id
            }

            if (this.combustivel) {
                data.tipoCombustivel = this.combustivel;
                data.data_registro = this.formatDataForDatabase(this.data_registro)
            } else {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }
            console.log(data)
            axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data);

                    const alert = alertController.create({
                        header: this.$t("Success"),
                        message: this.$t("successfully_registered!"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });

                    this.combustivel = ''
                    return;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        cadastrarEstavaEmGeral() {
            const data3 = {};

            const token = localStorage.getItem('token');
            const dataType = localStorage.getItem('CZSL')

            if (dataType == 'Center') {
                data3.idCentro = this.$route.params.id
            } else if (dataType == 'Zone') {
                data3.idZona = this.$route.params.id
            } else if (dataType == 'Section') {
                data3.idSecao = this.$route.params.id
            } else {
                data3.id_lago = this.$route.params.id
            }

            if (this.estavaEm) {
                data3.estavaEm = this.estavaEm;
                data3.data_registro = this.formatDataForDatabase(this.data_registro)
            } else {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }
            
            axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data3, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data);

                    const alert = alertController.create({
                        header: this.$t("Success"),
                        message: this.$t("successfully_registered!"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });

                    this.estavaEm = ''
                    return;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        cadastrarConsumoGeral() {
            const data4 = {};

            const token = localStorage.getItem('token');
            const dataType = localStorage.getItem('CZSL')

            if (dataType == 'Center') {
                data4.idCentro = this.$route.params.id
            } else if (dataType == 'Zone') {
                data4.idZona = this.$route.params.id
            } else if (dataType == 'Section') {
                data4.idSecao = this.$route.params.id
            } else {
                data4.id_lago = this.$route.params.id
            }

            if (this.consumo) {
                data4.consumo = this.consumo;
                data4.data_registro = this.formatDataForDatabase(this.data_registro)
            } else {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }
            console.log(data4)
            axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data4, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data);

                    const alert = alertController.create({
                        header: this.$t("Success"),
                        message: this.$t("successfully_registered!"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });

                    this.consumo = ''
                    return;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        cadastrarTipo() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/fuel/type`)
        },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        formatKg(value) {
            return value.replace(/[^\d.,]/g, '') + " Kg";
        },
        async fetchCombustiveis(empresa) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/combustiveis`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const allCombustiveis = response.data.items;

                this.combustiveis = allCombustiveis.filter(item => item.empresa == parseInt(empresa));
            } catch (error) {
                console.log(error);
            }
        },
        cadastrarTipoCombustivel() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getCombustivel`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.tipoCombustivel);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.combustivel) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.combustivel = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {

                                    const data = {};
                                    if (this.combustivel) {
                                        data.id_lago = this.$route.params.id
                                        data.tipoCombustivel = this.combustivel;
                                        data.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.combustivel = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data = {};
                        if (this.combustivel) {
                            data.id_lago = this.$route.params.id
                            data.tipoCombustivel = this.combustivel;
                            data.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.combustivel = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });


        },
        cadastrarEstavaEm() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getCombustivel`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.estavaEm);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.estavaEm) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.estavaEm = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data3 = {};
                                    if (this.estavaEm) {
                                        data3.id_lago = this.$route.params.id
                                        data3.estavaEm = this.estavaEm;
                                        data3.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data3)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data3, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.estavaEm = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data3 = {};
                        if (this.estavaEm) {
                            data3.id_lago = this.$route.params.id
                            data3.estavaEm = this.estavaEm;
                            data3.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data3)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data3, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.estavaEm = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarConsumo() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getCombustivel`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.consumo);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.consumo) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.consumo = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data4 = {};
                                    if (this.consumo) {
                                        data4.id_lago = this.$route.params.id
                                        data4.consumo = this.consumo;
                                        data4.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data4)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data4, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.consumo = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data4 = {};
                        if (this.consumo) {
                            data4.id_lago = this.$route.params.id
                            data4.consumo = this.consumo;
                            data4.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data4)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarCombustivel/create`, data4, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.consumo = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        }
    }
};
</script>
    
<style scoped>
ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>