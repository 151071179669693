<template>
    <ion-page>

        <ion-page id="main-content">

            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/cultivation/species"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Register") }} {{ $t("Species") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items" v-for="especie of especies" :key="especie.id">
                    <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="empresaSelecionada">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input id="speciesnameInput" v-model="especie.nome"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Scientific_Name") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input id="scientificInput" v-model="especie.nomeCientifico"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Initial_reduction") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input id="reductionInput" v-model="especie.reducao" type="number"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Decrease_Increment") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input id="decreaseInput" v-model="especie.diminuicao" type="number"></ion-input>
                    </ion-item>
                    <ion-label position="floating">{{ $t("Commercial_sizes") }} <span
                            style="color: red;">*</span></ion-label>
                    <div class="contentCommercial">

                        <div class="commercialSizes" v-for="(tm, index) in especie.tm1" :key="index">
                            <ion-item fill="outline">
                                <ion-input class="commercial-input" v-model="tm.a" type="number"
                                    :placeholder="$t('From')"></ion-input>
                            </ion-item>
                            <ion-item fill="outline">
                                <ion-input class="commercial-input" v-model="tm.b" type="number"
                                    :placeholder="$t('To')"></ion-input>
                            </ion-item>
                            <div class="iconDelete" style="width: 30px;display: flex; align-items: center;">
                                <ion-icon @click="deleteInput(especie, index)" :ios="closeOutline" :md="closeSharp"
                                    style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                            </div>
                        </div>

                        <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
                            <ion-icon @click="addInput(especie)" :ios="addOutline" :md="addSharp"
                                style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
                        </div>
                    </div>


                    <ion-button size="large" @click="cadastrarEspecie">{{ $t("Register") }}</ion-button>
                </div>

            </ion-content>

        </ion-page>
    </ion-page>
</template>
  
<script>
import { IonIcon, IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { addOutline, addSharp, closeOutline, closeSharp } from 'ionicons/icons';

export default {
    components: {
        IonIcon, IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            especies: [{
                nome: '',
                nomeCientifico: '',
                reducao: '',
                diminuicao: '',
                tm1: [{
                    a: '',
                    b: '',
                }
                ],
            }]
            ,
            empresas: "",
            empresa: this.empresa,
            addOutline,
            addSharp,
            closeOutline,
            closeSharp,
            empresaSelecionada: '',
            funcionalidades: '',
            master: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchUser()
        this.fetchEmpresas()
    },
    methods: {
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(14)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message:  this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async fetchUser() {
            const userId = localStorage.getItem('id');
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.nomeEmpresa = parseInt(localStorage.getItem('idEmpresa'));
                this.master = response.data.master
            } catch (error) {
                console.log(error);
            }
        },
        addInput(especie) {
            especie.tm1.push({
                a: '',
                b: '',
            })
        },
        async deleteInput(especie, index) {
            if (especie.tm1.length > 1) {
                especie.tm1 = especie.tm1.filter((tm, i) => i !== index);
            } else {
                const alert = await alertController.create({
                    header: this.$t("Invalid_action"),
                    message: this.$t("You_can_not_delete_all"),
                    buttons: ['OK']
                });
                await alert.present();
            }
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresas = response.data.items;
            } catch (error) {
                console.log(error);
            }
        },
        async cadastrarEspecie() {

            if (!this.especies[0].nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.especies[0].nomeCientifico) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Scientific_Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.especies[0].diminuicao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Decrease_Increment"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.especies[0].reducao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Initial_reduction"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            for (let i = 0; i < this.especies.length; i++) {
                const especie = this.especies[i];
                for (let j = 0; j < especie.tm1.length; j++) {
                    const produto = especie.tm1[j];
                    if (!produto.a || !produto.b) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Commercial_sizes"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                }
            }

            const especies = {
                especies: {
                    nome: this.especies[0].nome,
                    nomeCientifico: this.especies[0].nomeCientifico,
                    reducao: this.especies[0].reducao,
                    diminuicao: this.especies[0].diminuicao,
                    empresa: this.empresaSelecionada ? this.empresaSelecionada : this.nomeEmpresa,
                    tm1: this.especies[0].tm1
                }
            };
            const token = localStorage.getItem('token');
            await axios.post(`${config.apiUrl}/configs/species/create`, especies, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_registered!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                    });

                    window.location.replace('/cultivation/species')
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_registering"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });

        }

    }
}
</script>
  
<style scoped>
.contentCommercial {
    text-align: center;
}

.commercialSizes {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto 30px;
    row-gap: 1px;
    column-gap: 15px;
    justify-items: center;
    margin-bottom: 15px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: fit-content;
}</style>