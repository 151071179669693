<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/config/disease"></ion-back-button>
                    </ion-buttons>
          <ion-title>{{ $t("Register") }} {{ $t("Disease") }} </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding" :scroll-y="false">
        <div class="content-items">
          <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="empresaSelecionada">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="nome" ></ion-input>
          </ion-item>
          <IonItem fill="outline">
            <ion-label position="floating">{{ $t("Affected_Species") }} <span style="color: red;">*</span></ion-label>
            <ion-select position="floating" aria-label="Fruit" class="select"
            v-model="especie" @ionChange="selecionarEspecie($event)">
              <ion-content class="ion-padding" id="content-lista">
                <ion-list v-for="especie in especies" :key="especie.id">
                  <ion-select-option :value=especie.nome>{{ especie.nome }}</ion-select-option>
                </ion-list>
              </ion-content>
            </ion-select>
          </IonItem>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Used_medicine") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="medicamento" ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Chemical_quantity/m3") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="quantidade" type="number" ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Normal_reduction_%") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="reducao" type="number"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Feeding_change_%") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="alteracao" type="number"></ion-input>
          </ion-item>
          <ion-button size="large" expand="block" @click="cadastrarDoenca">{{ $t("Register") }}</ion-button>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>
  
<script>
import { IonBackButton, IonList,IonSelectOption,IonSelect,alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"

export default {
  components: {
   IonBackButton, IonList,IonSelectOption,IonSelect,IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
        return {
            especies: "",
            empresas: "",
            especie:'',
            reducao: "",
            alteracao: "", 
            medicamento: "",
            quantidade: "",
            nome:"",
            empresaSelecionada:'',
            funcionalidades:'',
            master: ''
        }
    },
    mounted() {
      this.getPermissoes();
        this.fetchEspecies()
        this.fetchEmpresas()
        this.fetchUser()
    },
  methods: {
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(20)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
            } catch (error) {
                console.log(error);
            }
        },
      async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.nomeEmpresa = parseInt(localStorage.getItem('idEmpresa'));
        this.master = response.data.master
      } catch (error) {
        console.log(error);
      }
    },
    async fetchEspecies() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/diseases/${userId}/species`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.especies = response.data.items;
                console.log(response)
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEspecie(event) {
            this.valueEspecie = event.target.value
        },
    async cadastrarDoenca() {

      if (!this.nome) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.especie) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Affected_Species"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.medicamento) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Used_medicine"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.quantidade) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Chemical_quantity/m3"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.reducao) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Normal_reduction_%"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.alteracao) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Feeding_change_%"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }


      const data = {
        nome: this.nome,
        alteracao: this.alteracao,
        reducao: this.reducao,
        quantidade: this.quantidade,
        medicamento: this.medicamento,
        especie: this.especie,
        empresa: this.empresaSelecionada ? this.empresaSelecionada : this.nomeEmpresa
      }
      const token = localStorage.getItem('token');
      await axios.post(`${config.apiUrl}/configs/disease/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          console.log(response.data.id);

          const alert = alertController.create({
            header: this.$t("successfully_registered!")
          });
          alert.then(_alert => {
            _alert.present();

            window.location.replace("/cultivation/disease")
          });
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_registering"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });
    }

  },

};
</script>
  
<style scoped>
ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

</style>