<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/cultivation/disease"></ion-back-button>
                    </ion-buttons>
          <ion-title>{{ $t("Edit") }} {{ $t("Disease") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding" >
        <div class="content-items">
          <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="doenca.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
          <ion-item v-if="doenca" fill="outline">
            <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="doenca.nome" ></ion-input>
          </ion-item>
          <IonItem fill="outline" v-if="especies">
            <ion-label position="floating">{{ $t("Affected_Species") }} <span style="color: red;">*</span></ion-label>
            <ion-select position="floating" aria-label="Fruit" class="select"
            v-model="doenca.especie" @ionChange="selecionarEspecie($event)">
              <ion-content class="ion-padding" id="content-lista">
                <ion-list v-for="especie in especies" :key="especie.id">
                  <ion-select-option :value=especie.nome>{{ especie.nome }}</ion-select-option>
                </ion-list>
              </ion-content>
            </ion-select>
          </IonItem>
          <ion-item v-if="doenca" fill="outline">
            <ion-label position="floating">{{ $t("Used_medicine") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="doenca.medicamento" ></ion-input>
          </ion-item>
          <ion-item v-if="doenca" fill="outline">
            <ion-label position="floating">{{ $t("Chemical_quantity/m3") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="doenca.quantidade" type="number" ></ion-input>
          </ion-item>
          <ion-item v-if="doenca" fill="outline">
            <ion-label position="floating">{{ $t("Normal_reduction_%") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="doenca.reducao" type="number" ></ion-input>
          </ion-item>
          <ion-item v-if="doenca" fill="outline">
            <ion-label position="floating">{{ $t("Feeding_change_%") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="doenca.alteracao" type="number" ></ion-input>
          </ion-item>
          <ion-button size="large" expand="block" @click="atualizarDoenca">{{ $t("Register") }}</ion-button>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>

<script>
import { IonBackButton, IonList,IonSelectOption,IonSelect,alertController, IonButton,  IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ref } from 'vue'; 

export default {
  components: {
    IonBackButton, IonList,IonSelectOption,IonSelect,IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
        const doenca = ref(null);
        return {
          doenca,
          especies: "",
          selectValue: '',
          empresas: "",
          funcionalidades:'',
            master: ''
        }
    },
    mounted() {
      this.getPermissoes();
        this.fetchDisease()
        this.fetchEspecies()
        this.fetchEmpresas()
        this.fetchUser()
    },
    
  methods: {
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(19)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
            } catch (error) {
                console.log(error);
            }
        },
      async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.nomeEmpresa = parseInt(localStorage.getItem('idEmpresa'));
        this.master = response.data.master
      } catch (error) {
        console.log(error);
      }
    },
    async fetchEspecies() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/diseases/${userId}/species`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.especies = response.data.items;
                console.log(response.data.items)
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEspecie(event) {
            this.valueEspecie = event.target.value
        },
    async fetchDisease() {
      const doencaId = this.$route.params.id
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/diseases/${doencaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.doenca = response.data;
                const selectedValue = response.data.especie;
                this.selectValue = selectedValue;
            } catch (error) {
                console.log(error);
            }
        },
    async atualizarDoenca() {
      
      if (!this.doenca.nome){
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.doenca.medicamento){
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Used_medicine"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.doenca.quantidade) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Chemical_quantity/m3"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.doenca.reducao){
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Normal_reduction_%"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.doenca.alteracao){
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Feeding_change_%"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      

      const data = {
        nome: this.doenca.nome,
        alteracao: this.doenca.alteracao,
        reducao: this.doenca.reducao,
        quantidade: this.doenca.quantidade,
        medicamento: this.doenca.medicamento,
        especie: this.doenca.especie,
        empresa: this.doenca.empresa
      }
      const doencaId = this.$route.params.id
      const token = localStorage.getItem('token');
      await axios.put(`${config.apiUrl}/configs/diseases/edit/${doencaId}`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          console.log(response.data.id);

          const alert = alertController.create({
            header: this.$t("successfully_updated!")
          });
          alert.then(_alert => {
            _alert.present();

          });
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_updating"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });
        window.location.replace('/cultivation/disease')
    }

  },

};
</script>

<style scoped>
ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

</style>