<template>
    <ion-page>

        <ion-page id="main-content">

            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/cultivation/species"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Edit") }} {{ $t("Species") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">

                <div class="content-items" v-if="especies">
                    <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="especies.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input id="speciesnameInput"  v-model="especies.nome" ></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Scientific_Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input id="scientificInput"  v-model="especies.nomeCientifico"
                           ></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Initial_reduction") }} <span style="color: red;">*</span></ion-label>
                        <ion-input id="reductionInput"  v-model="especies.reducao"
                            ></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Decrease_Increment") }} <span style="color: red;">*</span></ion-label>
                        <ion-input id="decreaseInput" type="number"  v-model="especies.diminuicao" ></ion-input>
                    </ion-item>
                    <ion-label position="floating">{{ $t("Commercial_sizes") }} <span style="color: red;">*</span></ion-label>
                    <div class="contentCommercial">

                        <div class="commercialSizes" v-for="(tm, index) of especies.tm1" :key="index">
                            <ion-item fill="outline" >
                                    <ion-input class="commercial-input" v-model="tm.a" type="number"
                                    :placeholder="$t('From')"></ion-input>
                                </ion-item>
                                <ion-item fill="outline">
                                    <ion-input class="commercial-input" v-model="tm.b" type="number"
                                    :placeholder="$t('To')"></ion-input>
                                </ion-item>
                                <div class="iconDelete" style="width: 30px;display: flex; align-items: center;">
                                    <ion-icon @click="deleteInput(especies, index)" :ios="closeOutline" :md="closeSharp"
                                        style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                                </div>
                        </div>

                        <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
                            <ion-icon @click="addInput(especies)" :ios="addOutline" :md="addSharp"
                                style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
                        </div>

                    </div>

                    <ion-button size="large" @click="cadastrarEspecie">{{ $t("Register") }}</ion-button>
                </div>

            </ion-content>

        </ion-page>
    </ion-page>
</template>
  
<script>
import { IonIcon,IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ref } from 'vue';
import { addOutline, addSharp, closeOutline, closeSharp } from 'ionicons/icons';

export default {
    components: {
        IonIcon,IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        const especies = ref(null);
        return {
            especies,
            items2: this.items2,
            empresa: this.empresa,
            addOutline, 
            addSharp, 
            closeOutline,
            closeSharp,
          empresas: "",
          funcionalidades:'',
            master: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchSpecie()
        this.fetchEmpresas()
        this.fetchUser()
    },

    methods: {
        async fetchUser() {
            const token = localStorage.getItem('token');
          const userId = localStorage.getItem('id');
          try {
              const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              this.empresa = parseInt(localStorage.getItem('idEmpresa'));
              this.master = response.data.master
          } catch (error) {
              console.log(error);
          }
          },
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(13)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        isEmpty(str) {
            return str.trim() == '';
        },
        addInput(especies) {
            especies.tm1.push({
                a: '',
                b: ''
          })
        },
        async deleteInput(especie, index) {
            if (especie.tm1.length > 1) {
                especie.tm1 = especie.tm1.filter((tm, i) => i !== index);
            } else {
                const alert = await alertController.create({
                    header: this.$t("Invalid_action"),
                    message: this.$t("You_can_not_delete_all"),
                    buttons: ['OK']
                });
                await alert.present();
            }
        },
        async fetchSpecie() {
            const token = localStorage.getItem('token');
            const specieId = this.$route.params.id
            try {
                const response = await axios.get(`${config.apiUrl}/configs/species/${specieId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.especies = response.data.especies;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items

            } catch (error) {
                console.log(error);
            }
        },
        async cadastrarEspecie() {
            

            if (!this.especies.nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.especies.nomeCientifico) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Scientific_Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.especies.diminuicao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Decrease_Increment"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.especies.reducao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Initial_reduction"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            for (let i = 0; i < this.especies.tm1.length; i++) {
                const tm = this.especies.tm1[i];
                if (!tm.a || !tm.b) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Commercial_sizes"),
                    buttons: ['OK']
                        });
                        await alert.present();
                        return false;
                }
                }

                const especies = {
                    especies: {
                        nome: this.especies.nome,
                        nomeCientifico: this.especies.nomeCientifico,
                        reducao: this.especies.reducao,
                        diminuicao: this.especies.diminuicao,
                        empresa: this.especies.empresa,
                        tm1: this.especies.tm1
                    }
                    };
                    const token = localStorage.getItem('token');
            const specieId = this.$route.params.id
            await axios.put(`${config.apiUrl}/configs/species/edit/${specieId}`, especies,{
                        headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        }
            })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_updated!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                    });
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_updating"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });
            window.location.replace('/cultivation/species')
        }

    }
}
</script>
  
<style scoped>
.contentCommercial {
    text-align: center;
}

ion-button.commercialButton {
    margin-top: 10px;
    --border-radius: 150px !important;
    width: 35px;
    height: 35px;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 0px !important;
}

.commercialSizes {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto 30px;
    row-gap: 1px;
    column-gap: 15px;
    justify-items: center;
    margin-bottom: 15px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: fit-content;
}

</style>