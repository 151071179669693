<template>
    <ion-page>

        <ion-page id="main-content">

            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/cultivation/feed"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Register") }} {{ $t("Feed") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">

                <div class="content-items">
                    <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="empresaSelecionada">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Manufacturer") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="alimento.fabricante"
                            @ionChange="selecionarFabricante($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in fabricantes" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                                <ion-select-option value="novoM">{{ $t("New") }}</ion-select-option>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline" v-if="alimento.fabricante == 'novoM'">
                        <ion-label position="floating">{{ $t("Register") }} {{ $t("Manufacturer") }}</ion-label>
                        <ion-input v-model="nomeFabricante"></ion-input>
                    </ion-item>
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Species") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            @ionChange="selecionarEspecie($event)" v-model="alimento.especie" id="companyInput">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="especie in especies" :key="especie.id">
                                    <ion-select-option :value=especie.nome v-model="especie.nome">{{ especie.nome
                                    }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Type_of_Feed_name") }}</ion-label>
                        <ion-input v-model="alimento.tipoAlimento"></ion-input>
                    </ion-item>
                    <ion-label>{{ $t("Names") }} <span style="color: red;">*</span></ion-label>
                    <ion-item fill="outline">

                        <ion-input slot="start" v-model="newTag" @keydown.enter.prevent="addTag"
                            :placeholder="$t('Add_name')"></ion-input>
                        <ion-chip-group v-model="alimento.tags">
                            <ion-chip v-for="(tag, index) in alimento.tags" :key="index">
                                <ion-label>{{ tag }}</ion-label>
                                <ion-icon :ios="closeCircleOutline" :md="closeCircleSharp"
                                    @click="removeTag(index)"></ion-icon>
                            </ion-chip>
                        </ion-chip-group>

                    </ion-item>
                   

                    <div class="contentCommercial" style="overflow-x: auto;">
                        <div class="divTempTitle">
                            <IonLabel class="TempTitle">Temperatures</IonLabel>
                        </div>
                        <table>
                            <tr style="position: relative;">
                                <th colspan="3"></th>
                                <td v-for="(temperatura, j) in alimento.tt1" :key="temperatura.id">
                                    <IonItem fill="outline" @click="linhaAtiva = j" mode="md">
                                        <ion-button v-show="linhaAtiva == j"
                                            :class="{ 'removeButtonT': true, 'fade-out': linhaAtiva == j }"
                                            @click="removeTemperatura(j)">
                                            <ion-icon :ios="closeOutline" :md="closeSharp"></ion-icon>
                                        </ion-button>
                                        <ion-input class="tableInput" v-model="temperatura.c1"
                                            @input="temperatura.c1 = formatC($event.target.value)" fill="solid"></ion-input>
                                    </IonItem>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t("Diameter") }}</th>
                                <th colspan="2">{{ $t("Sizes") }}</th>
                                <td v-for="(temperatura, j) in alimento.tt1" :key="temperatura.id">
                                    <IonItem fill="outline" @click="linhaAtiva = j" mode="md">
                                        <ion-input class="tableInput" v-model="temperatura.c2"
                                            @input="temperatura.c2 = formatC($event.target.value)" fill="solid"></ion-input>
                                    </IonItem>
                                </td>
                            </tr>
                            <tbody>
                                <tr v-for="(peso, i) in alimento.tm1" :key="peso.id" style="position: relative;">

                                    <td>
                                        <IonItem fill="outline" @click="colunaAtiva = i" mode="md">
                                            <ion-button v-show="colunaAtiva == i"
                                                :class="{ 'removeButtonF': true, 'fade-out': colunaAtiva == i }"
                                                @click="removeSize(i)">
                                                <ion-icon :ios="closeOutline" :md="closeSharp"></ion-icon>
                                            </ion-button>
                                            <ion-input class="tableInput" v-model="peso.d" @input="peso.d = formatMM($event.target.value)" fill="solid"></ion-input>
                                        </IonItem>
                                    </td>
                                    <td>
                                        <IonItem fill="outline" @click="colunaAtiva = i" mode="md">
                                            <ion-input class="tableInput" v-model="peso.a" fill="solid"></ion-input>
                                        </IonItem>
                                    </td>
                                    <td>
                                        <IonItem fill="outline" @click="colunaAtiva = i" mode="md">
                                            <ion-input class="tableInput" v-model="peso.b" fill="solid"></ion-input>
                                        </IonItem>
                                    </td>
                                    <td v-for="(temperatura, j) in alimento.tt1" :key="temperatura.id">
                                        <ion-item fill="outline" @click="colunaELinha(j, i)" mode="md">
                                            <ion-input class="tableInput" v-model="temperatura.coeficientes[i]" fill="solid"
                                                @input="temperatura.coeficientes[i] = formatP($event.target.value)"></ion-input>
                                        </ion-item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tButtonDiv">
                        <ion-button class="tButton2" @click="addSize()"> <ion-icon class="addButtonIcon" :ios="addOutline"
                                :md="addSharp"></ion-icon> {{ $t("Add_row") }}</ion-button>
                        <ion-button class="tButton2" @click="addTemperatura()"> <ion-icon class="addButtonIcon"
                                :ios="addOutline" :md="addSharp"></ion-icon> {{ $t("Add_column") }}</ion-button>
                    </div>
                    <ion-button size="large" @click="cadastrarEspecie">{{ $t("Register") }}</ion-button>
                    <!-- Sof delete -->
                    <!-- <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Type_of_Feed") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="alimento.tipoAlimento"
                            @ionChange="selecionarTipo($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in alimentos" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                                <ion-select-option value="novoT">{{ $t("New") }}</ion-select-option>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline" v-if="alimento.tipoAlimento == 'novoT'">
                        <ion-label position="floating">{{ $t("Register") }} {{ $t("Type_of_Feed") }}</ion-label>
                        <ion-input v-model="nomeAlimento"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="alimento.nome"></ion-input>
                    </ion-item>
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Batch") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="alimento.lote"
                            @ionChange="selecionarLote($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in lotes" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Warehouse") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="alimento.armazem"
                            @ionChange="selecionarArmazem($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in armazens" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>  -->
                     
                   
                </div>
            </ion-content>

        </ion-page>
    </ion-page>
</template>

<script>
import {
    IonBackButton,
    IonChipGroup, 
    IonChip, 
    IonList, IonSelect, IonIcon, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController
} from '@ionic/vue';
import axios from 'axios';
import { closeCircleOutline, closeCircleSharp, closeOutline, closeSharp, addOutline, addSharp } from 'ionicons/icons';
import { config } from "../../config"

export default {
    components: {
        IonBackButton, IonList, IonSelect,
        IonChipGroup, 
        IonChip, 
        IonIcon, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            alimento: {
                especiesId: this.valueEspecie,
                nome: '',
                fabricante: '',
                especie: '',
                empresa: '',
                tipoAlimento: '',
                tm1: [],
                tt1: [],
                armazem: '',
                lote: '',
                tags: []
            },
            items2: this.items2,
            fabricantes: "",
            nomeFabricante: '',
            especies: "",
            empresas: "",
            alimentos: '',
            nomeAlimento: '',
            empresaSelecionada: '',
            funcionalidades: '',
            master: '',
            armazens: '',
            lotes: '',
            newTag: '',
            closeCircleOutline,
            closeCircleSharp,
            closeOutline,
            closeSharp,
            addOutline,
            addSharp,
            linhaAtiva: null,
            colunaAtiva: null,
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchFabricantes()
        this.fetchEspecies()
        this.fetchEmpresas()
        this.fetchUser()
        // this.fetchTipos()
        this.buscarArmazens()
        this.fetchLotes()
        this.addTemperaturaInicial()
        this.addSizeInicial()
    },
    methods: {
        colunaELinha(j, i) {
            this.linhaAtiva = j
            this.colunaAtiva = i
        },
        addTemperaturaInicial() {
            this.alimento.tt1 = [];

            this.alimento.tt1.push({
                c1: '',
                c2: '',
                coeficientes: new Array(this.alimento.tm1.length).fill(null)
            });
        },
        addSizeInicial() {
            this.alimento.tm1 = [];

            this.alimento.tm1.push({
                a: '',
                b: '',
                d: ''
            });
        },
        addTemperatura() {
            this.alimento.tt1.push({
                c1: '',
                c2: '',
                coeficientes: []
            });

            this.alimento.tt1.forEach(temperatura => {
                if (temperatura.coeficientes.length < this.alimento.tm1.length) {
                    temperatura.coeficientes = [
                        ...temperatura.coeficientes,
                        ...Array(this.alimento.tm1.length - temperatura.coeficientes.length)
                    ];
                }
            });
        },
        addSize() {
            this.alimento.tm1.push({
                a: '',
                b: '',
                d: ''
            });
        },
        removeSize(index) {
            if (this.alimento.tm1.length > 1) {

                this.alimento.tm1.splice(index, 1);

                this.alimento.tt1.forEach(temperatura => {
                    temperatura.coeficientes.splice(index, 1);
                });

                this.alimento.tt1.forEach(temperatura => {
                    if (temperatura.coeficientes.length < this.alimento.tm1.length) {
                        temperatura.coeficientes = [
                            ...temperatura.coeficientes,
                            ...Array(this.alimento.tm1.length - temperatura.coeficientes.length)
                        ];
                    }
                });
            }
        },
        removeTemperatura(index) {
            if (this.alimento.tt1.length > 1) {

                this.alimento.tt1.splice(index, 1);
            }
        },
        addTag() {
            if (this.newTag.trim() !== '') {
                this.alimento.tags.push(this.newTag.trim());
                this.newTag = '';
            }
        },
        removeTag(index) {
            this.alimento.tags.splice(index, 1);
        },
        async buscarArmazens() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/warehouse?search=${this.termoPesquisa}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const empresaId = parseInt(localStorage.getItem('idEmpresa'), 10);

                const items = response.data.items.filter((item) => {
                    const empresa = parseInt(item.empresa, 10);
                    return empresa == empresaId;
                }).map((item) => {
                    return {
                        nome: item.nome,
                        id: item.id
                    };
                });

                this.armazens = items;
            } catch (error) {
                return console.error("Erro ao buscar nomes ", error);
            }
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(17)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        // async fetchTipos() {
        //     const token = localStorage.getItem('token');
        //     const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);

        //     try {
        //         const response = await axios.get(`${config.apiUrl}/configs/feedtypes`, {
        //             headers: {
        //                 'Authorization': `Bearer ${token}`,
        //                 'Content-Type': 'application/json',
        //             },
        //         });
        //         this.data = response.data.items;
        //         this.alimentos = this.data.filter(item => item.empresa == idEmpresa);
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresas = response.data.items;

            } catch (error) {
                console.log(error);
            }
        },
        async fetchUser() {
            const userId = localStorage.getItem('id');
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresa = parseInt(localStorage.getItem('idEmpresa'));
                this.master = response.data.master
            } catch (error) {
                console.log(error);
            }
        },
        // async showBoxes() {
        //     const nomeEspecie = {
        //         nomeEspecie: {
        //             nomeEspecie: this.valueEspecie
        //         }
        //     }
        //     const token = localStorage.getItem('token');
        //     console.log(this.valueEspecie)
        //     try {
        //         const response = await axios.put(`${config.apiUrl}/configs/species/byName`, nomeEspecie, {
        //             headers: {
        //                 'Authorization': `Bearer ${token}`,
        //                 'Content-Type': 'application/json',
        //             },
        //         });
        //         const tamanhos = response.data.especies.tm1

        //         if (this.alimento.tm1.length > 0) {
        //             this.alimento.tm1 = [];
        //         }

        //         tamanhos.forEach(tamanho => {
        //             console.log(tamanho)
        //             this.alimento.tm1.push({
        //                 a: tamanho.a,
        //                 b: tamanho.b,
        //             });
        //         })

        //         this.addTemperaturaInicial();
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
        async fetchEspecies() {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);
            try {
                const response = await axios.get(`${config.apiUrl}/configs/diseases/${userId}/species`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.data = response.data.items;
                this.especies = this.data.filter(item => item.empresa == idEmpresa);
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEspecie($event) {
            this.valueEspecie = $event.target.value
            console.log(this.valueEspecie)
            // this.showBoxes(this.valueEspecie);
        },
        selecionarFabricante(event) {
            this.valueFabricante = event.target.value
        },
        formatG(value) {
            return value.replace(/[^\d.,]/g, '') + " g";
        },
        formatMM(value) {
            return value.replace(/[^\d.,]/g, '') + " mm";
        },
        formatP(value) {
            return value.replace(/[^\d.,]/g, '') + " %";
        },
        formatC(value) {
            return value.replace(/[^\d.,]/g, '') + " °C";
        },
        async fetchFabricantes() {
            const token = localStorage.getItem('token');
            const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);
            try {
                const response = await axios.get(`${config.apiUrl}/configs/manufacturers`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.data = response.data.items;
                this.fabricantes = this.data.filter(item => item.empresa == idEmpresa);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchLotes() {
            const token = localStorage.getItem('token');
            const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);
            try {
                const response = await axios.get(`${config.apiUrl}/configs/batches`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.data = response.data.items;
                this.lotes = this.data.filter(item => item.empresa == idEmpresa);
            } catch (error) {
                console.log(error);
            }
        },
        async cadastrarEspecie() {

            // if (!this.alimento.tipoAlimento && !this.nomeAlimento) {
            //     const alert = await alertController.create({
            //         header: this.$t("Error"),
            //         message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Type_of_Feed"),
            //         buttons: ['OK']
            //     });
            //     await alert.present();
            //     return;

            // }

            if (!this.nomeFabricante && !this.alimento.fabricante) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Manufacturer"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.alimento.especie) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Specie"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.alimento.tipoAlimento) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Type_of_Feed_name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            // if (!this.alimento.armazem) {
            //     const alert = await alertController.create({
            //         header: this.$t("Error"),
            //         message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Warehouse"),
            //         buttons: ['OK']
            //     });
            //     await alert.present();
            //     return;

            // }

            // if (!this.alimento.lote) {
            //     const alert = await alertController.create({
            //         header: this.$t("Error"),
            //         message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Batch"),
            //         buttons: ['OK']
            //     });
            //     await alert.present();
            //     return;

            // }

            // // todo: Ver o que é isso.
            // // não existe o campo de tags mas ele valida, por isso comentei
            // if (this.alimento.tags.length == 0) {
            //     const alert = await alertController.create({
            //         header: this.$t("Error"),
            //         message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Reasons_for_fasting"),
            //         buttons: ['OK']
            //     });
            //     await alert.present();
            //     return;
            // }

            for (let i = 0; i < this.alimento.tm1.length; i++) {
                const tm = this.alimento.tm1[i];
                if (!tm.a || !tm.b || !tm.d) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name_or_sizes"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }
            }

            for (let i = 0; i < this.alimento.tt1.length; i++) {
                const tm = this.alimento.tt1[i];
                if (!tm.c1 || !tm.c2) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Temperature"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }
            }

            for (let i = 0; i < this.alimento.tt1.length; i++) {
                const temperatura = this.alimento.tt1[i];

                for (let j = 0; j < temperatura.coeficientes.length; j++) {
                    const q = temperatura.coeficientes[j];

                    if (q == null || q == undefined) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Coefficients"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                }
            }


            // if (this.nomeAlimento !== "") {
            //     const objetoEncontrado = this.alimentos.filter(objeto => objeto.nome == this.nomeAlimento);
            //     console.log(objetoEncontrado)
            //     if (objetoEncontrado.length > 0) {
            //         const alert = await alertController.create({
            //             header: this.$t("Error"),
            //             message: this.$t("The_following_field_cannot_be_inserted") + " " + this.$t("Type_of_Feed"),
            //             buttons: ['OK']
            //         });
            //         await alert.present();
            //         return;
            //     } else {
            //         const idEmpresa = localStorage.getItem('idEmpresa')
            //         const id = null
            //         const data3 = {
            //             nome: this.nomeAlimento,
            //             empresa: idEmpresa
            //         }
            //         const token = localStorage.getItem('token');
            //         axios.post(`${config.apiUrl}/configs/feedtypes/createEdit/${id}`, data3, {
            //             headers: {
            //                 'Authorization': `Bearer ${token}`,
            //                 'Content-Type': 'application/json',
            //             },
            //         })
            //             .then(response => {
            //                 console.log(response.data.id);

            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             });
            //     }

            // }

            if (this.nomeFabricante !== "") {

                const idEmpresa = localStorage.getItem('idEmpresa')
                const id = null
                const data2 = {
                    nome: this.nomeFabricante,
                    empresa: idEmpresa
                }
                const token = localStorage.getItem('token');
                axios.post(`${config.apiUrl}/configs/manufacturers/createEdit/${id}`, data2, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        console.log(response.data.id);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            }

            // if (this.alimento.tipoAlimento == 'novoT') {
            //     this.alimento.tipoAlimento = ''
            // }

            if (this.alimento.fabricante == 'novoM') {
                this.alimento.fabricante = ''
            }

            const alimento = {
                tipoAlimento: this.nomeAlimento ? this.nomeAlimento : this.alimento.tipoAlimento,
                especiesId: this.valueEspecie,
                // nome: this.alimento.nome,
                fabricante: this.nomeFabricante ? this.nomeFabricante : this.alimento.fabricante,
                especie: this.valueEspecie,
                empresa: this.empresaSelecionada ? this.empresaSelecionada : this.empresa,
                tm1: this.alimento.tm1,
                tt1: this.alimento.tt1,
                armazem: this.alimento.armazem,
                // lote: this.alimento.lote,
                tags: this.alimento.tags.join(','),

            }

            const token = localStorage.getItem('token');
            await axios.post(`${config.apiUrl}/configs/feeds/create`, alimento, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_registered!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                    });

                    window.location.replace('/cultivation/feed')
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_registering"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });


        }

    }
}
</script>

<style scoped>
.commercial-input {
    font-size: 14px;
}

.sizesTemp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
}

.contentCommercial {
    max-width: 100%;
}

ion-button.commercialButton {
    margin-top: 10px;
    --border-radius: 150px !important;
    width: 35px;
    height: 35px;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 0px !important;
}

.removeButtonF::part(native) {
    transition: 0.5s;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    width: 25px !important;
    height: 48px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    --background: rgba(255, 0, 0);
}

.removeButtonT::part(native) {
    transition: 0.5s;
    position: absolute;
    z-index: 10;
    top: 13.5%;
    width: 125%;
    left: -25%;
    height: 13px;
    transform: translateY(-50%);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    --background: rgba(255, 0, 0);
    font-size: 8px;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-out {
    animation: fadeOut 0.6s ease;
}

.commercialSizes {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 1px;
    column-gap: 15px;
    justify-items: center;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    z-index: 5;
    height: fit-content;
}

.tableInput {
    width: 40px;
    margin-left: 10px;
}

.tButton {
    width: max-content;
    --background: transparent !important;
    border: 2px #0049b1 solid;
    border-radius: 4px;
    color: #0049b1;
}

.tButton2 {
    text-transform: none;
}

.divTempTitle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.TempTitle {
    font-weight: 700;
    margin-bottom: 10px;
}

.tButtonR {
    width: max-content;
    --background: transparent !important;
    border: 2px rgb(226, 18, 18) solid;
    border-radius: 4px;
    color: rgb(226, 18, 18);
}

.addButtonIcon {
    margin-right: 10px;
}

.tButtonDiv {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap-reverse !important;
}</style>