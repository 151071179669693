<template>
    <ion-page>

        <ion-page id="main-content">

            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/users"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Edit") }} {{ $t("User") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">

                <div class="content-items" v-if="!this.master">
                    <ion-item v-if="user" fill="outline">
                        <ion-label position="floating">{{ $t("Complete_Name") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="user.nome"></ion-input>
                    </ion-item>
                    <ion-item v-if="user" fill="outline" ref="item">
                        <ion-label position="floating">Email <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="email" :value="user.email" @ionInput="validate" @ionBlur="markTouched"
                            ref="email"></ion-input>
                    </ion-item>
                    <ion-item v-if="emailExiste">
                        <ion-text color="danger">{{ $t("This_email_is_already_in_use.") }}</ion-text>
                    </ion-item>
                    <IonItem fill="outline" v-if="items">
                        <ion-label position="floating">{{ $t("Group") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" side="top" v-model="grupo"
                            @ionChange="selecionarGrupo($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in items" :key="item.id">
                                    <ion-select-option :value="item.id">{{ item.nome
                                    }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item v-if="user" fill="outline">
                        <ion-label position="stacked">{{ $t("Password") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha" type="password" :placeholder="$t('Keep the field empty')"></ion-input>
                    </ion-item>
                    <ion-item v-if="user" fill="outline">
                        <ion-label position="stacked">{{ $t("Confirm_Password") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha2" type="password" :placeholder="$t('Keep the field empty')"></ion-input>
                    </ion-item>

                    <ion-button size="large" @click="cadastrarUsuario">{{ $t("Confirm") }}</ion-button>
                </div>
                <div class="content-items" v-else>
                    <ion-item v-if="user" fill="outline">
                        <ion-label position="floating">{{ $t("Complete_Name") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="user.nome"></ion-input>
                    </ion-item>
                    <ion-item v-if="user" fill="outline" ref="item">
                        <ion-label position="floating">Email <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="email" :value="user.email" @ionInput="validate" @ionBlur="markTouched"
                            ref="email"></ion-input>
                    </ion-item>
                    <ion-item v-if="emailExiste">
                        <ion-text color="danger">{{ $t("This_email_is_already_in_use.") }}</ion-text>
                    </ion-item>
                    <div class="empresaGru"
                        style="display: flex;flex-direction: row;gap: 10px;width: 100%; align-items: center;"
                        v-for="(empresa, index) in user.empresas" :key="index">
                        <IonItem fill="outline" style="width: 50%;" v-if="empresas">
                            <ion-label position="floating">{{ $t("Company") }} <span
                                    style="color: red;">*</span></ion-label>
                            <ion-select position="floating" aria-label="Fruit" class="select" v-model="empresa.empresa">
                                <ion-content class="ion-padding" id="content-lista">
                                    <ion-list v-for="item in empresas" :key="item.id">
                                        <ion-select-option :value="item.id" v-if="!isEmpresaSelecionada(item.id, index)">{{
                                            item.nome }}</ion-select-option>
                                    </ion-list>
                                </ion-content>
                            </ion-select>
                        </IonItem>
                        <IonItem fill="outline" style="width: 50%;" v-if="empresas">
                            <ion-label position="floating">{{ $t("Group") }} <span style="color: red;">*</span></ion-label>
                            <ion-select position="floating" aria-label="Fruit" class="select" side="top"
                                v-model="empresa.grupo" @ionChange="selecionarGrupo($event)">
                                <ion-content class="ion-padding" id="content-lista">
                                    <ion-list v-for="e in gruposEmpresa(empresa)" :key="e.id">
                                        <ion-select-option :value="e.id">{{ e.nome
                                        }}</ion-select-option>
                                    </ion-list>
                                </ion-content>
                            </ion-select>
                        </IonItem>
                        <div class="iconDelete" style="width: 30px;">
                            <ion-icon @click="removerEmpresa(empresa.empresa)" :ios="closeOutline" :md="closeSharp" style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                        </div>
                    </div>
                    <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
                            <ion-icon @click="adicionarEmpresa()" :ios="addOutline" :md="addSharp" style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
                    </div>

                    <ion-item v-if="user" fill="outline">
                        <ion-label position="stacked">{{ $t("Password") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha" type="password" :placeholder="$t('Keep the field empty')"></ion-input>
                    </ion-item>
                    <ion-item v-if="user" fill="outline">
                        <ion-label position="stacked">{{ $t("Confirm_Password") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha2" type="password" :placeholder="$t('Keep the field empty')"></ion-input>
                    </ion-item>

                    <ion-button size="large" @click="cadastrarUsuario">{{ $t("Confirm") }}</ion-button>
                </div>
            </ion-content>

        </ion-page>
    </ion-page>
</template>
  
<script>
import { IonIcon,IonList, IonBackButton, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController, IonText } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ellipsisVerticalSharp, addOutline, addSharp, closeOutline, closeSharp } from 'ionicons/icons';
import { ref } from 'vue';

export default {
    components: {
        IonIcon,IonList, IonBackButton, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonText
    },
    data() {
        const user = ref(null);
        const email = ref(user.value ? user.value.email : '');
        return {
            user,
            email,
            emails: [],
            emailExiste: false,
            items: '',
            senha: '',
            senha2: '',
            funcionalidades: '',
            master: '',
            empresas: "",
            grupo: '', 
            addOutline, 
            addSharp, 
            closeOutline, 
            closeSharp
        }
    },
    mounted() {
        this.getPermissoes();
        this.getEmails()
        this.isMaster()

        this.fetchEmpresas()
    },


    methods: {
        isEmpresaSelecionada(empresaId, currentIndex) {
            for (let i = 0; i < currentIndex; i++) {
                if (this.user.empresas[i].empresa == empresaId) {
                    return true;
                }
            }
            return false;
        },
        gruposEmpresa(empresa) {
            for (let e of this.empresas) {
                if (e.id == empresa.empresa) return e.grupos;
            }
        },
        async adicionarEmpresa() {

            const ultimaEmpresa = this.user.empresas[this.user.empresas.length - 1];

            if (ultimaEmpresa.empresa && ultimaEmpresa.grupo) {
                this.user.empresas.push({
                    empresa: '', grupo: ''
                });
            } else {
                const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("Fill_in_all_company_fields")
                        });
                        await alert.present();
            }
        },
        async removerEmpresa(id) {
            if (this.user.empresas.length > 1) {
                this.user.empresas = this.user.empresas.filter(empresa => empresa.empresa !== id);
            } else {
                const alert = await alertController.create({
                    header: this.$t("Invalid_action"),
                    message: this.$t("You_can_not_delete_all"),
                    buttons: ['OK']
                });
                await alert.present();
            }
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
            } catch (error) {
                console.log(error);
            }
        },
        async isMaster(){
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.master = response.data.master
                this.fetchUser()
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUser() {
            const token = localStorage.getItem('token');
            const userId2 = this.$route.params.id;
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId2}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.user = response.data
                console.log(response)
                this.empresa = parseInt(localStorage.getItem('idEmpresa'));
                const empresas = this.user.empresas || [];
                const grupos = empresas
                    .filter((empresa) => parseInt(empresa.empresa) == this.empresa)
                    .map((empresa) => empresa.grupo);

                this.grupo = grupos.length > 0 ? grupos[0] : null;
                this.fetchItems()
            } catch (error) {
                console.log(error);
            }
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(7)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message:  this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        selecionarGrupo(event) {
            this.idGrupoSelecionado = event.target.value
        },
        async fetchItems() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users_groups_normal`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                if (!this.master) {
                    const { nomes, ids, empresas } = response.data.items;
                    const empresaId = parseInt(localStorage.getItem('idEmpresa'));

                    this.items = nomes
                        .map((nome, index) => ({
                            id: ids[index],
                            nome,
                            empresa: empresas[index]
                        }))
                        .filter((item) => {
                            const empresa = parseInt(item.empresa);
                            return empresa == empresaId;
                        });
                    return this.items;
                } else {

                    const { nomes, ids } = response.data.items;
                    this.items = nomes.map((nome, index) => ({
                        id: ids[index],
                        nome
                    }));
                    console.log(this.items)
                }

            } catch (error) {
                console.log(error);
            }
        },
        async getEmails(email) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users/emails`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.emails = response.data.emails;

                return this.emails.includes(email);
            } catch (error) {
                console.error(error);
            }
        },

        validateEmail(email) {
            const validTLDs = ["com", "net", "org", "io", "br"];
            return email.match(`^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\\.(${validTLDs.join("|")})$`);
        },

        validate(ev) {
            const value = ev.target.value;

            this.$refs.item.$el.classList.remove('ion-valid');
            this.$refs.item.$el.classList.remove('ion-invalid');

            if (value == '') return;

            this.validateEmail(value)
                ? this.$refs.item.$el.classList.add('ion-valid')
                : this.$refs.item.$el.classList.add('ion-invalid');
        },

        markTouched() {
            this.$refs.item.$el.classList.add('ion-touched')
        },

        async cadastrarUsuario() {
            if (this.master) {
                const senhaRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&*]{8,}$/;

                if (!this.user.nome) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;

                }

                if ((this.emailExiste == true)) {

                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("The_following_field_cannot_be_inserted") + " " + " Email ",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                if (!this.validateEmail(this.email)) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }


                if (!this.email) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                const ultimaEmpresa = this.user.empresas[this.user.empresas.length - 1];

                    if (!ultimaEmpresa.empresa) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Company"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if (!ultimaEmpresa.grupo) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Group"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                if (this.senha != '') {
                    if (!senhaRegex.test(this.senha)) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("The_password_must_contain"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                }

                if (this.senha2 != '') {
                    if (!senhaRegex.test(this.senha2)) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("The_password_must_contain"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                }

                if ((!this.senha2) && this.senha) {
                    const alert = await alertController.create({
                        header: this.$t("Confirm_Password"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                if (this.senha2 !== this.senha) {
                    const alert = await alertController.create({
                        header: this.$t("Passwords_do_not_match"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                const data = {
                    nome: this.user.nome,
                    email: this.email ? this.email : this.user.email,
                    senha: this.senha ? this.senha : this.user.senha,
                    empresas: this.user.empresas
                }

                console.log(data)
                const token = localStorage.getItem('token');
                const userId = this.$route.params.id
                await axios.put(`${config.apiUrl}/users/${userId}/edit`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                    .then(response => {
                        console.log(response.data);
                        const alert = alertController.create({
                            header: this.$t("successfully_updated!")
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        window.location.replace('/users')

                    })
                    .catch(error => {
                        console.log(error);
                        const alert = alertController.create({
                            header: this.$t("Error_updating"),
                            buttons: ['OK']
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                    });

            } else {
                const senhaRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&*]{8,}$/;

                if (!this.user.nome) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;

                }

                if ((this.emailExiste == true)) {

                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("The_following_field_cannot_be_inserted") + " " + " Email ",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                if (!this.validateEmail(this.email)) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }


                if (!this.email) {
                    const alert = await alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                if (this.senha != '') {
                    if (!senhaRegex.test(this.senha)) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("The_password_must_contain"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                }

                if (this.senha2 != '') {
                    if (!senhaRegex.test(this.senha2)) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("The_password_must_contain"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                }

                if ((!this.senha2) && this.senha) {
                    const alert = await alertController.create({
                        header: this.$t("Confirm_Password"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                if (this.senha2 !== this.senha) {
                    const alert = await alertController.create({
                        header: this.$t("Passwords_do_not_match"),
                        buttons: ['OK']
                    });
                    await alert.present();
                    return;
                }

                const data = {
                    nome: this.user.nome,
                    email: this.email ? this.email : this.user.email,
                    senha: this.senha ? this.senha : this.user.senha,
                    idGrupo: this.grupo,
                    empresa: this.empresa
                }
                const token = localStorage.getItem('token');
                const userId = this.$route.params.id
                await axios.put(`${config.apiUrl}/users/${userId}/edit`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                    .then(response => {
                        console.log(response.data);
                        const alert = alertController.create({
                            header: this.$t("successfully_updated!")
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        window.location.replace('/users')

                    })
                    .catch(error => {
                        console.log(error);
                        const alert = alertController.create({
                            header: this.$t("Error_updating"),
                            buttons: ['OK']
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                    });

            }

        }

    }
    ,
    watch: {
        email: async function (newEmail) {

            if (newEmail !== this.user.email) {
                this.emailExiste = await this.getEmails(newEmail);
            }

        },
        'user.email': function (newVal) {
            this.email = newVal;
        }
    },

    setup() {

        return { ellipsisVerticalSharp };
    }
}
</script>
  
<style scoped>
ion-input ::placeholder {
    font-size: 12px;
}


ion-avatar {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: fit-content;
}


ion-content #content-lista {
    min-height: 240px;
}

.select {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>