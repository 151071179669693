<template>
    <ion-page>
  
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <language-selector></language-selector>
            </ion-buttons>
  
            <ion-title>{{ $t("Diseases") }}</ion-title>
          </ion-toolbar>
        </ion-header>
  
        <ion-content class="ion-padding">
          <div class="content">
            <div class="content-items">
             
              <ion-button v-if="this.funcionalidades.includes(20)" size="large" style="margin: 0px ;" class="createButton" @click="criarDisease()">{{ $t("Register") }} {{ $t("Disease") }}</ion-button>
              <ion-item fill="outline">
                <ion-label position="floating">{{ $t("Filter") }}</ion-label>
                <ion-input v-model="termoPesquisa"></ion-input>
              </ion-item>
              <template v-if="itemsFiltrados.length == 0">
                <div class="noData">
                  <ion-icon :ios="medkitOutline " :md="medkitSharp"></ion-icon>
                  <p>{{ $t("No_data_Found") }}...</p>
                </div>
              </template>
              <ion-card v-for="(item, index) in itemsFiltrados" :key="index" class="doencasCard" >
                <ion-card-content class="ion-padding-card">
                  <ion-item fill="none" lines="none" style="width: 100%;">
                    
                    <ion-label @click="editarDisease(item.id)" class="nomeGrupo">{{ item.nome }}</ion-label>
                    <ion-icon v-if="this.funcionalidades.includes(19) || this.funcionalidades.includes(21)"  class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(item.id)" slot="end"></ion-icon>
                  
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </div>
          </div>
          <ion-infinite-scroll @ionInfinite="loadMoreData" style="height: 50px; margin-top: 20px;">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </ion-page>
    </ion-page>
  </template>
  
  <script>
  import {IonInfiniteScroll,IonInfiniteScrollContent, IonInput,IonCardContent,IonButton,IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon, alertController,  } from '@ionic/vue';
  import { ellipsisVerticalSharp, peopleSharp, medkitOutline, medkitSharp,
    peopleOutline } from "ionicons/icons"
  import { config } from "../../config"
  import axios from 'axios';
  import LanguageSelector from "@/components/LanguageSelector.vue";

  export default {
    components: {
      'language-selector' : LanguageSelector ,IonInfiniteScroll,IonInfiniteScrollContent,IonInput,IonCardContent,IonButton,IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon, 
    },
    data(){
      return{
        peopleSharp,
        peopleOutline,
        medkitOutline,
         medkitSharp,
        items: [],
        termoPesquisa:'',
        funcionalidades: '',
        currentPage: 1, 
        itemsPerPage: 10, 
        isDataFinished: false, 
      }
    },
    computed: {
    itemsFiltrados() {
      return this.items.filter(item => {
        return item.nome.toLowerCase().includes(this.termoPesquisa.toLowerCase());
      });
    }
  },
  mounted() {
    this.getPermissoes();
    this.fetchItems();
    },
    methods: {
      async loadMoreData(event) {
        setTimeout(async () => {
          if (!this.isDataFinished) {
            this.currentPage++;
            await this.fetchItems();
          }
        }, 1000);

        setTimeout(async () => {
          event.target.complete();
        }, 1000);
      },
      async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(19) && !funcionalidades.includes(20)  && !funcionalidades.includes(21)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
      criarDisease() {
        this.$router.push('/cultivation/disease/create')
    },
    editarDisease(id) {
      if(this.funcionalidades.includes(19)){
        this.$router.push(`/cultivation/disease/${id}/edit`)
      }
    },
      async deleteDoenca(id) {
        const token = localStorage.getItem('token');
  const alert = await alertController.create({
    header:  this.$t("Confirm_Deletion"),
          message: this.$t("Do_you_really_want_to_delete_the"),
    buttons: [
      {
        text: this.$t("Cancel"),
        role: 'cancel'
      },
      {
        text:  this.$t("Yes"),
        role: "destructive",
        handler: async () => {
          await axios.delete(`${config.apiUrl}/configs/diseases/delete/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
          const alert = await alertController.create({
            header:this.$t("Deleted"),
            message: this.$t("has_been_successfully_deleted")
          });
          await alert.present();
          window.location.reload();
        }
      }
    ]
  });
  await alert.present();
  },
      async exibirOpcoes(id) {
        if(this.funcionalidades.includes(19) && this.funcionalidades.includes(21)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/cultivation/disease/${id}/edit`);
              }
            },
            
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteDoenca(id)
              }
              
            },
          ]
        });
        await alert.present();
      }else if(this.funcionalidades.includes(19) && !this.funcionalidades.includes(21)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/cultivation/disease/${id}/edit`);
              }
            },
          ]
        });
        await alert.present();
      }else if(!this.funcionalidades.includes(19) && this.funcionalidades.includes(21)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteDoenca(id)
              }
              
            },
          ]
        });
        await alert.present();
      }
      },
      async fetchItems () {
        const token = localStorage.getItem('token');
        const empresaId = parseInt(localStorage.getItem('idEmpresa'));
        try {
          const response = await axios.get(`${config.apiUrl}/configs/diseases?search=${this.termoPesquisa}&page=${this.currentPage}&limit=${this.itemsPerPage}&empresaId=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
            
        const items = response.data.items.map((item) => {
            return {
              nome: item.nome,
              id: item.id
            };
          });
          this.items = [...this.items, ...items];

            if (items.length < this.itemsPerPage) {
              this.isDataFinished = true;
            }
        } catch (error) {
          console.log(error);
        }
      }
    },
    setup() {
  
      return { ellipsisVerticalSharp };
    }
  
  }
  
  
  </script>
  
  
  <style scoped>
  
  @media (prefers-color-scheme: dark) {
    ion-item {
         --background: transparent !important;
    }
  }

  .doencasCard{
    margin: 0px;
  }
  
  .nomeGrupo{
   margin-right: 0px;
  }

  .tresPontinhos{
    font-size: 20px;
  }
  .noData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .noData ion-icon{
    height: 100px;
    width: 100px;
  }
  
  ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  }
  
  .content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  
  .content.ios {
    position: relative;
    transform: translateY(-50%);
  }

  .createButton{
  margin-bottom:9px;
}
  </style>