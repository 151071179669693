<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/config/infrastructure"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Pond") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <ion-button size="large" expand="block" @click="visaoGeral">{{ $t("Overview") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarOxigenio">{{ $t("Oxygen") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarAlimento">{{ $t("Feed") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarCombustivel">{{ $t("Fuel") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarParametros">{{ $t("Parameters") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarEletricidade">{{ $t("Electricity") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarTrabalho">{{ $t("Work") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarAgua">{{ $t("Water") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarProduto">{{ $t("Product") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarDoenca">{{ $t("Disease") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarQuimica">{{ $t("Chemistry") }}</ion-button>
                    <ion-button size="large" expand="block" @click="cadastrarAgriculture">{{ $t("Aquaculture") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { alertController,IonBackButton,IonButton, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    name: "InfrastructuresLakePage",
    components: {
        IonBackButton,IonButton, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    mounted(){
        this.getPermissoes();
    },
    methods: {
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        visaoGeral() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/overview`)
        },
        cadastrarOxigenio() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/oxygen`)
        },
        cadastrarAlimento() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/feed`)
        },
        cadastrarCombustivel() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/fuel`)
        },
        cadastrarEletricidade() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/electricity`)
        },
        cadastrarParametros() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/parameters`)
        },
        cadastrarTrabalho() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/work`)
        },
        cadastrarAgua() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/water`)
        },
        cadastrarProduto() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/product`)
        },
        cadastrarDoenca() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/disease`)
        },
        cadastrarQuimica() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/chemistry`)
        },
        cadastrarAgriculture() {
            const id = this.$route.params.id
            this.$router.push(`/config/infrastructure/lake/${id}/agriculture`)
        }

    }
};
</script>
    
<style scoped>

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

</style>