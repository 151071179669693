<template>
  <ion-page>

      <ion-page id="main-content">

          <ion-header>
              <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/cultivation/warehouse"></ion-back-button>
                    </ion-buttons>
                  <ion-title>{{ $t("Edit") }} {{ $t("Warehouse") }}</ion-title>
              </ion-toolbar>
          </ion-header>

          <ion-content class="ion-padding">

              <div class="content-items" v-if="armazem">
                <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="armazem.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                  <ion-item fill="outline" >
                      <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                      <ion-input v-model="armazem.nome"></ion-input>
                  </ion-item>
                  

                  <ion-button size="large" @click="cadastrarArmazem">{{ $t("Register") }}</ion-button>
              </div>
          </ion-content>

      </ion-page>
  </ion-page>
</template>

<script>
import { IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ref } from 'vue';

export default {
  components: {
      IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
    const armazem = ref(null);
      return {
          armazem,
          empresas: "",
          funcionalidades:'',
            master: ''
      }
  },
  mounted() {
    this.getPermissoes();
      this.fetchEmpresas()
      this.fetchUser()
      this.fetchData()
  },
  methods: {
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(22)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
      async fetchData(){
        const token = localStorage.getItem('token');
            const Id = this.$route.params.id
            try {
                const response = await axios.get(`${config.apiUrl}/configs/warehouse/${Id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                const armazem = response.data.armazem

                this.armazem = armazem;
                
            } catch (error) {
                console.log(error);
            }
      },
      async fetchEmpresas() {
        const token = localStorage.getItem('token');
          try {
              const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              this.empresas = response.data.items;
          } catch (error) {
              console.log(error);
          }
      },
      async fetchUser() {
          const userId = localStorage.getItem('id');
          const token = localStorage.getItem('token');
          try {
              const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              this.empresa = parseInt(localStorage.getItem('idEmpresa'));
              this.master = response.data.master
          } catch (error) {
              console.log(error);
          }
          },
      async cadastrarArmazem() {
          
          if (!this.armazem.nome) {
              const alert = await alertController.create({
                header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
                  buttons: ['OK']
              });
              await alert.present();
              return;

          }
          const token = localStorage.getItem('token');
          const armazem = {
              armazem: {
                  nome: this.armazem.nome,
                  empresa: this.armazem.empresa,
                  id: this.armazem.id
              }
              };
          await axios.post(`${config.apiUrl}/configs/warehouse/createAndUpdate`, armazem,{
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}`,
                      }
          }) 
              .then(response => {
                  console.log(response.data.id);

                  const alert = alertController.create({
                      header: this.$t("successfully_updated!")
                  });
                  alert.then(_alert => {
                      _alert.present();

                  });

                  window.location.replace('/cultivation/warehouse')
              })
              .catch(error => {
                  console.log(error);
                  const alert = alertController.create({
                      header: this.$t("Error_updating"),
                      buttons: ['OK']
                  });
                  alert.then(_alert => {
                      _alert.present();
                  });
              });

      }

  }
}
</script>

<style scoped>

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: fit-content;
}

</style>