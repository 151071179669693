
<template>
    <ion-page>

        <ion-page id="main-content">

            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/financial/providers"></ion-back-button>
                    </ion-buttons>

                    <ion-title v-if="this.$route.params.id">{{ $t("Edit") }} {{ $t("Provider") }}</ion-title>
                    <ion-title v-else>{{ $t("Register") }} {{ $t("Provider") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">

                <div class="content-items" v-if="item">
                    <IonItem fill="outline" v-if="this.master && empresas">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="item.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="item.nome"></ion-input>
                    </ion-item>
                    <ion-button size="large" @click="addEditTipoAlimento">{{ $t("Register") }}</ion-button>
                </div>
            </ion-content>

        </ion-page>

    </ion-page>
</template>

<script>
import { IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"

export default {
    components: {
        IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            item: {
                nome: '',
                empresa: '',
            },
            empresas: "",
            funcionalidades: '',
            master: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchEmpresas()
        this.fetchUser()
        this.fetchManufacturer()
    },
    methods: {
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(52) || !funcionalidades.includes(53)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresas = response.data.items;

            } catch (error) {
                console.log(error);
            }
        },
        async fetchUser() {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresa = parseInt(localStorage.getItem('idEmpresa'));
                this.master = response.data.master
            } catch (error) {
                console.log(error);
            }
        },
        async fetchManufacturer() {
            const ftId = this.$route.params.id
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/getManufacturerById/${ftId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.item = response.data.fornecedores;
            } catch (error) {
                console.log(error);
            }
        },
        async addEditTipoAlimento() {

            if (!this.item.nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }
            
            const fornecedor = {
                id: this.item.id,
                nome: this.item.nome,
                empresa: this.item.empresa ? this.item.empresa : this.empresa,
            }

            const token = localStorage.getItem('token');
      
            const id = this.$route.params.id
            await axios.post(`${config.apiUrl}/configs/manufacturers/createEdit/${id}`, fornecedor, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_updated!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                    });

                    window.location.replace('/financial/providers')
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_updating") + " " + this.$t("Provider"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });


        }

    }
}
</script>

<style scoped>
ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: fit-content;
}

.selectLago {
    --min-width: 600px;
}
</style>