<template>
  <ion-page>
    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector></language-selector>
          </ion-buttons>
          <ion-title>{{ $t("Dashboard") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content :scroll-y="false" class="ion-padding">
        <div style="display: none;">
            <language-selector ref="languageSelector"></language-selector>
        </div>
        <div class="content-items">

          <!-- <ion-button size="large" expand="block" @click="cadastrarRegistroDiario">{{ $t("Daily_records") }}</ion-button> -->
        </div>

      </ion-content>

    </ion-page>
  </ion-page>
</template>

<script>
import {
//   IonButton,
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonTitle,
} from "@ionic/vue";
import { mapState } from 'vuex'
import LanguageSelector from "../../components/LanguageSelector.vue";

export default {
  data() {
    return {
      locations: []
    };
  },
  name: "HomePage",
  components: {
    'language-selector' : LanguageSelector ,
    LanguageSelector,
    // IonButton,
    IonPage,
    IonContent,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonTitle
  },
    mounted(){
      this.ifNotLang()
    }
  ,
  computed: {
    ...mapState("user", {
      user: (m) => m.user
    })
  },
  methods: {
        cadastrarRegistroDiario() {
            this.$router.push(`/daily`)
        },
        async ifNotLang(){
          if (!localStorage.getItem('locale')) {
            this.$nextTick(() => {
              const languageSelector = this.$refs.languageSelector;
              languageSelector.openSelector();
            });
          } else {
            return;
          }
        }
      }
};
</script>

<style scoped>

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

</style>