<template>
    <ion-page>
  
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <language-selector></language-selector>
            </ion-buttons>
            <ion-title>{{ $t("Production_centers") }}</ion-title>
          </ion-toolbar>
        </ion-header>
  
        <ion-content class="ion-padding">
          <div class="content">
            <div class="content-items">
              <ion-button v-if="this.funcionalidades.includes(11)" size="large" class="createButton" style="margin: 0px ;" @click="criarCentro()">{{ $t("Register") }} {{ $t("Center") }}</ion-button>
              <template v-if="centros.length == 0">
                <div class="noData">
                  <ion-icon :ios="earthOutline" :md="earthSharp"></ion-icon>
                  <p>{{ $t("No_data_Found") }}...</p>
                </div>
              </template>
              <ion-card v-for="(centro, index) in centros" :key="index" class="centrosCard" >
                <ion-card-content class="ion-padding-card">
                  <ion-item lines="none" id="avatar-item" class="ion-margin">
                   
                    <div class="info" @click="editarCentro(centro.id)">

                        <h2 style="margin: 0px;">{{ centro.nomeLegal }}</h2>

                        <h2 style="margin: 0px; color: #838383; font-size: 14px;">{{ $t("Registration_Number") }}: {{ centro.numeroRegistro }}</h2>

                    </div>
  
                    <ion-icon v-if="this.funcionalidades.includes(10) || this.funcionalidades.includes(12)"  class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(centro.id)" slot="end"></ion-icon>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </div>
          </div>
          <ion-infinite-scroll @ionInfinite="loadMoreData" style="height: 50px; margin-top: 20px;">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </ion-page>
    </ion-page>
  </template>
  
  <script>
  import {IonInfiniteScroll,IonInfiniteScrollContent,  IonButton, IonTitle,  IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonIcon, alertController } from '@ionic/vue';
  import {
    ellipsisVerticalSharp, earthOutline, earthSharp
  } from "ionicons/icons"
  import axios from 'axios';
  import { config } from "../../config"
  import LanguageSelector from "@/components/LanguageSelector.vue";

  export default {
    components: {
      'language-selector' : LanguageSelector ,IonInfiniteScroll,IonInfiniteScrollContent, IonButton,IonTitle, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard,  IonIcon
    },
    data() {
      return {
        centros: [],
        earthOutline,
        earthSharp,
        funcionalidades: '',
        currentPage: 1, 
        itemsPerPage: 10, 
        isDataFinished: false, 
      };
    },
    mounted() {
      this.getPermissoes();
    this.buscarDados();
    },
    methods: {
      async loadMoreData(event) {
        setTimeout(async () => {
          if (!this.isDataFinished) {
            this.currentPage++;
            await this.buscarDados();
          }
        }, 1000);

        setTimeout(async () => {
          event.target.complete();
        }, 1000);
      },
      async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(10) && !funcionalidades.includes(11)  && !funcionalidades.includes(12)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
      criarCentro() {
        this.$router.push('/users/production-center/create')
      },
      editarCentro(index) {
        if(this.funcionalidades.includes(10) ){
        this.$router.push(`/users/production-center/${index}/edit`)
        }
      },
      async exibirOpcoes(index) {
        if(this.funcionalidades.includes(10) && this.funcionalidades.includes(12)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/users/production-center/${index}/edit`);
              }
            },
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteCenter(index)
              }
            }
          ]
        });
        await alert.present();
      }else if(this.funcionalidades.includes(10) && !this.funcionalidades.includes(12)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/users/production-center/${index}/edit`);
              }
            }
          ]
        });
        await alert.present();
      }else if(!this.funcionalidades.includes(10) && this.funcionalidades.includes(12)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteCenter(index)
              }
            }
          ]
        });
        await alert.present();
      }
      },
      async buscarDados() {
        const token = localStorage.getItem('token');
        const empresaId = parseInt(localStorage.getItem('idEmpresa'));
        try {
          const response = await axios.get(`${config.apiUrl}/productionCenters?page=${this.currentPage}&limit=${this.itemsPerPage}&empresaId=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
            
            const items = response.data.centros.map((item) => {
            return {
              nomeLegal: item.nomeLegal,
              id: item.id,
              numeroRegistro: item.numeroRegistro
            };
          });
           this.centros = [...this.centros, ...items];

            if (items.length < this.itemsPerPage) {
              this.isDataFinished = true;
            }
            
            console.log(this.centros)
        } catch (error) {
          console.error("Erro ao buscar nomes ", error);
        }
      },
      async deleteCenter(index) {
        const token = localStorage.getItem('token');
        const alert = await alertController.create({
          header:  this.$t("Confirm_Deletion"),
          message: this.$t("Deleting_this_Production_Center"),
          buttons: [
            {
              text: this.$t("Cancel"),
              role: 'cancel'
            },
            {
              text: this.$t("Yes"),
              role: "destructive",
              handler: async () => {
                await axios.delete(`${config.apiUrl}/users/productionCenters/delete/${index}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                const alert = await alertController.create({
                  header: this.$t("Deleted"),
                  message: this.$t("has_been_successfully_deleted")
                });
                await alert.present();
                window.location.reload();
              }
            }
          ]
        });
        await alert.present();
      }
    },
    setup() {
      return { ellipsisVerticalSharp };
    }
  }
  
  </script>
  
  
  <style scoped>
  
  .createButton{
  margin-bottom:9px;
  }
  .ion-margin {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .centrosCard{
    margin: 0px;
  }
  .noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .noData ion-icon {
    height: 100px;
    width: 100px;
  }
  
  .info {
    height: 45px;
    padding-left: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ion-padding-card {
    padding: 0px;
  }
  
  
  #avatar-item {
    --background: transparent;
  }
  
  #avatar-item img {
    width: 50px;
    height: 50px;
  }
  
  .content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .tresPontinhos{
    font-size: 20px;
  } 
  
  .content.ios {
    position: relative;
    transform: translateY(-50%);
  }
  
  ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  }
  
  ion-card-subtitle {
    margin: 0px;
  }</style>