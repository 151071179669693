import { request } from "./request"

export const auth = {
    login(email, senha){
        let response = request.post("/login", {
            email,
            senha
        })
        response.then((response) => {
            const token = response.data.token;
            const id = response.data.id;
            const user = response.data.user;
            localStorage.setItem('token', token);
            localStorage.setItem('id', id);
            localStorage.setItem('user', JSON.stringify(user));
            const tokenExpiration = new Date();
            tokenExpiration.setHours(tokenExpiration.getHours() + 24);
            localStorage.setItem('tokenExpiration', tokenExpiration);
            
        })
        return response;
    },
    checkTokenExpiration() {
        const tokenExpiration = new Date(localStorage.getItem('tokenExpiration'));
        const currentTime = new Date();
    
        if ((currentTime >= tokenExpiration) && tokenExpiration > 0) {
          return true; 
        } else {
          setTimeout(this.checkTokenExpiration, 60000); 
          return false; 
        }
      },
}