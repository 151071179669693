<template>
  <ion-page>

    <ion-page id="main-content">

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector></language-selector>
          </ion-buttons>
          <ion-title>{{ $t("Edit") }} {{ $t("Account") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content-items">
          <div class="menuAvatar">
            <ion-item lines="none" class="avatar-item">
              <div class="avatar-content">
                <ion-avatar @click="exibirOpcoes">
                  <img src="https://ionicframework.com/docs/img/demos/avatar.svg"
                  id="img-avatar" v-if="fotoUrl == null || fotoUrl == 'data:image/jpeg;base64,null' || fotoUrl == 'data:image/jpeg;base64,Pw=='" />
                  <img id="img-avatar" :src="fotoUrl" v-else />
                </ion-avatar>
                <ion-icon :ios="brush" :md="brush" class="editIcon" @click="exibirOpcoes"></ion-icon>
              </div>
            </ion-item>
          </div>
          <!-- <IonItem fill="outline" v-if="user && empresas">
                        <ion-label position="floating">{{ $t("Company") }} </ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                        v-model="user.empresa" >
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem> -->
          <ion-item v-if="user" fill="outline">
            <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="user.nome"></ion-input>
          </ion-item>
          <ion-item v-if="user" fill="outline" ref="item">
            <ion-label position="floating">Email <span style="color: red;">*</span></ion-label>
            <ion-input v-model="email" :value="user.email" @ionInput="validate" @ionBlur="markTouched"
              ref="email"></ion-input>
          </ion-item>
          <ion-item v-if="emailExiste">
            <ion-text color="danger">
              {{ $t("This email is already in use.") }}
            </ion-text>
          </ion-item>
          <ion-item v-if="user" fill="outline">
            <ion-label position="stacked">{{ $t("Password") }} </ion-label>
            <ion-input v-model="senha" type="password" :placeholder="$t('Keep the field empty')"></ion-input>
          </ion-item>
          <ion-item v-if="user" fill="outline">
            <ion-label position="stacked">{{ $t("Confirm_Password") }}</ion-label>
            <ion-input v-model="senha2" type="password" :placeholder="$t('Keep the field empty')"></ion-input>
          </ion-item>
          <ion-item v-if="user" fill="outline">
            <ion-label position="floating">{{ $t("Telephone") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-if="user" @input="formatarTelefone" v-model="user.telefone" v-model.trim="telefone" type="tel"
              @blur="removerMascara">
            </ion-input>
          </ion-item>

          <ion-button size="large" @click="alterarDados">{{ $t("Confirm") }}</ion-button>
        </div>
      </ion-content>

    </ion-page>
  </ion-page>
</template>

<script>
import { IonIcon, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonAvatar, alertController, IonText } from '@ionic/vue';
import axios from 'axios';
import { config } from "../config"
import { ref } from 'vue';
import { brush, ellipsisVerticalSharp } from 'ionicons/icons';
import { auth } from "../services/auth"
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  components: {
    'language-selector': LanguageSelector, IonIcon, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonAvatar, IonText
  },
  data() {
    const user = ref(null);
    const email = ref(user.value ? user.value.email : '');
    return {
      items: "",
      user,
      userId: 1,
      fotoUrl: null,
      emails: [],
      emailExiste: false,
      brush,
      empresas: "",
      senha2: '',
      senha: '',
      email
    }
  },
  beforeUpdate() {
    this.fetchUser()
    this.getEmails()
  },
  mounted() {
    this.fetchEmpresas()

    const userId = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    axios.get(`${config.apiUrl}/users/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        this.fotoUrl = `data:image/jpeg;base64,${response.data.foto_perfil}`;
      })
      .catch(error => {
        console.log(error);
      });

    this.fetchUser();
    this.getEmails()
  },


  methods: {
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresas = response.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    removerMascara(event) {
      if (event && event.target) {
        const input = event.target.value;
        const digitsOnly = input.replace(/\D/g, '');
        const masked = `(${digitsOnly.substring(0, 2)}) ${digitsOnly.substring(2, 7)}-${digitsOnly.substring(7, 11)}`;
        this.user.telefone = masked;
      }
    },

    formatarTelefone(event) {
      const isDeleting = event.data == null;
      if (!isDeleting && this.user.telefone) {
        const whats = event.target.value.replace(/\D/g, '');
        const whatsLength = whats.length;

        if (whatsLength <= 2) {
          this.user.telefone = `(${whats})`;
        } else if (whatsLength <= 6) {
          this.user.telefone = `(${whats.substring(0, 2)}) ${whats.substring(2)}`;
        } else if (whatsLength <= 10) {
          this.user.telefone = `(${whats.substring(0, 2)}) ${whats.substring(2, 6)}-${whats.substring(6)}`;
        } else {
          this.user.telefone = `(${whats.substring(0, 2)}) ${whats.substring(2, 7)}-${whats.substring(7, 11)}`;
        }
      }
      this.removerMascara()
    },

    async getEmails(email) {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/emails`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.emails = response.data.emails;

        return this.emails.includes(email);
      } catch (error) {
        console.error(error);
      }
    },

    validateEmail(email) {
      const validTLDs = ["com", "net", "org", "io", "br"];
      return email.match(`^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\\.(${validTLDs.join("|")})$`);
    },

    validate(ev) {
      const value = ev.target.value;

      this.$refs.item.$el.classList.remove('ion-valid');
      this.$refs.item.$el.classList.remove('ion-invalid');

      if (value == '') return;

      this.validateEmail(value)
        ? this.$refs.item.$el.classList.add('ion-valid')
        : this.$refs.item.$el.classList.add('ion-invalid');
    },

    markTouched() {
      this.$refs.item.$el.classList.add('ion-touched')
    },
    async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.user = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async exibirOpcoes() {
      const alert = await alertController.create({
        header: this.$t("Options"),
        buttons: [
          {
            text: this.$t("Change_image"),
            handler: async () => {
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = 'image/*';
              input.click();

              input.addEventListener('change', () => {
                const file = input.files[0];
                const reader = new FileReader();

                if (reader)
                  reader.addEventListener('load', () => {
                    localStorage.setItem('fotoA', reader.result);
                    const img = document.getElementById('img-avatar');
                    img.src = reader.result;
                  });
                reader.readAsDataURL(file);

              });
            }

          },
          {
            text: this.$t("Cancel"),
            role: 'destructive',
          }
        ]
      });
      await alert.present();
    },

    async alterarDados() {
      const senhaRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&*]{8,}$/;

      if ((this.emailExiste == true)) {

        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("The_following_field_cannot_be_inserted") + " " + "email",
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      console.log(this.email)
      if (!this.validateEmail(this.email)) {
        if ((!this.validateEmail(this.email))) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") + " " + "email",
            buttons: ['OK']
          });
          await alert.present();
          return;
        }
      }

      if (!this.user.nome) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
          buttons: ['OK']
        });
        await alert.present();
        return;

      }

      if (this.senha) {
        if (!senhaRegex.test(this.senha)) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("The_password_must_contain"),
            buttons: ['OK']
          });
          await alert.present();
          return;
        }
      }

      if (this.senha2) {
        if (!senhaRegex.test(this.senha2)) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("The_password_must_contain"),
            buttons: ['OK']
          });
          await alert.present();
          return;
        }
      }

      if ((!this.senha) && this.senha2) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Password"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if ((!this.senha2) && this.senha) {
        const alert = await alertController.create({
          header: this.$t("Confirm_Password"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }


      if (this.senha2 !== this.senha) {
        const alert = await alertController.create({
          header: this.$t("Passwords_do_not_match"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.user.telefone) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Telephone"),
          buttons: ['OK']
        });
        await alert.present();
        return;

      }

      const formattedPhone = this.user.telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')

      let data = [];

      data.push({
        nome: this.user.nome,
        email: this.email ? this.email : this.user.email,
        telefone: formattedPhone,
        foto_perfil: this.fotoPerfilUrl
      });

      if (this.senha) {
        data[data.length - 1].senha = this.senha;
      }
      console.log(data)
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');

      await axios.put(`${config.apiUrl}/users/${userId}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          console.log(response.data);

          const foto = localStorage.getItem('fotoA');

          if (foto !== null) {
            const byteCharacters = atob(foto.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });
            const formData = new FormData();
            formData.append('foto', blob, 'foto.png');
            fetch(`${config.apiUrl}/users/${userId}/foto`, {
              method: 'PUT',
              body: formData,
              headers: {
                'Authorization': `Bearer ${token}`
              },
            });
          }
          const alert = alertController.create({
            header: this.$t("successfully_updated!")
          });
            
          localStorage.removeItem('fotoA')
          alert.then(_alert => {
            _alert.present();
          });
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_updating"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });

      auth.login(data.email, data.senha)
        .then((response) => {
          this.login(response.data.user)
        })
        .catch(async error => {
          console.log(error);
          await alert.present();
        });
      location.reload()
    }

  }
  ,
  watch: {
    email: async function (newEmail) {

      if (newEmail !== this.user.email) {
        this.emailExiste = await this.getEmails(newEmail);
      }


    },
    'user.email': function (newVal) {
      this.email = newVal;
    }
  },

  setup() {

    return { ellipsisVerticalSharp };
  }
}
</script>

<style scoped>
.menuAvatar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.editIcon {
  position: absolute;
  background-color: #417cf3;
  border-radius: 100px;
  padding: 4px;
  border: 3px solid white;
  height: 16px;
  width: 16px;
  top: 67%;
  left: 67%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.799);
}

ion-input ::placeholder {
  font-size: 12px;
}

.avatar-content {
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatar-item {
  --min-height: 100px;
  --background: transparent;
  width: 200px;
}

.avatar-item img {
  width: 100px;
  height: 100px;
  border: 3px solid white;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.799);
}

ion-avatar {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: fit-content;
}

ion-content #content-lista {
  min-height: 240px;
}

.select {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>