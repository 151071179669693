const state = {
    isLogin: false,
    token:null,
    user:null,
};
const actions = {
    login({commit}, user ) {
            commit('loginSuccess',{user})
        
    },
    logout({commit}) {
        commit('resetState');
    },
    clearState ({ commit }) {
        commit('resetState');
    },
};
const mutations = {
    resetState (state) {
        state.isLogin=false;
        state.token=null;
        state.user=null;
    },
    
    loginSuccess(state,{user}) {
        state.isLogin=true;
        // state.token=null;
        // state.user=null;
        state.user = user
    },
    loginFailure(state) {
        state.isLogin=false;
    },
};
export const user = {
    namespaced: true,
    state,
    actions,
    mutations
};