<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="start">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-title>Register autentication</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <div class="contentCommercial" v-if="centros">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="stacked">Production centers from {{ this.nomeEmpresa }}</ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select"
                                    v-model="autenticacao.centroProducao" id="companyInput">
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="(centro, index) in centros" :key="index">
                                            <ion-select-option>{{ centro.nomeLegal }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <ion-item fill="outline">
                        <ion-label position="floating">Autentication</ion-label>
                        <ion-input id="invoiceInput" type="number" v-model="autenticacao.autenticacao"
                            ></ion-input>
                    </ion-item>

                    <ion-button size="large" expand="block" @click="cadastrarAutenticacao">Register autentication</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonSelect, IonSelectOption, IonList, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"

export default {
    components: {
        IonSelect, IonSelectOption, IonList, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            autenticacao: {
                centroProducao: '',
                autenticacao: '',
            },
            centros: "",
        }
    },
    mounted() {
        this.getUser()
    },
    methods: {
        async getUser() {
            const Id = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${Id}`);
                this.userData = response.data;
                this.getCompanyName(this.userData.empresa)
            } catch (error) {
                console.log(error);
            }
        },
        async getCompanyName(empresaId) {
            try {
                const response = await axios
                .get(`${config.apiUrl}/companies/${empresaId}`);
                this.nomeEmpresa = response.data.nome
                this.showCentros(empresaId)
            } catch (error) {
                console.error("Erro ao buscar nomes ", error);
            }
            },
        async showCentros() {

            const data = {
                empresa: this.userData.empresa
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getProductionCenterbyCompanyName`, data);

                console.log(response)
                const centros = response.data.items.map((centro) => {
                    return {
                        nomeLegal: centro.nomeLegal,
                        id: centro.id
                    };
                });
                this.centros = centros;
            } catch (error) {
                console.log(error);
            }

        },
        async cadastrarAutenticacao() {

            if (!this.autenticacao.centroProducao) {
                const alert = await alertController.create({
                    header: 'Invalid Production center',
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.autenticacao.autenticacao) {
                const alert = await alertController.create({
                    header: 'Invalid Autentication',
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            const autenticacao = {
                autenticacao: {
                    autenticacao: this.autenticacao.autenticacao,
                    centroProducao: this.autenticacao.centroProducao,
                    empresa: this.userData.empresa
                }
            };
            await axios.post(`${config.apiUrl}/infrastructures/autenticacao/create`, autenticacao, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: 'Autentication successfully registered!'
                    });
                    alert.then(_alert => {
                        _alert.present();

                        window.location.replace("/config/infrastructure")
                    });
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: 'Error registering Autentication',
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });
        }

    }
};
</script>
    
<style scoped>
.botoes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.addProduct {
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    background: #0049b1;
    color: white;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addProduct h2 {
    margin: 0px;
    font-size: 16px;
}

.plusIcon {
    font-size: 35px;
    color: white;

}

.products {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>