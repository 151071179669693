<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector></language-selector>
          </ion-buttons>

          <ion-title>{{ $t("Shopping") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content">
          <div class="content-items">

            <ion-button v-if="this.funcionalidades.includes(26)" size="large" class="createButton" style="margin: 0px ;" @click="criarCompra()">{{ $t("Register") }} {{ $t("Purchase") }}</ion-button>
            <!-- <ion-button v-if="this.funcionalidades.includes(26)" size="large" @click="importCSVs" id="importButton" style="--box-shadow:none;margin: 0px;"><ion-icon name="cloud-upload" :ios="enterOutline" :md="enterSharp" style="--box-shadow:none;transform: rotate(90deg); margin-right:10px"></ion-icon>     Import</ion-button> -->
            <IonItem class="selectFilter" fill="outline" style="margin: 0px ;">
              <ion-label>{{ $t("Select_Filter_type") }}</ion-label>
              <ion-select position="floating" aria-label="Fruit" class="select" v-model="valueFiltro" id="companyInput"
                @ionChange="selecionarFiltro($event)">
                <ion-content class="ion-padding" id="content-lista">
                  <ion-list>
                    <ion-select-option value="fatura">{{ $t("Invoice/Note_No.") }}</ion-select-option>
                    <ion-select-option value="data">{{ $t("Date") }}</ion-select-option>
                    <ion-select-option value="provedor">{{ $t("Provider") }}</ion-select-option>
                  </ion-list>
                </ion-content>
              </ion-select>
            </IonItem>
            <ion-item fill="outline">
              <ion-label position="floating">{{ $t("Filter") }}</ion-label>
              <ion-input id="nomeInput" v-model="termoPesquisa"></ion-input>
            </ion-item>
            <template v-if="itemsFiltrados.length == 0">
              <div class="noData">
                <ion-icon :ios="bagOutline" :md="bagSharp"></ion-icon>
                <p>{{ $t("No_data_Found") }}...</p>
              </div>
            </template>
            <ion-card v-for="(item, index) in itemsFiltrados" :key="index" class="comprasCard" >
              <ion-card-content class="ion-padding-card ">
                <ion-item fill="none" lines="none" class="infos ion-margin" >

                  <div class="info" @click="editarCompra(item.id)">

                    <h2 style="margin: 0px;">{{ $t("Invoice/Note_No.") }}:{{ item.fatura }}</h2>

                    <h2 style="margin: 0px; color: #838383; font-size: 14px;">{{ $t("Provider") }}: {{ item.provedor }}</h2>

                    <h2 style="margin: 0px; color: #838383; font-size: 14px;">{{ $t("Date") }}: {{ formatarData(item.data) }}</h2>

                  </div>
                  <ion-icon v-if="this.funcionalidades.includes(25) || this.funcionalidades.includes(27)"  class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(item.id)" slot="end"></ion-icon>

                </ion-item>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
        <ion-infinite-scroll @ionInfinite="loadMoreData" style="height: 50px; margin-top: 20px;">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
      </ion-content>
    </ion-page>
  </ion-page>
</template>
  
<script>
import { IonInfiniteScroll,IonInfiniteScrollContent, IonSelect, IonList, IonSelectOption, IonInput, IonCardContent, IonButton, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon, alertController, } from '@ionic/vue';
import {
  ellipsisVerticalSharp, bagOutline, bagSharp, enterOutline, enterSharp} from "ionicons/icons"
import { config } from "../../config"
import axios from 'axios';
import LanguageSelector from "@/components/LanguageSelector.vue";
import Papa from 'papaparse';

export default {
  components: {
    'language-selector' : LanguageSelector ,IonInfiniteScroll,IonInfiniteScrollContent, IonSelect, IonList, IonSelectOption, IonInput, IonCardContent, IonButton, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon,
  },
  data() {
    return {
      bagOutline,
      bagSharp,
      enterOutline, 
      enterSharp,
      items: [],
      termoPesquisa: '',
      funcionalidades: '',
      valueFiltro: 'fatura',
      currentPage: 1, 
      itemsPerPage: 10, 
      isDataFinished: false, 
    }
  },
  computed: {
    itemsFiltrados() {
      return this.items.filter(item => {
        let propriedade;
        if (this.valueFiltro == 'fatura') {
          propriedade = item.fatura;
        } else if (this.valueFiltro == 'data') {
          propriedade = item.data;
        } else if (this.valueFiltro == 'provedor') {
          propriedade = item.provedor;
        }
        return propriedade.toLowerCase().includes(this.termoPesquisa.toLowerCase());
      });
    }
  },
  mounted() {
    this.getPermissoes();
    this.fetchItems();
    this.selecionarFiltro()
    
  },
  methods: {
    async importCSVs() {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.multiple = true;
  fileInput.accept = '.csv';

  // Oculte o elemento do DOM
  fileInput.style.display = 'none';

  // Adicione o evento de change para processar os arquivos selecionados
  fileInput.addEventListener('change', async (event) => {
    const files = event.target.files;

    const csvObjectsArray = [];
    
    // Função para ler um arquivo CSV e gerar os objetos correspondentes
    const processCSV = (file) => {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          delimiter: ',', // Define o delimitador do CSV como vírgula
          header: true,
          complete: (results) => {
            const data = results.data;
            csvObjectsArray.push(...data.map(this.transformCSVObject));
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
      });
    };

    // Processar todos os arquivos CSV em paralelo
    const processPromises = [];
    for (let i = 0; i < files.length; i++) {
      processPromises.push(processCSV(files[i]));
    }

    try {
      await Promise.all(processPromises);

      const filteredCsvObjectsArray = csvObjectsArray.filter((obj) => {
        const values = Object.values(obj);
        const hasEmptyFields = values.some((value) => value == '' || value == null);
        const hasEmptyProducts = Array.isArray(obj.produtos) && obj.produtos.some((product) => {
          const productValues = Object.values(product);
          return productValues.some((value) => value == '' || value == null);
        });
        return !hasEmptyFields && !hasEmptyProducts;
      });

      // Se this.master for verdadeiro, substituir o valor da empresa em cada objeto
      if (!this.master) {
        const empresaId = parseInt(localStorage.getItem('idEmpresa'));
        filteredCsvObjectsArray.forEach((obj) => {
          if (obj.empresa != '') {
            obj.empresa = empresaId;
          }
        });
      }
      const token = localStorage.getItem('token');
      // Envie o array de objetos para o backend usando o axios
      const response = await axios.post(`${config.apiUrl}/financial/shopping/create`, filteredCsvObjectsArray, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(response);
      const alert = alertController.create({
        header: this.$t('successfully_registered!'),
        buttons: ['OK'],
      });
      alert.then((_alert) => {
        _alert.present();
      });
    } catch (error) {
      const alert = alertController.create({
        header: this.$t('Error_registering'),
        buttons: ['OK'],
      });
      alert.then((_alert) => {
        _alert.present();
      });
    }
  });

  // Dispare o evento de click no novo elemento para abrir o seletor de arquivos
  fileInput.click();
},

transformCSVObject(csvObject) {
  // Realize as transformações necessárias para ajustar o formato dos dados
  const produtosString = csvObject.produtos || '';
  const produtosArray = produtosString.split(';');
  const produtos = [];

  for (const produtoString of produtosArray) {
    const [produto, preco, kg_ou_qntd, destino, expiracao, lote, total, armazem] = produtoString.split('|');

    // Verificar se todos os campos do produto estão preenchidos antes de adicioná-lo à matriz de produtos
    if (produto && preco && kg_ou_qntd && destino && expiracao && lote && total && armazem) {
      produtos.push({
        produto,
        preco,
        kg_ou_qntd,
        destino,
        expiracao,
        lote,
        total,
        armazem,
      });
    }
  }

  return {
    fatura: csvObject.fatura || '',
    data: csvObject.data || '',
    provedor: csvObject.provedor || '',
    comentarios: csvObject.comentarios || '',
    empresa: parseInt(csvObject.empresa),
    produtos,
  };
},
async fetchUser() {
  const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.master = response.data.master
            } catch (error) {
                console.log(error);
            }
        },
    async loadMoreData(event) {
            setTimeout(async () => {
          if (!this.isDataFinished) {
            this.currentPage++;
            await this.fetchItems();
          }
        }, 1000);
        setTimeout(async () => {
          event.target.complete();
        }, 1000);
      },
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(25) && !funcionalidades.includes(26)  && !funcionalidades.includes(27)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    formatarData(data) {
      const dataObj = new Date(data);
      const dia = dataObj.getDate();
      const mes = dataObj.getMonth() + 1;
      const ano = dataObj.getFullYear();
      return `${dia < 10 ? '0' : ''}${dia}-${mes < 10 ? '0' : ''}${mes}-${ano}`;
    },
    selecionarFiltro(event) {
      if (event) {
        this.valueFiltro = event.target.value
      }

    },
    criarCompra() {
      this.$router.push('/financial/shopping/create')
    },
    editarCompra(id) {
      if(this.funcionalidades.includes(25)){
      this.$router.push(`/financial/shopping/${id}/edit`)
      }
    },
    async deleteDoenca(id) {
      const token = localStorage.getItem('token');
      const alert = await alertController.create({
        header:  this.$t("Confirm_Deletion"),
          message: this.$t("Do_you_really_want_to_delete_the"),
        buttons: [
          {
            text: this.$t("Cancel"),
            role: 'cancel'
          },
          {
            text: this.$t("Yes"),
            role: "destructive",
            handler: async () => {
              await axios.delete(`${config.apiUrl}/financial/shopping/delete/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              const alert = await alertController.create({
                header: this.$t("Deleted"),
                message: this.$t("has_been_successfully_deleted")
              });
              await alert.present();
              window.location.reload();
            }
          }
        ]
      });
      await alert.present();
    },
    async exibirOpcoes(id) {
      if(this.funcionalidades.includes(25) && this.funcionalidades.includes(27)){
      const alert = await alertController.create({
        header: this.$t("Options"),
        buttons: [

          {
            text: this.$t("Edit"),
            handler: () => {
              this.$router.push(`/financial/shopping/${id}/edit`);
            }
          },
          {
            text: this.$t("Delete"),
            role: 'destructive',
            handler: () => {
              this.deleteDoenca(id)
            }

          },
        ]
      });
      await alert.present();
    }else if(this.funcionalidades.includes(25) && !this.funcionalidades.includes(27)){
      const alert = await alertController.create({
        header: this.$t("Options"),
        buttons: [
          {
            text: this.$t("Edit"),
            handler: () => {
              this.$router.push(`/financial/shopping/${id}/edit`);
            }
          },
        ]
      });
      await alert.present();
    }else if(!this.funcionalidades.includes(25) && this.funcionalidades.includes(27)){
      const alert = await alertController.create({
        header: this.$t("Options"),
        buttons: [

          {
            text: this.$t("Delete"),
            role: 'destructive',
            handler: () => {
              this.deleteDoenca(id)
            }

          },
        ]
      });
      await alert.present();
    }
    },
    async fetchItems() {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'));
      try {
        const response = await axios.get(`${config.apiUrl}/financial/shopping?search=${this.termoPesquisa}&page=${this.currentPage}&limit=${this.itemsPerPage}&empresaId=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
          
          const items = response.data
          
          this.items = [...this.items, ...items];

          if (items.length < this.itemsPerPage) {
            this.isDataFinished = true;
          }
      } catch (error) {
        console.log(error);
      }
    }
  },
  setup() {

    return { ellipsisVerticalSharp };
  }

}


</script>
  
  
<style scoped>
@media (prefers-color-scheme: dark) {
  ion-item {
    --background: transparent !important;
  }
}

.ion-padding-card{
  padding: 0px !important;
}

#importButton{
    --background: transparent;
    border: #0049b1 2px solid;
    --color: #0049b1;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }
.infos {
  --padding-end: 0px;
  --padding-start: 0px;
}

.info {
  height: 70px;
  padding-left: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tresPontinhos{
    font-size: 20px;
    padding: 0px;
  } 
.selectFilter {
  margin-bottom: 9px;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.noData ion-icon {
  height: 100px;
  width: 100px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.content.ios {
  position: relative;
  transform: translateY(-50%);
}
.comprasCard{
  margin: 0px;
  padding: 0px;
}
.createButton {
  margin-bottom: 9px;
}
</style>