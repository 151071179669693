<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/financial/updates"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Edit") }} {{ $t("Updates") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items" v-if="atualizacao">
                    <!-- <ion-input size="large" expand="block" type="file" accept=".csv" @change="handleFileSelect">import</ion-input> -->
                    <IonItem fill="outline" v-if="this.master && empresas">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="atualizacao.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("New_quantity") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="atualizacao.novaQuantidade" type="number"></ion-input>
                    </ion-item>
                    <IonItem fill="outline" v-if="this.especies">
                        <ion-label position="floating">{{ $t("Product/Service") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="atualizacao.produtoServico">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in especies" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Current_price") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="atualizacao.precoAtual"
                            @input="atualizacao.precoAtual = formatEuro($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="stacked" style="font-size: 18px;">{{ $t("Next_date_of_birth") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="atualizacao.novaNascimentoData" type="date"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("New_price") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="atualizacao.novoPreco"
                            @input="atualizacao.novoPreco = formatEuro($event.target.value)"></ion-input>
                    </ion-item>

                    <div class="cem">

                        <ion-label position="floating">{{ $t("Applicable_Period") }} <span
                                style="color: red;">*</span></ion-label>
                        <div class="periodoAplicavel">

                            <ion-item fill="outline" style="width: 50%;">
                                <ion-label> </ion-label>
                                <ion-input v-model="atualizacao.dataInicio" type="date"
                                    style="font-size: 14px;"></ion-input>
                            </ion-item>
                            <ion-item fill="outline" style="width: 50%;">
                                <ion-label> </ion-label>
                                <ion-input v-model="atualizacao.dataFim" type="date" style="font-size: 14px;"></ion-input>
                            </ion-item>
                        </div>
                    </div>

                    <ion-button size="large" expand="block" @click="cadastrarAtualizacao">{{ $t("Register") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
      
<script>
import { IonBackButton, IonSelect, IonList, IonSelectOption, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ref } from 'vue';
import Papa from 'papaparse';
import moment from 'moment';

export default {
    components: {
        IonBackButton, IonSelect, IonList, IonSelectOption, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        const atualizacao = ref(null);
        return {
            atualizacao,
            empresas: '',
            empresaSelecionada: '',
            especies: '',
            funcionalidades: '',
            master: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchEmpresas()
        this.fetchUser()
        this.fetchEspecies()
        this.getAtualizacao()
    },
    methods: {
        handleFileSelect(event) {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const csvData = e.target.result;
                    this.parseCSV(csvData);
                };

                reader.readAsText(file);
            }
        },
        parseCSV(csvData) {
            Papa.parse(csvData, {
                header: true,
                complete: (results) => {
                    const csvRows = results.data;

                    csvRows.forEach((row) => {
                        this.atualizacao.novaQuantidade = row.new_quantity;
                        this.atualizacao.produtoServico = parseInt(row.product_service);
                        this.atualizacao.precoAtual = row.current_price;
                        const DateFormatted1 = moment(row.next_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                        this.atualizacao.novaNascimentoData = DateFormatted1;
                        this.atualizacao.novoPreco = row.new_price;
                        const DateFormatted2 = moment(row.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                        this.atualizacao.dataInicio = DateFormatted2;
                        const DateFormatted3 = moment(row.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                        this.atualizacao.dataFim = DateFormatted3;
                        if (this.master) {
                            this.atualizacao.empresa = parseInt(row.company);
                        }
                    });
                },
            });
        },
        async getAtualizacao() {
            const Id = this.$route.params.id
            const token = localStorage.getItem('token');
            console.log(Id)
            try {
                const response = await axios.get(`${config.apiUrl}/financial/updates/${Id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.atualizacao = response.data.item;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchEspecies() {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/diseases/${userId}/species`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const empresaId = parseInt(localStorage.getItem('idEmpresa'), 10);

                const items = response.data.items.filter((item) => {
                    const empresa = parseInt(item.empresa, 10);
                    return empresa == empresaId;
                }).map((item) => {
                    return {
                        nome: item.nome,
                        id: item.id
                    };
                });

                this.especies = items;
            } catch (error) {
                console.log(error);
            }
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(43)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        formatEuro(value) {
            return value.replace(/[^\d.,]/g, '') + " €";
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresas = response.data.items;

            } catch (error) {
                console.log(error);
            }
        },
        async fetchUser() {
            const userId = localStorage.getItem('id');
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresa = parseInt(localStorage.getItem('idEmpresa'));
                const idEmpresa = parseInt(localStorage.getItem('idEmpresa'))
                this.master = response.data.master
                this.fetchLagos(idEmpresa)
            } catch (error) {
                console.log(error);
            }
        },
        async cadastrarAtualizacao() {

            if (!this.atualizacao.novaQuantidade) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("New_quantity"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.atualizacao.produtoServico) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Product/Service"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }
            if (!this.atualizacao.precoAtual) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Current_price"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }
            if (!this.atualizacao.novaNascimentoData) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Next_date_of_birth"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }
            if (!this.atualizacao.novoPreco) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("New_price"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.atualizacao.dataInicio || !this.atualizacao.dataFim) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Applicable_Period"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            const dataInicio = new Date(this.atualizacao.dataInicio);
            const dataFim = new Date(this.atualizacao.dataFim);

            if (dataInicio > dataFim) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Applicable_Period"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            const atualizacao = {
                produtoServico: this.atualizacao.produtoServico,
                novaNascimentoData: this.atualizacao.novaNascimentoData,
                novaQuantidade: this.atualizacao.novaQuantidade,
                novoPreco: this.atualizacao.novoPreco,
                precoAtual: this.atualizacao.precoAtual,
                dataFim: this.atualizacao.dataFim,
                dataInicio: this.atualizacao.dataInicio,
                empresa: this.atualizacao.empresa
            };

            console.log(atualizacao);
            const idAtt = this.$route.params.id
            const token = localStorage.getItem('token');
            axios.put(`${config.apiUrl}/financial/updates/edit/${idAtt}`, atualizacao, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_registered!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                        window.location.replace("/financial/updates")
                    });
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_registering"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });
        }

    }
};
</script>
      
<style scoped>
.select {
    --inner-padding-end: 0px;
    width: 100%;
}

.selects {
    display: flex;
    flex-direction: column;
}

.item {
    padding: 0px;
}

.singleCheckBox .check {
    margin-left: 10px;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.periodoAplicavel {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.botoes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.addProduct {
    width: 49.5%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    background: #0049b1;
    color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addProduct h2 {
    margin: 0px;
    font-size: 16px;
}

.plusIcon {
    font-size: 35px;
    color: white;

}

.cem {
    width: 100%;
}

.products {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}</style>