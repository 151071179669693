<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector></language-selector>
          </ion-buttons>
          <ion-title>{{ $t("License_Information") }} </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding" :scroll-y="false">
        <div class="content-items">
          <ion-item v-if="version" fill="outline">
            <ion-label position="stacked">{{ $t("Current_version") }}</ion-label>
            <ion-input class="naoEdita" :readonly="version">{{ version }}</ion-input>
          </ion-item>
          <ion-item v-if="user" fill="outline">
            <ion-label position="stacked">{{ $t("Client_Name") }}</ion-label>
            <ion-input class="naoEdita" :value="user.empresa" :readonly="user.empresa"></ion-input>
          </ion-item>
          <ion-item v-if="companyFone" fill="outline">
            <ion-label position="stacked">{{ $t("Contact_Telephone") }}</ion-label>
            <ion-input class="naoEdita" :value="companyFone.whatsapp" :readonly="companyFone.whatsapp"></ion-input>
          </ion-item>
          <ion-item v-if="dataVencimentoValor" fill="outline">
            <ion-label position="stacked">{{ $t("Next_due_date") }}</ion-label>
            <ion-input class="naoEdita"  :value="dataVencimentoValor" :readonly="dataVencimentoValor"></ion-input>
          </ion-item>
          <ion-item v-if="dataPagamentoValor" fill="outline">
            <ion-label position="stacked">{{ $t("Payday") }}</ion-label>
            <ion-input class="naoEdita"  :value="dataPagamentoValor" :readonly="dataPagamentoValor"></ion-input>
          </ion-item>
          <ion-button size="large" expand="block" id="open_amostragem_modal1">{{ $t("Invoices") }}</ion-button>
          <!-- <ion-button size="large" expand="block">signatures</ion-button> -->
          <ion-modal ref="modal" trigger="open_amostragem_modal1" @ionModalDidPresent="onModalDidPresent">
            <ion-content>
              <ion-toolbar>
                <ion-title>{{ $t("Invoices") }}</ion-title>
                <ion-buttons slot="end">
                  <ion-button color="light" @click="dismiss()">{{ $t("Close") }}</ion-button>
                </ion-buttons>
              </ion-toolbar>
              <ion-content class="ion-padding">
                <div class="main">
                  <div class="content-items">
                    <table class="faturas-table">
                      <thead>
                        <tr>
                          <th>{{ $t("Invoice") }}</th>
                          <th>{{ $t("Amount") }}</th>
                          <th>{{ $t("Status") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="fatura in faturas" :key="fatura.id">
                          <td>{{ fatura.data }}</td>
                          <td>{{ fatura.valor }}</td>
                          <td>{{ fatura.status }}</td>
                          <td v-if="fatura.link == 'Pay now'">
                            {{ fatura.link }}
                          </td>
                          <td v-else>
                            <a :href="fatura.link" target="_blank">{{ $t("Download") }}</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ion-content>

            </ion-content>
          </ion-modal>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>
  
<script>
import { alertController,IonButton, IonModal ,IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ref } from 'vue';
import { version } from "../../version";
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  components: {
    'language-selector' : LanguageSelector , IonButton, IonModal ,IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
    const user = ref(null);
    const dataVencimentoValor = ref(null);
    const dataPagamentoValor = ref(null);
    const companyFone = ref(null);
    return {
      funcionalidades:'',
      user,
      dataVencimentoValor,
      dataPagamentoValor,
      companyFone,
      especies: "",
      version: version,
      faturas: [
        {
          id: 1,
          data: 'May 2023',
          valor: 100,
          status: 'Paid',
          link: 'https://exemplo.com/fatura1.pdf'
        },
        {
          id: 2,
          data: 'April 2023',
          valor: 150,
          status: 'Paid',
          link: 'https://exemplo.com/fatura2.pdf'
        },
        {
          id: 3,
          data: 'March 2023',
          valor: 200,
          status: 'Unpaid',
          link: 'Pay now'
        }
      ]
    }
  },
  mounted() {
    this.getPermissoes();
    this.fetchUser();
    this.dataVencimento();
  },
  methods: {
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(40)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    dismiss() {
            this.$refs.modal.$el.dismiss();
        },
    adicionarUmMes(data) {
      let mesAtual = data.getMonth();
      let novaData = new Date(data);
      novaData.setMonth(mesAtual + 1);
      return novaData;
    },
    dataVencimento() {
      let data = new Date(2023, 3, 19);
      let formatadaData = data.toLocaleDateString('pt-br');
      this.dataPagamentoValor = formatadaData
      let novaData = this.adicionarUmMes(data);
      let dataFormatada = novaData.toLocaleDateString('pt-br');
      this.dataVencimentoValor = dataFormatada
    },
    async fetchCompany() {
      const companyId = this.userCompany
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/companies/${companyId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.nomeEmpresa = response.data.nome;
        this.user.empresa = this.nomeEmpresa
        this.fetchFone(this.nomeEmpresa)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          this.user = response.data
          this.userCompany = parseInt(localStorage.getItem('idEmpresa'))
          this.fetchCompany(this.userCompany)

        })
        .catch(error => {
          console.log(error);
        });

    },
    async fetchFone() {
      const companyName = this.nomeEmpresa
      const data = {
        nome: companyName
      }
      const token = localStorage.getItem('token');
      axios.put(`${config.apiUrl}/companies/getCompany/name`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          this.companyFone = response.data
        })
        .catch(error => {
          console.log(error);
        });
    }

  },

};
</script>
  
<style scoped>

.faturas-table {
  width: 100%;
  border-collapse: collapse;
}

.faturas-table th,
.faturas-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.faturas-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.faturas-table td a {
  color: #007bff;
  text-decoration: none;
}

.faturas-table td a:hover {
  text-decoration: underline;
}
.naoEdita {
  color: #838383;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

#open_amostragem_modal1 {
    --background: transparent;
    border: #0049b1 2px solid;
    --color: #0049b1;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
}

.content-items {
  max-width: 450px;
  max-height: 550px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
</style>