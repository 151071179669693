<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Chemistry") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <!-- <IonItem fill="outline">
                        <ion-label position="stacked">Company name</ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" placeholder="Select company"
                            id="companyInput" v-model="centro.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem> -->
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Element") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="elemento">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in elementos" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Register") }} {{ $t("Element") }}</ion-label>
                        <ion-input v-model="nomeElemento"></ion-input>
                    </ion-item>
                    <IonItem fill="outline" >
                        <ion-label position="floating">{{ $t("Warehouse") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="armazem" @ionChange="selecionarArmazem($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in armazens" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Was_in") }}</ion-label>
                        <ion-input v-model="estavaEm" @input="estavaEm = formatKg($event.target.value)"
                        :placeholder="$t('apply_to_warehouse')"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarEstava">{{ $t("Confirm") }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Consumption") }}</ion-label>
                        <ion-input v-model="consumo" @input="consumo = formatKg($event.target.value)"
                        :placeholder="$t('Applies_to_tanks_only')"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarConsumo">{{ $t("Confirm") }}</ion-button>
                    <br>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import {IonBackButton,  IonSelect, IonList, IonSelectOption, alertController, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    components: {
        IonBackButton, IonSelect, IonList, IonSelectOption, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            elementos: '',
            consumo: '',
            elemento: '',
            estavaEm: '',
            empresas: '',
            nomeElemento: '',
            armazens: '',
            armazem: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchEmpresas()
        this.buscarArmazens()
        this.nomeEmpresa = parseInt(localStorage.getItem('idEmpresa'));
                this.fetchElementos(this.nomeEmpresa)
        this.obterDataAtual()
    },
    methods: {
        async buscarArmazens() {

            const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${config.apiUrl}/configs/warehouse?search=${this.termoPesquisa}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

            const empresaId = parseInt(localStorage.getItem('idEmpresa'), 10);

            const items = response.data.items.filter((item) => {
              const empresa = parseInt(item.empresa, 10);
              return empresa == empresaId;
            }).map((item) => {
              return {
                nome: item.nome,
                id: item.id
              };
            });

            this.armazens = items;
        } catch (error) {
          return console.error("Erro ao buscar nomes ", error);
        }
      },
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;

            } catch (error) {
                console.log(error);
            }
        },
        formatKg(value) {
            return value.replace(/[^\d.,]/g, '') + " Kg";
        },
        async fetchElementos() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/elementos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.itens = response.data.items;
                this.elementos = this.itens.filter(item => item.empresa == this.nomeEmpresa);
                console.log(response)
            } catch (error) {
                console.log(error);
            }
        },

        cadastrarEstava() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getQuimica`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.estavaEm);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.estavaEm && (this.elemento || this.nomeElemento)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.nomeElemento = '',
                                        this.elemento = '',
                                        this.armazem = '',
                                        this.estavaEm = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    for (let i = 0; i < this.elementos.length; i++) {
                                        const elemento = this.elementos[i];

                                        if (elemento.empresa == this.nomeEmpresa && elemento.nome == this.nomeElemento) {
                                            const alert = alertController.create({
                                                header: this.$t("Error"),
                                                 message: this.$t("The_following_field_cannot_be_inserted") +" "+ this.$t("Element"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });
                                            this.nomeElemento = ''
                                            return
                                        }
                                    }

                                    if (!this.elemento && !this.nomeElemento) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    if (!this.armazem) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    if (this.nomeElemento !== "") {
                                        const data3 = {
                                            nome: this.nomeElemento,
                                            empresa: this.nomeEmpresa
                                        }

                                        axios.post(`${config.apiUrl}/elementos/create`, data3, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                            .then(response => {
                                                console.log(response.data.id);
                                                this.fetchElementos(this.nomeEmpresa)
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }

                                    const data = {};
                                    if (this.estavaEm) {
                                        data.id_lago = this.$route.params.id
                                        data.elemento = this.nomeElemento ? this.nomeElemento : this.elemento;
                                        data.empresa = this.nomeEmpresa;
                                        data.estavaEm = this.estavaEm
                                        data.data_registro = this.dataAtual
                                        data.armazem = this.armazem
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarQuimica/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.nomeElemento = '',
                                                this.elemento = '',
                                                this.estavaEm = ''
                                                this.armazem = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        for (let i = 0; i < this.elementos.length; i++) {
                            const elemento = this.elementos[i];

                            if (elemento.empresa == this.nomeEmpresa && elemento.nome == this.nomeElemento) {
                                const alert = alertController.create({
                                    header: this.$t("Error"),
            message: this.$t("The_following_field_cannot_be_inserted") +" "+this.$t("Element"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });
                                this.nomeElemento = ''
                                return
                            }
                        }

                        if (!this.elemento && !this.nomeElemento) {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }

                        if (!this.armazem) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                        if (this.nomeElemento !== "") {
                            const data3 = {
                                nome: this.nomeElemento,
                                empresa: this.nomeEmpresa
                            }

                            axios.post(`${config.apiUrl}/elementos/create`, data3, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                .then(response => {
                                    console.log(response.data.id);
                                    this.fetchElementos(this.nomeEmpresa)
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }

                        const data = {};
                        if (this.estavaEm) {
                            data.id_lago = this.$route.params.id
                            data.elemento = this.nomeElemento ? this.nomeElemento : this.elemento;
                            data.empresa = this.nomeEmpresa;
                            data.estavaEm = this.estavaEm
                            data.data_registro = this.dataAtual
                            data.armazem = this.armazem
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarQuimica/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.nomeElemento = '',
                                    this.elemento = '',
                                    this.estavaEm = ''
                                    this.armazem = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarConsumo() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getQuimica`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.consumo);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.consumo && (this.elemento || this.nomeElemento)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                                this.nomeElemento = '',
                                                this.elemento = '',
                                                this.consumo = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    for (let i = 0; i < this.elementos.length; i++) {
                                        const elemento = this.elementos[i];

                                        if (elemento.empresa == this.nomeEmpresa && elemento.nome == this.nomeElemento) {
                                            const alert = alertController.create({
                                                header: this.$t("Error"),
            message: this.$t("The_following_field_cannot_be_inserted") +" "+ this.$t("Element"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });
                                            this.nomeElemento = ''
                                            return
                                        }
                                    }

                                    if (!this.elemento && !this.nomeElemento) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    if (!this.armazem) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    if (this.nomeElemento !== "") {
                                        const data4 = {
                                            nome: this.nomeElemento,
                                            empresa: this.nomeEmpresa
                                        }

                                        axios.post(`${config.apiUrl}/elementos/create`, data4, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                            .then(response => {
                                                console.log(response.data.id);
                                                this.fetchElementos(this.nomeEmpresa)
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }

                                    const data2 = {};
                                    if (this.consumo) {
                                        data2.id_lago = this.$route.params.id
                                        data2.elemento = this.nomeElemento ? this.nomeElemento : this.elemento;
                                        data2.empresa = this.nomeEmpresa;
                                        data2.consumo = this.consumo;
                                        data2.data_registro = this.dataAtual
                                        data2.armazem = this.armazem
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data2)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarQuimica/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.nomeElemento = '',
                                                this.elemento = '',
                                                this.consumo = ''
                                                this.armazem = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        for (let i = 0; i < this.elementos.length; i++) {
                            const elemento = this.elementos[i];

                            if (elemento.empresa == this.nomeEmpresa && elemento.nome == this.nomeElemento) {
                                const alert = alertController.create({
                                    header: this.$t("Error"),
            message: this.$t("The_following_field_cannot_be_inserted") +" "+ this.$t("Element"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });
                                this.nomeElemento = ''
                                return
                            }
                        }

                        if (!this.elemento && !this.nomeElemento) {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }

                        if (!this.armazem) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                        if (this.nomeElemento !== "") {
                            const data4 = {
                                nome: this.nomeElemento,
                                empresa: this.nomeEmpresa
                            }

                            axios.post(`${config.apiUrl}/elementos/create`, data4, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                .then(response => {
                                    console.log(response.data.id);
                                    this.fetchElementos(this.nomeEmpresa)
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }

                        const data2 = {};
                        if (this.consumo) {
                            data2.id_lago = this.$route.params.id
                            data2.elemento = this.nomeElemento ? this.nomeElemento : this.elemento;
                            data2.empresa = this.nomeEmpresa;
                            data2.consumo = this.consumo;
                            data2.data_registro = this.dataAtual
                            data2.armazem = this.armazem
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data2)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarQuimica/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.nomeElemento = '',
                                    this.elemento = '',
                                    this.consumo = ''
                                    this.armazem = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        }
    }
};
</script>
    
<style scoped>
ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>