<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/config/infrastructure"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Register") }} {{ $t("Zone") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding" v-if="empresas">
                <div class="content-items" v-for="zona of zonas" :key="zona.id">
                    <IonItem fill="outline" v-if="master">
                        <ion-label position="floating">{{ $t("Companies") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            @ionChange="selecionarEmpresa($event)" v-model="zona.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="empresa in empresas" :key="empresa.id">
                                    <ion-select-option :value=empresa.id v-model="empresa.id">{{
                                        empresa.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <div class="contentCommercial" v-if="centros">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Production_centers") }} <span
                                        style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select" v-model="zona.id_centro">
                                    <ion-select-option v-for="(centro, index) in centros" :value="centro.id" :key="index">
                                        {{ centro.nomeLegal }}
                                    </ion-select-option>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="zona.nome"></ion-input>
                    </ion-item>
                    <div class="products">
                        <ion-label slot="end">{{ $t("Sections") }} <span style="color: red;">*</span></ion-label>
                    </div>
                    <div v-for="(secao, index) of zona.secoes" :key="index" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                        <ion-item fill="outline"  class="produto" style="width: 88%;">
                            <ion-label position="floating">{{ $t("Section") }}</ion-label>
                            <ion-input v-model="secao.texto"></ion-input>
                        </ion-item>

                        <div class="iconDelete" style="width: 30px;display: flex; align-items: center;">
                            <ion-icon @click="removeSecao(zona, index)" :ios="closeOutline" :md="closeSharp"
                                style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                        </div>
                    </div>
                    <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
                        <ion-icon @click="addSecao(zona)" :ios="addOutline" :md="addSharp"
                            style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
                    </div>

                    <ion-button size="large" expand="block" @click="cadastrarCompra">{{ $t("Register") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonSelect, IonSelectOption, IonIcon, IonList, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { addOutline, addSharp, closeOutline, closeSharp } from 'ionicons/icons';

export default {
    components: {
        IonBackButton, IonSelect, IonSelectOption, IonIcon, IonList, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            zonas: [{
                empresa: '',
                id_centro: '',
                nome: '',
                secoes: [{
                    texto: ''
                }
                ],
            }]
            ,
            centros: "",
            addOutline,
            addSharp,
            closeOutline,
            closeSharp,
            empresas: '',
            funcionalidades: '',
            master: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchUser()
    },
    methods: {
        async fetchUser() {
            const userId = localStorage.getItem('id');
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.master = response.data.master
                this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
                if (!this.master) {
                    this.getCompanyName(this.idEmpresa)
                    this.zonas[0].empresa = this.idEmpresa
                }
                this.fetchEmpresas()
            } catch (error) {
                console.log(error);
            }
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message:  this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async showCentros(empresaId) {
            const token = localStorage.getItem('token');
            const data = {
                empresa: empresaId
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getProductionCenterbyCompanyName`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const centros = response.data.items.map((centro) => {
                    return {
                        nomeLegal: centro.nomeLegal,
                        id: centro.id
                    };
                });
                this.centros = centros;
                this.zonas[0].id_centro = parseInt(localStorage.getItem('idCentro'))
            } catch (error) {
                console.log(error);
            }
        },
        async getCompanyName(empresaId) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios
                    .get(`${config.apiUrl}/companies/${empresaId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                this.nomeEmpresa = response.data.nome
                this.showCentros(empresaId)
            } catch (error) {
                console.error("Erro ao buscar nomes ", error);
            }
        },
        selecionarEmpresa($event) {
            this.valueEmpresa = $event.target.value
            this.getCompanyName(this.valueEmpresa);
        },
        addSecao(zona) {
            zona.secoes.push({
                texto: ''
            })
        },
        async removeSecao(zona, index) {
            if (zona.secoes.length > 1) {
                zona.secoes = zona.secoes.filter((z, i) => i !== index )
            } else {
                const alert = await alertController.create({
                    header: this.$t("Invalid_action"),
                    message: this.$t("You_can_not_delete_all"),
                    buttons: ['OK']
                });
                await alert.present();
            }
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresas = response.data.items;

                const hasPermissoes = this.master

                if (!hasPermissoes) {
                    this.empresas = this.empresas.filter(empresa => empresa.id == this.idEmpresa);
                }

                if (hasPermissoes) {
                    const id = localStorage.getItem('idEmpresaCentro')
                    this.zonas[0].empresa = parseInt(id)
                    this.showCentros(parseInt(id))
                }
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEspecie(event) {
            this.valueEspecie = event.target.value
        },
        async cadastrarCompra() {

            if (!this.zonas[0].empresa) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Company"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.zonas[0].id_centro) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Production_Center"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.zonas[0].nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            for (let i = 0; i < this.zonas.length; i++) {
                const zona = this.zonas[i];
                for (let j = 0; j < zona.secoes.length; j++) {
                    const secao = zona.secoes[j];
                    if (!secao.texto) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Section"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                }
            }

            const zonas = {
                zonas: {
                    empresa: this.zonas[0].empresa,
                    centroProducao: this.zonas[0].id_centro,
                    nome: this.zonas[0].nome,
                    id_centro: this.zonas[0].id_centro,
                    secoes: this.zonas[0].secoes,
                }
            };
            const token = localStorage.getItem('token');
            await axios.post(`${config.apiUrl}/infrastructures/zona/create`, zonas, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data.id);
                    localStorage.setItem('idZona', response.data.id)
                    const alert = alertController.create({
                        header: this.$t("successfully_registered!")
                    });
                    alert.then(_alert => {
                        _alert.present();
                        const inTutorial = localStorage.getItem('inTutorial')
                        if (inTutorial) {
                            localStorage.setItem('tutorialCompleted', false);
                            localStorage.setItem('inTutorial', true);
                        }
                        window.location.replace("/config/infrastructure")
                    });
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_registering"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });
        }

    }
};
</script>
    
<style scoped>
.botoes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.addProduct {
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    background: #0049b1;
    color: white;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addProduct h2 {
    margin: 0px;
    font-size: 16px;
}

.plusIcon {
    font-size: 35px;
    color: white;

}

.products {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>