<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button
                            :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Oxygen") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <ion-item fill="outline">
                        <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date") }} <span
                            style="color: red;">*</span></ion-label>
                        <ion-input v-model="data_registro" type="date" @ionChange="onDataChange"></ion-input>
                    </ion-item>
                    <ion-item fill="outline" lines="none">
                        <div class="rechargeDiv">
                            <ion-item lines="full">
                                <ion-label position="floating">{{ $t("Recharge") }}</ion-label>
                                <ion-input type="number" v-model="recarga"
                                    :placeholder="$t('Applies_to_tanks_only')"></ion-input>
                            </ion-item>
                            <ion-radio-group v-model="recargaUnits">
                                <ion-item lines="full">
                                    <ion-label>Kg</ion-label>
                                    <ion-radio value="kg"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>m³</ion-label>
                                    <ion-radio value="m3"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </div>
                    </ion-item>
                    <ion-button v-if="!isNoLake" size="large" expand="block" @click="cadastrarRecarga">{{ $t("Confirm")
                    }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="cadastrarRecargaGeral">{{ $t("Confirm")
                    }}</ion-button>
                    <br>
                    <ion-item fill="outline" lines="none">
                        <div class="rechargeDiv">
                            <ion-item lines="full">
                                <ion-label position="floating">{{ $t("Consumption") }}</ion-label>
                                <ion-input v-model="consumo" type="number"></ion-input>
                            </ion-item>
                            <ion-radio-group v-model="consumoUnits">
                                <ion-item lines="full">
                                    <ion-label>Kg</ion-label>
                                    <ion-radio value="kg"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>m³</ion-label>
                                    <ion-radio value="m3"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </div>
                    </ion-item>
                    <div class="c-datas-flex">
                        <ion-item fill="outline" class="c-data">
                            <ion-label position="stacked" style="font-size: 18px;">{{ $t("Inicial_Date") }} <span
                                    style="color: red;">*</span></ion-label>
                            <ion-input v-model="cDataInicial" type="date"></ion-input>
                        </ion-item>
                        <ion-item fill="outline" class="c-data">
                            <ion-label position="stacked" style="font-size: 18px;">{{ $t("Final_Date") }} <span
                                    style="color: red;">*</span></ion-label>
                            <ion-input v-model="cDataFinal" type="date"></ion-input>
                        </ion-item>
                    </div>
                    <ion-button v-if="!isNoLake" size="large" expand="block" @click="cadastrarConsumo">{{ $t("Confirm")
                    }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="cadastrarConsumoGeral">{{ $t("Confirm")
                    }}</ion-button>
                    <br>
                    <ion-item fill="outline" lines="none">
                        <div class="rechargeDiv">
                            <ion-item lines="full">
                                <ion-label position="floating">{{ $t("Use") }}</ion-label>
                                <ion-input type="number" v-model="uso"></ion-input>
                            </ion-item>
                            <ion-radio-group v-model="usoUnits">
                                <ion-item lines="full">
                                    <ion-label>g/h</ion-label>
                                    <ion-radio value="g/h"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>L/h</ion-label>
                                    <ion-radio value="L/h"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>Kg/h</ion-label>
                                    <ion-radio value="Kg/h"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>m3/h</ion-label>
                                    <ion-radio value="m3/h"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                            <ion-item lines="none">
                                <ion-label position="stacked">{{ $t("Total_amount_of_hours") }}</ion-label>
                                <ion-input type="number" v-model="total_horas"></ion-input>
                            </ion-item>
                        </div>
                    </ion-item>
                    <ion-button v-if="!isNoLake" size="large" expand="block" @click="cadastrarUso">{{ $t("Confirm")
                    }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="cadastrarUsoGeral">{{ $t("Confirm")
                    }}</ion-button>
                    <br>
                    <ion-item fill="outline" lines="none">
                        <div class="rechargeDiv">
                            <ion-item lines="full">
                                <ion-label position="floating">{{ $t("Measurement") }}</ion-label>
                                <ion-input type="number" v-model="medicao"
                                    :placeholder="$t('Could_also_be_a_sensor')"></ion-input>
                            </ion-item>
                            <ion-radio-group v-model="medicaoUnits">
                                <ion-item lines="full">
                                    <ion-label>mg/L</ion-label>
                                    <ion-radio value="mg/L"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>%</ion-label>
                                    <ion-radio value="%"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </div>
                    </ion-item>
                    <ion-button v-if="!isNoLake" size="large" expand="block" @click="cadastrarMedicao">{{ $t("Confirm")
                    }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="cadastrarMedicaoGeral">{{ $t("Confirm")
                    }}</ion-button>
                    <br>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonRadio, IonRadioGroup, IonButton, IonLabel, alertController, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    components: {
        IonBackButton, IonRadio, IonRadioGroup, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            total_horas: '',
            recarga: '',
            recargaUnits: [],
            consumo: '',
            consumoUnits: [],
            uso: '',
            usoUnits: [],
            medicao: '',
            medicaoUnits: [],
            dataAtual: '',
            cDataInicial: '',
            cDataFinal: '',
            isNoLake: false,
            data_registro: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.obterDataAtual()
        this.defineType()
    },
    methods: {
        async onDataChange() {
            this.fetchDataGeral(this.data_registro);
        },
        formatDataForDatabase2(date) {
            const parts = date.split('-');
            const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
            return formattedDate;
        },
        defineType() {
            const data = localStorage.getItem('CZSL')

            if (data != 'Lake') {
                this.isNoLake = true
            } else {
                this.isNoLake = false
            }
        },
        async fetchDataGeral(date) {
            try {
                this.consumo = null;
                this.recarga = null;
                this.total_horas = null;
                this.uso = null;
                this.medicao = null;
                this.cDataFinal = null;
                this.cDataInicial = null;
                this.recargaUnits = [];
                this.consumoUnits = [];
                this.usoUnits = [];
                this.medicaoUnits = [];

                const tipo = localStorage.getItem('CZSL')
                const id = this.$route.params.id
                const token = localStorage.getItem('token');

                const data = {
                    type: tipo,
                    data_registro: this.formatDataForDatabase2(date)
                }
              
                const response = await axios.get(`${config.apiUrl}/getByIdOxigenio/${id}`, {
                    params: data,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const consumo = response.data.consumo;
                const recarga = response.data.recarga;
                const uso = response.data.uso;
                const medicao = response.data.medicao;
                const total_horas = response.data.total_horas;
                const cdf = response.data.c_data_final;
                const cdi = response.data.c_data_inicial;
                const rt = response.data.recarga_tipo;
                const ct = response.data.consumo_tipo;
                const ut = response.data.uso_tipo;
                const mt = response.data.medicao_tipo;
console.log(response)
                this.consumo = consumo;
                this.recarga = recarga;
                this.uso = uso;
                this.medicao = medicao;
                this.total_horas = total_horas;
                if (cdf && cdi){
                    this.cDataFinal = this.formatDataForDatabase(cdf);
                    this.cDataInicial = this.formatDataForDatabase(cdi);
                }
                this.recargaUnits = rt;
                this.usoUnits = ut;
                this.medicaoUnits = mt;
                this.consumoUnits = ct;

            } catch (error) {
                console.log(error);
            }
        },
        cadastrarRecargaGeral() {
            const data = {};

            const token = localStorage.getItem('token');
            const dataType = localStorage.getItem('CZSL')

            if (dataType == 'Center') {
                data.idCentro = this.$route.params.id
            } else if (dataType == 'Zone') {
                data.idZona = this.$route.params.id
            } else if (dataType == 'Section') {
                data.idSecao = this.$route.params.id
            } else {
                data.id_lago = this.$route.params.id
            }

            if (this.recarga && this.recargaUnits.length !== 0) {
                data.recarga_tipo = this.recargaUnits;
                data.recarga = this.recarga
                data.data_registro = this.formatDataForDatabase2(this.data_registro)
            } else {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }
            console.log(data)
            axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data);

                    const alert = alertController.create({
                        header: this.$t("Success"),
                        message: this.$t("successfully_registered!"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });

                    this.recargaUnits = []
                    this.recarga = ''
                    return;
                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarConsumoGeral() {
            const data2 = {};

            const token = localStorage.getItem('token');
            const dataType = localStorage.getItem('CZSL')

            if (dataType == 'Center') {
                data2.idCentro = this.$route.params.id
            } else if (dataType == 'Zone') {
                data2.idZona = this.$route.params.id
            } else if (dataType == 'Section') {
                data2.idSecao = this.$route.params.id
            } else {
                data2.id_lago = this.$route.params.id
            }

            if (this.consumo && this.consumoUnits.length !== 0 && this.cDataFinal && this.cDataInicial) {
                data2.consumo_tipo = this.consumoUnits;
                data2.consumo = this.consumo
                data2.data_registro = this.formatDataForDatabase2(this.data_registro)
                data2.c_data_final = this.formatDataForDatabase(this.cDataFinal)
                data2.c_data_inicial = this.formatDataForDatabase(this.cDataInicial)
                if (this.cDataFinal < this.cDataInicial) {
                    const alert = alertController.create({
                        header: this.$t("Error"),
                        message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Inicial_Date") + ", " + this.$t("Final_Date"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                    return
                }
            } else {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }
            console.log(data2)
            axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data2, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data);

                    const alert = alertController.create({
                        header: this.$t("Success"),
                        message: this.$t("successfully_registered!"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });

                    this.consumoUnits = []
                    this.consumo = ''
                    this.cDataFinal = ''
                    this.cDataInicial = ''
                    return;
                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarUsoGeral() {
            const data3 = {};

            const token = localStorage.getItem('token');
            const dataType = localStorage.getItem('CZSL')

            if (dataType == 'Center') {
                data3.idCentro = this.$route.params.id
            } else if (dataType == 'Zone') {
                data3.idZona = this.$route.params.id
            } else if (dataType == 'Section') {
                data3.idSecao = this.$route.params.id
            } else {
                data3.id_lago = this.$route.params.id
            }

            if (this.uso && this.total_horas && this.usoUnits.length !== 0) {
                data3.uso_tipo = this.usoUnits;
                data3.uso = this.uso
                data3.total_horas = this.total_horas
                data3.data_registro = this.formatDataForDatabase2(this.data_registro)
            } else {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }
            console.log(data3)
            axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data3, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data);

                    const alert = alertController.create({
                        header: this.$t("Success"),
                        message: this.$t("successfully_registered!"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });

                    this.usoUnits = []
                    this.uso = ''
                    this.total_horas = ''
                    return;
                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarMedicaoGeral() {
            const data4 = {};

            const token = localStorage.getItem('token');
            const dataType = localStorage.getItem('CZSL')

            if (dataType == 'Center') {
                data4.idCentro = this.$route.params.id
            } else if (dataType == 'Zone') {
                data4.idZona = this.$route.params.id
            } else if (dataType == 'Section') {
                data4.idSecao = this.$route.params.id
            } else {
                data4.id_lago = this.$route.params.id
            }

            if (this.medicao && this.medicaoUnits.length !== 0) {
                data4.medicao_tipo = this.medicaoUnits;
                data4.medicao = this.medicao
                data4.data_registro = this.formatDataForDatabase2(this.data_registro)
            } else {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }
            console.log(data4)
            axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data4, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data);

                    const alert = alertController.create({
                        header: this.$t("Success"),
                        message: this.$t("successfully_registered!"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });

                    this.medicaoUnits = []
                    this.medicao = ''
                    return;
                })
                .catch(error => {

                    console.log(error);
                });
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        formatDataForDatabase(date) {
            const parts = date.split('-');
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            return formattedDate;
        },
        cadastrarRecarga() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getOxigenio`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.recarga);
                    console.log(objetoEncontrado)
                    if (objetoEncontrado.length > 0 && (this.recarga && this.recargaUnits.length !== 0)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.recargaUnits = []
                                    this.recarga = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data = {};

                                    if (this.recarga && this.recargaUnits.length !== 0) {
                                        data.id_lago = this.$route.params.id
                                        data.recarga_tipo = this.recargaUnits;
                                        data.recarga = this.recarga
                                        data.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.recargaUnits = []
                                            this.recarga = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data = {};

                        if (this.recarga && this.recargaUnits.length !== 0) {
                            data.id_lago = this.$route.params.id
                            data.recarga_tipo = this.recargaUnits;
                            data.recarga = this.recarga
                            data.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.recargaUnits = []
                                this.recarga = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarConsumo() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getOxigenio`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.consumo);

                    if (objetoEncontrado.length > 0 && (this.consumo && this.consumoUnits.length !== 0)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.consumoUnits = []
                                    this.consumo = ''
                                    this.cDataFinal = ''
                                    this.cDataInicial = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data2 = {};
                                    if (this.consumo && this.consumoUnits.length !== 0 && this.cDataFinal && this.cDataInicial) {
                                        data2.id_lago = this.$route.params.id
                                        data2.consumo_tipo = this.consumoUnits;
                                        data2.consumo = this.consumo
                                        data2.data_registro = this.dataAtual
                                        data2.c_data_final = this.formatDataForDatabase(this.cDataFinal)
                                        data2.c_data_inicial = this.formatDataForDatabase(this.cDataInicial)

                                        if (this.cDataFinal < this.cDataInicial) {
                                            const alert = alertController.create({
                                                header: this.$t("Error"),
                                                message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Inicial_Date") + ", " + this.$t("Final_Date"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });
                                            return
                                        }
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data2)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data2, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.consumoUnits = []
                                            this.consumo = ''
                                            this.cDataFinal = ''
                                            this.cDataInicial = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data2 = {};
                        if (this.consumo && this.consumoUnits.length !== 0 && this.cDataFinal && this.cDataInicial) {
                            data2.id_lago = this.$route.params.id
                            data2.consumo_tipo = this.consumoUnits;
                            data2.consumo = this.consumo
                            data2.data_registro = this.dataAtual
                            data2.c_data_final = this.formatDataForDatabase(this.cDataFinal)
                            data2.c_data_inicial = this.formatDataForDatabase(this.cDataInicial)
                            if (this.cDataFinal < this.cDataInicial) {
                                const alert = alertController.create({
                                    header: this.$t("Error"),
                                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Inicial_Date") + ", " + this.$t("Final_Date"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });
                                return
                            }
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data2)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data2, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.consumoUnits = []
                                this.consumo = ''
                                this.cDataFinal = ''
                                this.cDataInicial = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });
        },
        cadastrarUso() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getOxigenio`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.uso);

                    if (objetoEncontrado.length > 0 && (this.uso && this.total_horas && this.usoUnits.length !== 0)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.usoUnits = []
                                    this.uso = ''
                                    this.total_horas = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data3 = {};
                                    if (this.uso && this.total_horas && this.usoUnits.length !== 0) {
                                        data3.id_lago = this.$route.params.id
                                        data3.uso_tipo = this.usoUnits;
                                        data3.uso = this.uso
                                        data3.total_horas = this.total_horas
                                        data3.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data3)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data3, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.usoUnits = []
                                            this.uso = ''
                                            this.total_horas = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data3 = {};
                        if (this.uso && this.total_horas && this.usoUnits.length !== 0) {
                            data3.id_lago = this.$route.params.id
                            data3.uso_tipo = this.usoUnits;
                            data3.uso = this.uso
                            data3.total_horas = this.total_horas
                            data3.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data3)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data3, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.usoUnits = []
                                this.uso = ''
                                this.total_horas = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarMedicao() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getOxigenio`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.medicao);

                    if (objetoEncontrado.length > 0 && (this.medicao && this.medicaoUnits.length !== 0)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.medicaoUnits = []
                                    this.medicao = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data4 = {};
                                    if (this.medicao && this.medicaoUnits.length !== 0) {
                                        data4.id_lago = this.$route.params.id
                                        data4.medicao_tipo = this.medicaoUnits;
                                        data4.medicao = this.medicao
                                        data4.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data4)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data4, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.medicaoUnits = []
                                            this.medicao = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data4 = {};
                        if (this.medicao && this.medicaoUnits.length !== 0) {
                            data4.id_lago = this.$route.params.id
                            data4.medicao_tipo = this.medicaoUnits;
                            data4.medicao = this.medicao
                            data4.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data4)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarOxigenio/create`, data4, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.medicaoUnits = []
                                this.medicao = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }
                })
                .catch(error => {

                    console.log(error);
                });

        },
    }
};
</script>
    
<style scoped>
.rechargeDiv {
    display: flex;
    flex-direction: column;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    width: 100%;
}

.c-datas-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.c-data {
    width: 48%;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>