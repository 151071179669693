<template>
    <ion-page>
  
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <language-selector></language-selector>
            </ion-buttons>
            <ion-title>{{ $t("Items") }}</ion-title>
          </ion-toolbar>
        </ion-header>
  
        <ion-content class="ion-padding">
          <div class="content">
            <div class="content-items">
              <ion-button v-if="this.funcionalidades.includes(32)" size="large" class="createButton" style="margin: 0px ;" @click="criarItem()">{{ $t("Register") }} {{ $t("item") }}</ion-button>
              <!-- <ion-button v-if="this.funcionalidades.includes(32)" size="large" @click="importCSVs" id="importButton" style="--box-shadow:none;margin: 0px;"><ion-icon name="cloud-upload" :ios="enterOutline" :md="enterSharp" style="--box-shadow:none;transform: rotate(90deg); margin-right:10px"></ion-icon>     Import</ion-button> -->
              <IonItem class="selectFilter" fill="outline" style="margin: 0px ;">
                          <ion-label >{{ $t("Select_Filter_type") }}</ion-label>
                          <ion-select position="floating" aria-label="Fruit" class="select" v-model="valueFiltro"
                              id="companyInput" @ionChange="selecionarFiltro($event)">
                              <ion-content class="ion-padding" id="content-lista">
                                  <ion-list >
                                      <ion-select-option value="descricao">{{ $t("Description") }}</ion-select-option>
                                      <ion-select-option value="preco">{{ $t("Unit_Price") }}</ion-select-option>
                                  </ion-list>
                              </ion-content>
                          </ion-select>
                      </IonItem>
              <ion-item fill="outline" >
                <ion-label position="floating">{{ $t("Filter") }} </ion-label>
                <ion-input v-model="termoPesquisa"></ion-input>
              </ion-item>
              <template v-if="itemsFiltrados.length == 0">
                <div class="noData">
                  <ion-icon :ios="fileTrayStackedOutline" :md="fileTrayStackedSharp"></ion-icon>
                  <p>{{ $t("No_data_Found") }}...</p>
                </div>
              </template>
              <ion-card v-for="(item, index) in itemsFiltrados" :key="index" class="itensCard">
                <ion-card-content class="ion-padding-card">
                  <ion-item lines="none" id="avatar-item" class="ion-margin">
                   
                    <div class="info" @click="editarItem(item.id)">

                        <h2 style="margin: 0px;">{{ $t("Description") }}: {{ item.descricao }}</h2>

                        <h2 style="margin: 0px; color: #838383; font-size: 14px;">{{ $t("Unit_Price") }}: {{ item.preco }}</h2>

                    </div>
  
                    <ion-icon v-if="this.funcionalidades.includes(31) || this.funcionalidades.includes(33)"  class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(item.id)" slot="end"></ion-icon>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </div>
          </div>
          <ion-infinite-scroll @ionInfinite="loadMoreData" style="height: 50px; margin-top: 20px;">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </ion-page>
    </ion-page>
  </template>
  
  <script>
  import { IonInfiniteScroll,IonInfiniteScrollContent,IonSelect,IonList,IonSelectOption,IonButton,IonInput, IonTitle, IonLabel, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonIcon, alertController } from '@ionic/vue';
  import {
    ellipsisVerticalSharp, fileTrayStackedOutline, fileTrayStackedSharp, enterOutline, enterSharp
  } from "ionicons/icons"
  import axios from 'axios';
  import { config } from "../../config"
  import LanguageSelector from "@/components/LanguageSelector.vue";
  import Papa from 'papaparse';

  export default {
    components: {
      'language-selector' : LanguageSelector ,IonInfiniteScroll,IonInfiniteScrollContent,IonSelect,IonList,IonSelectOption,IonButton,IonInput, IonTitle, IonLabel, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard,  IonIcon
    },
    data() {
      return {
        items: [],
        termoPesquisa:'',
        fileTrayStackedOutline,
        fileTrayStackedSharp,
        valueFiltro: 'preco',
        funcionalidades: '', 
        enterOutline, 
        enterSharp,
        currentPage: 1, 
        itemsPerPage: 10, 
        isDataFinished: false, 
      };
    },
    mounted() {
      this.getPermissoes();
    this.buscarDados();
    this.fetchUser()
      this.selecionarFiltro()
    },
    computed: {
      itemsFiltrados() {
        return this.items.filter(item => {  
          let propriedade;
          if (this.valueFiltro == 'preco') {
            propriedade = item.preco.toString();
          } else if (this.valueFiltro == 'descricao') {
            propriedade = item.descricao;
          }
          return propriedade.toLowerCase().includes(this.termoPesquisa.toLowerCase());
        });
      }
  },
  watch: {
    valueFiltro() {
      this.itemsFiltrados();
    }
  },
    methods: {
      async importCSVs() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.multiple = true;
      fileInput.accept = '.csv';

      // Oculte o elemento do DOM
      fileInput.style.display = 'none';

      // Adicione o evento de change para processar os arquivos selecionados
      fileInput.addEventListener('change', async (event) => {
        const files = event.target.files;

        const csvObjectsArray = [];

        // Função para ler um arquivo CSV e gerar os objetos correspondentes
        const transformCSVObject = (csvObject) => {
          // Realize as transformações necessárias para ajustar o formato dos dados
          return {
            empresa: csvObject.empresa || '',
            quimica: csvObject[' quimica'],
            produtos: csvObject[' produtos'],
            unidade: csvObject[' unidade'],
            servico: csvObject[' servico'],
            descricao: csvObject[' descricao'],
            unidade_medida: csvObject[' unidadeMedida'],
            preco: csvObject[' precoUnitario'],
            config_1: csvObject[' configuracao1'],
            config_2: csvObject[' configuracao2'],
            config_3: csvObject[' configuracao3'] ? csvObject[' precoUnitario'].replace(/;/g, '') : '',
          };
        };

        // Função para ler um arquivo CSV e gerar os objetos correspondentes
        const processCSV = (file) => {
          return new Promise((resolve, reject) => {
            Papa.parse(file, {
              header: true,
              complete: (results) => {
                const data = results.data;
                const csvObjects = data.map((csvObject) => transformCSVObject(csvObject));
                csvObjectsArray.push(...csvObjects);
                resolve();
              },
              error: (error) => {
                reject(error);
              },
            });
          });
        };

        // Processar todos os arquivos CSV em paralelo
        const processPromises = [];
        for (let i = 0; i < files.length; i++) {
          processPromises.push(processCSV(files[i]));
        }

        try {
          await Promise.all(processPromises);

          // Filtrar objetos vazios antes de enviar para o backend
          const filteredCsvObjectsArray = csvObjectsArray.filter((obj) => Object.values(obj).some((value) => value !== ''));

          // Se this.master for verdadeiro, substituir o valor da empresa em cada objeto
          if (!this.master) {
            const empresaId = parseInt(localStorage.getItem('idEmpresa'));
            filteredCsvObjectsArray.forEach((obj) => {
              if (obj.empresa !== '') {
                obj.empresa = empresaId;
              }
              if (obj.quimica == 'true') {
                obj.quimica = true;
              } else if (obj.quimica == 'false') {
                obj.quimica = false;
              }
              if (obj.produtos == 'true') {
                obj.produtos = true;
              } else if (obj.produtos == 'false') {
                obj.produtos = false;
              }
              if (obj.servico == 'true') {
                obj.servico = true;
              } else if (obj.servico == 'false') {
                obj.servico = false;
              }
            });
          } else {
            filteredCsvObjectsArray.forEach((obj) => {
              if (obj.quimica == 'true') {
                obj.quimica = true;
              } else if (obj.quimica == 'false') {
                obj.quimica = false;
              }
              if (obj.produtos == 'true') {
                obj.produtos = true;
              } else if (obj.produtos == 'false') {
                obj.produtos = false;
              }
              if (obj.servico == 'true') {
                obj.servico = true;
              } else if (obj.servico == 'false') {
                obj.servico = false;
              }
            });
          }

          const token = localStorage.getItem('token');
          // Envie o array de objetos para o backend usando o axios
          const response = await axios.post(`${config.apiUrl}/financial/items/create`, filteredCsvObjectsArray, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          console.log(response);

          const alert = alertController.create({
            header: this.$t("successfully_registered!"),
            buttons: ['OK'],
          });
          alert.then(_alert => {
            _alert.present();
          });
          alert.then(_alert => {
            _alert.present();
          });
        } catch (error) {
          const alert = alertController.create({
            header: this.$t("Error_registering"),
            buttons: ['OK'],
          });
          alert.then(_alert => {
            _alert.present();
          });
        }
      });

      // Dispare o evento de click no novo elemento para abrir o seletor de arquivos
      fileInput.click();
    },
    async fetchUser() {
      const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.master = response.data.master
            } catch (error) {
                console.log(error);
            }
        },
      async loadMoreData(event) {
        setTimeout(async () => {
          if (!this.isDataFinished) {
            this.currentPage++;
            await this.buscarDados();
          }
        }, 1000);

        setTimeout(async () => {
          event.target.complete();
        }, 1000);
      },
      async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(31) && !funcionalidades.includes(32)  && !funcionalidades.includes(33)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
      selecionarFiltro(event) {
        if(event){
          this.valueFiltro = event.target.value
              console.log(this.valueFiltro)
        }
              
          },
      criarItem() {
        this.$router.push('/financial/items/create')
      },
      editarItem(index) {
        if(this.funcionalidades.includes(31)){
        this.$router.push(`/financial/items/${index}/edit`)
        }
      },
      async exibirOpcoes(index) {
        if(this.funcionalidades.includes(31) && this.funcionalidades.includes(33)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/financial/items/${index}/edit`);
              }
            },
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteSpecie(index)
              }
            }
          ]
        });
        await alert.present();
        console.log(index)
      }else if(this.funcionalidades.includes(31) && !this.funcionalidades.includes(33)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/financial/items/${index}/edit`);
              }
            }
          ]
        });
        await alert.present();
      }else if(!this.funcionalidades.includes(31) && this.funcionalidades.includes(33)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteSpecie(index)
              }
            }
          ]
        });
        await alert.present();
      }
      },
      async buscarDados() {
        const token = localStorage.getItem('token');
        const empresaId = parseInt(localStorage.getItem('idEmpresa'));
        try {
          const response = await axios.get(`${config.apiUrl}/financial/items?search=${this.termoPesquisa}&page=${this.currentPage}&limit=${this.itemsPerPage}&empresaId=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
           
        const items = response.data.items.map((item) => {
            return {
              descricao: item.descricao,
              id: item.id,
              preco: item.preco
            };
          });
          this.items = [...this.items, ...items];

            if (items.length < this.itemsPerPage) {
              this.isDataFinished = true;
            }
        } catch (error) {
          console.error("Erro ao buscar nomes ", error);
        }
      },
      async deleteSpecie(index) {
        const token = localStorage.getItem('token');
        const alert = await alertController.create({
          header:  this.$t("Confirm_Deletion"),
          message: this.$t("Do_you_really_want_to_delete_the"),
          buttons: [
            {
              text: this.$t("Cancel"),
              role: 'cancel'
            },
            {
              text: this.$t("Yes"),
              role: "destructive",
              handler: async () => {
                await axios.delete(`${config.apiUrl}/financial/items/delete/${index}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                const alert = await alertController.create({
                  header: this.$t("Deleted"),
                message: this.$t("has_been_successfully_deleted")
                });
                await alert.present();
                window.location.reload();
              }
            }
          ]
        });
        await alert.present();
      }
    },
    setup() {
      return { ellipsisVerticalSharp };
    }
  }
  
  </script>
  
  
  <style scoped>

.itensCard{
  margin: 0px;
}

.tresPontinhos{
    font-size: 20px;
  } 
  .createButton{
  margin-bottom:9px;
  }
  .ion-margin {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .noData ion-icon {
    height: 100px;
    width: 100px;
  }
  
  .info {
    padding-left: 7px;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ion-padding-card {
    padding: 0px;
  }
  
  #avatar-item {
    --background: transparent;
  }
  
  #avatar-item img {
    width: 50px;
    height: 50px;
  }

  
  #importButton{
    --background: transparent;
    border: #0049b1 2px solid;
    --color: #0049b1;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }
  .content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  
  .content.ios {
    position: relative;
    transform: translateY(-50%);
  }
  
  ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  }
  
  ion-card-subtitle {
    margin: 0px;
  }</style>