<template>
    <ion-page>
  
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <language-selector></language-selector>
            </ion-buttons>
            <ion-title>{{ $t("Batches") }}</ion-title>
          </ion-toolbar>
        </ion-header>
  
        <ion-content class="ion-padding">
          <div class="content">
            <div class="content-items">
              <!-- <ion-button  v-if="this.funcionalidades.includes(45)" size="large" class="createButton" style="margin: 0px ;" @click="criarLote()">{{ $t("Register") }} {{ $t("Batch") }}</ion-button> -->
              <IonItem class="selectFilter" fill="outline" style="margin: 0px ;">
                <ion-label>{{ $t("Select_Batch_type") }}</ion-label>
                <ion-select position="floating" aria-label="Fruit" class="select" v-model="tipoLote" @ionChange="selecionarFiltro">
                  <ion-content class="ion-padding" id="content-lista">
                    <ion-list>
                      <ion-select-option :value=1>{{ $t("Specie") }}</ion-select-option>
                      <ion-select-option :value=2>{{ $t("Feed") }}</ion-select-option>
                    </ion-list>
                  </ion-content>
                </ion-select>
              </IonItem>
              <ion-item fill="outline" style="margin: 0px ;">
                <ion-label position="floating">{{ $t("Filter") }}</ion-label>
                <ion-input id="nomeInput" v-model="termoPesquisa"></ion-input>
              </ion-item>
              <template v-if="itemsFiltrados.length == 0">
                <div class="noData">
                  <ion-icon :ios="expandOutline" :md="expandSharp"></ion-icon>
                  <p>{{ $t("No_data_Found") }}...</p>
                </div>
              </template>
              <ion-card v-for="(item, index) in itemsFiltrados" :key="index" class="lotesCard" >
                <ion-card-content class="ion-padding-card">
                  <ion-item lines="none" id="avatar-item" class="ion-margin">
                   
                    <div class="info">
                      <ion-label @click="editarBatch(item.id)">
                        <h2>{{ item.nome }}</h2>
                      </ion-label>
                    </div>
  
                    <ion-icon v-if="this.funcionalidades.includes(46) || this.funcionalidades.includes(47)"  class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(item.id)" slot="end"></ion-icon>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </div>
          </div>
          <ion-infinite-scroll @ionInfinite="loadMoreData" style="height: 50px; margin-top: 20px;">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </ion-page>
    </ion-page>
  </template>
  
  <script>
  import { IonSelect, IonSelectOption, IonList, IonInfiniteScroll,IonInfiniteScrollContent, IonInput, IonTitle, IonLabel, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonIcon, alertController } from '@ionic/vue';
  import {
    ellipsisVerticalSharp,expandOutline,expandSharp
  } from "ionicons/icons"
  import axios from 'axios';
  import { config } from "../../config"
  import LanguageSelector from "@/components/LanguageSelector.vue";
  
  export default {
    components: {
      'language-selector' : LanguageSelector , IonSelect, IonSelectOption, IonList, IonInfiniteScroll,IonInfiniteScrollContent, IonInput, IonTitle, IonLabel, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard,  IonIcon
    },
    data() {
      return {
        items: [],
        selectedItem: null,
        termoPesquisa:'',
        expandOutline,
        expandSharp,
        funcionalidades: '',
        currentPage: 1, 
        itemsPerPage: 10, 
        isDataFinished: false, 
        tipoLote: 1,
        };
    },
    mounted() {
      this.getPermissoes();
      this.buscarDados();
    },
    computed: {
    itemsFiltrados() {
      return this.items.filter(item => {
        return item.nome.toLowerCase().includes(this.termoPesquisa.toLowerCase());
      });
    }
  },
    methods: {
      selecionarFiltro() {
        this.currentPage = 1;
        this.items = [];
        this.isDataFinished = false;
        this.buscarDados();
      },
      async loadMoreData(event) {
        setTimeout(async () => {
          if (!this.isDataFinished) {
            this.currentPage++;
            await this.buscarDados();
          }
        }, 1000);

        setTimeout(async () => {
          event.target.complete();
        }, 1000);
      },
      async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          console.log(funcionalidades)
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(45) && !funcionalidades.includes(46)  && !funcionalidades.includes(47)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
      criarLote() {
        this.$router.push('/cultivation/batche/create')
      },
      editarBatch(index) {
        if(this.funcionalidades.includes(46)){
        this.$router.push(`/cultivation/batche/${index}/edit`)
        }
      },
      async exibirOpcoes(index) {
        if(this.funcionalidades.includes(46) && this.funcionalidades.includes(47)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/cultivation/batche/${index}/edit`);
              }
            },
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteBatch(index)
              }
            }
          ]
        });
        await alert.present();
        console.log(index)
      }else if(this.funcionalidades.includes(46) && !this.funcionalidades.includes(47)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/cultivation/batches/${index}/edit`);
              }
            },
          ]
        });
        await alert.present();
      }else if(!this.funcionalidades.includes(46) && this.funcionalidades.includes(47)){
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteBatch(index)
              }
            }
          ]
        });
        await alert.present();
      }
      },
      async buscarDados() {
        const token = localStorage.getItem('token');
        const empresaId = parseInt(localStorage.getItem('idEmpresa'));
        const tipo = this.tipoLote ? this.tipoLote : 1
        try {
          const response = await axios.get(`${config.apiUrl}/configs/batches?search=${this.termoPesquisa}&page=${this.currentPage}&limit=${this.itemsPerPage}&empresaId=${empresaId}&tipo=${tipo}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
          
            const items = response.data.items.map((item) => {
              return {
                nome: item.nome,
                id: item.id,
                empresa: item.empresa,
                tipo: item.tipo
              };
            });
            this.items = [...this.items, ...items];

            if (items.length < this.itemsPerPage) {
              this.isDataFinished = true;
            }
        } catch (error) {
          return console.error("Erro ao buscar nomes ", error);
        }
      },
      async deleteBatch(index) {
        const token = localStorage.getItem('token');
        const alert = await alertController.create({
          header:  this.$t("Confirm_Deletion"),
          message: this.$t("Do_you_really_want_to_delete_the"),
          buttons: [
            {
              text: this.$t("Cancel"),
              role: 'cancel'
            },
            {
              text: this.$t("Yes"),
              role: "destructive",
              handler: async () => {
                await axios.delete(`${config.apiUrl}/configs/batch/delete/${index}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                const alert = await alertController.create({
                  header: this.$t("Deleted"),
                  message: this.$t("has_been_successfully_deleted")
                });
                await alert.present();
                
                window.location.reload();
              }
              
            }
          ]
        });
        await alert.present();
      }
    },
    setup() {
      return { ellipsisVerticalSharp };
    }
  }
  
  </script>
  
  
  <style scoped>
  .createButton{
  margin-bottom:9px;
}
  .ion-margin {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lotesCard{
    margin: 0px;
  }
  .noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .noData ion-icon {
    height: 100px;
    width: 100px;
  }
  
  .tresPontinhos{
    font-size: 20px;
  } 
  .info {
    padding-left: 7px;
  }
  
  .ion-padding-card {
    padding: 0px;
  }
  
  
  #avatar-item {
    --background: transparent;
  }
  
  #avatar-item img {
    width: 50px;
    height: 50px;
  }
  
  .content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  
  .content.ios {
    position: relative;
    transform: translateY(-50%);
  }
  
  ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  }
  
  ion-card-subtitle {
    margin: 0px;
  }
  </style>