<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button
                            :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Parameters") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <ion-item fill="outline" lines="none">
                        <div class="rechargeDiv">
                            <div class="tempsDiv">
                                <ion-item lines="full">
                                    <ion-label position="floating">{{ $t("Temperature") }}</ion-label>
                                    <ion-input type="number" v-model="temperature1"
                                        :placeholder="$t('Applies_to_tanks_only')"></ion-input>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label position="floating">{{ $t("Temperature") }}</ion-label>
                                    <ion-input type="number" v-model="temperature2"
                                        :placeholder="$t('Applies_to_tanks_only')"></ion-input>
                                </ion-item>
                            </div>
                            <ion-radio-group v-model="temperatureUnits">
                                <ion-item lines="full">
                                    <ion-label>ºC</ion-label>
                                    <ion-radio value="ºC"></ion-radio>
                                </ion-item>
                                <ion-item lines="full">
                                    <ion-label>ºF</ion-label>
                                    <ion-radio value="ºF"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </div>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarTemperature">{{ $t("Confirm") }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Ammonia") }}</ion-label>
                        <ion-input v-model="amonia" @input="amonia = formatMgL($event.target.value)"
                            :placeholder="$t('Applies_to_tanks_only')"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarAmmonia">{{ $t("Confirm") }}</ion-button>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Salinity") }}</ion-label>
                        <ion-input v-model="salinidade" @input="salinidade = formatPpt($event.target.value)"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarSalinidade">{{ $t("Confirm") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonRadio, IonRadioGroup, IonButton, IonLabel, alertController, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    components: {
        IonBackButton, IonRadio, IonRadioGroup, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            amonia: '',
            temperature1: '',
            temperature2: '',
            salinidade: '',
            temperatureUnits: []
        }
    },
    mounted() {
        this.getPermissoes();
        this.obterDataAtual()
    },
    methods: {
        async getPermissoes() {

            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        formatMgL(value) {
            return value.replace(/[^\d.,]/g, '') + " mg/L";
        },
        formatPpt(value) {
            return value.replace(/[^\d.,]/g, '') + " ppt";
        },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        cadastrarTemperature() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getParametros`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.tipoTemperatura);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && (this.temperature1 && this.temperature2 && this.temperatureUnits.length !== 0)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.temperatureUnits = []
                                    this.temperature1 = '',
                                        this.temperature2 = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data = {};
                                    if (this.temperature1 && this.temperature2 && this.temperatureUnits.length !== 0) {
                                        data.id_lago = this.$route.params.id
                                        data.tipoTemperatura = this.temperatureUnits;
                                        data.temperatura1 = this.temperature1
                                        data.temperatura2 = this.temperature2
                                        data.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/parametros/create`, data, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.temperatureUnits = []
                                            this.temperature1 = ''
                                            this.temperature2 = ''
                                            return;
                                        })
                                        .catch(error => {

                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data = {};
                        if (this.temperature1 && this.temperature2 && this.temperatureUnits.length !== 0) {
                            data.id_lago = this.$route.params.id
                            data.tipoTemperatura = this.temperatureUnits;
                            data.temperatura1 = this.temperature1
                            data.temperatura2 = this.temperature2
                            data.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/parametros/create`, data, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.temperatureUnits = []
                                this.temperature1 = ''
                                this.temperature2 = ''
                                return;
                            })
                            .catch(error => {

                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        },
        cadastrarAmmonia() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getParametros`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.amonia);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.amonia) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.amonia = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data2 = {};
                                    if (this.amonia) {
                                        data2.id_lago = this.$route.params.id
                                        data2.amonia = this.amonia;
                                        data2.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data2)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/parametros/create`, data2, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.amonia = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data2 = {};
                        if (this.amonia) {
                            data2.id_lago = this.$route.params.id
                            data2.amonia = this.amonia;
                            data2.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data2)
                        axios.post(`${config.apiUrl}/infrastructures/lago/parametros/create`, data2, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.amonia = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });


        },
        cadastrarSalinidade() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getParametros`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.salinidade);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.salinidade) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.salinidade = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    const data2 = {};
                                    if (this.salinidade) {
                                        data2.id_lago = this.$route.params.id
                                        data2.salinidade = this.salinidade;
                                        data2.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data2)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/parametros/create`, data2, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.salinidade = ''
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        const data2 = {};
                        if (this.salinidade) {
                            data2.id_lago = this.$route.params.id
                            data2.salinidade = this.salinidade;
                            data2.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data2)
                        axios.post(`${config.apiUrl}/infrastructures/lago/parametros/create`, data2, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.salinidade = ''
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });


        }
    }
};
</script>
    
<style scoped>
.rechargeDiv {
    display: flex;
    flex-direction: column;
    --padding-start: 0px;
    --inner-padding-end: 0px;
    width: 100%;
}

.tempsDiv {
    display: flex;
    flex-direction: row;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>