<template>
  <ion-page>

    <ion-page id="main-content">

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button default-href="/companies"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ $t("Edit") }} {{ $t("Company") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">

        <div class="content-items">
          <div class="menuAvatar">
            <ion-item lines="none" class="avatar-item">
              <div class="avatar-content">
                <ion-avatar @click="exibirOpcoes">
                  <img id="avatar-img" src="https://ionicframework.com/docs/img/demos/avatar.svg" v-if="foto == null || foto == 'data:image/jpeg;base64,null' || foto == 'data:image/jpeg;base64,Pw=='  || foto == 'data:image/jpeg;base64,'"/>
                  <img id="avatar-img" :src="foto" v-else />
                </ion-avatar>
                <ion-icon :ios="brush" :md="brush" class="editIcon" @click="exibirOpcoes"></ion-icon>
              </div>
            </ion-item>
          </div>
          <ion-item v-if="company" fill="outline" ref="cnpj">
            <ion-label position="floating">CNPJ</ion-label>
            <ion-input id="cnpjInput" type="cnpj" :value="company.cnpj" v-model="cnpj" @ionInput="validate1"
              @ionBlur="markTouched"></ion-input>
          </ion-item>
          <ion-item v-if="company" fill="outline">
            <ion-label position="floating">
              {{ $t("Name") }} <span style="color: red;">*</span>
            </ion-label>
            <ion-input id="nomeEmpresa2Input" :value="company.nome"></ion-input>
          </ion-item>
          <ion-list-header class="campoTitle">{{ $t("Address") }}</ion-list-header>
          <div class="country">
            <ion-item fill="outline" v-if="company">
              <ion-label position="floating">{{ $t("Country") }} <span style="color: red;">*</span></ion-label>
              <ion-input id="paisInput" v-model="company.pais"
              @ionFocus="showCountryList = true"></ion-input>
            </ion-item>
            <ion-list class="listaPaíses" v-if="showCountryList" style="padding-top: 0px; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);">
              <ion-content scrool="y">
                <ion-list lines="full">
                  <ion-item button v-for="country in filteredCountries" :key="country.id" @click="selectCountry(country)">
                    <ion-label slot="start">{{ country }}</ion-label>
                  </ion-item>
                </ion-list>
              </ion-content>
            </ion-list>
          </div>
          <ion-item v-if="company" fill="outline">
            <ion-label position="floating">{{ $t("Zip_code") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="cepInput" type="cep" @input="formatarCep" v-model.trim="company.cep" @blur="removerMascara"
              ></ion-input>
          </ion-item>
          <ion-item v-if="company" fill="outline">
            <ion-label position="floating">{{ $t("Address 1") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="address1Input" :value="company.address1" ></ion-input>
          </ion-item>
          <ion-item v-if="company" fill="outline">
            <ion-label position="floating">{{ $t("Address 2") }}</ion-label>
            <ion-input id="address2Input" :value="company.address2"
              ></ion-input>
          </ion-item>
          <ion-item v-if="company" fill="outline">
            <ion-label position="floating">{{ $t("City") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="cidadeInput" :value="company.cidade" ></ion-input>
          </ion-item>
          <ion-item v-if="company" fill="outline">
            <ion-label position="floating">{{ $t("State") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="estadoInput" :value="company.estado"></ion-input>
          </ion-item>
          <ion-list-header class="campoTitle">{{ $t("Contact_information") }}</ion-list-header>
          <ion-item v-if="company" fill="outline" ref="number1">
            <ion-label position="floating">Whatsapp <span style="color: red;">*</span></ion-label>
            <ion-input id="whatsappInput" @ionInput="validate2" type="cep"
              @input="formatarWhatsapp" v-model.trim="company.whatsapp" @blur="removerMascara"
              @ionBlur="markTouched"></ion-input>
          </ion-item>
          <ion-item v-if="company" fill="outline" ref="number2">
            <ion-label position="floating">{{ $t("Telephone") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="telefoneInput"  @ionInput="validate3" type="cep"
              @input="formatarTelefone" v-model.trim="company.telefone" @blur="removerMascara"
              @ionBlur="markTouched"></ion-input>
          </ion-item>
          <ion-item v-if="company" fill="outline" ref="email">
            <ion-label position="floating">Email <span style="color: red;">*</span></ion-label>
            <ion-input id="emailInput" :value="company.email"
              @ionInput="validate4" @ionBlur="markTouched"></ion-input>
          </ion-item>

          <ion-button size="large" @click="cadastrarEmpresa">{{ $t("Confirm") }}</ion-button>
        </div>

      </ion-content>

    </ion-page>
  </ion-page>
</template>
  
<script>
import { IonBackButton, IonList,  IonIcon, IonAvatar, IonListHeader, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { brush } from 'ionicons/icons';
import { ref } from 'vue';

export default {
  components: {
    IonBackButton, IonList,  IonIcon, IonAvatar, IonListHeader, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
    const company = ref(null);
    const searchTerm = this.pais || '';
    const telefone = this.telefone || '';
    const whatsapp = this.whatsapp || '';
    const cep = this.cep || '';
    return {
      foto: null,
      company,
      brush,
      countriesNames: [],
      searchTerm,
      showCountryList: false,
      cep,
      telefone,
      whatsapp,
      funcionalidades:''
    }
  },
  computed: {
    filteredCountries() {
      return this.countriesNames.filter((country) =>
        country.toLowerCase().includes(this.company.pais.toLowerCase())
      );
    },
  },
  mounted() {
    if (this.company && this.company.pais) {
      this.pais = this.company.pais;
    }

    if (this.company && this.company.telefone) {
      this.telefone = this.company.telefone;
    }

    if (this.company && this.company.whatsapp) {
      this.whatsapp = this.company.whatsapp;
    }

    if (this.company && this.company.cep) {
      this.cep = this.company.cep;
    }

    const companyId = this.$route.params.id
    const token = localStorage.getItem('token');
    axios.get(`${config.apiUrl}/companies/${companyId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
      .then(response => {
        this.foto = `data:image/jpeg;base64,${response.data.foto_perfil}`;
      })
      .catch(error => {
        console.log(error);
      });

    this.getCountries()
    this.fetchUser()
    this.fetchCompany();
  },
  methods: {
    async fetchUser() {
      const token = localStorage.getItem('token');
          const userId = localStorage.getItem('id');
          try {
              const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              
              this.master = response.data.master
              if(!this.master){
                const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
                });
                await alert.present();
                const userId = localStorage.getItem('id');
                window.location.replace(`/users/${userId}/my-account`);
              }
          } catch (error) {
              console.log(error);
          }
          },
    async fetchCompany() {
      const token = localStorage.getItem('token');
      const companyId = this.$route.params.id
      try {
        const response = await axios.get(`${config.apiUrl}/companies/${companyId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.company = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    removerMascara(event) {
      if (event && event.target) {
        const input = event.target.value;
        const digitsOnly = input.replace(/\D/g, '');
        const masked = `${digitsOnly.substring(0, 5)}-${digitsOnly.substring(5, 7)}`;
        this.user.cep = masked;
      }
    },

    formatarCep(event) {
      const isDeleting = event.data == null;
      if (!isDeleting) {
        const cep = event.target.value.replace(/\D/g, '');
        const cepLength = cep.length;

        if (cepLength <= 5) {
          this.company.cep = `${cep}`;
        } else if (cepLength <= 8) {
          this.company.cep = `${cep.substring(0, 5)}-${cep.substring(5)}`;
        }
      }
      this.removerMascara()
    },

    formatarTelefone(event) {
      const isDeleting = event.data == null;
      if (!isDeleting) {
        const tel = event.target.value.replace(/\D/g, '');
        const telLength = tel.length;

        if (telLength <= 2) {
          this.company.telefone = `(${tel})`;
        } else if (telLength <= 5) {
          this.company.telefone = `(${tel.substring(0, 2)}) ${tel.substring(2)}`;
        } else if (telLength <= 9) {
          this.company.telefone = `(${tel.substring(0, 2)}) ${tel.substring(2, 5)}-${tel.substring(5)}`;
        } else {
          this.company.telefone = `(${tel.substring(0, 2)}) ${tel.substring(2, 6)}-${tel.substring(6, 10)}`;
        }
      }
      this.removerMascara()
    },

    formatarWhatsapp(event) {
      const isDeleting = event.data == null;
      if (!isDeleting && this.company.whatsapp) {
        const whats = event.target.value.replace(/\D/g, '');
        const whatsLength = whats.length;

        if (whatsLength <= 2) {
          this.company.whatsapp = `(${whats})`;
        } else if (whatsLength <= 6) {
          this.company.whatsapp = `(${whats.substring(0, 2)}) ${whats.substring(2)}`;
        } else if (whatsLength <= 10) {
          this.company.whatsapp = `(${whats.substring(0, 2)}) ${whats.substring(2, 6)}-${whats.substring(6)}`;
        } else {
          this.company.whatsapp = `(${whats.substring(0, 2)}) ${whats.substring(2, 7)}-${whats.substring(7, 11)}`;
        }
      }

      this.removerMascara()
    },
    getCountries() {
      axios.get('https://restcountries.com/v3.1/all')
        .then(response => {
          this.countries = response.data;
          this.countriesNames = this.countries.map(country => country.name.common)
          console.log(this.countries);
        })
        .catch(error => {
          console.log(error);
        });
    },
    selectCountry(country) {
      this.company.pais = country;
      this.showCountryList = false;
    },
    validateCNPJ(cnpj) {
      return cnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/);
    },

    validateNumber1(number1) {
      return number1.replace(/\D/g, '').match(/^(\(\d{2}\)\s\d{5}-\d{4}|\(\d{2}\)\d{9}|\d{11}|\d{2}\s\d{9}|\d{2}\s\d{5}-\d{4})$/);
    },

    validateNumber2(number2) {
      return number2.replace(/\D/g, '').match(/^(\(\d{2}\)\s\d{4}-\d{4}|\(\d{2}\)\d{8}|\d{10}|\d{2}\s\d{8}|\d{2}\s\d{4}-\d{4})$/);
    },

    validateEmail(email) {
      const validTLDs = ["com", "net", "org", "io"];
      return email.match(`^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\\.(${validTLDs.join("|")})$`);
    },

    validate1(ev) {
      const value1 = ev.target.value;

      this.$refs.cnpj.$el.classList.remove('ion-valid');
      this.$refs.cnpj.$el.classList.remove('ion-invalid');

      if (value1 == '') return;

      if ((this.validateCNPJ(value1)) != "") {
        this.validateCNPJ(value1)
          ? this.$refs.cnpj.$el.classList.add('ion-valid')
          : this.$refs.cnpj.$el.classList.add('ion-invalid');
      }
    },
    validate2(ev) {
      const value2 = ev.target.value;

      this.$refs.number1.$el.classList.remove('ion-valid');
      this.$refs.number1.$el.classList.remove('ion-invalid');

      if (value2 == '') return;

      if ((this.validateNumber1(value2)) != "") {
        this.validateNumber1(value2)
          ? this.$refs.number1.$el.classList.add('ion-valid')
          : this.$refs.number1.$el.classList.add('ion-invalid');
      }

    },
    validate3(ev) {
      const value3 = ev.target.value;


      this.$refs.number2.$el.classList.remove('ion-valid');

      this.$refs.number2.$el.classList.remove('ion-invalid');

      if (value3 == '') return;

      if ((this.validateNumber2(value3)) != "") {
        this.validateNumber2(value3)
          ? this.$refs.number2.$el.classList.add('ion-valid')
          : this.$refs.number2.$el.classList.add('ion-invalid');
      }
    },
    validate4(ev) {
      const value4 = ev.target.value;

      this.$refs.email.$el.classList.remove('ion-valid');
      this.$refs.email.$el.classList.remove('ion-invalid');

      if (value4 == '') return;

      if ((this.validateEmail(value4)) != "") {
        this.validateEmail(value4)
          ? this.$refs.email.$el.classList.add('ion-valid')
          : this.$refs.email.$el.classList.add('ion-invalid');
      }
    },

    markTouched() {
      this.$refs.cnpj.$el.classList.add('ion-touched')
      this.$refs.number1.$el.classList.add('ion-touched')
      this.$refs.number2.$el.classList.add('ion-touched')
      this.$refs.email.$el.classList.add('ion-touched')
    },

    async exibirOpcoes() {
      const alert = await alertController.create({
        header: this.$t("Options"),
        buttons: [
          {
            text: this.$t("Change_image"),
            handler: async () => {
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = 'image/*';
              input.click();

              input.addEventListener('change', () => {
                const file = input.files[0];
                const reader = new FileReader();

                if (reader)
                  reader.addEventListener('load', () => {
                    localStorage.setItem('fotoEmp', reader.result);
                    const img = document.getElementById('avatar-img');
                    img.src = reader.result;
                  });
                reader.readAsDataURL(file);

              });
            }

          },
          {
            text: this.$t("Cancel"),
            role: 'destructive',
          }
        ]
      });
      await alert.present();
    },
    async buscarEmpresas() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${ config.apiUrl }/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data.items);
        const empresas = response.data.items.map((item) => {
          return {
            nome: item.nome,
            cnpj: item.cnpj,
            whatsapp: item.whatsapp,
            foto: `data:image/jpeg;base64,${item.foto}`
          }
        })
        return empresas;
      } catch (error) {
        console.error("Erro ao buscar nomes ", error);
      }
    },
    async cadastrarEmpresa() {
      const nomeEmpresa = document.getElementById("nomeEmpresa2Input").value;
      const nome2 = this.company.nome
      console.log(nome2)
      if(nomeEmpresa){
        const empresas = await this.buscarEmpresas();
        const empresaExistente = empresas.some((empresa) => {
        return empresa.nome == nomeEmpresa;
        });
        if (empresaExistente && nomeEmpresa != nome2) {
          const alert = await alertController.create({
            header: this.$t("Duplicate_company_name"),
            buttons: ['OK']
          });
          await alert.present();
          document.getElementById("nomeEmpresa2Input").value = ''
          return
        } 
      }
      const cnpj = document.getElementById("cnpjInput").value;
      const cep = document.getElementById("cepInput").value;
      const address1 = document.getElementById("address1Input").value;
      const pais = document.getElementById("paisInput").value;
      const address2 = document.getElementById("address2Input").value;
      const cidade = document.getElementById("cidadeInput").value;
      const estado = document.getElementById("estadoInput").value;
      const whatsapp = document.getElementById("whatsappInput").value;
      const telefone = document.getElementById("telefoneInput").value;
      const email = document.getElementById("emailInput").value;
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/companies/${companyId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.company = response.data;
      } catch (error) {
        console.log(error);
      }

      const pais2 = this.company.pais

      if ((cnpj !== "") && (!this.validateCNPJ(cnpj))) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+" CNPJ ",
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (nomeEmpresa == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (pais2 == "" && pais == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Country"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (cep == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Zip_code"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (address1 == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Address 1"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      // if (address22 == "" && address2 == "") {
      //   const alert = await alertController.create({
      //     header: this.$t("Error"),
      //       message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Address 2") ,
      //     buttons: ['OK']
      //   });
      //   await alert.present();
      //   return;
      // }

      if (cidade == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("City"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (estado == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("State") ,
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (whatsapp == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+"whatsapp",
          buttons: ['OK']
        });
        await alert.present();
        return;
      } else if (!this.validateNumber1(whatsapp)) {
        const alert = await alertController.create({
          header: this.$t("Fill_in_the_Whatsapp_field_correctly"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (telefone == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Telephone") ,
          buttons: ['OK']
        });
        await alert.present();
        return;
      } else if (!this.validateNumber2(telefone)) {
        const alert = await alertController.create({
          header: this.$t("Fill_in_the_Telephone_field_correctly"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (email == "") {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+" email ",
          buttons: ['OK']
        });
        await alert.present();
        return;
      } else if (!this.validateEmail(email)) {
        const alert = await alertController.create({
          header: this.$t("Fill_in_the_Telephone_field_correctly"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      const data = {
        nome: nomeEmpresa !== '' ? nomeEmpresa : this.company.nome,
        cnpj: cnpj,
        cep: cep !== '' ? cep : this.company.cep,
        address1: address1 !== '' ? address1 : this.company.address1,
        address2: address2 !== '' ? address2 : this.company.address2,
        cidade: cidade !== '' ? cidade : this.company.cidade,
        estado: estado !== '' ? estado : this.company.estado,
        whatsapp: whatsapp !== '' ? whatsapp  : this.company.whatsapp ,
        telefone: telefone !== '' ? telefone : this.company.telefone,
        email: email !== '' ? email : this.company.email,
        pais: pais !== '' ? pais : this.company.pais
      }
      const companyId = this.$route.params.id
      const Id = localStorage.getItem('idEmpresa');
      await axios.put(`${config.apiUrl}/companies/${companyId}`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          console.log(response.data);

          const foto = localStorage.getItem('fotoEmp');

          if (foto !== null) {
            const byteCharacters = atob(foto.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });
            const formData = new FormData();
            formData.append('foto', blob, 'foto.png');
            fetch(`${config.apiUrl}/company/${Id}/foto`, {
              method: 'PUT',
              body: formData,
              headers: {
                'Authorization': `Bearer ${token}`
              },
            });
          }
          const alert = alertController.create({
            header: this.$t("successfully_updated!")
          });
          alert.then(_alert => {
            _alert.present();

          });
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_updating"),
            buttons: ['OK']
          
          });
          alert.then(_alert => {
            _alert.present();
          });
        });
        window.location.replace('/companies')
    }

  }
}
</script>
  
<style scoped>
.campoTitle {
  font-size: 18px;
}

.avatar-content {
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatar-item {
  --min-height: 100px;
  --background: transparent;
  width: 200px;
}

.avatar-item img {
  width: 100px;
  height: 100px;
  border: 3px solid white;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.799);
}

ion-avatar {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: fit-content;
}

.menuAvatar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.editIcon {
  position: absolute;
  background-color: #417cf3;
  border-radius: 100px;
  padding: 4px;
  border: 3px solid white;
  height: 16px;
  width: 16px;
  top: 67%;
  left: 67%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.799);
}

.country{
  height: max-content;
  max-width: 450px;
}

.listaPaíses{
  height: 250px;
}
</style>