<template>
    <ion-page>
  
      <ion-page id="main-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-title>Edit Disease</ion-title>
          </ion-toolbar>
        </ion-header>
  
        <ion-content class="ion-padding" :scroll-y="false">
          <div class="content-items">
            <ion-item fill="outline" v-if="group">
              <ion-label position="floating">Name</ion-label>
              <ion-input id="nomeInput" :value="group.nome"></ion-input>
            </ion-item>
            <ion-button size="large" expand="block" @click="updateGrupoDeUsuario">Update Disease</ion-button>
          </div>
        </ion-content>
      </ion-page>
    </ion-page>
  </template>
    
  <script>
  import { alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
  import axios from 'axios';
  import { config } from "../../config"
  import { ref } from 'vue';
  
  export default {
    components: {
      IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
      const group = ref(null);
      return {
        group,
        checkboxValues: [],
        valoresApi: '',
        funcionalidades:''
      }
    },
    mounted() {
      this.fetchFuncionalidades()
      this.fetchGroup()
    },
    methods: {
      async fetchFuncionalidades() {
        const groupId = this.$route.params.id
  
        const response = await axios.get(`${config.apiUrl}/users/groups/${groupId}`);
  
        this.funcionalidades = response.data.funcionalidades
  
        const checkboxes = document.querySelectorAll('ion-checkbox');
        this.valoresApi = this.funcionalidades
        checkboxes.forEach((checkbox) => {
          if (this.valoresApi.includes(checkbox.value)) {
            checkbox.checked = true;
            this.checkboxValues.push(checkbox.value);
          }
        });
  
  
      },
      updateCheckboxValues(event) {
        const checked = event.target.checked;
        const value = event.target.value;
        if (checked && !this.checkboxValues.includes(value)) {
          this.checkboxValues.push(value);
        } else if (!checked && this.checkboxValues.includes(value)) {
          this.checkboxValues = this.checkboxValues.filter((val) => val !== value);
        }
      },
      todasSelecionadas1() {
        const checkboxes = document.querySelectorAll('#funcionalidadeInput1 ion-checkbox');
        let todosSelecionados = true;
  
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            todosSelecionados = false;
          }
        });
  
        const checkboxTodos = document.querySelector('#funcionalidadeInput11');
  
        checkboxTodos.checked = todosSelecionados;
      },
      todasSelecionadas2() {
        const checkboxes = document.querySelectorAll('#funcionalidadeInput2 ion-checkbox');
        let todosSelecionados = true;
  
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            todosSelecionados = false;
          }
        });
  
        const checkboxTodos = document.querySelector('#funcionalidadeInput22');
  
        checkboxTodos.checked = todosSelecionados;
      },
      todasSelecionadas3() {
        const checkboxes = document.querySelectorAll('#funcionalidadeInput3 ion-checkbox');
        let todosSelecionados = true;
  
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            todosSelecionados = false;
          }
        });
  
        const checkboxTodos = document.querySelector('#funcionalidadeInput33');
  
        checkboxTodos.checked = todosSelecionados;
      },
      todasSelecionadas4() {
        const checkboxes = document.querySelectorAll('#funcionalidadeInput4 ion-checkbox');
        let todosSelecionados = true;
  
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            todosSelecionados = false;
          }
        });
  
        const checkboxTodos = document.querySelector('#funcionalidadeInput44');
  
        checkboxTodos.checked = todosSelecionados;
      },
      selecionarTodas1(event) {
        const checkboxes = document.querySelectorAll('.companies ion-checkbox');
        const selecionado = event.target.checked;
        checkboxes.forEach((checkbox) => {
          checkbox.checked = !selecionado;
        });
      },
      selecionarTodas2(event) {
        const checkboxes = document.querySelectorAll('.groups ion-checkbox');
        const selecionado = event.target.checked;
        checkboxes.forEach((checkbox) => {
          checkbox.checked = !selecionado;
        });
      },
      selecionarTodas3(event) {
        const checkboxes = document.querySelectorAll('.users ion-checkbox');
        const selecionado = event.target.checked;
        checkboxes.forEach((checkbox) => {
          checkbox.checked = !selecionado;
        });
      },
      selecionarTodas4(event) {
        const checkboxes = document.querySelectorAll('.species ion-checkbox');
        const selecionado = event.target.checked;
        checkboxes.forEach((checkbox) => {
          checkbox.checked = !selecionado;
        });
      },
      async fetchGroup() {
        const groupId = this.$route.params.id
        try {
          const response = await axios.get(`${config.apiUrl}/users/groups/${groupId}`);
          this.group = response.data;
          this.funcionalidades = response.data.funcionalidades
        } catch (error) {
          console.log(error);
        }
      },
      async updateGrupoDeUsuario() {
  
        const nome = document.getElementById("nomeInput").value;
        const checkboxes = document.querySelectorAll('ion-checkbox');
        const funcionalidades = [];
  
        checkboxes.forEach(checkbox => {
          if (checkbox.checked) {
            funcionalidades.push(checkbox.value);
          }
        });
        const groupId = this.$route.params.id
        try {
          const response = await axios.get(`${config.apiUrl}/users/groups/${groupId}`);
          this.group = response.data;
        } catch (error) {
          console.log(error);
        }
        if (funcionalidades.length == 0) {
          const alert = await alertController.create({
            header: 'Invalid functionality',
            message: 'Please choose a functionality',
            buttons: ['OK']
          });
          await alert.present();
          return;
        }
  
        if (nome == "") {
          const alert = await alertController.create({
            header: 'Invalid name',
            message: 'Please enter a name',
            buttons: ['OK']
          });
          await alert.present();
          return;
        }
  
        const data = {
          nome: nome !== '' ? nome : this.user.nome,
          funcionalidades: funcionalidades
        }
  
        axios.put(`${config.apiUrl}/users/groups/${groupId}`, data)
          .then(response => {
            console.log(response.data);
            const alert = alertController.create({
              header: 'Data updated successfully!'
            });
            alert.then(_alert => {
              _alert.present();
            });
  
            window.location.replace("/users/users-groups")
          })
          .catch(error => {
            console.log(error);
            const alert = alertController.create({
              header: 'Error updating data',
              buttons: ['OK']
            });
            alert.then(_alert => {
              _alert.present();
            });
          });
  
        const alert = await alertController.create({
          header: 'Data updated successfully!'
        });
        await alert.present();
      }
    },
  
  };
  </script>
    
  <style scoped>
  ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  }
  
  ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
  }
  
  .content-items {
    max-width: 450px;
    max-height: 550px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  
  </style>