<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector ref="languageSelector"></language-selector>
          </ion-buttons>

          <ion-title>{{ $t("infrastructures") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content">
          <div class="content-items">
            <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">

              <IonItem class="selectFilter" fill="outline" style="margin: 0px ; width: calc(100% - 62px);">

                <ion-label position="floating">{{ $t("Production_centers") }}</ion-label>
                <ion-select label-placement="floating" v-model="selectedCentro" @ionChange="selecionarCentro($event)">
                  <ion-select-option v-for="centro in centros" :key="centro.id" :value="centro.id">{{
                    centro.nomeLegal }}</ion-select-option>
                </ion-select>
              </IonItem>
              <ion-button v-if="this.funcionalidades.includes(11)" style="width: 57px;height: 57px;"
                @click="criarInfrastructure4()"><ion-icon
                  style="right: 0;font-weight: bolder; color: white; width: 28px; height: 28px;" :ios="addOutline"
                  :md="addSharp"></ion-icon></ion-button>
            </div>
            <!-- 
            <ion-button size="large" class="createButton" @click="criarInfrastructure3()">Register Autentication</ion-button> -->

            <ion-card v-for="zona in zonas" :key="zona.id" class="IFPcard">
              <ion-card-content class="ion-padding-card" style="padding-bottom: 0px;">
                <ion-item fill="none" style="display: flex;align-items: center;" class="iconeEnomeZona" lines="full">
                  <ion-label class="nomeGrupo"
                    style="left: 0;display: flex; color: #0049b1;align-items: center;font-weight: bolder;"><ion-icon
                      style="margin-right: 5px;color: #0049b1; padding-bottom: 3px; width: 22px; height: 22px;"
                      :ios="cubeOutline" :md="cubeSharp"></ion-icon> {{ zona.nome }}</ion-label>
                  <ion-icon v-if="this.funcionalidades.includes(38) || this.funcionalidades.includes(39)"
                    style="width: 22.02px; height: 24px;" class="tresPontinhos" :src="ellipsisVerticalSharp"
                    @click="exibirOpcoes(zona.id)" slot="end"></ion-icon>
                </ion-item>
                <!-- <br>
                <IonItem fill="outline">
                  <ion-label position="floating">Select Autentication</ion-label>
                  <ion-select @ionChange="selecionarAutenticacao($event)">
                    <ion-select-option value="312412">312412</ion-select-option>
                    <ion-select-option value="523424">523424</ion-select-option>
                    <ion-select-option value="96786">96786</ion-select-option>
                  </ion-select>
                </IonItem> -->
                <br>
                <div class="secoes" v-if="secoesPorZona[zona.id] && secoesPorZona[zona.id].length">
                  <div v-for="secao in secoesPorZona[zona.id]" :key="secao.id">
                    <ion-label style="display: flex; justify-content: space-between;align-items: center;">
                      <h2 style="left: 0;display: flex;align-items: center;font-weight: bolder;"><ion-icon
                          style="margin-right: 5px;width: 22px; height: 22px; padding-bottom: 2px;" :ios="layersOutline"
                          :md="layersSharp"></ion-icon> {{ secao.texto }}</h2>
                      <ion-icon v-if="this.funcionalidades.includes(37)"
                        style="right: 0;font-weight: bolder; color: #0049b1; width: 22px; height: 22px;" :ios="addOutline"
                        :md="addSharp" @click="criarInfrastructure2(zona.id, secao.id)"></ion-icon>
                    </ion-label>
                    <div v-if="lagosPorSecao[secao.id] && lagosPorSecao[secao.id].length">
                      <div class="lagoOpcoes" v-for="lago in lagosPorSecao[secao.id]" :key="lago.id">
                        <ion-label v-if="this.funcionalidades.includes(37)"
                          style="padding-left: 30px;display: flex;align-items: center;font-weight: bolder;" size="large"
                          :class="getButtonClass(lago.id)">
                          <ion-icon style="margin-right: 5px; padding-bottom: 2px;width: 22px; height: 22px;"
                            :ios="stopOutline" :md="stopSharp"></ion-icon> {{ lago.nome }}
                        </ion-label>
                        <ion-icon v-if="this.funcionalidades.includes(38) || this.funcionalidades.includes(39)"
                          :src="ellipsisVerticalSharp" class="tresP" style="width: 24px !important; height: 24px;"
                          @click="exibirOpcoes2(lago.id)" slot="end"></ion-icon>
                      </div>
                    </div>
                    <div v-else>
                      <ion-item fill="none" lines="full">
                        <ion-label class="nomeGrupo lagoNaoEncontrado">{{ $t("No_data_Found") }}</ion-label>
                      </ion-item>
                    </div>
                    <div style="height: 20px;"></div>
                  </div>
                </div>
                <div v-else>
                  <ion-item class="secoes" fill="none" lines="full">
                    <ion-label class="nomeGrupo">{{ $t("No_data_Found") }}</ion-label>
                  </ion-item>
                </div>

              </ion-card-content>
            </ion-card>
            <ion-button v-if="this.funcionalidades.includes(37)" size="large" id="Rzona" class="createButton"
              style="margin: 0px ;" @click="criarInfrastructure()">{{ $t("Register") }} {{ $t("Zone") }}</ion-button>
            <template v-if="zonas.length == 0">
              <div class="noData">
                <ion-icon :ios="cogOutline" :md="cogSharp"></ion-icon>
                <p>{{ $t("No_data_Found") }}...</p>
              </div>
            </template>
            <div class="infraGeral" v-if="zonas.length !== 0">
              <ion-list-header class="geral">{{ $t("General_Infrastructure") }}</ion-list-header>
              <!-- <ion-card class="IFPcard" v-if="this.funcionalidades.includes(37)">
                <ion-card-content class="ion-padding-card">
                  <div>
                    <ion-content class="lagosGeral">
                      <ion-list class="listaGeral" v-for="lago in lagos" :key="lago.id">
                        <ion-button  size="large" :class="getButtonClasses(lago.id)" @click="irParaLago(lago.id)">
                          {{ lago.nome }}
                        </ion-button>
                      </ion-list>
                      <template v-if="lagos.length == 0">
                        <div class="noData">
                          <p>{{ $t("No_data_Found") }}...</p>
                        </div>
                      </template>
                  </ion-content>
                  </div>
                </ion-card-content>
              </ion-card> -->
              <ion-card class="IFPcard" :disabled="disableButton">
                <ion-card-content class="ion-padding-card">
                  <ion-content class="dieselGeral" :scroll-y="false">
                    <div class="dieselDiv">
                      <ion-icon :ios="serverOutline" :md="serverSharp" class="iconOxig" @click="setOpen(true)"></ion-icon>
                      <h2 class="listOxigenio">{{ $t("Oxygen") }}</h2>
                      <ion-modal :is-open="isOpen" @ionModalDidPresent="onModalDidPresent"
                        @ionModalDidDismiss="onModalDidDismiss">
                        <ion-header>
                          <ion-toolbar>
                            <ion-title>{{ $t("Oxygen") }}</ion-title>
                            <ion-buttons slot="end">
                              <ion-button @click="setOpen(false)">{{ $t("Close") }}</ion-button>
                            </ion-buttons>
                          </ion-toolbar>
                        </ion-header>
                        <ion-content class="ion-padding modal-content">
                          <div class="selectLago">
                            <div class="datas">
                              <div class="fromTo">
                                <ion-item fill="outline" class="lagoList " lines="none">
                                  <div class="input-wrapper">
                                    <ion-label>{{ $t("Show_Center_data") }}</ion-label>
                                    <ion-toggle @ionChange="showCenterData()" v-model="data">Checked Toggle</ion-toggle>
                                  </div>
                                </ion-item>
                              </div>
                              <ion-button class="refresh addButtonCZSL" style="margin-bottom: 20px;"
                                @click="cadastrarParaCentro('oxygen')">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>
                            <div class="datas">
                              <div class="fromTo">
                                <IonItem fill="outline" class="lagoList " >
                                  <ion-label position="floating">{{ $t("Zones") }} </ion-label>
                                  <ion-select position="floating" aria-label="Fruit" class="select"
                                    :disabled="disableButton" @ionChange="selecionarZona1($event)">
                                    <ion-content class="ion-padding" id="content-lista">
                                      <ion-list v-for="(zona, index) in zonas" :key="index">
                                        <ion-select-option :value="zona.id">{{ zona.nome }}</ion-select-option>
                                      </ion-list>
                                    </ion-content>
                                  </ion-select>
                                </IonItem>
                              </div>
                              <ion-button class="refresh addButtonCZSL" v-if="secoesDaZona1" style="margin-bottom: 20px;"
                                @click="cadastrarParaZona('oxygen')">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>
                            <div class="datas">
                              <div class="fromTo">
                                <IonItem fill="outline" v-if="secoesDaZona1" class="lagoList " >
                                  <ion-label position="floating">{{ $t("Sections") }}</ion-label>
                                  <ion-select position="floating" aria-label="Fruit" class="select"
                                    :disabled="disableButton" @ionChange="selecionarSecao1($event)">
                                    <ion-content class="ion-padding" id="content-lista">
                                      <ion-list v-for="(secao, index) in secoesDaZona1" :key="index">
                                        <ion-select-option :value="secao.id">{{ secao.texto }}</ion-select-option>
                                      </ion-list>
                                    </ion-content>
                                  </ion-select>
                                </IonItem>
                              </div>
                              <ion-button class="refresh addButtonCZSL" style="margin-bottom: 20px;"
                                @click="cadastrarParaSecao('oxygen')" v-if="lagosDaSecao1">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>
                            <div class="datas">
                              <div class="fromTo">
                                <IonItem fill="outline" class="lagoList" v-if="lagosDaSecao1" >
                                  <ion-label position="floating">{{ $t("Pond") }}</ion-label>
                                  <ion-select position="floating" aria-label="Fruit" class="select"
                                    :disabled="disableButton" @ionChange="selecionarLago($event)">
                                    <ion-content class="ion-padding" id="content-lista">
                                      <ion-list v-for="lago in lagosDaSecao1" :key="lago.id">
                                        <ion-select-option :value=lago.id>{{ lago.nome }}</ion-select-option>
                                      </ion-list>
                                    </ion-content>
                                  </ion-select>
                                </IonItem>
                              </div>
                              <ion-button class="refresh addButtonCZSL" style="margin-bottom: 20px;" v-if="idLagoSelecionado" @click="cadastrarParaLago('oxygen')">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>

                            <br>
                            <div class="graficoModal">
                              <div class="datas">
                                <div class="fromTo">
                                  <ion-item fill="outline" class="from">
                                    <ion-label position="floating">{{ $t("From") }}:</ion-label>
                                    <ion-input class="inputData" v-model="dataInicio" type="date"></ion-input>
                                  </ion-item>

                                  <ion-item fill="outline" class="to">
                                    <ion-label position="floating">{{ $t("To") }}:</ion-label>
                                    <ion-input class="inputData" v-model="dataFim" type="date"></ion-input>
                                  </ion-item>
                                </div>

                                <ion-button class="refresh" @click="refresh2()" style="margin-bottom: 20px;"
                                  :disabled="disableButton" @ionChange="desabilitarBotoes">
                                  <ion-icon :ios="checkmarkOutline" :md="checkmarkSharp" class="iconRefresh"></ion-icon>
                                </ion-button>


                              </div>
                              <ion-label v-if="total1 && isWhat == 'centro'"> <span style="font-weight: bolder;"> {{
                                $t("Current_Center_oxygen_amount") }}:</span> {{ total1 }} m³</ion-label>
                              <ion-label v-if="total1 && isWhat == 'zona'"> <span style="font-weight: bolder;"> {{
                                $t("Current_Zone_oxygen_amount") }}:</span> {{ total1 }} m³</ion-label>
                              <ion-label v-if="total1 && isWhat == 'secao'"> <span style="font-weight: bolder;"> {{
                                $t("Current_Section_oxygen_amount") }}:</span> {{ total1 }} m³</ion-label>
                              <ion-label v-if="total1 && isWhat == null"> <span style="font-weight: bolder;"> {{
                                $t("Current_Pond_oxygen_amount") }}:</span> {{ total1 }} m³</ion-label>
                              <ion-label v-if="!total1"> <span style="font-weight: bolder;">{{ $t("No_data_Found")
                              }}</span> </ion-label>
                              <h2 class="titleGraph">{{ $t("Recharge_Graph") }}</h2>
                              <div class="canvas">
                                <canvas v-if="isChartAvailable" id="myChart2"></canvas>
                              </div>
                              <br>
                              <h2 class="titleGraph">{{ $t("Consumption_Graph") }}</h2>
                              <div class="canvas">
                                <canvas v-if="isChartAvailable" id="myChart3" style="margin-bottom: 100px;"></canvas>
                              </div>
                            </div>
                          </div>



                        </ion-content>
                      </ion-modal>
                    </div>
                  </ion-content>
                </ion-card-content>
              </ion-card>
              <!-- <ion-card class="IFPcard">
                <ion-card-content class="ion-padding-card">
                  <ion-content class="dieselGeral" :scroll-y="false">
                    <div class="dieselDiv">
                      <ion-icon :ios="storefrontOutline" :md="storefrontSharp" class="iconArmaz"></ion-icon>
                      <h2 class="listOxigenio">{{ $t("Warehouse") }}</h2>
                      
                    </div>
                  </ion-content>
                </ion-card-content>
              </ion-card> -->
              <ion-card class="IFPcard" :disabled="disableButton">
                <ion-card-content class="ion-padding-card">
                  <ion-content class="dieselGeral" :scroll-y="false">
                    <div class="dieselDiv">
                      <ion-icon :ios="flashOutline" :md="flashSharp" class="iconElet" @click="setOpen3(true)"></ion-icon>
                      <h2 class="listOxigenio">{{ $t("Genset") }}</h2>
                      <ion-modal :is-open="isOpen3" @ionModalDidPresent="onModalDidPresent3"
                        @ionModalDidDismiss="onModalDidDismiss3">
                        <ion-header>
                          <ion-toolbar>
                            <ion-title>{{ $t("Genset") }}</ion-title>
                            <ion-buttons slot="end">
                              <ion-button @click="setOpen3(false)">{{ $t("Close") }}</ion-button>
                            </ion-buttons>
                          </ion-toolbar>
                        </ion-header>
                        <ion-content class="ion-padding modal-content">
                          <div class="selectLago">
                            <ion-item lines="none" fill="outline">
                              <ion-label>{{ $t("Show_Center_data") }}</ion-label>
                              <ion-toggle @ionChange="showCenterData2()" v-model="data">Checked Toggle</ion-toggle>
                            </ion-item>
                            <IonItem fill="outline" style="margin-top: 20px;">
                              <ion-label position="floating">{{ $t("Zones") }} </ion-label>
                              <ion-select position="floating" aria-label="Fruit" class="select" :disabled="disableButton"
                                @ionChange="selecionarZona2($event)">
                                <ion-content class="ion-padding" id="content-lista">
                                  <ion-list v-for="(zona, index) in zonas" :key="index">
                                    <ion-select-option :value="zona.id">{{ zona.nome }}</ion-select-option>
                                  </ion-list>
                                </ion-content>
                              </ion-select>
                            </IonItem>
                            <IonItem fill="outline" v-if="secoesDaZona2" style="margin-top: 20px;">
                              <ion-label position="floating">{{ $t("Sections") }}</ion-label>
                              <ion-select position="floating" aria-label="Fruit" class="select" :disabled="disableButton"
                                @ionChange="selecionarSecao2($event)">
                                <ion-content class="ion-padding" id="content-lista">
                                  <ion-list v-for="(secao, index) in secoesDaZona2" :key="index">
                                    <ion-select-option :value="secao.id">{{ secao.texto }}</ion-select-option>
                                  </ion-list>
                                </ion-content>
                              </ion-select>
                            </IonItem>
                            <IonItem fill="outline" class="lagoList" v-if="lagosDaSecao2" style="margin-top: 20px;">
                              <ion-label position="floating">{{ $t("Pond") }}</ion-label>
                              <ion-select position="floating" aria-label="Fruit" class="select" :disabled="disableButton"
                                @ionChange="selecionarLago3($event)">
                                <ion-content class="ion-padding" id="content-lista">
                                  <ion-list v-for="lago in lagosDaSecao2" :key="lago.id">
                                    <ion-select-option :value=lago.id>{{ lago.nome }}</ion-select-option>
                                  </ion-list>
                                </ion-content>
                              </ion-select>
                            </IonItem>


                            <br>
                            <div class="graficoModal">
                              <div class="datas">
                                <div class="fromTo">
                                  <ion-item fill="outline" class="from">
                                    <ion-label position="floating">{{ $t("From") }}:</ion-label>
                                    <ion-input class="inputData" v-model="dataInicio" type="date"></ion-input>
                                  </ion-item>

                                  <ion-item fill="outline" class="to">
                                    <ion-label position="floating">{{ $t("To") }}:</ion-label>
                                    <ion-input class="inputData" v-model="dataFim" type="date"></ion-input>
                                  </ion-item>
                                </div>

                                <ion-button class="refresh" @click="refresh222()" :disabled="disableButton"
                                  style="margin-bottom: 20px;" @ionChange="desabilitarBotoes">
                                  <ion-icon :ios="checkmarkOutline" :md="checkmarkSharp" class="iconRefresh"></ion-icon>
                                </ion-button>
                              </div>
                              <ion-label v-if="total2 && isWhat == 'centro'"> <span style="font-weight: bolder;"> {{
                                $t("Total_Center_consumption") }}:</span> {{ total2 }} KWh</ion-label>
                              <ion-label v-if="total2 && isWhat == 'zona'"> <span style="font-weight: bolder;"> {{
                                $t("Total_Zone_consumption") }}:</span> {{ total2 }} KWh</ion-label>
                              <ion-label v-if="total2 && isWhat == 'secao'"> <span style="font-weight: bolder;"> {{
                                $t("Total_Sectio_ consumption") }}:</span> {{ total2 }} KWh</ion-label>
                              <ion-label v-if="total2 && isWhat == null"> <span style="font-weight: bolder;"> {{
                                $t("Total_Pond_consumption") }}:</span> {{ total2 }} KWh</ion-label>
                              <ion-label v-if="!total2"> <span style="font-weight: bolder;"> {{ $t("No_data_Found")
                              }}</span> </ion-label>

                              <h2 class="titleGraph">{{ $t("Consumption_Graph") }}</h2>
                              <div class="canvas">
                                <canvas v-if="isChartAvailable" id="myChart5" style="margin-bottom: 100px;"></canvas>
                              </div>
                            </div>
                          </div>


                        </ion-content>
                      </ion-modal>
                    </div>
                  </ion-content>
                </ion-card-content>
              </ion-card>
              <ion-card class="IFPcard" :disabled="disableButton">
                <ion-card-content class="ion-padding-card">
                  <ion-content class="dieselGeral" :scroll-y="false">
                    <div class="dieselDiv">
                      <ion-icon :ios="flameOutline" :md="flameSharp" class="iconDiesel"
                        @click="setOpen2(true)"></ion-icon>
                      <h2 class="listOxigenio">{{ $t("Diesel") }}</h2>
                      <ion-modal :is-open="isOpen2" @ionModalDidPresent="onModalDidPresent2"
                        @ionModalDidDismiss="onModalDidDismiss2">
                        <ion-header>
                          <ion-toolbar>
                            <ion-title>{{ $t("Diesel") }}</ion-title>
                            <ion-buttons slot="end">
                              <ion-button @click="setOpen2(false)">{{ $t("Close") }}</ion-button>
                            </ion-buttons>
                          </ion-toolbar>
                        </ion-header>
                        <ion-content class="ion-padding modal-content">
                          <div class="selectLago">
                            <div class="datas">

                              <div class="fromTo">
                                <ion-item fill="outline" class="lagoList " lines="none">
                                  <div class="input-wrapper">
                                    <ion-label>{{ $t("Show_Center_data") }}</ion-label>
                                    <ion-toggle slot="end" @ionChange="showCenterData3()" v-model="data">Checked
                                      Toggle</ion-toggle>

                                  </div>
                                </ion-item>
                              </div>
                              <ion-button class="refresh addButtonCZSL" style="margin-bottom: 20px;"
                                @click="cadastrarParaCentro('diesel')">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>
                            <div class="datas">
                              <div class="fromTo">
                                <IonItem fill="outline" class="lagoList">
                                  <ion-label position="floating">{{ $t("Zones") }} </ion-label>
                                  <ion-select position="floating" aria-label="Fruit" class="select"
                                    :disabled="disableButton" @ionChange="selecionarZona3($event)">
                                    <ion-content class="ion-padding" id="content-lista">
                                      <ion-list v-for="(zona, index) in zonas" :key="index">
                                        <ion-select-option :value="zona.id">{{ zona.nome }}</ion-select-option>
                                      </ion-list>
                                    </ion-content>
                                  </ion-select>
                                </IonItem>
                              </div>
                              <ion-button class="refresh addButtonCZSL" v-if="secoesDaZona3" style="margin-bottom: 20px;"
                                @click="cadastrarParaZona('diesel')">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>
                            <div class="datas">
                              <div class="fromTo">
                                <IonItem fill="outline" class="lagoList" v-if="secoesDaZona3">
                                  <ion-label position="floating">{{ $t("Sections") }}</ion-label>
                                  <ion-select position="floating" aria-label="Fruit" class="select"
                                    :disabled="disableButton" @ionChange="selecionarSecao3($event)">
                                    <ion-content class="ion-padding" id="content-lista">
                                      <ion-list v-for="(secao, index) in secoesDaZona3" :key="index">
                                        <ion-select-option :value="secao.id">{{ secao.texto }}</ion-select-option>
                                      </ion-list>
                                    </ion-content>
                                  </ion-select>
                                </IonItem>
                              </div>
                              <ion-button class="refresh addButtonCZSL" style="margin-bottom: 20px;"
                                @click="cadastrarParaSecao('diesel')" v-if="lagosDaSecao3">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>
                            <div class="datas">
                              <div class="fromTo">
                                <IonItem fill="outline" class="lagoList" v-if="lagosDaSecao3">
                                  <ion-label position="floating">{{ $t("Pond") }}</ion-label>
                                  <ion-select position="floating" aria-label="Fruit" class="select"
                                    :disabled="disableButton" @ionChange="selecionarLago2($event)">
                                    <ion-content class="ion-padding" id="content-lista">
                                      <ion-list v-for="lago in lagosDaSecao3" :key="lago.id">
                                        <ion-select-option :value=lago.id>{{ lago.nome }}</ion-select-option>
                                      </ion-list>
                                    </ion-content>
                                  </ion-select>
                                </IonItem>
                              </div>
                              <ion-button class="refresh addButtonCZSL" style="margin-bottom: 20px;"
                                v-if="idLagoSelecionado" @click="cadastrarParaLago('diesel')">
                                <ion-icon :ios="addOutline" :md="addSharp" class="iconRefresh CZSLicon"></ion-icon>
                              </ion-button>
                            </div>
                            <br>
                            <div class="graficoModal">
                              <div class="datas">
                                <div class="fromTo">
                                  <ion-item fill="outline" class="from">
                                    <ion-label position="floating">{{ $t("From") }}:</ion-label>
                                    <ion-input class="inputData" v-model="dataInicio" type="date"></ion-input>
                                  </ion-item>

                                  <ion-item fill="outline" class="to">
                                    <ion-label position="floating">{{ $t("To") }}:</ion-label>
                                    <ion-input class="inputData" v-model="dataFim" type="date"></ion-input>
                                  </ion-item>
                                </div>

                                <ion-button class="refresh" @click="refresh22()" :disabled="disableButton"
                                  style="margin-bottom: 20px;" @ionChange="desabilitarBotoes">
                                  <ion-icon :ios="checkmarkOutline" :md="checkmarkSharp" class="iconRefresh"></ion-icon>
                                </ion-button>
                              </div>
                              <ion-label v-if="total3 && isWhat == 'centro'"> <span style="font-weight: bolder;"> {{
                                $t("Total_Center_consumption") }}:</span> {{ total3 }} Kg</ion-label>
                              <ion-label v-if="total3 && isWhat == 'zona'"> <span style="font-weight: bolder;"> {{
                                $t("Total_Zone_consumption") }}:</span> {{ total3 }} Kg</ion-label>
                              <ion-label v-if="total3 && isWhat == 'secao'"> <span style="font-weight: bolder;"> {{
                                $t("Total_Section_consumption") }}:</span> {{ total3 }} Kg</ion-label>
                              <ion-label v-if="total3 && isWhat == null"> <span style="font-weight: bolder;"> {{
                                $t("Total_Pond_consumption") }}:</span> {{ total3 }} Kg</ion-label>
                              <ion-label v-if="!total3"> <span style="font-weight: bolder;"> {{ $t("No_data_Found")
                              }}</span> </ion-label>

                              <h2 class="titleGraph">{{ $t("Consumption_Graph") }}</h2>
                              <div class="canvas">
                                <canvas v-if="isChartAvailable" id="myChart4" style="margin-bottom: 100px;"></canvas>
                              </div>
                            </div>
                          </div>

                        </ion-content>
                      </ion-modal>
                    </div>
                  </ion-content>
                </ion-card-content>
              </ion-card>

            </div>

            <div v-if="hasLenght" style="height: 50vh;"></div>
            <ion-button size="large" class="fixed-button"
              v-if="this.funcionalidades.includes(37) || this.funcionalidades.includes(38) || this.funcionalidades.includes(39)"
              expand="block" @click="cadastrarRegistroDiario">{{ $t("Daily_records") }}</ion-button>
          </div>

        </div>

      </ion-content>

    </ion-page>
  </ion-page>
</template>
  
<script>
import { IonToggle, IonInput, IonModal, IonTitle, IonList, IonListHeader, IonCardContent, IonSelect, IonSelectOption, IonButton, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon, alertController, } from '@ionic/vue';
import { ellipsisVerticalSharp, addOutline, addSharp, cubeOutline, cubeSharp, layersOutline, layersSharp, stopOutline, stopSharp, cogOutline, cogSharp, flameOutline, flameSharp, serverOutline, serverSharp, storefrontOutline, storefrontSharp, flashOutline, flashSharp, refreshOutline, refreshSharp, checkmarkOutline, checkmarkSharp, } from "ionicons/icons"
import { config } from "../../config"
import axios from 'axios';
import Chart from 'chart.js/auto'
import moment from 'moment';
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  name: "InfrastructuresPage",
  components: {
    'language-selector': LanguageSelector, IonToggle, IonInput, IonModal, IonTitle, IonList, IonListHeader, IonCardContent, IonSelect, IonSelectOption, IonButton, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon
  },
  data() {
    return {
      data: true,
      checkmarkOutline,
      checkmarkSharp,
      refreshOutline,
      refreshSharp,
      flashOutline,
      flashSharp,
      storefrontOutline,
      storefrontSharp,
      serverOutline,
      serverSharp,
      flameOutline,
      flameSharp,
      cogOutline,
      cogSharp,
      centros: '',
      zonas: '',
      ellipsisVerticalSharp,
      valueZona: '',
      secoes: '',
      lagos: '',
      isWhat: 'centro',
      lagosPorSecao: {},
      secoesPorZona: {},
      isOpen: false,
      isOpen2: false,
      isOpen3: false,
      id: '',
      id2: '',
      id3: '',
      total1: '',
      total2: '',
      total3: '',
      secoesDaZona1: '',
      lagosDaSecao1: '',
      secoesDaZona2: '',
      lagosDaSecao2: '',
      secoesDaZona3: '',
      lagosDaSecao3: '',
      chart: null,
      chart2: null,
      chart3: null,
      chart4: null,
      dataInicio: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
      disableButton: false,
      funcionalidades: '',
      isChartAvailable: false,
      selectedCentro: null,
      hasLenght: true,
      addOutline,
      addSharp,
      cubeOutline,
      cubeSharp,
      layersOutline,
      layersSharp,
      stopOutline,
      stopSharp,
    }
  },
  watch: {
    zonas: {
      handler() {
        this.zonas.forEach(zona => {
          this.getSecoes(zona.id)
        })
      },
      deep: true
    },
    secoesPorZona: {
      handler() {
        Object.values(this.secoesPorZona).forEach(secoes => {
          secoes.forEach(secao => {
            this.getLagos(secao.id)
          })
        })
      },
      deep: true
    }
  },
  mounted() {
    this.getUser()
    this.openLanguageSelector();
  },
  methods: {
    openLanguageSelector() {
      if (!localStorage.getItem('locale')) {
        const languageSelector = this.$refs.languageSelector;
        languageSelector.openSelector();
      }
    },
    async getPermissoes() {
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
      await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {

          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
            const alert = await alertController.create({
              header: "Error",
              message: this.$t("You_haven't_access")
            });
            await alert.present();
            const userId = localStorage.getItem('id');
            window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    desabilitarBotoes() {
      this.disableButton = true
      setTimeout(() => {
        this.disableButton = false;
      }, 1200);

    },
    showCenterData() {
      if (this.data) {
        const id = localStorage.getItem('idCentroDefault')
        this.isWhat = 'centro'
        this.id = id
        this.pegarDadosLago(id);
      }

    },
    showCenterData2() {
      if (this.data) {
        const id = localStorage.getItem('idCentroDefault')
        this.isWhat = 'centro'
        this.id3 = id
        this.pegarDadosLago3(id);
      }

    },
    showCenterData3() {
      if (this.data) {
        const id = localStorage.getItem('idCentroDefault')
        this.isWhat = 'centro'
        this.id2 = id
        this.pegarDadosLago2(id);
      }

    },
    refresh2() {
      this.desabilitarBotoes()
      if (this.id && this.dataInicio && this.dataFim) {
        if (this.chart) {
          this.chart.destroy();
          this.chart = null;
        }
        if (this.chart2) {
          this.chart2.destroy();
          this.chart2 = null;
        }
        this.pegarDadosLago(this.id); // PROBLEMA DA VARIÁVEL
      }
    },
    refresh22() {
      this.desabilitarBotoes()
      if (this.id2 && this.dataInicio && this.dataFim) {
        if (this.chart3) {
          this.chart3.destroy();
          this.chart3 = null;
        }
        this.pegarDadosLago2(this.id2);
      }
    },
    refresh222() {
      this.desabilitarBotoes()
      if (this.id3 && this.dataInicio && this.dataFim) {
        if (this.chart4) {
          this.chart4.destroy();
          this.chart4 = null;
        }
        this.pegarDadosLago3(this.id3);
      }
    },
    mostrarDadosLago(dadosFiltrados) {

      this.isChartAvailable = true;
      const canvas = document.getElementById('myChart2');
      if (!canvas) {
        return;
      }
      const ctx = canvas.getContext('2d');
      if (this.recargaData && dadosFiltrados) {
        this.chart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: dadosFiltrados,
            datasets: [
              {
                label: 'm³',
                data: this.recargaData,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 2,
              },
            ],
          },
          options: {
            scales: {
              y: {
                min: 0,
              },
            },
          },
        });
      }
    },
    mostrarDadosLago2(dadosFiltrados) {

      this.isChartAvailable = true;
      const canvas = document.getElementById('myChart3');
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      if (this.consumoData && dadosFiltrados) {
        this.chart2 = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: dadosFiltrados,
            datasets: [
              {
                label: 'm³',
                data: this.consumoData,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 2,
              },
            ],
          },
          options: {
            scales: {
              y: {
                min: 0,
              },
            },
          },
        });
      }
    },
    mostrarDadosLago3(dadosFiltrados) {

      this.isChartAvailable = true;
      const canvas = document.getElementById('myChart4');
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      if (this.consumoCData.length > 0 && dadosFiltrados) {
        const max = Math.max(...this.consumoCData);
        const diffData = this.consumoCData.map(value => max - value);

        this.chart3 = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: dadosFiltrados,
            datasets: [
              {
                label: 'Kg',
                data: this.consumoCData,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 2,
              },
              {
                label: 'Kg',
                data: diffData,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 2,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                stacked: false,
                grid: {
                  drawBorder: false,
                },
                ticks: {
                  stepSize: max / 5,
                },
              },
            },
          },
        });
      } else {
        this.chart3 = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: dadosFiltrados,
            datasets: [
              {
                label: 'Kg',
                data: this.consumoCData,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 2,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                stacked: false,
              },
            },
          },
        });
      }


    },
    mostrarDadosLago4(dadosFiltrados) {
      this.isChartAvailable = true;
      const canvas = document.getElementById('myChart5');
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      this.chart4 = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: dadosFiltrados,
          datasets: [
            {
              label: 'KWh',
              data: this.consumoCData,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 2,
            },
          ],
        },
        options: {
          scales: {
            y: {
              min: 0,
            },
          },
        },
      });

    },
    async pegarDadosLago(id) {
      const token = localStorage.getItem('token');
      if (this.isWhat) {
        const data = {
          nivel: this.isWhat,
          dados: id
        }
        try {
          const response = await axios.put(`${config.apiUrl}/getOxigenios`, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          this.recargaData = response.data.recargas;
          this.consumoData = response.data.consumos;
          this.datasRegistro = response.data.dates;
          this.datasRegistro.forEach(dado => {
            dado.data = moment(dado.data, 'YYYY-MM-DD');
          });
        } catch (error) {
          console.log(error);
        }
        this.filtrarDados();
      } else {
        try {
          const response = await axios.get(`${config.apiUrl}/getByIdOxigenio/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          const recargaData = response.data.items.map(item => parseFloat(item.recarga));
          const consumoData = response.data.items.map(item => parseFloat(item.consumo));
          const datasRegistro = response.data.items.map(item => item.data_registro);
          this.recargaData = recargaData;
          this.consumoData = consumoData;
          this.datasRegistro = datasRegistro;
          this.datasRegistro.forEach(dado => {
            dado.data = moment(dado.data, 'YYYY-MM-DD');
          });
        } catch (error) {
          console.log(error);
        }
        this.filtrarDados();
      }

    },
    async pegarDadosLago2(id) {
      const token = localStorage.getItem('token');
      if (this.isWhat) {
        const data = {
          nivel: this.isWhat,
          dados: id
        }
        try {
          const response = await axios.put(`${config.apiUrl}/getCombustiveis`, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          this.consumoCData = response.data.consumos;
          this.datasRegistro = response.data.dates;
          this.datasRegistro.forEach(dado => {
            dado.data = moment(dado.data, 'YYYY-MM-DD');
          });
        } catch (error) {
          console.log(error);
        }
        this.filtrarDados2();
      } else {
        try {
          const response = await axios.get(`${config.apiUrl}/getByIdCombustivel/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          const consumoCData = response.data.items.map(item => parseFloat(item.consumo));
          const datasRegistro = response.data.items.map(item => item.data_registro);
          this.consumoCData = consumoCData;
          this.datasRegistro = datasRegistro;

          this.datasRegistro.forEach(dado => {
            dado.data = moment(dado.data, 'YYYY-MM-DD');
          });
        } catch (error) {
          console.log(error);
        }
        this.filtrarDados2();
      }
    },
    async pegarDadosLago3(id) {
      const token = localStorage.getItem('token');
      if (this.isWhat) {
        const data = {
          nivel: this.isWhat,
          dados: id
        }
        try {
          const response = await axios.put(`${config.apiUrl}/getEletricidades`, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          this.consumoCData = response.data.consumos;
          this.datasRegistro = response.data.dates;
          this.datasRegistro.forEach(dado => {
            dado.data = moment(dado.data, 'YYYY-MM-DD');
          });
        } catch (error) {
          console.log(error);
        }
        this.filtrarDados3();
      } else {
        try {
          const response = await axios.get(`${config.apiUrl}/getEletricidade/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          const consumoCData = response.data.items.map(item => parseFloat(item.consumo));
          const datasRegistro = response.data.items.map(item => item.data_registro);
          this.consumoCData = consumoCData;
          this.datasRegistro = datasRegistro;

          this.datasRegistro.forEach(dado => {
            dado.data = moment(dado.data, 'YYYY-MM-DD');
          });
        } catch (error) {
          console.log(error);
        }
        this.filtrarDados3();
      }
    },
    filtrarDados() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      if (this.chart2) {
        this.chart2.destroy();
        this.chart2 = null;
      }
      const dataInicioMoment = this.dataInicio
      const dataFimMoment = this.dataFim

      const dadosFiltrados = this.datasRegistro.filter(dado => {
        const dataDado = moment(dado, 'DD-MM-YYYY');
        return dataDado.isBetween(dataInicioMoment, dataFimMoment, null, '[]');
      });
      if (this.id) {

        var totalRecarga = 0
        for (var i = 0; i < this.recargaData.length; i++) {
          totalRecarga += this.recargaData[i];
        }

        var totalConsumo = 0
        for (var z = 0; z < this.consumoData.length; z++) {
          totalConsumo += this.consumoData[z];
        }

        this.total1 = (totalRecarga - totalConsumo)
      }

      this.mostrarDadosLago(dadosFiltrados);
      this.mostrarDadosLago2(dadosFiltrados);
    },
    filtrarDados2() {
      if (this.chart3) {
        this.chart3.destroy();
        this.chart3 = null;
      }
      const dataInicioMoment = this.dataInicio
      const dataFimMoment = this.dataFim

      const dadosFiltrados = this.datasRegistro.filter(dado => {
        const dataDado = moment(dado, 'DD-MM-YYYY');
        return dataDado.isBetween(dataInicioMoment, dataFimMoment, null, '[]');
      });

      if (this.id2) {
        var totalConsumo = 0
        for (var z = 0; z < this.consumoCData.length; z++) {
          totalConsumo += this.consumoCData[z];
        }

        this.total3 = totalConsumo
      }

      this.mostrarDadosLago3(dadosFiltrados);
    },
    filtrarDados3() {
      if (this.chart4) {
        this.chart4.destroy();
        this.chart4 = null;
      }
      const dataInicioMoment = this.dataInicio
      const dataFimMoment = this.dataFim

      const dadosFiltrados = this.datasRegistro.filter(dado => {
        const dataDado = moment(dado, 'DD-MM-YYYY');
        return dataDado.isBetween(dataInicioMoment, dataFimMoment, null, '[]');
      });

      if (this.id3) {
        var totalConsumo = 0
        for (var z = 0; z < this.consumoCData.length; z++) {
          totalConsumo += this.consumoCData[z];
        }

        this.total2 = totalConsumo
      }
      this.mostrarDadosLago4(dadosFiltrados);
    },
    async onModalDidPresent() {
      this.pegarDadosLago();
      const id = localStorage.getItem('idCentroDefault')
      this.isWhat = 'centro'
      this.id = id
      this.pegarDadosLago(id);
    },
    async onModalDidPresent2() {
      this.pegarDadosLago2();
      const id = localStorage.getItem('idCentroDefault')
      this.isWhat = 'centro'
      this.id2 = id
      this.pegarDadosLago2(id);
    },
    async onModalDidPresent3() {
      this.mostrarDadosLago3();
      const id = localStorage.getItem('idCentroDefault')
      this.isWhat = 'centro'
      this.id3 = id
      this.pegarDadosLago3(id);
    },
    cadastrarParaCentro(oORd) {
      const idCentro = localStorage.getItem('idCentroDefault')
      this.onModalDidDismiss()
      this.onModalDidDismiss2()
      this.onModalDidDismiss3()
      localStorage.setItem('CZSL', 'Center')
      if (oORd == 'oxygen'){
        this.$router.push(`/config/infrastructure/lake/${idCentro}/oxygen`)
      } else {
        this.$router.push(`/config/infrastructure/lake/${idCentro}/fuel`)
      }
    },
    cadastrarParaZona(oORd) {
      const idZona = this.valueZona
      this.onModalDidDismiss()
      this.onModalDidDismiss2()
      this.onModalDidDismiss3()
      localStorage.setItem('CZSL', 'Zone')
      if (oORd == 'oxygen'){
        this.$router.push(`/config/infrastructure/lake/${idZona}/oxygen`)
      } else {
        this.$router.push(`/config/infrastructure/lake/${idZona}/fuel`)
      }
    },
    cadastrarParaSecao(oORd) {
      const idSecao = this.valueSecao
      this.onModalDidDismiss()
      this.onModalDidDismiss2()
      this.onModalDidDismiss3()
      localStorage.setItem('CZSL', 'Section')
      if (oORd == 'oxygen'){
        this.$router.push(`/config/infrastructure/lake/${idSecao}/oxygen`)
      } else {
        this.$router.push(`/config/infrastructure/lake/${idSecao}/fuel`)
      }
    },
    cadastrarParaLago(oORd) {
      const idLago = this.idLagoSelecionado
      this.onModalDidDismiss()
      this.onModalDidDismiss2()
      this.onModalDidDismiss3()
      localStorage.setItem('CZSL', 'Lake')
      if (oORd == 'oxygen'){
        this.$router.push(`/config/infrastructure/lake/${idLago}/oxygen`)
      } else {
        this.$router.push(`/config/infrastructure/lake/${idLago}/fuel`)
      }
    },
    onModalDidDismiss() {
      this.isOpen = false
      this.isChartAvailable = false
      this.id = ''
      this.lagosDaSecao1 = ''
      this.secoesDaZona1 = ''
      this.idLagoSelecionado = ''
    },
    onModalDidDismiss2() {
      this.isOpen2 = false
      this.isChartAvailable = false
      this.lagosDaSecao3 = ''
      this.secoesDaZona3 = ''
      this.id2 = ''
      this.idLagoSelecionado = ''
    },
    onModalDidDismiss3() {
      this.isOpen3 = false
      this.isChartAvailable = false
      this.id3 = ''
      this.lagosDaSecao2 = ''
      this.secoesDaZona2 = ''
      this.idLagoSelecionado = ''
    },
    selecionarLago($event) {
      this.data = false
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      if (this.chart2) {
        this.chart2.destroy();
        this.chart2 = null;
      }
      if(this.idLagoSelecionado){
        this.idLagoSelecionado = ''
      }
      this.idLagoSelecionado = $event.target.value;
      this.isWhat = null
      this.id = this.idLagoSelecionado
      const id = this.id
      this.pegarDadosLago(id);
    },
    selecionarLago2($event) {
      this.data = false
      if (this.chart3) {
        this.chart3.destroy();
        this.chart3 = null;
      }
      if(this.idLagoSelecionado){
        this.idLagoSelecionado = ''
      }
      this.idLagoSelecionado = $event.target.value;
      this.isWhat = null
      this.id2 = this.idLagoSelecionado
      const id = this.id2
      this.pegarDadosLago2(id);
    },
    selecionarLago3($event) {
      this.data = false
      if (this.chart4) {
        this.chart4.destroy();
        this.chart4 = null;
      }
      this.idLagoSelecionado = $event.target.value;
      this.isWhat = null
      this.id3 = this.idLagoSelecionado
      const id = this.id3
      this.pegarDadosLago3(id);
    },
    setOpen(isOpen) {
      this.isOpen = isOpen;
      this.id = ''
    },
    setOpen2(isOpen2) {
      this.isOpen2 = isOpen2;
      this.id2 = ''
    },
    setOpen3(isOpen3) {
      this.isOpen3 = isOpen3;
      this.id3 = ''
    },
    irParaLago(id) {
      this.$router.push(`/config/infrastructure/lake/${id}`)
    },
    cadastrarRegistroDiario() {
      this.$router.push(`/daily`)
    },
    getButtonClass(lagoId) {
      if (lagoId % 2 == 0) {
        return 'button-class-1';
      } else {
        return 'button-class-2';
      }
    },
    getButtonClasses(lagoId) {
      const cycleIndex = (lagoId - 1) % 3;

      if (cycleIndex == 0) {
        return 'button-class-1';
      } else if (cycleIndex == 1) {
        return 'button-class-2';
      } else {
        return 'button-class-3';
      }
    },
    async getUser() {
      const Id = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${Id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.userData = response.data;

        this.showCentros()
      } catch (error) {
        console.log(error);
      }
    },
    async showCentros() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/productionCenters`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const idDefault = parseInt(localStorage.getItem('idCentroDefault'));

        let idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
        if (!idEmpresa) {
          await new Promise((resolve) => {
            const checkIdEmpresa = setInterval(() => {
              idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
              if (idEmpresa) {
                clearInterval(checkIdEmpresa);
                resolve();
              }
            }, 100);
          });
        }

        this.centros = response.data.centros.filter((centro) => centro.id_empresa == idEmpresa);

        const naoTem = this.centros.filter((centro) => centro.id == idDefault);
        if (this.centros.length == 1) {
          this.selectedCentro = this.centros[0].id;
        } else if (this.centros.length > 1 && naoTem.length == 0) {
          this.selectedCentro = this.centros[0].id;

        } else if (this.centros.length > 1 && idDefault) {
          this.selectedCentro = parseInt(idDefault);
        } else if (naoTem.length > 0) {
          this.selectedCentro = parseInt(idDefault);
        } else {
          this.selectedCentro = this.centros[0].id;
        }
        this.getPermissoes()
      } catch (error) {
        console.log(error);
      }
    },
    // criarInfrastructure3() {
    //   this.$router.push('/config/infrastructure/create/autentication')
    // },
    criarInfrastructure2(idZona, idSecao) {
      localStorage.setItem('idCentro', this.valueCentro)
      localStorage.setItem('idZona', idZona)
      localStorage.setItem('idSecao', idSecao)
      this.$router.push('/config/infrastructure/create/lake')
    },
    criarInfrastructure() {
      localStorage.setItem('idCentro', this.valueCentro)
      this.$router.push('/config/infrastructure/create/zone')
    },
    criarInfrastructure4() {
      localStorage.setItem('addInicial', 1)
      this.$router.push('/users/production-center/create')
    },
    selecionarCentro($event) {
      this.valueCentro = $event.target.value
      localStorage.setItem('idCentroDefault', this.valueCentro)
      this.getCompanyCentroDefault()
      this.idCentro = $event.target.value
      this.hasLenght = true
      this.getZonas(this.valueCentro)
      this.getLagosByCentro(this.idCentro)
    },
    async getCompanyCentroDefault() {
      const id = localStorage.getItem('idCentroDefault', this.valueCentro)
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/productionCenter/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        localStorage.setItem('idEmpresaCentro', response.data.centro.id_empresa)
      } catch (error) {
        console.log(error);
      }
    },
    // selecionarAutenticacao($event) {
    //   this.valueAutenticacao = $event.target.value
    //   console.log(this.valueAutenticacao)
    // },
    async getZonas() {
      const data = {
        idCentro: this.valueCentro
      }
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getZonebyProductionCenter`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const zonas = response.data.items.map((zona) => {
          return {
            nome: zona.nome,
            id: zona.id
          };
        });
        this.zonas = zonas;
        if (zonas) {
          this.hasLenght = true
        }

        if (zonas.length > 0) {
          this.hasLenght = false
        }

      } catch (error) {
        console.log(error);
      }
    },
    async getSecoes(id) {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/infrastructures/zona/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const secoes = response.data.zonas.secoes;
        this.secoesPorZona = {
          ...this.secoesPorZona,
          [id]: secoes
        };
      } catch (error) {
        console.log(error);
      }
    },
    async getLagos(id) {
      const data = {
        id: id
      };
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getLagoBySecao`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const lagos = response.data.items.map((lago) => ({ nome: lago.nome, id: lago.id }));
        this.lagosPorSecao = {
          ...this.lagosPorSecao,
          [id]: lagos
        };
      } catch (error) {
        console.log(error);
      }
    },
    async getLagosByCentro() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/getLagosByCentro/${this.idCentro}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const lagos = response.data.lagos
        this.lagos = lagos

      } catch (error) {
        console.log(error);
      }
    },
    selecionarZona1($event) {
      this.data = false
      this.valueZona = $event.target.value
      this.isWhat = 'zona'
      this.id = this.valueZona
      const id = this.id
      this.pegarDadosLago(id);
      this.showSecoes1(this.valueZona);
      this.lagosDaSecao1 = ''
    },
    async showSecoes1() {
      const data = {
        idZona: this.valueZona
      }
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getSecaoByZone`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const secoes = response.data.items.map((secao) => {
          return {
            texto: secao.texto,
            id: secao.id
          };
        });
        this.secoesDaZona1 = secoes;
      } catch (error) {
        console.log(error);
      }
    },
    selecionarSecao1($event) {
      this.data = false
      this.valueZona = $event.target.value
      this.isWhat = 'secao'
      this.id = this.valueZona
      const id = this.id
      this.pegarDadosLago(id);
      this.showLagos1(this.valueZona);
    },
    async showLagos1(id) {
      const data = {
        id: id
      };
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getLagoBySecao`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.lagosDaSecao1 = response.data.items;

      } catch (error) {
        console.log(error);
      }
    },
    selecionarZona2($event) {
      this.data = false
      this.valueZona = $event.target.value
      this.isWhat = 'zona'
      this.id3 = this.valueZona
      const id = this.id3
      this.pegarDadosLago3(id);
      this.showSecoes2(this.valueZona);
      this.lagosDaSecao2 = ''
    },
    async showSecoes2() {
      const data = {
        idZona: this.valueZona
      }
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getSecaoByZone`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const secoes = response.data.items.map((secao) => {
          return {
            texto: secao.texto,
            id: secao.id
          };
        });
        this.secoesDaZona2 = secoes;
      } catch (error) {
        console.log(error);
      }
    },
    selecionarSecao2($event) {
      this.data = false
      this.valueZona = $event.target.value
      this.isWhat = 'secao'
      this.id3 = this.valueZona
      const id = this.id3
      this.pegarDadosLago3(id);
      this.showLagos2(this.valueZona);
    },
    async showLagos2(id) {
      const data = {
        id: id
      };
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getLagoBySecao`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.lagosDaSecao2 = response.data.items;

      } catch (error) {
        console.log(error);
      }
    },
    selecionarZona3($event) {
      this.data = false
      this.valueZona = $event.target.value
      this.showSecoes2(this.valueZona);
      this.isWhat = 'zona'
      this.id2 = this.valueZona
      const id = this.id2
      this.pegarDadosLago2(id);
      this.showSecoes3(this.valueZona);
      this.lagosDaSecao2 = ''
    },
    async showSecoes3() {
      const data = {
        idZona: this.valueZona
      }
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getSecaoByZone`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const secoes = response.data.items.map((secao) => {
          return {
            texto: secao.texto,
            id: secao.id
          };
        });
        this.secoesDaZona3 = secoes;
      } catch (error) {
        console.log(error);
      }
    },
    selecionarSecao3($event) {
      this.data = false
      this.valueZona = $event.target.value
      this.valueSecao = $event.target.value
      this.isWhat = 'secao'
      this.id2 = this.valueZona
      const id = this.id2
      this.pegarDadosLago2(id);
      this.showLagos3(this.valueZona);
    },
    async showLagos3(id) {
      const data = {
        id: id
      };
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put(`${config.apiUrl}/getLagoBySecao`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.lagosDaSecao3 = response.data.items;

      } catch (error) {
        console.log(error);
      }
    },
    async deleteZona(id) {
      const token = localStorage.getItem('token');
      const alert = await alertController.create({
        header: this.$t("Confirm_Deletion"),
        message: this.$t("Deleting_this_Zone_will_also"),
        buttons: [
          {
            text: this.$t("Cancel"),
            role: 'cancel'
          },
          {
            text: this.$t("Yes"),
            role: "destructive",
            handler: async () => {
              await axios.delete(`${config.apiUrl}/infrastructures/zona/delete/${id}`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              });
              const alert = await alertController.create({
                header: this.$t("Deleted"),
                message: this.$t("has_been_successfully_deleted")
              });
              await alert.present();
              window.location.reload();
            }
          }
        ]
      });
      await alert.present();
    },
    async deleteLago(id) {
      const token = localStorage.getItem('token');
      const alert = await alertController.create({
        header: this.$t("Confirm_Deletion"),
        message: this.$t("Do_you_really_want_to_delete_the"),
        buttons: [
          {
            text: this.$t("Cancel"),
            role: 'cancel'
          },
          {
            text: this.$t("Yes"),
            role: "destructive",
            handler: async () => {
              await axios.delete(`${config.apiUrl}/infrastructures/lago/delete/${id}`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              });
              const alert = await alertController.create({
                header: this.$t("Deleted"),
                message: this.$t("has_been_successfully_deleted")
              });
              await alert.present();
              window.location.reload();
            }
          }
        ]
      });
      await alert.present();
    },
    async exibirOpcoes(id) {
      if (this.funcionalidades.includes(38) && this.funcionalidades.includes(39)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/config/infrastructure/edit/zone/${id}`);
              }
            },
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteZona(id)
              }

            }
          ]
        });
        await alert.present();
      } else if (this.funcionalidades.includes(38) && !this.funcionalidades.includes(39)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/config/infrastructure/edit/zone/${id}`);
              }
            },
          ]
        });
        await alert.present();
      } else if (!this.funcionalidades.includes(38) && this.funcionalidades.includes(39)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteZona(id)
              }

            }
          ]
        });
        await alert.present();
      }
    },
    async exibirOpcoes2(id) {
      if (this.funcionalidades.includes(38) && this.funcionalidades.includes(39) && this.funcionalidades.includes(37)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Configure"),
              handler: () => {
                this.irParaLago(id)
              }
            },
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/config/infrastructure/edit/lake/${id}`);
              }
            },
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteLago(id)
              }

            }
          ]
        });
        await alert.present();
      } else if (this.funcionalidades.includes(38) && !this.funcionalidades.includes(39) && !this.funcionalidades.includes(37)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/config/infrastructure/edit/lake/${id}`);
              }
            },
          ]
        });
        await alert.present();
      } else if (!this.funcionalidades.includes(38) && this.funcionalidades.includes(39) && !this.funcionalidades.includes(37)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteLago(id)
              }

            }
          ]
        });
        await alert.present();
      } else if (!this.funcionalidades.includes(38) && !this.funcionalidades.includes(39) && this.funcionalidades.includes(37)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Configure"),
              handler: () => {
                this.irParaLago(id)
              }
            },
          ]
        });
        await alert.present();
      }
    }
  },

}


</script>
  
  
<style scoped>
@media (prefers-color-scheme: dark) {
  ion-item {
    --background: transparent !important;
  }
}

.inputData {
  font-size: 12px;
}

.iconeEnomeZona {
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.refresh {
  height: 55px;
  width: 55px;
}

.lagoList {
  width: 100%;
}

.from {
  width: 49%;
}

.to {
  width: 49%;
}

.fromTo {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.IFPcard {
  margin: 0px;
}

.datas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.modal-content {
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 200px;
}

.canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75%;
}

.geral {
  font-size: 25px;
  color: #5a5c69;
  padding: 0px;
}

.listaGeral {
  padding: 0px;
}

.lagosGeral {
  min-height: 180px;
  max-height: 230px;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}

ion-content.lagosGeral {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
}

ion-content.lagosGeral::-webkit-scrollbar {
  display: none;
}

.dieselDiv {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listOxigenio {
  width: 35%;
  padding: 0px;
  font-size: 18px;
  text-align: center;
  color: #5a5c69;
}

.comBotao {
  display: grid;
  grid-template-columns: 90% auto;
  align-items: center;
}

.titleGraph {
  width: 100%;
  padding: 0px;
  font-size: 18px;
  text-align: center;
  color: #5a5c69;
}

.inner-scroll {
  padding-right: 0;
}

.dieselGeral {
  min-height: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconDiesel {
  width: 80%;
  height: 70%;
  color: #4e73df;
}

.iconRefresh {
  width: 80%;
  height: 70%;
  color: white;
}

.fixed-button {
  position: sticky;
  bottom: 0;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
}

.iconElet {
  width: 80%;
  height: 70%;
  color: #f6c23e;
}

.iconOxig {
  width: 80%;
  height: 70%;
  color: #E74A3B;
}

.iconArmaz {
  width: 80%;
  height: 70%;
  color: #1CC88A;
}


.lagoOpcoes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.secoes {
  margin-bottom: 20px;
}

.button-class-1 {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  --background: #E74A3B;
  font-size: 15px;
}

.button-class-2 {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  --background: #1CC88A;
  font-size: 15px;
}

#Rzona {
  --background: transparent;
  border: #0049b1 2px solid;
  --color: #0049b1;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.addButtonCZSL {
  --background: transparent;
  border: #0049b1 2px solid;
  --color: #0049b1;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.CZSLicon {
  color: #0049b1;
  height: 55px;
  width: 55px;
}

.button-class-3 {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  --background: #f6c23e;
  font-size: 15px;
}

.lagoNaoEncontrado {
  display: block !important;
}

.nomeGrupo {
  margin-right: 0px;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.noData ion-icon {
  height: 100px;
  width: 100px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.infraGeral {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.input-wrapper {
  height: 55px !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content.ios {
  position: relative;
  transform: translateY(-50%);
}

.createButton {
  margin-bottom: 10px;
  width: 100%;
}

.selectFilter {
  margin-bottom: 10px;
}

.selectLago {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>