<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/config/infrastructure"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Edit") }} {{ $t("Zone") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding" v-if="empresas">
                <div class="content-items" v-if="zonas">
                    <IonItem fill="outline" v-if="master">
                        <ion-label position="floating">{{ $t("Companies") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            @ionChange="selecionarEmpresa($event)" v-model="zonas.empresa"
                            >
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{
                                        item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <div class="contentCommercial" v-if="centros">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Production_centers") }} <span style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select"
                                    v-model="zonas.id_centro">
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="(centro, index) in centros" :key="index">
                                            <ion-select-option :value="centro.id">{{ centro.nomeLegal }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="zonas.nome" ></ion-input>
                    </ion-item>
                    <div class="products">
                        <ion-label slot="end">{{ $t("Sections") }} <span style="color: red;">*</span></ion-label>
                    </div>
                    <div v-for="(secao, index) of zonas.secoes" :key="index" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                        <ion-item fill="outline"  class="produto" style="width: 88%;">
                            <ion-label position="floating">{{ $t("Section") }}</ion-label>
                            <ion-input v-model="secao.texto"></ion-input>
                        </ion-item>

                        <div class="iconDelete" style="width: 30px;display: flex; align-items: center;">
                            <ion-icon @click="removeSecao(zonas, index)" :ios="closeOutline" :md="closeSharp"
                                style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                        </div>
                    </div>
                    <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
                        <ion-icon @click="addSecao(zonas)" :ios="addOutline" :md="addSharp"
                            style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
                    </div>

                    <ion-button size="large" expand="block" @click="cadastrarCompra">{{ $t("Register") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton,IonSelect, IonSelectOption, IonIcon,  IonList, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { addOutline, addSharp, closeOutline, closeSharp } from 'ionicons/icons';
import { ref } from 'vue';

export default {
    components: {
       IonBackButton, IonSelect, IonSelectOption, IonIcon, IonList, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        const zonas = ref(null)
        return {
            zonas,
            centros: "",
            addOutline,
            addSharp,
            closeOutline,
            closeSharp,
            empresas: '',
            funcionalidades:'',
            master:''
        }
    },
    mounted() {
        this.getPermissoes();
        this.getZona()
        this.fetchUser()
    },
    methods: {
        async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              this.master = response.data.master
              this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
              this.fetchEmpresas()
      } catch (error) {
        console.log(error);
      }
    },
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
        
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        async getZona(){
      const Id = this.$route.params.id
      const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${config.apiUrl}/infrastructures/zona/${Id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
          this.zonas = response.data.zonas;
          this.getCompanyName(this.zonas.empresa);
        } catch (error) {
          console.log(error);
        }
    },
        async showCentros() {
            const token = localStorage.getItem('token');
                const data = {
                empresa: this.valueEmpresa ? this.valueEmpresa : this.zonas.empresa
                }
                try {
                    const response = await axios.put(`${config.apiUrl}/getProductionCenterbyCompanyName`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                    const centros = response.data.items.map((centro) => {
                        return {
                            nomeLegal: centro.nomeLegal,
                            id: centro.id
                        };
                    });
                    this.centros = centros;
                } catch (error) {
                    console.log(error);
                }
            
        },
        async getCompanyName(empresaId) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios
                .get(`${config.apiUrl}/companies/${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.nomeEmpresa = response.data.nome
                this.showCentros(empresaId)
            } catch (error) {
                console.error("Erro ao buscar nomes ", error);
            }
            },
        selecionarEmpresa($event) {
            this.valueEmpresa = $event.target.value
            this.getCompanyName(this.valueEmpresa);
            this.zonas.id_centro = ''
        },
        addSecao(zona) {
            zona.secoes.push({
                texto: ''
            })
        },
        async removeSecao(zonas, index) {
            if (zonas.secoes.length > 1 ) {
                if(zonas.secoes[index].texto != ''){
                    const alert = await alertController.create({
                    header: this.$t("Caution"),
                message: this.$t("this_action_will_exclude_all_ponds"),
                    buttons: [
                        
                    {
                        text: this.$t("Cancel"),
                        role:'Cancel',
                        handler: async () => {
                            return
                        }
                    },{
                        text:'OK',
                        role:'Destructive',
                        handler: async () => {
                            zonas.secoes = zonas.secoes.filter((z, i) => i !== index )
                        }
                    },
                    ]
                });
                await alert.present();
                }else{
                    zonas.secoes = zonas.secoes.filter((z, i) => i !== index )
                }
                
            } else {
                const alert = await alertController.create({
                    header: this.$t("Invalid_action"),
                message: this.$t("You_can_not_delete_all"),
                    buttons: ['OK']
                });
                await alert.present();
            }
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
                const hasPermissoes = this.master
               
                if (!hasPermissoes) {
                this.empresas = this.empresas.filter(empresa => empresa.id == this.idEmpresa);
                }
            } catch (error) {
                console.log(error);
            }
            },
        selecionarEspecie(event) {
            this.valueEspecie = event.target.value
        },
        async cadastrarCompra() {

            if (!this.zonas.id_centro) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Production_Center"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.zonas.nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            for (let i = 0; i < this.zonas.secoes.length; i++) {
                const zona = this.zonas.secoes[i];
                    if (!zona.texto) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Section"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
            }

            const zonas = {
                zonas: {
                    empresa: this.zonas.empresa,
                    centroProducao: this.zonas.centroProducao,
                    nome: this.zonas.nome,
                    id_centro: this.idCentro ? this.idCentro : this.zonas.id_centro,
                    secoes: this.zonas.secoes,
                }
            };
            console.log(zonas)
            const token = localStorage.getItem('token');
            const zonaId = this.$route.params.id
            await axios.put(`${config.apiUrl}/infrastructures/zona/${zonaId}/edit`, zonas, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_updated!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                        window.location.replace("/config/infrastructure")
                    });
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_updating"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });
        }

    }
};
</script>
    
<style scoped>
.botoes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.addProduct {
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    background: #0049b1;
    color: white;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.addProduct h2 {
    margin: 0px;
    font-size: 16px;
}

.plusIcon {
    font-size: 35px;
    color: white;

}

.products {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

</style>