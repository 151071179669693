<template>
  <ion-button @click="openSelector" :fill="fill" :size="size" :expand="expand" :color="color">
    <ion-icon v-if="caret" name="caret-down" size="small" slot="start"></ion-icon>
    <ion-icon :icon="getBrandImage(language)"></ion-icon>
  </ion-button>
</template>

<script>
import { alertController, IonIcon, IonButton } from '@ionic/vue';
import { languageService } from "../services/languageService"

export default {
  props: [
    'color',
    'expand',
    'fill',
    'size',
    'caret'
  ],
  components: {
    IonIcon, IonButton
  },
  data() {
    return {
      language: languageService.get(),
      languages: languageService.list()
    }
  },
  created() {
    //
  },
  methods: {
    getBrandImage(lang) {
        return `/assets/flags/${lang}.svg`;
      },
    async openSelector() {
      const alert = await alertController.create({
        header: "Select the language for the application",
        buttons: [
          this.$t('Cancel'),
          {
            text: "OK",
            role: 'confirm',
            handler: (ev) => {
              this.selectLanguage(ev);
            }
          },
        ],
        inputs: Object.keys(this.languages).map(i => {
          return {
            checked: i == this.language,
            name: 'locale',
            label: this.languages[i],
            type: 'radio',
            value: i,
            icon: this.getBrandImage(i)
          }
        })
      });

      alert.present();
    },
    selectLanguage(locale) {
      this.language = locale;
      languageService.set(locale);
    }
  }
}
</script>
