<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector></language-selector>
          </ion-buttons>

          <ion-title>{{ $t("Users_Group") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content">
          <div class="content-items">
            <ion-button v-if="this.funcionalidades.includes(5)" size="large" expand="block" style="margin: 0px ;"
              @click="criarGrupo()">{{ $t("Register") }} {{ $t("Group") }}</ion-button>
            <ion-item fill="outline">
              <ion-label position="floating">{{ $t("Filter") }}</ion-label>
              <ion-input v-model="termoPesquisa"></ion-input>
            </ion-item>
            <template v-if="itemsFiltrados.length == 0">
              <div class="noData">
                <ion-icon :ios="peopleOutline" :md="peopleSharp"></ion-icon>
                <p>{{ $t("No_data_Found") }}...</p>
              </div>
            </template>
            <ion-card v-for="(item, index) in itemsFiltrados" :key="index" class="gruposCard">
              <ion-card-content class="ion-padding-card">
                <ion-item fill="none" lines="none">

                  <ion-label @click="editarGrupo(item.id)" class="nomeGrupo">{{ item.nome }}</ion-label>
                  <ion-icon v-if="this.funcionalidades.includes(4) || this.funcionalidades.includes(6)"
                    class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(item.id)"
                    slot="end"></ion-icon>

                </ion-item>
              </ion-card-content>
            </ion-card>
            <ion-infinite-scroll @ionInfinite="ionInfinite">
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </div>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>

<script>
import { IonInput, IonTitle, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon, alertController, IonButton, IonInfiniteScroll, IonInfiniteScrollContent, } from '@ionic/vue';
import {
  ellipsisVerticalSharp, peopleSharp,
  peopleOutline
} from "ionicons/icons"
import { reactive, ref } from 'vue';
import { config } from "../../config"
import axios from 'axios';
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  components: {
    'language-selector': LanguageSelector, IonInput, IonTitle, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonItem, IonLabel, IonIcon, IonButton, IonInfiniteScroll, IonInfiniteScrollContent,
  },
  data() {
    return {
      peopleSharp,
      peopleOutline,
      funcionalidades: '',
      termoPesquisa: '',
    }
  },
  computed: {
    itemsFiltrados() {
      return this.items.filter(item => {
        return item.nome.toLowerCase().includes(this.termoPesquisa.toLowerCase());
      });
    }
  },
  mounted() {
    this.getPermissoes();
  },
  methods: {
    async getPermissoes() {
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
      await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {

          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades

          if (!funcionalidades.includes(4) && !funcionalidades.includes(5) && !funcionalidades.includes(6)) {
            const alert = await alertController.create({
              header: "Error",
              message:  this.$t("You_haven't_access")
            });
            await alert.present();
            const userId = localStorage.getItem('id');
            window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async deleteGroup(id) {
      const token = localStorage.getItem('token');
      const alert = await alertController.create({
        header: this.$t("Confirm_Deletion"),
        message: this.$t("Do_you_really_want_to_delete_the"),
        buttons: [
          {
            text: this.$t("Cancel"),
            role: 'cancel'
          },
          {
            text: this.$t("Yes"),
            role: "destructive",
            handler: async () => {
              await axios.delete(`${config.apiUrl}/users/groups/delete/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              const alert = await alertController.create({
                header: this.$t("Deleted"),
                message: this.$t("has_been_successfully_deleted")
              });
              await alert.present();
              window.location.reload();
            }
          }
        ]
      });
      await alert.present();
    },
    async exibirOpcoes(id) {
      if (this.funcionalidades.includes(4) && this.funcionalidades.includes(6)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/users/groups/${id}/edit`);
              }
            },
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteGroup(id)
              }

            }
          ]
        });
        await alert.present();
      } else if (this.funcionalidades.includes(4) && !this.funcionalidades.includes(6)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: () => {
                this.$router.push(`/users/groups/${id}/edit`);
              }
            },
          ]
        });
        await alert.present();
      } else if (!this.funcionalidades.includes(4) && this.funcionalidades.includes(6)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteGroup(id)
              }

            }
          ]
        });
        await alert.present();
      }
    }
    ,
    criarGrupo() {
      this.$router.push('/users/users-groups/create-groups')
    },
    editarGrupo(id) {
      if (this.funcionalidades.includes(4)) {
        this.$router.push(`/users/groups/${id}/edit`)
      }
    }
  },
  setup() {
    const items = reactive([]);
    const loading = ref(false);
    const fetchItems = async (start, end) => {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'));
      try {
        const response = await axios.get(`${config.apiUrl}/users_groups?_start=${start}&_end=${end}&empresaId=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data.items)
        const { nomes, fotos, ids, empresas } = response.data.items;
        

        const newItems = nomes
          .map((nome, index) => ({
            id: ids[index],
            nome,
            foto: fotos[index],
            empresa: empresas[index]
          }))
        return newItems;
      } catch (error) {
        console.log(error);
      }

    };

    const ionInfinite = (ev) => {
      loadMoreItems(ev);
    };

    const loadMoreItems = (ev) => {
      if (loading.value) {
        return;
      }

      loading.value = true;

      setTimeout(() => {
        const start = items.length;
        const end = start + 10;

        fetchItems(start, end).then((newItems) => {
          items.push(...newItems);
          ev.target.complete();
          loading.value = false;
        });
      }, 1000);
    };

    fetchItems(0, 10).then((initialItems) => {
      items.push(...initialItems);
    });


    return { ionInfinite, items, ellipsisVerticalSharp };
  }

}


</script>


<style scoped>
@media (prefers-color-scheme: dark) {
  ion-item {
    --background: transparent !important;
  }
}

.tresPontinhos {
  font-size: 20px;
}

.gruposCard {
  margin: 0px;
}

.nomeGrupo {
  margin-right: 0px;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.noData ion-icon {
  height: 100px;
  width: 100px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.content.ios {
  position: relative;
  transform: translateY(-50%);
}</style>