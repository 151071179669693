
<template>
    <ion-page>

        <ion-page id="main-content">

            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/cultivation/batches"></ion-back-button>
                    </ion-buttons>

                    <ion-title v-if="this.$route.params.id">{{ $t("Edit") }} {{ $t("Batch") }}</ion-title>
                    <ion-title v-else>{{ $t("Register") }} {{ $t("Batch") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">

                <div class="content-items" v-if="item">
                    <IonItem fill="outline" v-if="this.master && empresas">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="item.empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="item.nome"></ion-input>
                    </ion-item>
                    <ion-item fill="outline" v-if="item.tipo == 1 || !this.$route.params.id">
                        <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date") }} <span
                            style="color: red;">*</span></ion-label>
                        <ion-input v-model="item.data" type="date"></ion-input>
                    </ion-item>
                    <IonItem fill="outline" v-if="especies && (item.tipo == 1 || !this.$route.params.id)">
                        <ion-label position="floating">{{ $t("Species") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            @ionChange="($event) => selecionarEspecie($event)" v-model="item.especie" id="companyInput">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="especie in especies" :key="especie.id">
                                    <ion-select-option :value=especie.id v-model="especie.id">{{ especie.nome
                                    }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <IonItem fill="outline" class="lagoList" v-if="lagos && (item.tipo == 1 || !this.$route.params.id)">
                        <ion-label position="floating">{{ $t("Pond") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" :disabled="disableButton"
                            v-model="item.lago">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="lago in lagos" :key="lago.id">
                                    <ion-select-option :value=lago.id v-model="lago.id" class="selectLago">{{ lago.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-button size="large" @click="addEditLote">{{ $t("Register") }}</ion-button>
                </div>
            </ion-content>

        </ion-page>

    </ion-page>
</template>

<script>
import { IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"

export default {
    components: {
        IonBackButton, IonList, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            item: {
                nome: '',
                especie: '',
                empresa: '',
                lago: '',
                data: '',
                tipo: ''
            },
            especies: "",
            empresas: "",
            lagos: '',
            funcionalidades: '',
            master: ''
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchEmpresas()
        this.fetchUser()
    },
    methods: {
        async fetchLagos(idEmpresa) {
            const token = localStorage.getItem('token');
            const data = {
                idEmpresa: idEmpresa
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getLagosByEmpresa`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const lagos = await Promise.all(response.data.items.map(async (lago) => {
                    const centroResponse = await axios.get(`${config.apiUrl}/users/productionCenter/${lago.centroProducao}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    console.log(centroResponse)
                    const centroProducaoNome = centroResponse.data.centro.nomeLegal;

                    const nomeLago = `${centroProducaoNome} - ${lago.nome}`;

                    return {
                        nome: nomeLago,
                        id: lago.id
                    };
                }));

                this.lagos = lagos;
                console.log(this.lagos)
            } catch (error) {
                console.log(error);
            }
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(45) || !funcionalidades.includes(46)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresas = response.data.items;

            } catch (error) {
                console.log(error);
            }
        },
        async fetchUser() {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.empresa = parseInt(localStorage.getItem('idEmpresa'));
                this.master = response.data.master

                this.fetchLagos(this.empresa)
                this.fetchEspecies(this.empresa)
                this.fetchBatch()
            } catch (error) {
                console.log(error);
            }
        },
        async fetchBatch() {
            const loteId = this.$route.params.id
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/getLoteById/${loteId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.item = response.data.lotes;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchEspecies() {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/diseases/${userId}/species`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                this.especies = response.data.items;
                this.especies = this.especies.filter(item => item.empresa == this.empresa);
                console.log(response)
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEspecie($event) {
            this.valueEspecie = $event.target.value
            console.log(this.valueEspecie)
            this.showBoxes(this.valueEspecie);
        },
        async addEditLote() {

            if (!this.item.nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.item.data && (this.item.tipo == 1 || !this.$route.params.id)) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Date"),
                    buttons: ['OK']
                });
                await alert.present();
                return;

            }

            if (!this.item.especie && (this.item.tipo == 1 || !this.$route.params.id)) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Specie"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.item.lago && (this.item.tipo == 1 || !this.$route.params.id)) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Pond"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            const tipo = this.$route.params.id ? (this.item.tipo == 1 ? 1 : 2) : 1;

            const lote = {
                id: this.item.id,
                nome: this.item.nome,
                especie: this.item.especie,
                lago: this.item.lago,
                data: this.item.data,
                tipo: tipo,
                empresa: this.item.empresa ? this.item.empresa : this.empresa,
            }

            const token = localStorage.getItem('token');
            console.log(lote)
            const id = this.$route.params.id
            await axios.post(`${config.apiUrl}/configs/batches/createEdit/${id}`, lote, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_updated!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                    });

                    window.location.replace('/cultivation/batches')
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_updating") + " " + this.$t("Batche"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });


        }

    }
}
</script>

<style scoped>
.commercial-input {
    font-size: 14px;
}

.sizesTemp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
}

.contentCommercial {
    text-align: center;
}

ion-button.commercialButton {
    margin-top: 10px;
    --border-radius: 150px !important;
    width: 35px;
    height: 35px;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 0px !important;
}

.commercialSizes {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 1px;
    column-gap: 15px;
    justify-items: center;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: fit-content;
}

.selectLago {
    --min-width: 600px;
}
</style>