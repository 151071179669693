<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button default-href="/users/production-center"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ $t("Edit") }} {{ $t("Production_Center") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content-items" v-if="centro">
          
          <IonItem fill="outline" v-if="this.master">
                        <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            v-model="centro.id_empresa">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in empresas" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Legal_name") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="centro.nomeLegal"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Registration_Number") }} <span style="color: red;">*</span></ion-label>
            <ion-input v-model="centro.numeroRegistro"></ion-input>
          </ion-item>
          <ion-item fill="outline" ref="numberP">
            <ion-label position="floating">{{ $t("Telephone") }} <span style="color: red;">*</span></ion-label>
            <ion-input @ionInput="validate3" type="cep"
              @input="formatarTelefone" v-model="centro.telefone" @blur="removerMascara" @ionBlur="markTouched"></ion-input>
          </ion-item>
          <ion-list-header class="campoTitle">{{ $t("Address") }}</ion-list-header>
          <div class="country">
            <ion-item fill="outline">
              <ion-label position="floating">{{ $t("Country") }} <span style="color: red;">*</span></ion-label>
              <ion-input id="paisInput" v-model="centro.pais"
                @ionFocus="showCountryList = true"></ion-input>
            </ion-item>
            <ion-list class="listaPaíses" v-if="showCountryList" style="padding-top: 0px; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);">
              <ion-content scrool="y">
                <ion-list lines="full">
                  <ion-item button v-for="country in filteredCountries" :key="country.id" @click="selectCountry(country)">
                    <ion-label slot="start">{{ country }}</ion-label>
                  </ion-item>
                </ion-list>
              </ion-content>
            </ion-list>
          </div>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Zip_code") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="cepInput" type="cep" @input="formatarCep" v-model="centro.cep" @blur="removerMascara"
              ></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Address 1") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="address1Input" v-model="centro.endereco1"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Address 2") }}</ion-label>
            <ion-input id="address2Input" v-model="centro.endereco2"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("City") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="cidadeInput" v-model="centro.cidade"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("State") }} <span style="color: red;">*</span></ion-label>
            <ion-input id="estadoInput" v-model="centro.estado"></ion-input>
          </ion-item>
          <ion-button size="large" expand="block" @click="cadastrarRegistro">{{ $t("Update") }}</ion-button>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>
    
<script>
import { IonSelect, IonSelectOption ,IonBackButton,IonListHeader,IonList,alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ref } from 'vue';

export default {
  components: {
    IonSelect, IonSelectOption ,IonBackButton,IonListHeader,IonList,IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
    const centro = ref(null);
    return {
      centro,
      countriesNames: [],
      showCountryList: false,
      empresas: "",
      funcionalidades:'',
            master: ''
    }
  },
  computed: {
  filteredCountries() {
    return this.countriesNames.filter((country) =>
      country.toLowerCase().includes(this.centro.pais.toLowerCase())
    );
  },
},
mounted() {
  this.getPermissoes();
  this.fetchEmpresas()
  this.fetchUser()
  this.getCountries()
  this.getCentro()
},
  methods: {
    async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(10)){
              const alert = await alertController.create({
                header: "Error",
                message: this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresa = parseInt(localStorage.getItem('idEmpresa'));
        this.master = response.data.master
      } catch (error) {
        console.log(error);
      }
    },
    async getCentro(){
      const Id = this.$route.params.id 
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/productionCenter/${Id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.centro = response.data.centro;
        console.log(response)
      } catch (error) {
        console.log(error);
      }
    },
    formatarCep(event) {
    const isDeleting = event.data == null;
    if (!isDeleting) {
      const cep = event.target.value.replace(/\D/g, '');
      const cepLength = cep.length;

      if (cepLength <= 5) {
        this.centro.cep = `${cep}`;
      } else if (cepLength <= 8) {
        this.centro.cep = `${cep.substring(0, 5)}-${cep.substring(5)}`;
      }
    }
    this.removerMascara()
  },
    validateNumber2(numberP) {
    return numberP.replace(/\D/g, '').match(/^(\(\d{2}\)\s\d{5}-\d{4}|\(\d{2}\)\d{9}|\d{11}|\d{2}\s\d{9}|\d{2}\s\d{5}-\d{4})$/);
  },
    markTouched() {
    this.$refs.numberP.$el.classList.add('ion-touched')
  },
    removerMascara(event) {
    if (event && event.target) {
      const input = event.target.value;
      const digitsOnly = input.replace(/\D/g, '');
      const masked = `${digitsOnly.substring(0, 5)}-${digitsOnly.substring(5, 7)}`;
      this.centro.telefone = masked;
    }
  },
  validate3(ev) {
    const value3 = ev.target.value;

    this.$refs.numberP.$el.classList.remove('ion-valid');

    this.$refs.numberP.$el.classList.remove('ion-invalid');

    if (value3 == '') return;

    if ((this.validateNumber2(value3)) != "") {
      this.validateNumber2(value3)
        ? this.$refs.numberP.$el.classList.add('ion-valid')
        : this.$refs.numberP.$el.classList.add('ion-invalid');
    }
  },
    formatarTelefone(event) {
    const isDeleting = event.data == null;
    if (!isDeleting) {
      const tel = event.target.value.replace(/\D/g, '');
      const telLength = tel.length;

      if (telLength <= 2) {
        this.centro.telefone = `(${tel})`;
      } else if (telLength <= 6) {
        this.centro.telefone = `(${tel.substring(0, 2)}) ${tel.substring(2)}`;
      } else if (telLength <= 10) {
        this.centro.telefone = `(${tel.substring(0, 2)}) ${tel.substring(2, 6)}-${tel.substring(6)}`;
      } else {
        this.centro.telefone = `(${tel.substring(0, 2)}) ${tel.substring(2, 7)}-${tel.substring(7, 11)}`;
      }
    }
    this.removerMascara()
  },
    getCountries() {
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        this.countries = response.data;
        this.countriesNames = this.countries.map(country => country.name.common)
      })
      .catch(error => {
        console.log(error);
      });
  },
    selectCountry(country) {
    this.centro.pais = country;
    this.showCountryList = false;
  },
    async cadastrarRegistro() {

      if (!this.centro.nomeLegal) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Legal_name"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.centro.numeroRegistro) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Registration_Number"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.centro.telefone || !this.validateNumber2(this.centro.telefone)) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Telephone"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.centro.pais) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Country"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.centro.cep) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Zip_code"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.centro.endereco1) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("Address 1"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.centro.cidade) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("City"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.centro.estado) {
        const alert = await alertController.create({
          header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("State"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      
      const centro = {
        centro: {
            id_empresa: this.centro.id_empresa,
          nomeLegal: this.centro.nomeLegal,
          numeroRegistro: this.centro.numeroRegistro,
          telefone: this.centro.telefone,
          pais: this.centro.pais,
          cep: this.centro.cep,
          endereco1: this.centro.endereco1,
          endereco2: this.centro.endereco2,
          cidade: this.centro.cidade,
          estado: this.centro.estado,
        }
      };
      const token = localStorage.getItem('token');
        const Id = this.$route.params.id 
        axios.put(`${config.apiUrl}/users/productionCenter/${Id}/edit`, centro, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            console.log(response.data.id);

            const alert = alertController.create({
              header: this.$t("successfully_updated!")
            });
            alert.then(_alert => {
              _alert.present();

              window.location.replace("/users/production-center")
            });
          })
          .catch(error => {
            console.log(error);
            const alert = alertController.create({
              header: this.$t("Error_updating"),
              buttons: ['OK']
            });
            alert.then(_alert => {
              _alert.present();
            });
          });
    }

  }
};
</script>
    
<style scoped>
.campoTitle {
font-size: 18px;
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
  max-width: 450px;
  max-height: max-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.country{
  height: max-content;
  max-width: 450px;
}

.listaPaíses{
  height: 250px;
}
</style>