<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Disease") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <IonItem fill="outline" >
                        <ion-label position="floating">{{ $t("Disease") }}</ion-label>
                            <ion-select position="floating" aria-label="Fruit" class="select"
                                v-model="nome_doenca">
                                <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in doencas" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                                </ion-content>
                            </ion-select>
                    </IonItem>
                    <ion-item fill="outline">
                        <ion-label position="stacked" style="font-size: 18px;" >{{ $t("Date") }}</ion-label>
                        <ion-input v-model="data" type="date"
                        :placeholder="$t('apply_to_warehouse')"></ion-input>
                    </ion-item>
                    <ion-button size="large" expand="block" @click="cadastrarDoenca">{{ $t("Start") }}/{{ $t("Stop") }}</ion-button>
                    <br>
                    <ion-item fill="outline" v-for="item in items2" :key="item.id" lines="none">
                        <div  class="historico">
                            <ion-label class="cinza" position="floating">{{ $t("Disease") }}: {{ item.nome_doenca }}</ion-label>
                            <ion-label class="cinza" position="floating">{{ $t("Start") }} {{ $t("Date") }}: {{ item.data_inicio }}</ion-label>
                            <ion-label class="cinza" position="floating">{{ $t("End") }} {{ $t("Date") }}: {{ item.data_fim }}</ion-label>
                        </div>
                    </ion-item>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonSelect, IonList, IonSelectOption, alertController,IonButton,IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    name: "InfrastructuresLakeDiseasePage",
    components: {
       IonBackButton,  IonSelect, IonList, IonSelectOption,IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
    return {
        nome_doenca:'',
        data:'',
        doencas:'',
      items2:''
    }
  },
    mounted() {
        this.getPermissoes();
        this.fetchHistorico()
        this.obterDataAtual()
        this.nomeEmpresa = parseInt(localStorage.getItem('idEmpresa'));
        this.fetchDoencas(this.nomeEmpresa)
        
    },
    methods: {
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        async fetchDoencas() {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.apiUrl}/configs/diseases`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
            const items = response.data.items.map((item) => {
            return {
                nome: item.nome,
                id: item.id,
                empresa: item.empresa
            };
            });
            this.itens = items;
            this.doencas = this.itens.filter(item => item.empresa == this.nomeEmpresa);
    },
    obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
    async fetchHistorico() {
        const id = this.$route.params.id
        const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/infrastructures/lago/getHistoricoDoenca/${id}/get`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const items = response.data.historico.map((item) => {
            return {
              nome_doenca: item.nome_doenca,
              data_inicio: item.data_inicio,
              data_fim: item.data_fim,
              id: item.id
            };
          });
          this.items2 = items;
      } catch (error) {
        console.log(error);
      }
    },
    cadastrarDoenca() {

        const data = {};
        if (this.nome_doenca && this.data) {
            data.id_lago = this.$route.params.id
            data.nome_doenca = this.nome_doenca;
            data.data = this.data;
            data.data_registro = this.dataAtual
        }else{
            const alert = alertController.create({
                        header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                    return
        }
        console.log(data)
        const token = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarDoenca/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
            .then(response => {
                console.log(response.data);
                
                const alert = alertController.create({
                    header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                    buttons: ['OK']
                    });
                    this.fetchHistorico()
                    alert.then(_alert => {
                        _alert.present();
                    });

                this.nome_doenca = '' 
                this.data = '' 
                const inTutorial = localStorage.getItem('inTutorial')
                                            if (inTutorial) {
                                                window.location.reload()
                                            }
                return;
            })
            .catch(error => {
                console.log(error);
            });
    }
    }
};
</script>
    
<style scoped>

.cinza{
 color: #838383;
}

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.historico{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 110px;
}
.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
</style>