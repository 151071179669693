<template >
  <ion-page>
    <ion-content class="ion-padding" :fullscreen="true" :scroll-y="false">
      <div class="content">
        <div class="content-items">
          <img src="../static/logo-cinteca-removebg-preview.png" alt="">
          <ion-item fill="outline">
            <ion-label position="floating">Email</ion-label>
            <ion-input v-model="email"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Password") }}</ion-label>
            <ion-input v-model="senha" type="password"></ion-input>
          </ion-item>
          <ion-button size="large" @click="fazerLogin()">{{ $t("Sign_In") }}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { alertController, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton } from '@ionic/vue';
import { auth } from "../services/auth"
import { mapActions } from 'vuex'
import axios from 'axios';
import { config } from "../config"

export default ({
  name: "LoginPage",
  components: {
    IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton
  },
  data() {
    return {
      email: '',
      senha: ''
    }
  },
  methods: {
    ...mapActions("user", {
      login: "login"
    }),
    async fazerLogin() {

      auth.login(this.email, this.senha)
        .then((response) => {
          this.login(response.data.user)
          this.userId = localStorage.getItem('id');
          const token = localStorage.getItem('token');
          axios.get(`${config.apiUrl}/users/${this.userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
            .then(response1 => {
              const groupId = response1.data.empresas[0].grupo;
              return groupId;
            })
            .then(groupId => {
              return axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
            })
            .then(response2 => {
            const funcionalidades = response2.data.funcionalidades;
                if (funcionalidades.includes(37) && funcionalidades.includes(38) && funcionalidades.includes(39)) {
                      window.location.replace(`/config/infrastructure`);
                } else if (funcionalidades.includes(4) && funcionalidades.includes(5) && funcionalidades.includes(6)) {
                  window.location.replace(`/users/users-groups`);
                } else if (funcionalidades.includes(7) && funcionalidades.includes(8) && funcionalidades.includes(9)) {
                  window.location.replace(`/users`);
                } else if (funcionalidades.includes(10) && funcionalidades.includes(11) && funcionalidades.includes(12)) {
                  window.location.replace(`/users/production-center`);
                } else if (funcionalidades.includes(13) && funcionalidades.includes(14) && funcionalidades.includes(15)) {
                  window.location.replace(`/cultivation/species`);
                } else if (funcionalidades.includes(16) && funcionalidades.includes(17) && funcionalidades.includes(18)) {
                  window.location.replace(`/cultivation/feed`);
                } else if (funcionalidades.includes(19) && funcionalidades.includes(20) && funcionalidades.includes(21)) {
                  window.location.replace(`/cultivation/disease`);
                } else if (funcionalidades.includes(22) && funcionalidades.includes(23) && funcionalidades.includes(24)) {
                  window.location.replace(`/cultivation/warehouse`);
                } else if (funcionalidades.includes(25) && funcionalidades.includes(26) && funcionalidades.includes(27)) {
                  window.location.replace(`/financial/shopping`);
                } else if (funcionalidades.includes(28) && funcionalidades.includes(29) && funcionalidades.includes(30)) {
                  window.location.replace(`/financial/records`);
                } else if (funcionalidades.includes(31) && funcionalidades.includes(32) && funcionalidades.includes(33)) {
                  window.location.replace(`/financial/items`);
                } else if (funcionalidades.includes(34) && funcionalidades.includes(35) && funcionalidades.includes(36)) {
                  window.location.replace(`/financial/sales`);
                } else {
                  const Id = localStorage.getItem('id');
                  window.location.replace(`/users/${Id}/my-account`);
                }
            })
            .catch(error => {
              console.log(error);
            });

        })
        .catch(async error => {
          console.log(error);
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Invalid_credentials,_please_try_again."),
            buttons: ['OK']
          });
          await alert.present();
        });
    }
  }
})
</script>

<style scoped>
.content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
</style>
