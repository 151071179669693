<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector></language-selector>
          </ion-buttons>
          <ion-title>{{ $t("Feeds") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content">
          <div class="content-items">
            <ion-button v-if="this.funcionalidades.includes(17)" size="large" class="createButton" style="margin: 0px ;"
              @click="criarAlimento()">{{ $t("Register") }} {{ $t("Feed") }}</ion-button>
            <IonItem class="selectFilter" fill="outline" style="margin: 0px ;">
              <ion-label>{{ $t("Select_Filter_type") }}</ion-label>
              <ion-select position="floating" aria-label="Fruit" class="select" v-model="valueFiltro" id="companyInput"
                @ionChange="selecionarFiltro($event)">
                <ion-content class="ion-padding" id="content-lista">
                  <ion-list>
                    <ion-select-option value="nome">{{ $t("Name") }}</ion-select-option>
                    <ion-select-option value="fabricante">{{ $t("Manufacturer") }}</ion-select-option>
                    <ion-select-option value="especie">{{ $t("Specie") }}</ion-select-option>
                  </ion-list>
                </ion-content>
              </ion-select>
            </IonItem>
            <ion-item fill="outline">
              <ion-label position="floating">{{ $t("Filter") }} </ion-label>
              <ion-input v-model="termoPesquisa"></ion-input>
            </ion-item>
            <template v-if="itemsFiltrados.length == 0">
              <div class="noData">
                <ion-icon :ios="bagHandleOutline" :md="bagHandleSharp"></ion-icon>
                <p>{{ $t("No_data_Found") }}...</p>
              </div>
            </template>
            <ion-card v-for="(item, index) in itemsFiltrados" :key="index" class="racoesCard">
              <ion-card-content class="ion-padding-card">
                <ion-item lines="none" id="avatar-item" class="ion-margin">

                  <div class="info" @click="editarAlimento(item.id)">

                    <h2 style="margin: 0px;">{{ item.tipoAlimento }}</h2>

                    <h2 style="margin: 0px; color: #838383; font-size: 14px;">{{ $t("Manufacturer") }}: {{ item.fabricante
                    }}</h2>

                  </div>

                  <ion-icon v-if="this.funcionalidades.includes(16) || this.funcionalidades.includes(18)"
                    class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(item.id)"
                    slot="end"></ion-icon>
                </ion-item>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
        <ion-infinite-scroll @ionInfinite="loadMoreData" style="height: 50px; margin-top: 20px;">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
    </ion-page>
  </ion-page>
</template>

<script>
import { IonInfiniteScroll, IonInfiniteScrollContent, IonSelect, IonList, IonSelectOption, IonButton, IonInput, IonTitle, IonLabel, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonIcon, alertController } from '@ionic/vue';
import {
  ellipsisVerticalSharp,
  businessSharp,
  businessOutline, peopleOutline, peopleSharp, bagHandleOutline, bagHandleSharp
} from "ionicons/icons"
import axios from 'axios';
import { config } from "../../config"
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  components: {
    'language-selector': LanguageSelector, IonInfiniteScroll, IonInfiniteScrollContent, IonSelect, IonList, IonSelectOption, IonButton, IonInput, IonTitle, IonLabel, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonIcon
  },
  data() {
    return {
      items: [],
      selectedItem: null,
      businessSharp,
      businessOutline,
      peopleOutline,
      peopleSharp,
      termoPesquisa: '',
      bagHandleOutline,
      bagHandleSharp,
      valueFiltro: 'nome',
      funcionalidades: '',
      currentPage: 1,
      itemsPerPage: 10,
      isDataFinished: false,
    };
  },
  mounted() {
    this.getPermissoes();
    this.selecionarFiltro()
    this.buscarDados();
  },
  computed: {
    itemsFiltrados() {
      return this.items.filter(item => {
        let propriedade;
        if (this.valueFiltro == 'nome') {
          propriedade = item.tipoAlimento;
        } else if (this.valueFiltro == 'especie') {
          propriedade = item.especie;
        } else if (this.valueFiltro == 'fabricante') {
          propriedade = item.fabricante;
        }
        return propriedade.toLowerCase().includes(this.termoPesquisa.toLowerCase());
      });
    }
  },
  watch: {
    valueFiltro() {
      this.itemsFiltrados();
    }
  },
  methods: {
    async loadMoreData(event) {
      setTimeout(async () => {
        if (!this.isDataFinished) {
          this.currentPage++;
          await this.buscarDados();
        }
      }, 1000);

      setTimeout(async () => {
        event.target.complete();
      }, 1000);
    },
    async getPermissoes() {
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
      await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {

          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if (!funcionalidades.includes(16) && !funcionalidades.includes(17) && !funcionalidades.includes(18)) {
            const alert = await alertController.create({
              header: "Error",
              message: this.$t("You_haven't_access")
            });
            await alert.present();
            const userId = localStorage.getItem('id');
            window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    selecionarFiltro(event) {
      if (event) {
        this.valueFiltro = event.target.value
        console.log(this.valueFiltro)
      }

    },
    criarAlimento() {
      this.$router.push('/cultivation/feed/create')
    },
    editarAlimento(index) {
      if (this.funcionalidades.includes(16)) {
        this.$router.push(`/cultivation/feed/${index}/edit`)
      }
    },
    async exibirOpcoes(index) {
      if (this.funcionalidades.includes(16) && this.funcionalidades.includes(18)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/cultivation/feed/${index}/edit`);
              }
            },
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteSpecie(index)
              }
            }
          ]
        });
        await alert.present();
        console.log(index)
      } else if (this.funcionalidades.includes(16) && !this.funcionalidades.includes(18)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Edit"),
              handler: async () => {
                this.$router.push(`/cultivation/feed/${index}/edit`);
              }
            },
          ]
        });
        await alert.present();
      } else if (!this.funcionalidades.includes(16) && this.funcionalidades.includes(18)) {
        const alert = await alertController.create({
          header: this.$t("Options"),
          buttons: [
            {
              text: this.$t("Delete"),
              role: 'destructive',
              handler: () => {
                this.deleteSpecie(index)
              }
            }
          ]
        });
        await alert.present();
      }
    },
    async buscarDados() {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'), 10);
      console.log(empresaId)
      try {
        const response = await axios.get(`${config.apiUrl}/configs/feeds?search=${this.termoPesquisa}&page=${this.currentPage}&limit=${this.itemsPerPage}&empresaId=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const items = response.data.items.map((item) => {
          return {
            tipoAlimento: item.tipoAlimento,
            id: item.id,
            fabricante: item.fabricante,
            especie: item.especie
          };
        });
        this.items = [...this.items, ...items];
console.log(response)
        if (items.length < this.itemsPerPage) {
          this.isDataFinished = true;
        }
        console.log(response)
      } catch (error) {
        console.error("Erro ao buscar nomes ", error);
      }
    },
    async deleteSpecie(index) {
      const token = localStorage.getItem('token');
      const alert = await alertController.create({
        header: this.$t("Confirm_Deletion"),
        message: this.$t("Do_you_really_want_to_delete_the"),
        buttons: [
          {
            text: this.$t("Cancel"),
            role: 'cancel'
          },
          {
            text: this.$t("Yes"),
            role: "destructive",
            handler: async () => {
              await axios.delete(`${config.apiUrl}/configs/feeds/delete/${index}`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              });
              const alert = await alertController.create({
                header: this.$t("Deleted"),
                message: this.$t("has_been_successfully_deleted")
              });
              await alert.present();
              window.location.reload();
            }
          }
        ]
      });
      await alert.present();
    }
  },
  setup() {
    return { ellipsisVerticalSharp };
  }
}

</script>


<style scoped>
.ion-margin {
  margin-left: 0px;
  margin-right: 0px;
}

.tresPontinhos {
  font-size: 20px;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.racoesCard {
  margin: 0px;
}

.noData ion-icon {
  height: 100px;
  width: 100px;
}

.info {
  padding-left: 7px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ion-padding-card {
  padding: 0px;
}


#avatar-item {
  --background: transparent;
}

#avatar-item img {
  width: 50px;
  height: 50px;
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.content.ios {
  position: relative;
  transform: translateY(-50%);
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

ion-card-subtitle {
  margin: 0px;
}</style>