<template >
  <ion-app>
    <ion-loading v-if="isLoading" trigger="open-loading" :message="$t('Switching_to')+' '+companyNameLoad" spinner="circles"></ion-loading>
    <ion-menu v-else :ionSwipeGesture="!user" menu-id="main-menu" content-id="main-content" type="overlay">

      <ion-content>
        <!-- <ion-list id="inbox-list" class="list-ios"> -->
        <ion-menu-toggle auto-hide="false"
          style="display: flex; flex-direction: column; gap: 10px; background-color:#0049b1">
          <div class="div1" style="height: 100px; margin-bottom: 15px;">
            <ion-item lines="none" v-if="user" class="avatar-item" :key="user.id">
              <div class="avatar-content">
                <ion-avatar v-if="fotoUrl == 'data:image/jpeg;base64,null' || fotoUrl == 'data:image/jpeg;base64,Pw=='"
                  slot="start" @click="goToMyAccount">
                  <img style=" min-width: 75px; height: 75px;margin-right:10px"
                    src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                </ion-avatar>
                <ion-avatar slot="start" v-else @click="goToMyAccount">
                  <img style=" min-width: 75px; height: 75px;" :src="fotoUrl" />
                </ion-avatar>
                <img id="iconeEmpresa"
                  v-if="imgEmpresa == 'data:image/jpeg;base64,null' || imgEmpresa == 'data:image/jpeg;base64,Pw=='"
                  src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                <img id="iconeEmpresa" v-else :src="imgEmpresa" />
              </div>
              <ion-label style="font-size: 25px; padding-left: 10%; color:white !important" @click="goToMyAccount">
                {{ user.nome }}
              </ion-label>
            </ion-item>
          </div>
          <div class="div2" style="border-radius:0px; height: 50px; margin-bottom: 15px;" v-if="empresasSelect[1]">
            <ion-select class="selecionarEmpresa" interface="popover" @click.stop="toggleSubMenu(i)" v-if="empresasSelect"
              style="border-radius:0px; height: 35px; margin-right:26px; margin-left: 26px; border: 1px white solid; border-radius: 3px; color: white; padding-right: 10px;"
              v-model="empresaSelecionada" @ion-change="onUpdateUserCompany($event)" fill="outline">
              <ion-content class="ion-padding" id="content-lista">
                <ion-list v-for="empresa in empresasSelect" :key="empresa.empresa"
                  style="border-radius:0px; width: 50px; display:block; border-top-left-radius:0px">
                  <ion-select-option :value="empresa.empresa">
                    <img style="height: 30px; width: 30px;" src="https://ionicframework.com/docs/img/demos/avatar.svg"
                      alt="Logo da Empresa" slot="start">
                    {{ empresa.nome }}
                  </ion-select-option>
                </ion-list>
              </ion-content>
            </ion-select>
          </div>
          <div class="else" v-else style="margin-bottom:10px;"></div>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
          <ion-item v-if="checkPermission(p)" @click="selectedIndex = i" router-direction="root"
            @click.stop="toggleSubMenu(i)" :router-link="p.url" lines="none" detail="false" class="hydrated"
            :class="{ selected: selectedIndex == i }">
            <ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
            <ion-label>{{ $t(p.title) }}</ion-label>
            <ion-button fill="clear" slot="end" @click.stop="toggleSubMenu(i)" v-if="p.subPages">
              <ion-icon :ios="chevronDownOutline" :md="chevronDownSharp" v-show="!showSubMenu[i]"></ion-icon>
              <ion-icon :ios="chevronUpOutline" :md="chevronUpSharp" v-show="showSubMenu[i]"></ion-icon>
            </ion-button>
          </ion-item>

          <ion-list v-show="showSubMenu[i]" v-if="p.subPages">
            <ion-menu-toggle auto-hide="false" v-for="(subp, j) in p.subPages" :key="j">
              <ion-item v-if="checkPermission(subp)" @click="selectedIndex = j" router-direction="root"
                :router-link="subp.url" lines="none" detail="false" class="hydrated"
                :class="{ selected: selectedIndex == j }">
                <ion-icon aria-hidden="true" slot="start" :ios="subp.iosIcon" :md="subp.mdIcon"></ion-icon>
                <ion-label>{{ $t(subp.title) }}</ion-label>
                <ion-icon :ios="add" :md="add" name="add" slot="end" v-if="subp.hasPlusIcon"></ion-icon>
              </ion-item>
            </ion-menu-toggle>

          </ion-list>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item @click="exit()" lines="none" detail="false" class="hydrated">
            <ion-icon aria-hidden="true" slot="start" :ios="exitOutline" :md="exitSharp"></ion-icon>
            <ion-label>{{ $t("Exit") }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- </ion-list> -->

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
    <FirstTutorial/>

  </ion-app>
</template>


<script>

import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonAvatar,
  alertController,
  IonButton,
  menuController,
  IonSelect,
  IonSelectOption,
  IonLoading
} from '@ionic/vue';
import axios from 'axios';
import {
  exitOutline,
  exitSharp,
  settingsOutline,
  settingsSharp,
  peopleOutline,
  peopleSharp,
  // homeOutline,
  // homeSharp,
  settings,
  chevronDownOutline,
  chevronDownSharp,
  chevronUpOutline,
  chevronUpSharp,
  businessSharp,
  businessOutline,
  personSharp,
  personOutline,
  fishOutline,
  fishSharp,
  bagHandleOutline,
  bagHandleSharp,
  medkitOutline,
  medkitSharp,
  cogOutline,
  cogSharp,
  documentTextOutline,
  documentTextSharp,
  cashOutline,
  cashSharp,
  bagOutline,
  bagSharp,
  fileTrayFullOutline,
  fileTrayFullSharp,
  fileTrayStackedOutline,
  fileTrayStackedSharp,
  earthOutline,
  earthSharp,
  // checkmarkDoneOutline,
  // checkmarkDoneSharp,
  storefrontOutline,
  storefrontSharp,
  receiptOutline,
  receiptSharp,
  downloadOutline,
  downloadSharp,
  trendingUpOutline,
  trendingUpSharp,
  expandOutline,
  expandSharp,
  // shapesOutline,
  // shapesSharp,
  readerOutline,
  readerSharp,
  constructOutline,
  constructSharp,
  peopleCircleOutline,
  peopleCircleSharp

} from 'ionicons/icons';
import { config } from "./config"
import { mapState } from 'vuex';
import { mapActions } from 'vuex'
import { ref, onMounted } from "vue";
import { languageService } from './services/languageService';
import { i18n } from './i18n';
import FirstTutorial from './components/FirstTutorial.vue';
import { auth } from '../src/services/auth.js';

export default {
  components: {
    FirstTutorial,
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonAvatar,
    IonButton,
    IonSelect,
    IonLoading,
    IonSelectOption
  },
  setup() {
    const isSessionExpired = ref(false);
    const isAlertActive = ref(false);

    onMounted(() => {
      isSessionExpired.value = auth.checkTokenExpiration();

      setInterval(() => {
        isSessionExpired.value = auth.checkTokenExpiration();

        showAlertIf(isSessionExpired.value);
      }, 600);
    });

    async function showAlertIf(value) {
      const token = localStorage.getItem("token")
      if (value && token && !isAlertActive.value) {
        isAlertActive.value = true;

        const alert = await alertController.create({
          header: 'Session expired',
          buttons: [
            {
              text: 'Restart session',
              handler: () => {
                localStorage.removeItem("token")
                localStorage.removeItem("id")
                localStorage.removeItem("user")
                localStorage.removeItem("especie")
                localStorage.removeItem("idGrupo")
                localStorage.removeItem("idEmpresa")
                localStorage.removeItem('idCentro')
                localStorage.removeItem('idDefault')
                localStorage.removeItem('idZona')
                localStorage.removeItem('idSecao')
                localStorage.removeItem('idEmpresaCentro')
                localStorage.removeItem('permissoes')
                localStorage.removeItem('addInicial')
                localStorage.removeItem('currentStep')
                localStorage.removeItem('inTutorial')
                window.location.replace("/login")
              },
            },
          ],
        });
        await alert.present();

        await alert.onDidDismiss();

        isAlertActive.value = false;
      }
    }

    return {
      isSessionExpired,
    };
  },
  data() {

    const userId = localStorage.getItem('id');
    return {
      isLoading: false,
      isLoged: false,
      showTutorial: false,
      imgEmpresa: null,
      empresasSelect: '',
      empresaSelecionada: '',
      chevronDownOutline,
      chevronDownSharp,
      chevronUpOutline,
      chevronUpSharp,
      exitOutline,
      exitSharp,
      downloadOutline,
      downloadSharp,
      showSubMenu: [],
      userId: null,
      selectedIndex: 0,
      settings,
      appPages: [
        // {
        //   title: "Dashboard",
        //   url: '/',
        //   iosIcon: homeOutline,
        //   mdIcon: homeSharp
        // },
        {
          title: 'infrastructures',
          url: '/config/infrastructure',
          iosIcon: cogOutline,
          mdIcon: cogSharp,
          permissions: [37, 38, 39]
        },
        {
          title: 'My Data',
          url: `/users/${userId}/my-account`,
          iosIcon: personOutline,
          mdIcon: personSharp
        },
        {
          title: 'Companies',
          url: '/companies',
          iosIcon: businessOutline,
          mdIcon: businessSharp,
          permissions: [1]
        },
        {
          title: 'Users',
          iosIcon: peopleOutline,
          mdIcon: peopleSharp,
          permissions: [4, 5, 6, 7, 8, 9, 10, 11, 12, 40],
          subPages: [
            {
              title: 'Users_Groups',
              url: '/users/users-groups',
              iosIcon: peopleOutline,
              mdIcon: peopleSharp,
              permissions: [4, 5, 6]
            },
            {
              title: 'See all',
              url: '/users',
              iosIcon: peopleOutline,
              mdIcon: peopleSharp,
              permissions: [7, 8, 9]
            },
            {
              title: 'Production_centers',
              url: '/users/production-center',
              iosIcon: earthOutline,
              mdIcon: earthSharp,
              permissions: [10, 11, 12]
            },
            {
              title: 'License',
              url: '/users/license',
              iosIcon: documentTextOutline,
              mdIcon: documentTextSharp,
              permissions: [40]
            },
          ]
          ,
          showSubMenu: []
        },
        {
          title: 'Cultivation',
          iosIcon: settingsOutline,
          mdIcon: settingsSharp,
          permissions: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 37, 38, 39, 41, 45, 46, 47, 48, 49, 50, 54, 55, 56],
          subPages: [
            {
              title: 'Species',
              url: '/cultivation/species',
              iosIcon: fishOutline,
              mdIcon: fishSharp,
              permissions: [13, 14, 15]
            },
            {
              title: 'Batches',
              url: '/cultivation/batches',
              iosIcon: expandOutline,
              mdIcon: expandSharp,
              permissions: [45, 46, 47]
            },
            {
              title: 'Reasons_for_fasting',
              url: '/cultivation/reasons_for_fasting',
              iosIcon: readerOutline,
              mdIcon: readerSharp,
              permissions: [48, 49, 50]
            },
            {
              title: 'Clients',
              url: '/cultivation/clients',
              iosIcon: peopleCircleOutline,
              mdIcon: peopleCircleSharp,
              permissions: [54, 55, 56]
            },
            {
              title: 'Feed',
              url: '/cultivation/feed',
              iosIcon: bagHandleOutline,
              mdIcon: bagHandleSharp,
              permissions: [16, 17, 18]
            },
            {
              title: 'Diseases',
              url: '/cultivation/disease',
              iosIcon: medkitOutline,
              mdIcon: medkitSharp,
              permissions: [19, 20, 21]
            },
            {
              title: 'Warehouse',
              url: '/cultivation/warehouse',
              iosIcon: storefrontOutline,
              mdIcon: storefrontSharp,
              permissions: [22, 23, 24]
            },
            {
              title: 'Importation/Exportation',
              url: '/cultivation/import-export',
              iosIcon: downloadOutline,
              mdIcon: downloadSharp,
              permissions: [41]
            }
            // ,
            // {
            //   title: 'Register Authentication',
            //   url: '/config/infrastructure/create/autentication',
            //   iosIcon: checkmarkDoneOutline,
            //   mdIcon: checkmarkDoneSharp
            // }
          ]
          ,
          showSubMenu: []
        },
        {
          title: 'Financial',
          iosIcon: cashOutline,
          mdIcon: cashSharp,
          permissions: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 42, 43, 44, 51, 52, 53],
          subPages: [
            {
              title: 'Shopping',
              url: '/financial/shopping',
              iosIcon: bagOutline,
              mdIcon: bagSharp,
              permissions: [25, 26, 27]
            },
            {
              title: 'Records',
              url: '/financial/records',
              iosIcon: fileTrayFullOutline,
              mdIcon: fileTrayFullSharp,
              permissions: [28, 29, 30]
            },
            {
              title: 'Items',
              url: '/financial/items',
              iosIcon: fileTrayStackedOutline,
              mdIcon: fileTrayStackedSharp,
              permissions: [31, 32, 33]
            },
            {
              title: 'Sales',
              url: '/financial/sales',
              iosIcon: receiptOutline,
              mdIcon: receiptSharp,
              permissions: [34, 35, 36]
            }
            ,
            {
              title: 'Updates',
              url: '/financial/updates',
              iosIcon: trendingUpOutline,
              mdIcon: trendingUpSharp,
              permissions: [42, 43, 44]
            },
            {
              title: 'Providers',
              url: '/financial/providers',
              iosIcon: constructOutline,
              mdIcon: constructSharp,
              permissions: [51, 52, 53]
            },
          ]
          ,
          showSubMenu: []
        },],
      funcionalidades: [],
      fotoUrl: null,
    };
  },
  mounted() {
    auth.checkTokenExpiration();

    i18n.global.locale = languageService.get();

    const tem = localStorage.getItem('id')

    if (tem) {
      this.updateUserCompany()
    }

    const user = localStorage.getItem('user');
    if (user) {
      this.login(JSON.parse(user));
      this.fotoUrl = `data:image/jpeg;base64,${user.foto_perfil}`;
      menuController.enable(true);
      this.isLoged = true
    } else {
      menuController.enable(false);
      this.isLoged = false
    }

    if (!this.userId) return;
    const token = localStorage.getItem('token');
    axios.get(`${config.apiUrl}/users/${this.userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        this.fotoUrl = `data:image/jpeg;base64,${response.data.foto_perfil}`;
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    async onUpdateUserCompany($event) {
    try {
      const id = $event.target.value ?? null;
      this.isLoading = true;

      // Define o nome da empresa selecionada
      this.companyNameLoad = this.empresasSelect.find(empresa => empresa.empresa === id)?.nome || '';

      // Atualiza a empresa do usuário e espera a conclusão
      await this.updateUserCompany();

      // Espera 2 segundos antes de desativar o indicador de carregamento
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.isLoading = false;

      // Limpa o nome da empresa e recarrega a página
      this.companyNameLoad = '';
      window.location.reload();
    } catch (error) {
      console.error('Erro ao atualizar empresa do usuário:', error);
      this.isLoading = false;
    }
  },
  async updateUserCompany() {
    try {
      const userId = localStorage.getItem('id');
      if (!userId) return;

      const token = localStorage.getItem('token');

      // Busca as empresas e espera a resposta
      const empresasResponse = await axios.get(`${config.apiUrl}/companies`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      this.empresas = empresasResponse.data.items;

      // Busca as informações do usuário e espera a resposta
      const userResponse = await axios.get(`${config.apiUrl}/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const userData = userResponse.data;
      this.empresasSelect = userData.empresas;

      // Atualiza os nomes das empresas selecionadas com base nos dados obtidos
      this.empresasSelect.forEach(empresa => {
        const empresaID = empresa.empresa;
        const empresaEncontrada = this.empresas.find(item => item.id == empresaID);
        if (empresaEncontrada) {
          empresa.nome = empresaEncontrada.nome;
        }
      });

      this.master = userData.master;

      // Define a empresa selecionada
      let empresaSelecionada;
      if (userData.empresas.length === 1) {
        empresaSelecionada = userData.empresas[0].empresa;
      } else {
        empresaSelecionada = this.empresaSelecionada || parseInt(localStorage.getItem('idEmpresa')) || userData.empresas[0].empresa;
      }
      localStorage.setItem('idEmpresa', empresaSelecionada);
      const empresaEncontrada = userData.empresas.find(item => item.empresa == empresaSelecionada);
      const groupId = empresaEncontrada.grupo;
      localStorage.setItem('idGrupo', groupId);
      this.empresaSelecionada = empresaSelecionada;

      // Carrega a fotoUrl
      await this.pegarImgEmpresa(empresaSelecionada);

      // Busca as funcionalidades do grupo do usuário e espera a resposta
      const groupResponse = await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const funcionalidades = groupResponse.data.funcionalidades;
      this.funcionalidades = funcionalidades;
      localStorage.setItem('permissoes', JSON.stringify(funcionalidades));
      this.funcionalidades.push(this.master);

      // Executa o tutorial e habilita o menu
      this.onTutorial();
      menuController.enable(true);
    } catch (error) {
      console.error('Erro ao atualizar empresa do usuário:', error);
      throw error;
    }
  },
    async noService() {
      /*const alert = await alertController.create({
        header: this.$t("No_service"),
        message: this.$t("You_will_be_redirected_to_the_login_screen")
      });
      setInterval(() => {
        this.exit()
      },2000)
      await alert.present();*/
    },
    onTutorial() {
      const tutorialCompleted = localStorage.getItem('tutorialCompleted');
      const inTutorial = localStorage.getItem('inTutorial');
      const locale = localStorage.getItem('locale')

      if ((!tutorialCompleted || inTutorial == 'true') && locale) {
        this.showTutorial = true;
        this.isLoged = true
      } else {
        if (tutorialCompleted && locale) {
          return
        } else {
          this.showTutorial = false;
          this.onUpdateUserCompany()
        }
      }
    },

    pegarImgEmpresa(id) {
      const token = localStorage.getItem('token');
      axios.get(`${config.apiUrl}/companies/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          this.imgEmpresa = `data:image/jpeg;base64,${response.data.foto_perfil}`;
        })
        .catch(error => {
          console.log(error);
        });
    },
    checkPermission(page) { page;
      return true;
      // if (!page.permissions) return true; // Se não houver permissões definidas, retorna true
      // if (page.permissions.some(permission => this.funcionalidades.includes(permission))) {
      //   return true; // Se o usuário tiver pelo menos uma das permissões necessárias, retorna true
      // } else {
      //   return false; // Se o usuário não tiver nenhuma das permissões necessárias, retorna false
      // }
    },
    async goToMyAccount() {
      const alert = await alertController.create({
        header: this.$t("Options"),
        buttons: [

          {
            text: this.$t("My Data"),
            handler: () => {
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
            }
          },
          {
            text: this.$t("Cancel"),
            role: 'destructive',
          }
        ]
      });
      await alert.present();
    },
    toggleSubMenu(index) {
      this.showSubMenu[index] = !this.showSubMenu[index];
    },
    ...mapActions("user", {
      logout: "logout",
      login: "login"
    }),
    exit() {

      localStorage.removeItem("token")
      localStorage.removeItem("id")
      localStorage.removeItem("user")
      localStorage.removeItem("especie")
      localStorage.removeItem("idGrupo")
      localStorage.removeItem("idEmpresa")
      localStorage.removeItem('idCentro')
      localStorage.removeItem('idDefault')
      localStorage.removeItem('idZona')
      localStorage.removeItem('idSecao')
      localStorage.removeItem('idEmpresaCentro')
      localStorage.removeItem('permissoes')
      localStorage.removeItem('addInicial')
      localStorage.removeItem('currentStep')
      localStorage.removeItem('inTutorial')
      this.logout()
      this.isLoged = false
      window.location.replace("/login")
    }
  },
  computed: {
    ...mapState("user", {
      user: (m) => m.user
    })

  }
  ,
  watch: {
    isLanguageSelectorOpen(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.onTutorial(); // Chamando a função do tutorial quando o seletor for fechado
      }
    },
    user(n) {
      menuController.enable(!!n, 'main-menu');
      if (n)
        this.fotoUrl = `data:image/jpeg;base64,${n.foto_perfil}`;
    }
  }
};
</script>

<style scoped>
#nome-perfil {
  padding-left: 16%;
  font-size: 26px;
}

.avatar-item {
  --min-height: 100px;
  --background: transparent;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 10px;
}

.avatar-content {
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
}

ion-icon {
  width: 24px !important;
  font-size: 24px !important;
  margin-left: 15px;
}

ion-avatar #foto-perfil {
  width: 40px !important;
}

ion-list #inbox-list {
  background-color: #F6FAFF;
}

#iconeEmpresa {
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: white;
  border-radius: 100px;
  border: 2px solid white;
  top: 56%;
  left: 27%;
  padding: 1px;
  transform: translateX(-50%);
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.799);
}

.selected {
  background-color: var(--ion-color-light);
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list {
  padding: 0 0 0 10px;
}

ion-list {
  padding: 0 0 0 10px;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

/* ion-item {
  --background: #ff0000 !important;
} */

ion-menu.ios ion-item {
  --padding-start: 18px;
  --min-height: 60px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 34px;
  color: #73849a;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-label {
  font-weight: bolder;
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54) !important;
}
</style>