<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <language-selector></language-selector>
          </ion-buttons>
          <ion-title>{{ $t("Companies") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content">
          <div class="content-items">
            <ion-button size="large" @click="criarEmpresa()"> {{ $t("Register") }} {{ $t("Company") }}</ion-button>
            <template v-if="items.length == 0">
              <div class="noData">
                <ion-icon :ios="businessOutline" :md="businessSharp"></ion-icon>
                <p> {{ $t("No_data_Found") }}...</p>
              </div>
            </template>
            <ion-card v-for="(item, index) in items" :key="index" class="cardEmpresas" >
              <ion-card-content class="ion-padding-card">
                <ion-item lines="none" id="avatar-item" class="ion-margin">
                  <div class="avatar-content"  @click="editarEmpresa(index)">
                    <ion-avatar id="avatar-perfil" slot="start"
                      v-if="item.foto !== null && (item.foto == 'data:image/jpeg;base64,null' || item.foto == 'data:image/jpeg;base64,Pw==')">
                      <img src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                    </ion-avatar>
                    <ion-avatar id="avatar-perfil" slot="start" v-else>
                      <img :src="item.foto">
                    </ion-avatar>
                  </div>
                  <div class="info" @click="editarEmpresa(index)">

                    <h2 style="margin: 0px;">{{ item.nome }}</h2>

                    <h2 style="margin: 0px; color: #838383; font-size: 14px;">CNPJ: {{ item.cnpj }}</h2>

                    <h2 style="margin: 0px; color: #838383; font-size: 14px;">Whatsapp: {{ item.whatsapp }}</h2>

                  </div>

                  <ion-icon class="tresPontinhos" :src="ellipsisVerticalSharp" @click="exibirOpcoes(index)"
                    slot="end"></ion-icon>
                </ion-item>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
        <ion-infinite-scroll @ionInfinite="loadMoreData" style="height: 50px; margin-top: 20px;">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
      </ion-content>
    </ion-page>
  </ion-page>
</template>

<script>
import { IonInfiniteScroll,IonInfiniteScrollContent, IonButton, IonTitle, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonAvatar, IonIcon, alertController } from '@ionic/vue';
import {
  ellipsisVerticalSharp,
  businessSharp,
  businessOutline,
} from "ionicons/icons"
import axios from 'axios';
import { config } from "../../config"
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  components: {
    'language-selector' : LanguageSelector , IonInfiniteScroll,IonInfiniteScrollContent, IonButton, IonTitle, IonItem, IonCardContent, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonCard, IonAvatar, IonIcon
  },
  data() {
    return {
      items: [],
      funcionalidades: '',
      selectedItem: null,
      businessSharp,
      businessOutline,
        currentPage: 1, 
        itemsPerPage: 10, 
        isDataFinished: false, 
    };
  },
  mounted() {
    this.fetchUser()
    localStorage.removeItem("foto")
  },
  methods: {
    async loadMoreData(event) {
        setTimeout(async () => {
          if (!this.isDataFinished) {
            this.currentPage++;
            await this.buscarDados();
          }
        }, 1000);

        setTimeout(async () => {
          event.target.complete();
        }, 1000);
      },
    async fetchUser() {
          const userId = localStorage.getItem('id');
          const token = localStorage.getItem('token');
          try {
              const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              
              this.master = response.data.master
              if(!this.master){
                const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
                });
                await alert.present();
                const userId = localStorage.getItem('id');
                window.location.replace(`/users/${userId}/my-account`);
              }
               this.buscarDados();
          } catch (error) {
              console.log(error);
          }
          },
    criarEmpresa() {
      this.$router.push('/users/create-company')
    },
    async editarEmpresa(index) {
      const token = localStorage.getItem('token');
      this.selectedItem = this.items[index];
                const nome = this.selectedItem.nome;
                const whatsapp = this.selectedItem.whatsapp;

                const data = {
                  nome: nome,
                  whatsapp: whatsapp
                };
                const response = await axios.put(`${config.apiUrl}/companies/getCompany`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.idEmpresa = response.data.id;
                this.$router.push(`/companies/${this.idEmpresa}/edit`);
    },
    async exibirOpcoes(index) {
      const token = localStorage.getItem('token');
      const alert = await alertController.create({
        header: this.$t("Options"),
        buttons: [
          {
            text: this.$t("Edit"),
            handler: async () => {
                this.selectedItem = this.items[index];
                const nome = this.selectedItem.nome;
                const whatsapp = this.selectedItem.whatsapp;

                const data = {
                  nome: nome,
                  whatsapp: whatsapp
                };
                const response = await axios.put(`${config.apiUrl}/companies/getCompany`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.idEmpresa = response.data.id;
                this.$router.push(`/companies/${this.idEmpresa}/edit`);
              
            }
          },
          {
            text: this.$t("Delete"),
            role: 'destructive',
            handler: () => {
              this.deleteCompany(index)
            }
          }
        ]
      });
      await alert.present();
    },
    async buscarDados() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios
          .get(`${config.apiUrl}/companies?page=${this.currentPage}&limit=${this.itemsPerPage}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data.items);
        const items = response.data.items.map((item) => {
          return {
            nome: item.nome,
            cnpj: item.cnpj,
            whatsapp: item.whatsapp,
            foto: `data:image/jpeg;base64,${item.foto}`
          };
        });
        this.items = [...this.items, ...items];

            if (items.length < this.itemsPerPage) {
              this.isDataFinished = true;
            }
      } catch (error) {
        console.error("Erro ao buscar nomes ", error);
      }
    },
    async deleteCompany(index) {
      const token = localStorage.getItem('token');
      this.selectedItem = this.items[index];

      const nome = this.selectedItem.nome;
      const whatsapp = this.selectedItem.whatsapp;

      const data = {
        nome: nome,
        whatsapp: whatsapp
      };
      const response = await axios.put(`${config.apiUrl}/companies/getCompany`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      this.idEmpresa = response.data.id;
      const alert = await alertController.create({
        header: this.$t("Confirm_Deletion"),
        message: this.$t("Do_you_really_want_to_delete_the"),
        buttons: [
          {
            text: this.$t("Cancel"),
            role: 'cancel'
          },
          {
            text: this.$t("Yes"),
            role: "destructive",
            handler: async () => {
              await axios.delete(`${config.apiUrl}/companies/${this.idEmpresa}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              const alert = await alertController.create({
                header: this.$t("Deleted"),
                message: this.$t("has_been_successfully_deleted")
              });
              await alert.present();
              window.location.reload();
            }
          }
        ]
      });
      await alert.present();
    }
  },
  setup() {
    return { ellipsisVerticalSharp };
  },
}

</script>


<style scoped>
.tresPontinhos {
  font-size: 20px;
}

.cardEmpresas {
  margin: 0px;
}

.ion-margin {
  margin-left: 0px;
  margin-right: 0px;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.noData ion-icon {
  height: 100px;
  width: 100px;
}

.info {
  height: 70px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.ion-padding-card {
  padding: 0px;
}


#avatar-item {
  --background: transparent;
}

#avatar-item img {
  width: 50px;
  height: 50px;
}

.avatar-content {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
}

.content-items {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.content.ios {
  position: relative;
  transform: translateY(-50%);
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

ion-card-subtitle {
  margin: 0px;
}</style>