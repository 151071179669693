<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/config/infrastructure"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Register") }} {{ $t("Pond") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding" v-if="empresas">
                <div class="content-items">
                    <IonItem fill="outline" v-if="master">
                        <ion-label position="floating">{{ $t("Companies") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            @ionChange="selecionarEmpresa($event)" v-model="lagos.empresa" 
                            >
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="empresa in empresas" :key="empresa.id">
                                    <ion-select-option :value=empresa.id v-model="empresa.id">{{
                                        empresa.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <div class="contentCommercial" v-if="centros">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Production_centers") }} <span style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select" @ionChange="selecionarCentro($event)"
                                    v-model="lagos.centroProducao" >
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="(centro, index) in centros" :key="index">
                                            <ion-select-option :value="centro.id" >{{ centro.nomeLegal }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <div class="contentCommercial" v-if="zonas">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Zones") }} <span style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select"
                                    v-model="lagos.zona"  @ionChange="selecionarZona($event)"  >
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="(zona, index) in zonas" :key="index">
                                            <ion-select-option :value="zona.id">{{ zona.nome }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <div class="contentCommercial" v-if="secoes">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Sections") }} <span style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select"
                                v-model="lagos.secao"  >
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="(secao, index) in secoes" :key="index">
                                            <ion-select-option :value="secao.id">{{ secao.texto }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="lagos.nome" ></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("depht") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="lagos.profundidade"></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">CBM {{ $t("Pond") }} <span style="color: red;">*</span></ion-label>
                        <ion-input  v-model="lagos.lagoaCBM"></ion-input>
                    </ion-item>

                    <ion-button size="large" expand="block" @click="cadastrarCompra">{{ $t("Register") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonSelect, IonSelectOption,  IonList, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"

export default {
    components: {
        IonBackButton, IonSelect, IonSelectOption,  IonList, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            lagos: {
                empresa: '',
                centroProducao: "",
                zona: "",
                secao: "",
                nome:'',
                profundidade:'',
                lagoaCBM:''
            }
            ,
            centros: "",
            zonas: "",
            secoes: "",
            empresas: '',
            funcionalidades:'',
            master:''
        }
    },
    mounted() {
        this.getPermissoes();
    this.fetchUser()
    
                
    },
    methods: {
        async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              this.master = response.data.master
              this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
              if(!this.master){
                this.showCentros(this.idEmpresa)
                this.lagos.empresa = this.idEmpresa
              }
              this.fetchEmpresas()
      } catch (error) {
        console.log(error);
      }
    },
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        async showCentros() {
            const token = localStorage.getItem('token');
            if(!this.master){
                const data = {
                empresa: this.valueEmpresa ? this.valueEmpresa : this.idEmpresa
                    }
                    try {
                        const response = await axios.put(`${config.apiUrl}/getProductionCenterbyCompanyName`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                        const centros = response.data.items.map((centro) => {
                            return {
                                nomeLegal: centro.nomeLegal,
                                id: centro.id
                            };
                        });
                        this.centros = centros;
                        this.lagos.centroProducao = parseInt(localStorage.getItem('idCentro'))
                        if(parseInt(localStorage.getItem('idCentro'))){
                            this.valueCentro = parseInt(localStorage.getItem('idCentro'))
                            this.showZonas();
                        }
                    } catch (error) {
                        console.log(error);
                    }
            }else{
                const id = localStorage.getItem('idEmpresaCentro')
                const data = {
                empresa: this.valueEmpresa ? this.valueEmpresa: id
                    }
                    try {
                        const response = await axios.put(`${config.apiUrl}/getProductionCenterbyCompanyName`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                        const centros = response.data.items.map((centro) => {
                            return {
                                nomeLegal: centro.nomeLegal,
                                id: centro.id
                            };
                        });
                        this.centros = centros;
                        this.lagos.centroProducao = parseInt(localStorage.getItem('idCentro'))
                        if(parseInt(localStorage.getItem('idCentro'))){
                            this.valueCentro = parseInt(localStorage.getItem('idCentro'))
                            this.showZonas();
                        }
                    } catch (error) {
                        console.log(error);
                    }
            }
            
        },
        async showZonas() {
            const token = localStorage.getItem('token');
            const data = {
                idCentro: this.valueCentro
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getZonebyProductionCenter`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                const zonas = response.data.items.map((zona) => {
                    return {
                        nome: zona.nome,
                        id: zona.id
                    };
                });
                this.zonas = zonas;
                this.lagos.zona = parseInt(localStorage.getItem('idZona'))
                if(parseInt(localStorage.getItem('idZona'))){
                    this.valueZona = parseInt(localStorage.getItem('idZona'))
                    this.showSecoes();
                }
            } catch (error) {
                console.log(error);
            }
        },
        async showSecoes() {
            const token = localStorage.getItem('token');
            const data = {
                idZona: this.valueZona
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getSecaoByZone`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                const secoes = response.data.items.map((secao) => {
                    return {
                        texto: secao.texto,
                        id: secao.id
                    };
                });
                this.secoes = secoes;
                this.lagos.secao = parseInt(localStorage.getItem('idSecao'))
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEmpresa($event) {
            this.valueEmpresa = $event.target.value
            this.showCentros(this.valueEmpresa);
            this.lagos.centroProducao = ''
        },
        selecionarCentro($event) {
            this.valueCentro = $event.target.value
            this.showZonas(this.valueCentro);
            this.lagos.zona = ''
        },
        selecionarZona($event) {
            this.valueZona = $event.target.value
            this.showSecoes(this.valueZona);
            this.lagos.secao = ''
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
                const hasPermissoes = this.master
                
                if (!hasPermissoes) {
                this.empresas = this.empresas.filter(empresa => empresa.id == this.idEmpresa);
                }

                if(hasPermissoes){
                    const id = localStorage.getItem('idEmpresaCentro')
                    this.lagos.empresa = parseInt(id)
                    this.showCentros(parseInt(id))
                }
            } catch (error) {
                console.log(error);
            }
            },
        async cadastrarCompra() {

            if (!this.lagos.empresa) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Company"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.centroProducao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Production_Center"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.zona) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Zone"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.secao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Section"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.profundidade) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("depht"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.lagoaCBM) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+" CBM ",
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            const lagos = {
                lagos: {
                    empresa: this.lagos.empresa,
                    centroProducao:this.lagos.centroProducao,
                    zona: this.lagos.zona,
                    secao:this.lagos.secao,
                    nome:this.lagos.nome,
                    profundidade:this.lagos.profundidade,
                    lagoaCBM:this.lagos.lagoaCBM,
                    id_secao: this.lagos.secao
                }
            };
            const token = localStorage.getItem('token');
            await axios.post(`${config.apiUrl}/infrastructures/lago/create`, lagos, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header:this.$t("successfully_registered!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                        window.location.replace("/config/infrastructure")
                    });
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header:  this.$t("Error_registering"),
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });
        }

    }
};
</script>
    
<style scoped>

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

</style>