<template>
  <ion-page>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button default-href="/financial/shopping"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ $t("Register") }} {{ $t("Purchase") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content-items">
          <IonItem fill="outline" v-if="this.master">
            <ion-label position="floating">{{ $t("Company") }} <span style="color: red;">*</span></ion-label>
            <ion-select position="floating" aria-label="Fruit" class="select" v-model="empresaSelecionada">
              <ion-content class="ion-padding" id="content-lista">
                <ion-list v-for="item in empresas" :key="item.id">
                  <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                </ion-list>
              </ion-content>
            </ion-select>
          </IonItem>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Invoice/Delivery_Note_No.") }} <span
                style="color: red;">*</span></ion-label>
            <ion-input id="invoiceInput" v-model="compra.fatura"></ion-input>
          </ion-item>
          <ion-item fill="outline">
            <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date_of_arrival") }} <span
                style="color: red;">*</span></ion-label>
            <ion-input id="dateInput" v-model="compra.data" type="date"></ion-input>
          </ion-item>
          <IonItem fill="outline">
            <ion-label position="floating">{{ $t("Provider_Name") }} <span style="color: red;">*</span></ion-label>
            <ion-select position="floating" aria-label="Fruit" class="select" v-model="compra.provedor"
              @ionChange="selecionarFabricante($event)">
              <ion-content class="ion-padding" id="content-lista">
                <ion-list v-for="item in fabricantes" :key="item.id">
                  <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                </ion-list>
                <ion-select-option value="novoM">{{ $t("New") }}</ion-select-option>
              </ion-content>
            </ion-select>
          </IonItem>
          <ion-item fill="outline" v-if="compra.provedor == 'novoM'">
            <ion-label position="floating">{{ $t("Register") }} {{ $t("Provider_Name") }} </ion-label>
            <ion-input v-model="nomeProvedor"></ion-input>
          </ion-item>
          <div class="products">
            <ion-label slot="end">{{ $t("Products") }}</ion-label>
          </div>

          <ion-accordion-group :value="expandedAccordion" class="produto">
            <ion-accordion :value="index" v-for="(produto, index) of compra.produtos" :key="index"
              toggle-icon-slot="start">
              <ion-item slot="header" color="light">
                <ion-label>{{ $t("Product") }} {{ index + 1 }}</ion-label>
                <div class="iconDelete" style="width: 30px;display: flex; align-items: center;">
                  <ion-icon @click="removeProduto(compra, index)" :ios="closeOutline" :md="closeSharp"
                    style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                </div>
              </ion-item>
              <ion-card slot="content" style="margin: 0px; padding: 0px; box-shadow: none;">
                <IonItem fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("Product_Type") }} <span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto.tipoProduto"
                    @ionChange="selecionarTipoProduto($event, index)">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list>
                        <ion-select-option value="Fry">{{ $t("Fry") }}</ion-select-option>
                        <ion-select-option value="Feed">{{ $t("Feed") }}</ion-select-option>
                        <ion-select-option value="Fuel">{{ $t("Fuel") }}</ion-select-option>
                        <ion-select-option value="Oxygen">{{ $t("Oxygen") }}</ion-select-option>
                        <ion-select-option value="Services">{{ $t("Services") }}</ion-select-option>
                        <ion-select-option value="Others">{{ $t("Others") }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <IonItem fill="outline" class="ion-margin"
                  v-if="produtos && produto.tipoProduto != '' && produto.tipoProduto != 'Services' && produto.tipoProduto != 'Oxygen' && produto.tipoProduto != 'Others'">
                  <ion-label position="floating">{{ $t("Product") }}<span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto.produto"
                    @ionChange="selecionarProduto($event, index)">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="item in produto.produtoLista" :key="item.id">
                        <ion-select-option :value="item.id">{{ item.nome }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <IonItem fill="outline" class="ion-margin" v-if="produto.feedNames && produto.tipoProduto == 'Feed'">
                  <ion-label position="floating">{{ $t("Feed") }}<span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto.nomeAlimento">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="item in produto.feedNames" :key="item.id">
                        <ion-select-option :value="item">{{ item }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <ion-item fill="outline" class="ion-margin"
                  v-if="produto.tipoProduto == 'Services' || produto.tipoProduto == 'Oxygen' || produto.tipoProduto == 'Others'">
                  <ion-label position="floating">{{ $t("Register") }} {{ $t("Product") }}</ion-label>
                  <ion-input v-model="produto.nomeProduto"></ion-input>
                </ion-item>
                <IonItem fill="outline" class="ion-margin"
                  v-if="produto.tipoProduto !== 'Services' && produto.tipoProduto !== 'Fry'">
                  <ion-label position="floating">{{ $t("Warehouse") }} <span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto.armazem"
                   >
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="item in armazens" :key="item.id">
                        <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <IonItem fill="outline" class="lagoList ion-margin" v-if="produto.tipoProduto == 'Fry'">
                  <ion-label position="floating">{{ $t("Pond") }} <span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" :disabled="disableButton" v-model="produto.lago">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="lago in lagos" :key="lago.id">
                        <ion-select-option :value=lago.id v-model="lago.id" class="selectLago">{{ lago.nome
                        }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem>
                <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("Price_per_unit") }} <span style="color: red;">*</span></ion-label>
                  <ion-input id="priceInput" v-model="produto.preco" type="number"
                    @ionChange="calcularTotal(produto)"></ion-input>
                </ion-item>
                <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">Kg {{ $t("or") }} {{ $t("Amount") }} <span
                      style="color: red;">*</span></ion-label>
                  <ion-input id="kgInput" v-model="produto.kg_ou_qntd" type="number"
                    @ionChange="calcularTotal(produto)"></ion-input>
                </ion-item>
                <!-- <IonItem fill="outline" class="ion-margin" v-if="destinos">
                  <ion-label position="floating">{{ $t("destiny") }} <span style="color: red;">*</span></ion-label>
                  <ion-select position="floating" aria-label="Fruit" class="select" v-model="produto.destino"
                    @ionChange="selecionarDestino($event)">
                    <ion-content class="ion-padding" id="content-lista">
                      <ion-list v-for="item in destinos" :key="item.id">
                        <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                      </ion-list>
                    </ion-content>
                  </ion-select>
                </IonItem> -->
                <!-- <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("Register") }} {{ $t("destiny") }}</ion-label>
                  <ion-input id="productInput" v-model="produto.nomeDestino"></ion-input>
                </ion-item> -->
                <ion-item fill="outline" class="ion-margin" v-if="produto.tipoProduto !== 'Fry'">
                  <ion-label position="stacked" style="font-size: 18px;">{{ $t("Expiration") }}</ion-label>
                  <ion-input id="priceInput" v-model="produto.expiracao" type="date"></ion-input>
                </ion-item>
                <IonItem fill="outline" class="ion-margin" v-if="lotes && ['Services', 'Fuel', 'Oxygen'].indexOf(produto.tipoProduto) == -1">
                  <ion-label position="floating">{{ $t("batch") }} <span style="color: red;">*</span></ion-label>
                  <ion-input v-model="produto.lote"></ion-input>
                </IonItem>
                <IonItem fill="outline" class="ion-margin" v-if="lotes && ['Fuel', 'Oxygen'].indexOf(produto.tipoProduto) != -1">
                  <ion-label position="floating">{{ $t("transport_guide") }} </ion-label>
                  <ion-input v-model="produto.guia_transporte"></ion-input>
                </IonItem>
                <ion-item fill="outline" class="ion-margin">
                  <ion-label position="floating">{{ $t("total") }}</ion-label>
                  <ion-input id="kgInput" v-model="produto.total" readonly></ion-input>
                </ion-item>
                <ion-item fill="outline" lines="none" class="ion-margin" v-if="produto.tipoProduto == 'Services'">
                  <div class="rateioDiv">
                    <ion-label class="rateioDivLabel">{{ $t("Type_of_apportionment") }}</ion-label>
                    <ion-radio-group class="rateioRadioDiv" v-model="produto.tipoRateio">
                      <ion-item lines="none" class="radioRateio">
                        <ion-radio value="By biomass"></ion-radio>
                        <ion-label class="radioRateioLabel">{{ $t("By_biomass") }}</ion-label>
                      </ion-item>
                      <ion-item lines="none" class="radioRateio">
                        <ion-radio value="Per tank"></ion-radio>
                        <ion-label class="radioRateioLabel">{{ $t("Per_tank") }}</ion-label>
                      </ion-item>
                    </ion-radio-group>
                  </div>
                </ion-item>
                <ion-item fill="outline" lines="none" v-if="produto.tipoProduto == 'Fry'" class="ion-margin">
                  <ion-label>{{ $t("Vaccinated_species") }} </ion-label>
                  <ion-checkbox v-model="produto.comVacina" justify="end"></ion-checkbox>
                </ion-item>

                <ion-item fill="outline" class="ion-margin" v-if="produto.comVacina && produto.tipoProduto == 'Fry'">
                  <h6 v-if="produto.tags.length == 0" class="ion-padding">{{ $t('VaccinesPress') }}</h6>
                  <ion-input slot="start" v-model="produto.newTag" @keydown.enter.prevent="addTag(index)"
                    :placeholder="$t('Vaccines')"></ion-input>
                  <ion-chip-group v-model="produto.tags">
                    <ion-chip v-for="(tag, indexT) in produto.tags" :key="indexT">
                      <ion-label>{{ tag }}</ion-label>
                      <ion-icon :ios="closeCircleOutline" :md="closeCircleSharp"
                        @click="removeTag(indexT, index)"></ion-icon>
                    </ion-chip>
                  </ion-chip-group>

                </ion-item>
                <ion-item fill="outline" lines="none" v-if="produto.tipoProduto == 'Feed'" class="ion-margin">
                  <ion-label>{{ $t("Feed_with_medicine") }} <span style="color: red;">*</span></ion-label>
                  <ion-checkbox v-model="produto.comMedicamento" justify="end"></ion-checkbox>
                </ion-item>
                <ion-item fill="outline" lines="none" class="ion-margin" v-if="produto.comMedicamento">
                  <ion-input id="arquivoInput" type="file" @input="onFileSelected($event, index)" accept="*"
                    aria-label="Choose file"></ion-input>
                </ion-item>
              </ion-card>

            </ion-accordion>

          </ion-accordion-group>
          <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
            <ion-icon @click="addProduto(compra)" :ios="addOutline" :md="addSharp"
              style="font-size: 8px; width: 30px !important; margin-right: 15px; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
          </div>
          <ion-item fill="outline">
            <ion-label position="floating">{{ $t("Comments") }}</ion-label>
            <ion-input v-model="compra.comentarios"></ion-input>
          </ion-item>
          <ion-button size="large" expand="block" @click="cadastrarCompra">{{ $t("Register") }}</ion-button>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>
    
<script>
import { IonChipGroup, IonChip, IonCheckbox, IonRadioGroup, IonRadio, IonAccordionGroup, IonAccordion, IonBackButton, IonSelect, IonCard, IonSelectOption, IonIcon, IonList, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { addOutline, addSharp, closeOutline, closeSharp, closeCircleOutline, closeCircleSharp } from 'ionicons/icons';

export default {
  components: {
    IonChipGroup, IonChip, IonCheckbox, IonRadioGroup, IonRadio, IonAccordionGroup, IonAccordion, IonBackButton, IonSelect, IonCard, IonSelectOption, IonIcon, IonList, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
  },
  data() {
    return {
      expandedAccordion: 0,
      compra: {
        fatura: '',
        data: '',
        provedor: '',
        comentarios: '',
        empresa: '',
        produtos: [{
          produto: '',
          preco: '',
          kg_ou_qntd: '',
          destino: '',
          expiracao: '',
          lote: '',
          total: '',
          armazem: '',
          tipoProduto: '',
          nomeAlimento: '',
          produtoLista: '',
          feedNames: '',
          tipoRateio: '',
          comMedicamento: false,
          zip: '',
          lago: '',
          comVacina: false,
          tags: [],
          newTag: ''
        }
        ],
      }
      ,
      addOutline,
      addSharp,
      closeOutline,
      closeSharp,
      destinos: '',
      lotes: '',
      lagos: '',
      produtos: '',
      empresas: '',
      empresaSelecionada: '',
      funcionalidades: '',
      master: '',
      armazens: '',
      valueTipoProduto: '',
      especies: '',
      alimentos: '',
      combustiveis: '',
      produtoLista: '',
      fabricantes: "",
      nomeProvedor: '',
      closeCircleOutline,
      closeCircleSharp,
      feedNames: ''
    }
  },
  mounted() {
    this.getPermissoes()
    this.fetchDestinos()
    this.fetchProdutos()
    this.fetchEspecies()
    this.fetchAlimentos()
    this.fetchCombustiveis()
    this.fetchLotes()
    this.fetchEmpresas()
    this.fetchFabricantes()
    this.fetchUser()
    this.buscarArmazens()
  },
  methods: {
    async fetchLagos(idEmpresa) {
      const token = localStorage.getItem('token');
      const data = {
        idEmpresa: idEmpresa
      }
      try {
        const response = await axios.put(`${config.apiUrl}/getLagosByEmpresa`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const lagos = await Promise.all(response.data.items.map(async (lago) => {
          const centroResponse = await axios.get(`${config.apiUrl}/users/productionCenter/${lago.centroProducao}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          console.log(centroResponse)
          const centroProducaoNome = centroResponse.data.centro.nomeLegal;

          const nomeLago = `${centroProducaoNome} - ${lago.nome}`;

          return {
            nome: nomeLago,
            id: lago.id
          };
        }));

        this.lagos = lagos;
        console.log(this.lagos)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchFabricantes() {
      const token = localStorage.getItem('token');
      const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);
      try {
        const response = await axios.get(`${config.apiUrl}/configs/manufacturers`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.data = response.data.items;
        this.fabricantes = this.data.filter(item => item.empresa == idEmpresa);
      } catch (error) {
        console.log(error);
      }
    },
    onFileSelected($event, produto) {
      const file = $event.target.files[0];
      this.compra.produtos[produto].zip = file;
    },
    async buscarArmazens() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/configs/warehouse?search=${this.termoPesquisa}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const empresaId = parseInt(localStorage.getItem('idEmpresa'), 10);

        const items = response.data.items.filter((item) => {
          const empresa = parseInt(item.empresa, 10);
          return empresa == empresaId;
        }).map((item) => {
          return {
            nome: item.nome,
            id: item.id
          };
        });

        this.armazens = items;
      } catch (error) {
        return console.error("Erro ao buscar nomes ", error);
      }
    },

    async getPermissoes() {
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
      await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {

          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if (!funcionalidades.includes(26)) {
            const alert = await alertController.create({
              header: "Error",
              message: this.$t("You_haven't_access")
            });
            await alert.present();
            const userId = localStorage.getItem('id');
            window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    async fetchEmpresas() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresas = response.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    addTag(produto) {
      if (this.compra.produtos[produto].newTag.trim() !== '') {
        this.compra.produtos[produto].tags.push(this.compra.produtos[produto].newTag.trim());
        this.compra.produtos[produto].newTag = '';
      }
    },
    removeTag(index, produto) {
      this.compra.produtos[produto].tags.splice(index, 1);
    },
    async fetchUser() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('id');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.empresa = parseInt(localStorage.getItem('idEmpresa'));
        this.master = response.data.master
        this.fetchLagos(this.empresa)
      } catch (error) {
        console.log(error);
      }
    },
    calcularTotal(produto) {
      produto.total = produto.preco * produto.kg_ou_qntd;
    },
    addProduto(compra) {
      compra.produtos.push({
        tipoProduto: '',
        produto: '',
        preco: '',
        kg_ou_qntd: '',
        expiracao: '',
        lote: '',
        total: '',
        tags: []
      })
      this.expandedAccordion = [compra.produtos.length - 1];
    },
    async removeProduto(compra, index) {
      if (compra.produtos.length > 1) {
        const produto = this.compra.produtos[index];
        if (produto.tipoProduto || produto.produto || produto.nomeProduto || produto.preco || produto.kg_ou_qntd || produto.preco || produto.lote || produto.total || produto.expiracao) {
          const alert = await alertController.create({
            header: this.$t("Confirm_Deletion"),
            message: this.$t("Do_you_really_want_to_delete_the"),
            buttons: [
              {
                text: this.$t("Cancel"),
                role: 'cancel'
              },
              {
                text: this.$t("Yes"),
                role: "destructive",
                handler: async () => {
                  compra.produtos = compra.produtos.filter((p, i) => i !== index)
                  this.expandedAccordion = [compra.produtos.length - 1];
                }
              }
            ]
          });
          await alert.present();
        } else {
          compra.produtos = compra.produtos.filter((p, i) => i !== index)
        }
      } else {
        const alert = await alertController.create({
          header: this.$t("Invalid_action"),
          message: this.$t("You_can_not_delete_all"),
          buttons: ['OK']
        });
        await alert.present();
      }
      this.expandedAccordion = [compra.produtos.length - 1];
    },
    atualizarListaProdutos(produto, index) {
      if (produto == 'Fry') {
        this.compra.produtos[index].produtoLista = this.especies;
      } else if (produto == 'Feed') {
        this.compra.produtos[index].produtoLista = this.alimentos;
      } else if (produto == 'Fuel') {
        this.compra.produtos[index].produtoLista = this.combustiveis;
      } else {
        this.compra.produtos[index].produtoLista = [];
      }
    },
    //Possíveis produtos:
    async fetchProdutos() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/produtos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.produtos = response.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchEspecies() {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'));
      try {
        const response = await axios.get(`${config.apiUrl}/configs/species?empresaId=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.especies = response.data.items;
        this.especies = this.especies.filter(item => item.empresa == empresaId);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAlimentos() {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'));
      try {
        const response = await axios.get(`${config.apiUrl}/configs/normalFeeds`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const items = response.data.items.map((item) => {
          return {
            nome: item.tipoAlimento,
            id: item.id,
            fabricante: item.fabricante,
            especie: item.especie,
            empresa: item.empresa
          };
        });
        this.items = items
        this.alimentos = this.items.filter(item => item.empresa == empresaId);
      } catch (error) {
        console.error("Erro ao buscar nomes ", error);
      }
    },
    async fetchCombustiveis() {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'));
      try {
        const response = await axios.get(`${config.apiUrl}/combustiveis`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const allCombustiveis = response.data.items.map((item) => {
          return {
            nome: item.nomeComercial,
            id: item.id,
            empresa: item.empresa
          };
        });
        this.items = allCombustiveis

        this.combustiveis = this.items.filter(item => item.empresa == empresaId);
      } catch (error) {
        console.log(error);
      }
    },
    // --------------------

    async fetchDestinos() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/destinos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.destinos = response.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchLotes() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/configs/batches`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.lotes = response.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchNomesTabela(id, index) {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'));
      try {
        const response = await axios.get(`${config.apiUrl}/getFeedNamesByCompanyId?alimento=${id}&empresa=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.compra.produtos[index].feedNames = response.data.feedNames

      } catch (error) {
        console.log(error);
      }
    },
    selecionarEspecie(event) {
      this.valueEspecie = event.target.value
    },
    selecionarFabricante(event) {
      this.valueFabricante = event.target.value
    },
    selecionarProduto(event, index) {
      this.valueProduto = event.target.value
      const idProdutoCompra = index
      const produtoEncontrado = this.alimentos.find(item => item.id == this.valueProduto);

      if (produtoEncontrado) {
        const idDoProduto = produtoEncontrado.id;
        this.fetchNomesTabela(idDoProduto, idProdutoCompra);
      }
    },
    selecionarTipoProduto(event, index) {
      const valorProduto = event.target.value
      this.atualizarListaProdutos(valorProduto, index)
    },
    selecionarDestino(event) {
      this.valueDestino = event.target.value
    },
    selecionarLote(event) {
      this.valueLote = event.target.value
    },
    async cadastrarCompra() {

      if (!this.compra.fatura) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Invoice/Delivery_Note_No."),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.compra.data) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Date"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      if (!this.compra.provedor && !this.nomeProvedor) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Provider"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      for (let i = 0; i < this.compra.produtos.length; i++) {
        const produto = this.compra.produtos[i];
        console.log(produto.zip)
        if (!produto.tipoProduto || (!produto.produto && !produto.nomeProduto) || !produto.preco || !produto.kg_ou_qntd || !produto.preco || (!produto.lote && !produto.tipoProduto == 'Services') || !produto.total || (!produto.armazem && (!produto.tipoProduto == 'Services' || !produto.tipoProduto == 'Fry')) || (!produto.tipoRateio && produto.tipoProduto == 'Services') || (produto.comMedicamento && !produto.zip) || (produto.comVacina && produto.tags.length == 0)) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Product"),
            buttons: ['OK']
          });
          await alert.present();
          return false;
        }
        if (produto.tipoProduto != 'Feed') {
          produto.nomeAlimento = ''
        } else {
          if (produto.nomeAlimento == '') {
            const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Product"),
              buttons: ['OK']
            });
            await alert.present();
            return false;
          }
        }
      }

      // if (!this.compra.comentarios) {
      //   const alert = await alertController.create({
      //     header: this.$t("Error"),
      //     message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Comments"),
      //     buttons: ['OK']
      //   });
      //   await alert.present();
      //   return;
      // }

      for (let i = 0; i < this.compra.produtos.length; i++) {
        for (let j = i + 1; j < this.compra.produtos.length; j++) {
          const produto1 = this.compra.produtos[i];
          const produto2 = this.compra.produtos[j];
          if (produto1.nomeProduto && produto1.nomeProduto == produto2.nomeProduto) {
            const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("The_following_field_cannot_be_inserted") + " " + this.$t("Product"),
              buttons: ['OK']
            });
            await alert.present();
            return;
          }
        }
      }

      if (this.produtos.some(produto => produto.nome == this.compra.produtos[0].nomeProduto ||
        this.compra.produtos.map(p => p.nomeProduto).includes(produto.nome))) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("The_following_field_cannot_be_inserted") + " " + this.$t("Product"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      // for (let i = 0; i < this.compra.produtos.length; i++) {
      //   for (let j = i + 1; j < this.compra.produtos.length; j++) {
      //     const destino1 = this.compra.produtos[i];
      //     const destino2 = this.compra.produtos[j];
      //     if (destino1.nomeDestino && destino1.nomeDestino == destino2.nomeDestino) {
      //       const alert = await alertController.create({
      //         header: this.$t("Error"),
      //         message: this.$t("The_following_field_cannot_be_inserted") + " " + this.$t("destiny"),
      //         buttons: ['OK']
      //       });
      //       await alert.present();
      //       return;
      //     }
      //   }
      // }

      // if (this.destinos.some(destino => destino.nome == this.compra.produtos[0].nomeDestino ||
      //   this.compra.produtos.map(p => p.nomeDestino).includes(destino.nome))) {
      //   const alert = await alertController.create({
      //     header: this.$t("Error"),
      //     message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("destiny"),
      //     buttons: ['OK']
      //   });
      //   await alert.present();
      //   return;
      // }

      // for (let i = 0; i < this.compra.produtos.length; i++) {
      //   for (let j = i + 1; j < this.compra.produtos.length; j++) {
      //     const lote1 = this.compra.produtos[i];
      //     const lote2 = this.compra.produtos[j];
      //     if (lote1.nomeLote && lote1.nomeLote == lote2.nomeLote) {
      //       const alert = await alertController.create({
      //         header: this.$t("Error"),
      //         message: this.$t("The_following_field_cannot_be_inserted") + " " + this.$t("batch"),
      //         buttons: ['OK']
      //       });
      //       await alert.present();
      //       return;
      //     }
      //   }
      // }

      // if (this.lotes.some(lote => lote.nome == this.compra.produtos[0].nomeLote ||
      //   this.compra.produtos.map(p => p.nomeLote).includes(lote.nome))) {
      //   const alert = await alertController.create({
      //     header: this.$t("Error"),
      //     message: this.$t("The_following_field_cannot_be_inserted") + " " + this.$t("batch"),
      //     buttons: ['OK']
      //   });
      //   await alert.present();
      //   return;
      // }

      if (this.nomeProvedor !== "") {

        const idEmpresa = localStorage.getItem('idEmpresa')
        const id = null
        const data2 = {
          nome: this.nomeProvedor,
          empresa: idEmpresa
        }
        const token = localStorage.getItem('token');
        axios.post(`${config.apiUrl}/configs/manufacturers/createEdit/${id}`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            console.log(response.data.id);

          })
          .catch(error => {
            console.log(error);
          });
      }

      if (this.compra.provedor == 'nomeM') {
        this.compra.provedor = ''
      }

      if (!this.master) {
        this.empresaSelecionada = ''
      }

      const compras = new FormData();

      const compraData = {
        fatura: this.compra.fatura,
        data: this.compra.data,
        provedor: this.nomeProvedor ? this.nomeProvedor : this.compra.provedor,
        comentarios: this.compra.comentarios,
        empresa: this.empresaSelecionada ? this.empresaSelecionada : this.empresa,
      };

      for (const [key, value] of Object.entries(compraData)) {
        compras.append(key, value);
      }

      this.compra.produtos.forEach((produto, index) => {
        const produtoData = {
          tipoProduto: produto.tipoProduto,
          produto: produto.nomeProduto ? produto.nomeProduto : produto.produto,
          preco: produto.preco,
          empresa: this.empresaSelecionada ? this.empresaSelecionada : this.empresa,
          kg_ou_qntd: produto.kg_ou_qntd,
          destino: produto.nomeDestino ? produto.nomeDestino : produto.destino,
          expiracao: produto.expiracao,
          lote: produto.lote,
          total: produto.total,
          tipoRateio: produto.tipoRateio,
          armazem: produto.armazem,
          nomeAlimento: produto.nomeAlimento,
          comMedicamento: produto.comMedicamento,
          lago: produto.lago ? produto.lago : 0,
          comVacina: produto.comVacina,
          tags: produto.tags.join(','),
          guia_transporte: produto.guia_transporte
        };

        for (const [key, value] of Object.entries(produtoData)) {
          const fieldName = `produtos[${index}][${key}]`;
          compras.append(fieldName, value);
        }

        if (produto.zip) {
          compras.append(`produtos[${index}][zip]`, produto.zip);
        }
      });
      
      const token = localStorage.getItem('token');

      await axios.post(`${config.apiUrl}/financial/shopping/create`, compras, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          console.log(response.data.id);

          for (const produto of this.compra.produtos) {
            if (produto && produto.nomeProduto && produto.nomeProduto !== "" && produto.nomeProduto.trim() !== "") {
              const data2 = {
                nome: produto.nomeProduto,
              };
              axios.post(`${config.apiUrl}/produtos/create`, data2, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              })
                .then(response => {
                  console.log(response.data.id);
                })
                .catch(error => {
                  console.log(error);
                });
            }
          }

          for (const produto of this.compra.produtos) {
            if (produto && produto.nomeDestino && produto.nomeDestino !== "" && produto.nomeDestino.trim() !== "") {
              const data2 = {
                nome: produto.nomeDestino,
              }
              axios.post(`${config.apiUrl}/destinos/create`, data2, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              })
                .then(response => {
                  console.log(response.data.id);
                })
                .catch(error => {
                  console.log(error);
                });
            }
          }

          for (const produto of this.compra.produtos) {
            const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);

            if (produto && produto.lote) {

              const tipoProdutoMap = {
                'Feed': 2,
                'Fry': 1,
              };

              const tipo = tipoProdutoMap[produto.tipoProduto] || 0;

              const lote = {
                nome: produto.lote,
                tipo: tipo,
                empresa: idEmpresa,
              }

              if (tipo == 2 && produto.nomeAlimento) {
                lote.alimento = produto.nomeAlimento;
              } else if (tipo == 1 && produto.produto && produto.lago) {
                lote.especie = produto.produto;
                lote.lago = produto.lago;
              }

              axios.post(`${config.apiUrl}/configs/batches/createEdit/${null}`, lote, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              })
                .then(response => {
                  console.log(response.data.id);
                })
                .catch(error => {
                  console.log(error);
                });
            }
          }

          const alert = alertController.create({
            header: this.$t("successfully_registered!")
          });
          alert.then(_alert => {
            _alert.present();

            window.location.replace("/financial/shopping")
          });
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_registering"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });
    }

  }
};
</script>
    
<style scoped>
.botoes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.addProduct {
  width: 49.5%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  background: #0049b1;
  color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.addProduct h2 {
  margin: 0px;
  font-size: 16px;
}

.plusIcon {
  font-size: 35px;
  color: white;

}

.rateioDivLabel {
  margin-top: 10px;
  --padding-inline: 0px;
}

.radioRateio::part(native) {
  --padding-start: 0px;
}

.radioRateioLabel {
  padding-left: 5px;
}

.rateioDiv {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

}

.rateioRadioDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.products {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  max-height: max-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
</style>