<template>
  <ion-page>
    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button default-href="/config/infrastructure"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ $t("Daily_records") }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <div class="content-items">
          <ion-list-header class="campoTitle">{{ $t("General_settings") }}</ion-list-header>
          <ion-item fill="outline">
            <ion-label position="stacked" style="font-size: 18px;">{{ $t("Date") }} <span
                style="color: red;">*</span></ion-label>
            <ion-input v-model="diario.data" type="date" @input="onDataChange"></ion-input>
          </ion-item>
          <ion-item fill="outline" lines="full" v-if="diario.data">
            <div class="tempsDiv">
              <div>
                <ion-label position="floating">{{ $t("Temperature") }}</ion-label>
                <ion-input v-model="diario.temperature1" @input="diario.temperature1 = formatC($event.target.value)"
                  :placeholder="$t('From')"></ion-input>
              </div>
              <div>
                <ion-label position="floating">{{ $t("Temperature") }}</ion-label>
                <ion-input v-model="diario.temperature2" @input="diario.temperature2 = formatC($event.target.value)"
                  :placeholder="$t('To')"></ion-input>
              </div>
            </div>
          </ion-item>
          <ion-item fill="outline" v-if="diario.data">
            <ion-label position="floating">{{ $t("Salinity") }}</ion-label>
            <ion-input v-model="diario.salinidade"
              @input="diario.salinidade = formatPpt($event.target.value)"></ion-input>
          </ion-item>
          <ion-list-header class="campoTitle" v-if="diario.data">{{ $t("Settings_per_pond") }}</ion-list-header>
          <div v-if="diario.data">
            <div class="lagos" v-for="(lago, index) in diario.lagos" :key="lago.id">

              <ion-card class="pondCard">
                <ion-card-header>
                  <ion-card-title>
                    {{ $t("Pond") }}: {{ lago.lago }}
                  </ion-card-title>
                  <ion-item fill="outline" v-model="lago.id" hidden="true">
                    <ion-label position="floating">{{ $t("Pond") }}</ion-label>
                    <ion-input class="naoEdita" v-model="lago.lago" readonly></ion-input>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                  <ion-item fill="outline" class="loteSelect">
                    <ion-label position="floating">{{ $t("Instant_death") }}</ion-label>
                    <ion-input v-model="lago.morteInstantanea"
                      ></ion-input>
                  </ion-item>
                  <IonItem fill="outline" class="loteSelect">
                    <ion-label position="floating">{{ $t("Feed") }} <span style="color: red;">*</span></ion-label>
                    <ion-select position="floating" aria-label="Fruit" class="select" v-model="lago.alimento"
                      @ionChange="updateFeed(index, $event)">
                      <ion-content class="ion-padding" id="content-lista">
                        <ion-list v-for="item in alimentos" :key="item.id">
                          <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                        </ion-list>
                      </ion-content>
                    </ion-select>
                  </IonItem>
                  <IonItem fill="outline" class="loteSelect" v-if="lago.alimento && lago.feedNames">
                    <ion-label position="floating">{{ $t("Feed_name") }} <span style="color: red;">*</span></ion-label>
                    <ion-select position="floating" aria-label="Fruit" class="select" v-model="lago.nomeAlimento"
                      @ionChange="updateLote(index, $event)">
                      <ion-content class="ion-padding" id="content-lista">
                        <ion-list v-for="(item, index) in lago.feedNames" :key="index">
                          <ion-select-option :value=item>{{ item }}</ion-select-option>
                        </ion-list>
                      </ion-content>
                    </ion-select>
                  </IonItem>
                  <IonItem fill="outline" class="loteSelect" v-if="lago.nomeAlimento && lago.lotes">
                    <ion-label position="floating">{{ $t("Batch") }} <span style="color: red;">*</span></ion-label>
                    <ion-select position="floating" aria-label="Fruit" class="select" v-model="lago.lote"
                      @ionChange="selecionarLote($event)">
                      <ion-content class="ion-padding" id="content-lista">
                        <ion-list v-for="item in lago.lotes" :key="item.id">
                          <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                        </ion-list>
                      </ion-content>
                    </ion-select>
                  </IonItem>
                  <div class="lagoQuantidade" style="width: 100%; display: flex; gap: 10px"
                    v-if="motivos && lago.nomeAlimento">
                    <div class="quantidade" style="width: 100%;">
                      <ion-item fill="outline" v-if="!lago.comJejum">
                        <ion-label position="floating">{{ $t("Amount") }} <span v-if="!lago.encontrado"
                            style="color: red;">*</span></ion-label>
                        <ion-input v-model="lago.quantidade"
                          @input="lago.quantidade = formatKg($event.target.value)"></ion-input>
                        <ion-icon slot="end" :ios="closeOutline" :md="closeSharp" @click="clearInput(lago)"></ion-icon>
                      </ion-item>
                      <ion-item v-if="lago.comJejum" fill="outline">
                        <ion-label position="floating">{{ $t("Fasting_explanation") }}</ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="lago.jejum">
                          <ion-content class="ion-padding" id="content-lista">
                            <ion-list v-for="item in motivos" :key="item.id">
                              <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                            </ion-list>
                          </ion-content>
                        </ion-select>
                      </ion-item>
                    </div>
                  </div>
                  <ion-item fill="outline" v-if="lago.nomeAlimento" lines="none" class="checkItem">
                    <ion-label>{{ $t("Food_Fast") }}</ion-label> 
                    <ion-checkbox @ionChange="zerarFeed(lago)" v-model="lago.comJejum" justify="end"></ion-checkbox>
                  </ion-item>
                </ion-card-content>

              </ion-card>

              <div class="lagosDivisao" v-if="index < diario.lagos.length - 1"></div>
            </div>
          </div>
          <ion-button size="large" expand="block" @click="enviarRegistroDiario">{{ $t("Send") }}</ion-button>
        </div>
      </ion-content>
    </ion-page>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  alertController,
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonList,
  IonIcon,
  IonCheckbox,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonListHeader
} from "@ionic/vue";
import { mapState } from 'vuex'
import axios from 'axios';
import { config } from "../../config"
import {
  alertCircleOutline, alertCircleSharp, closeOutline,
  closeSharp,
} from 'ionicons/icons';
// import moment from 'moment';

export default {
  name: "HomePage",
  components: {
    IonBackButton,
    IonCardContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonInput,
    IonPage,
    IonContent,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonTitle,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonList,
    IonIcon,
    IonCheckbox,
    IonListHeader
  },
  data() {
    return {
      diario: {
        data: '',
        empresa: this.idEmpresa,
        temperature1: '',
        temperature2: '',
        salinidade: '',
        lagos: [{
          alimento: '',
          nomeAlimento: '',
          lago: '',
          id: '',
          morteInstantanea: '',
          quantidade: '',
          encontrado: false,
          jejum: '',
          lote: '',
          comJejum: false,
          lotes: '',
        }],
      },
      alimentos: '',
      lagos: '',
      alertCircleOutline,
      alertCircleSharp,
      closeOutline,
      closeSharp,
      showPopover: false,
      popoverEvent: null,
      encontrado: false,
      motivos: ''
    }
  },
  computed: {
    ...mapState("user", {
      user: (m) => m.user
    })
  },
  watch: {
    // "diario.data": {
    //   immediate: true,
    //   handler: async function (newValue) {
    //     await this.verificarObjetosEncontrados(newValue);
    //   },
    // },
    'diario.lagos': {
      deep: true,
      handler(lagos) {
        const encontrado = lagos.some(item => item.encontrado);
        if (encontrado) {
          this.encontrado = true
        } else {
          this.encontrado = false
        }
      }
    }
  },
  mounted() {
    this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
    this.fetchAlimentos(this.idEmpresa)
    this.fetchLagos(this.idEmpresa)
    this.getPermissoes();
  },
  methods: {
    async onDataChange() {
      await this.verificarObjetosEncontrados(this.diario.data);
    },
    formatDataForDatabase(date) {
      const parts = date.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      return formattedDate;
    },
    formatPpt(value) {
      return value.replace(/[^\d.,]/g, '') + " ppt";
    },
    formatPorcent(value) {
      return value.replace(/[^\d.,]/g, '') + " %";
    },
    zerarFeed(lago) {
      lago.quantidade = '0 Kg'
    },
    async getMotivos() {
      const token = localStorage.getItem('token');
      const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);
      if(!idEmpresa) return;
      try {
        const response = await axios.get(`${config.apiUrl}/configs/reasonsforfasting`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = response.data.items.filter(item => item.empresa == idEmpresa);
        this.motivos = data
      } catch (error) {
        console.log(error);
      }
    },
    async updateFeed(lago, $event) {
      const data = this.alimentos.filter(item => item.nome == $event.target.value);
      const id = data[0] && data[0].id
      this.fetchFeedNames(lago, id)
    },
    async updateLote(lago, $event) {
      const nome = $event.target.value
      this.fetchLotes(lago, nome)
      await this.getMotivos()
    },
    async updateLote2(lago, nome) {
      this.fetchLotes(lago, nome)
      await this.getMotivos()
    },
    async fetchFeedNames(index, id) {
      const token = localStorage.getItem('token');
      const empresaId = parseInt(localStorage.getItem('idEmpresa'));

      try {
        const response = await axios.get(`${config.apiUrl}/getFeedNamesByCompanyId?alimento=${id}&empresa=${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        this.diario.lagos[index].feedNames = response.data.feedNames

      } catch (error) {
        console.log(error);
      }
    },
    async fetchLotes(lago, nome) {
      const token = localStorage.getItem('token');
      const idEmpresa = parseInt(localStorage.getItem('idEmpresa'), 10);
      try {
        const response = await axios.get(`${config.apiUrl}/configs/batches`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data1 = response.data.items;
        const data2 = data1.filter(item => item.empresa == idEmpresa);
        const data3 = data2.filter(item => item.tipo == 2);
        const data4 = data3.filter(item => item.alimento == nome);
        this.diario.lagos[lago].lotes = data4
        if (data4.length == 1) {
          this.diario.lagos[lago].lote = data4[0].id;
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearInput(lago) {
      lago.quantidade = '';
    },
    async buscarDadosExistentes() {
      const token = localStorage.getItem('token');
      const idEmpresa = localStorage.getItem('idEmpresa');
      const dataF = this.diario.data

      const response = await axios.get(`${config.apiUrl}/getRegistrosDiarios/${idEmpresa}/${dataF}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      this.diario = response.data.diario;

      for (let i = 0; i < this.diario.lagos.length; i++) {
        const lago = this.diario.lagos[i]
        lago.comJejum = lago.comJejum == '1';
        lago.lote = parseInt(lago.lote)
        await this.updateLote2(i, lago.nomeAlimento)
      }
    },
    async verificarObjetosEncontrados() {

      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiUrl}/getAlimento`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const alimentoItems = response.data.items;

      let encontrado = []

      for (const lago of this.diario.lagos) {
        const objetoEncontrado = alimentoItems.find(
          (objeto) =>
            objeto.id_lago.toString() == lago.id.toString() &&
            objeto.data_registro == this.formatDataForDatabase(this.diario.data)
        );

        encontrado.push(objetoEncontrado)
      }

      if (encontrado[0] != undefined) {
        await this.buscarDadosExistentes();
      }
      if (encontrado[0] == undefined) {
        this.diario.temperature1 = ''
        this.diario.temperature2 = ''
        this.diario.salinidade = ''
        for (const lago of this.diario.lagos) {
          lago.alimento = '';
          lago.nomeAlimento = '';
          lago.morteInstantanea = '';
          lago.quantidade = '';
          lago.jejum = '';
          lago.lote = '';
          lago.comJejum = false;
        }
      }
    },
    async getPermissoes() {
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
      await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {

          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
            const alert = await alertController.create({
              header: "Error",
              message: this.$t("You_haven't_access")
            });
            await alert.present();
            const userId = localStorage.getItem('id');
            window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    formatKg(value) {
      return value.replace(/[^\d.,]/g, '') + " Kg";
    },
    formatC(value) {
      return value.replace(/[^\d.,]/g, '') + " °C";
    },
    async fetchAlimentos() {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiUrl}/configs/normalFeeds`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const items = response.data.items.map((item) => {
        return {
          nome: item.tipoAlimento,
          id: item.id,
          empresa: item.empresa,
          lote: item.lote
        };
      });
      this.itens = items;
      this.alimentos = this.itens.filter(item => item.empresa == this.idEmpresa);
    },
    async fetchLagos() {
      const token = localStorage.getItem('token');
      const data = {
        idEmpresa: this.idEmpresa
      }
      try {
        const response = await axios.put(`${config.apiUrl}/getLagosByEmpresa`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const lagos = response.data.items.map((lago) => {
          return {
            lago: lago.nome,
            id: lago.id,
            quantidade: ''
          };
        });
        this.diario.lagos = lagos;
      } catch (error) {
        console.log(error);
      }
    },
    async enviarRegistroDiario() {

      if (!this.diario.data) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Date"),
          buttons: ['OK']
        });
        await alert.present();
        return;

      }

      if ((!this.diario.temperature1 || !this.diario.temperature2)) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Temperatures"),
          buttons: ['OK']
        });
        await alert.present();
        return;

      }

      if (!this.diario.salinidade) {
        const alert = await alertController.create({
          header: this.$t("Error"),
          message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Salinity"),
          buttons: ['OK']
        });
        await alert.present();
        return;
      }

      let algumLagoPreenchido = false; 

      for (let j = 0; j < this.diario.lagos.length; j++) {
        const lago = this.diario.lagos[j];
        if (lago.morteInstantanea || lago.quantidade || lago.alimento || lago.comJejum) {

          algumLagoPreenchido = true;

          if (!lago.morteInstantanea){
            const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Instant_death"),
              buttons: ['OK']
            });
            await alert.present();
          }

          if (!lago.alimento || !lago.nomeAlimento) {
            const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Feed"),
              buttons: ['OK']
            });
            await alert.present();
            return;
          }

          if (lago.alimento && !lago.nomeAlimento) {
            const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Feed_name"),
              buttons: ['OK']
            });
            await alert.present();
            return;
          }

          if (lago.alimento && lago.nomeAlimento && !lago.lote) {
            const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Batch"),
              buttons: ['OK']
            });
            await alert.present();
            return;
          }

          if ((!lago.quantidade && !lago.encontrado) && !lago.comJejum) {
            const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Amount"),
              buttons: ['OK']
            });
            await alert.present();
            return;
          }
        }
      }

      if (!algumLagoPreenchido) {
          const alert = await alertController.create({
              header: this.$t("Error"),
              message: this.$t("Fill_in_at_least_one_pond"),
              buttons: ['OK']
          });
          await alert.present();
          return;
      }

      for (let j = 0; j < this.diario.lagos.length; j++) {
        const lago = this.diario.lagos[j];
        if (lago.comJejum && !lago.jejum) {
          const alert = await alertController.create({
            header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("FoodFast"),
            buttons: ['OK']
          });
          await alert.present();
          return;
        }
        if (!lago.comJejum) {
          lago.jejum = " "
        }
      }
      const token = localStorage.getItem('token');
      const diario = {
        data_registro: this.diario.data,
        empresa: this.idEmpresa,
        temperatura1: this.diario.temperature1,
        temperatura2: this.diario.temperature2,
        salinidade: this.diario.salinidade,
        lagos: this.diario.lagos
      };
      await axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAlimento/create`, diario, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (response) {
            for (let j = 0; j < this.diario.lagos.length; j++) {
              const lago = this.diario.lagos[j];
              // if (lago.quantidade) {
              //   lago.quantidade = ''
              // } else if (lago.jejum) {
              //   lago.jejum = ''
              // }

              lago.alimento = ''
              lago.nomeAlimento = ''
              lago.lote = ''
              lago.morteInstantanea = ''
            }
          }

          const alert = alertController.create({
            header: this.$t("successfully_registered!"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();

          });

          this.diario.data = ''
          this.diario.temperature1 = ''
          this.diario.temperature2 = ''
          this.diario.salinidade = ''
        })
        .catch(error => {
          console.log(error);
          const alert = alertController.create({
            header: this.$t("Error_registering"),
            buttons: ['OK']
          });
          alert.then(_alert => {
            _alert.present();
          });
        });

    }
  }
};
</script>
  
<style scoped>
.loteSelect {
  margin-top: 10px;
}

.checkItem {
  margin-top: 10px;
}

.quantidade {
  width: 40%;
}

.lago {
  width: 60%;
}

.tempsDiv {
  display: flex;
  flex-direction: row;
}

.naoEdita {
  color: #838383;
}

.campoTitle {
  font-size: 18px;
}

ion-content #content-lista {
  min-height: 250px;
  max-height: 300px;
}

ion-button {
  --background: #0049b1;
  margin: 0px;
  --border-radius: 6px;
  --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
  max-width: 450px;
  max-height: max-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.pondCard {
  margin: 0px;
}

.lagoQuantidade {
  margin-top: 10px;
}

.lagosDivisao {
  height: 1px;
  width: 100%;
  background-color: #0049b1;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>