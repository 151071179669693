<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Overview") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <ion-card class="ion-padding">
                        <ion-card-title>{{ $t("Disease_State") }}:</ion-card-title><br>
                            <ion-label v-if="this.doencas.length != 0" class="comDoenca">{{ $t("With") }} {{ $t("Disease") }}</ion-label>
                            <ion-label v-else class="semDoenca">{{ $t("Without") }} {{ $t("Disease") }}</ion-label>
                    </ion-card>
                    <ion-card class="ion-padding">
                        <ion-card-title>{{ $t("Feed_stock") }}:</ion-card-title><br>
                        <ion-card-title class="peso"> {{ this.quantidade ? this.quantidade : "0" }} Kg </ion-card-title>
                        <ion-button size="large" class="showGraph" id="open-modal" expand="block" style="font-size: 18px;">{{ $t("Show") }} {{ $t("Graph") }} </ion-button>
                        <ion-modal ref="modal" trigger="open-modal" @ionModalDidPresent="onModalDidPresent">
                            <ion-content >
                                <ion-toolbar>
                                <ion-title>{{ $t("Graph") }} </ion-title>
                                <ion-buttons slot="end">
                                    <ion-button color="light" @click="dismiss()">{{ $t("Close") }} </ion-button>
                                </ion-buttons>
                                </ion-toolbar>
                                <div class="graficoModal">
                                    <div class="canvas">
                                        <canvas id="myChart"></canvas>
                                    </div>

                                </div>
                                
                            </ion-content>
                        </ion-modal>
                    </ion-card>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { alertController,IonBackButton,  IonModal,IonButton,IonLabel,IonCard, IonCardTitle, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"
import Chart from 'chart.js/auto'

export default {
    name: "InfrastructuresLakePage",
    components: {
         IonBackButton,  IonModal,IonButton,IonLabel,IonCard, IonCardTitle, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            doencas:'',
            quantidade:'',
            chart:'',
            entrada:'',
            usado:''
        }
    },
    // beforeRouteEnter(){
    //     this.fetchDoencas()
    //     this.fetchAlimento()
    // },
    mounted() {
        this.getPermissoes();
        this.fetchDoencas()
        this.fetchAlimento()
        
    },
    methods: {
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        onModalDidPresent() {
                
            const ctx = document.getElementById('myChart').getContext('2d')

            new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['before', 'now'], 
                datasets: [{
                label: 'Kg', 
                data: this.chartData, 
                backgroundColor: 'rgba(54, 162, 235, 0.2)', 
                borderColor: 'rgba(54, 162, 235, 1)', 
                borderWidth: 2 
                }]
            },
            options: {
                scales: {
                        y: {  
                            min: 0
                            }
                        },
                }
            })
        },
        async fetchDoencas() {
            const id = this.$route.params.id
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/infrastructures/lago/getHistoricoDoenca/${id}/get`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                const items = response.data.historico;
                const result = [];

                for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (!Object.prototype.hasOwnProperty.call(item, 'data_fim') || item.data_fim == '') {
                    result.push(item);
                }
                }
                this.doencas = result
                console.log(result);
                    console.log(response.data.historico)
                } catch (error) {
                console.log(error);
            }
        },
        async fetchAlimento(){
            const id = this.$route.params.id
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/infrastructures/lago/getAlimentoLago/${id}/get`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.entrada = parseFloat(response.data.entrada)
                this.usado = parseFloat(response.data.usado)
                console.log(response)
                if(this.entrada && this.usado){
                    this.quantidade = (this.entrada - this.usado) 
                }else{
                    this.quantidade = this.entrada
                }
                
                this.chartData = [this.entrada,this.quantidade];
                } catch (error) {
                console.log(error);
            }
        },
        dismiss() {
        this.$refs.modal.$el.dismiss();
      },
    }
};
</script>
    
<style scoped>

.canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 75%;
}

.graficoModal{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
}

ion-modal {
    --height: 50vh;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  ion-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }

  ion-modal ion-toolbar {
    --background: #0049b1;
    --color: white;
  }

.peso{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}
.comDoenca{
    color: rgb(255, 0, 0);
    font-weight: bold;
    font-size: 16px;
}

.semDoenca{
    color: rgb(30, 182, 0);
    font-weight: bold;
    font-size: 16px;
}


ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

.showGraph{
    --background: #0049b1;
    margin: 0px;
    margin-top: 15px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}
.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

</style>