<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button
                            :default-href="`/config/infrastructure/lake/${this.$route.params.id}`"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Feed") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">
                <div class="content-items">
                    <IonItem fill="outline">
                        <ion-label position="floating">{{ $t("Feed") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="alimento"
                        @ionChange="updateLote(lago)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in alimentos" :key="item.id">
                                    <ion-select-option :value=item.nome>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <IonItem fill="outline" disabled v-if="alimento && lote">
                        <ion-label position="floating">{{ $t("Batch") }} </ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" v-model="lote">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in lotes" :key="item.id">
                                    <ion-select-option :value=item.id>{{ item.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Warehouse_Entrance") }}</ion-label>
                        <ion-input v-model="entrada" @input="entrada = formatKg($event.target.value)"
                            :placeholder="$t('apply_to_warehouse')"></ion-input>
                    </ion-item>
                    <ion-button v-if="retroativas.length <= 1" size="large" expand="block" @click="cadastrarEntrada">{{
                        $t("Confirm") }}</ion-button>
                    <ion-button v-else size="large" expand="block" @click="setOpen(true)">{{ $t("Confirm") }}</ion-button>
                    <ion-modal :is-open="isOpen" id="ModalRetroativo">
                        <ion-header>
                            <ion-toolbar>
                                <ion-title>{{ $t("Fill_in_missing_data") }}</ion-title>
                                <ion-buttons slot="end">
                                    <ion-button @click="setOpen(false)">{{ $t("Close") }}</ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                        </ion-header>
                        <ion-content class="ion-padding" id="retroativoModal">
                            <ion-item v-for="(retroativa, index) in retroativas" style="margin-bottom: 20px;"
                                :key="retroativa.id">
                                <ion-label style="color: #838383;">{{ retroativa }}:</ion-label>
                                <ion-input :placeholder="$t('Warehouse_Entrance')" v-model="entradas[index]"
                                    @input="entradas[index] = formatKg($event.target.value)"></ion-input>
                            </ion-item>
                            <ion-button size="large" expand="block" style="margin-bottom: 50px;"
                                @click="cadastrarEntradas">{{ $t("Confirm") }}</ion-button>
                        </ion-content>
                    </ion-modal>
                    <br>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Used") }}/{{ $t("Consumption") }}</ion-label>
                        <ion-input v-model="usado" @input="usado = formatKg($event.target.value)"
                            :placeholder="$t('apply_to_warehouse')"></ion-input>
                    </ion-item>

                    <ion-button size="large" expand="block" @click="cadastrarUsado">{{ $t("Confirm") }}</ion-button>
                    <br>

                    <ion-button size="large" expand="block" id="open-modal2">{{ $t("Food_Fast") }}</ion-button>
                    <ion-modal ref="modal" trigger="open-modal2" @ionModalDidPresent="onModalDidPresent">
                        <ion-content :scroll-y="false">
                            <ion-toolbar>
                                <ion-title class="justificativa">{{ $t("Reason_for_not_feeding_on_the_day") }}</ion-title>
                                <ion-buttons slot="end">
                                    <ion-button color="light" @click="dismiss()">{{ $t("Close") }}</ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                            <ion-content :scroll-y="false" class="ion-padding">
                                <div class="main">

                                    <ion-item fill="outline">
                                        <ion-input v-model="jejum" type="text" class="ion-padding"></ion-input>
                                    </ion-item>
                                    <ion-button size="large" expand="block" @click="cadastrarUsado">{{ $t("Confirm")
                                    }}</ion-button>
                                </div>
                            </ion-content>

                        </ion-content>
                    </ion-modal>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonModal, IonSelect, IonList, IonSelectOption, alertController, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../../config"

export default {
    components: {
        IonBackButton, IonModal, IonSelect, IonList, IonSelectOption, IonButton, IonLabel, IonItem, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        return {
            alimentos: '',
            lotes: '',
            entrada: '',
            usado: '',
            alimento: '',
            jejum: '',
            lote: '',
            retroativas: '',
            isOpen: false,
            entradas: []
        }
    },
    mounted() {
        this.getPermissoes();
        this.fetchAlimentos()
        this.fetchLotes()
        this.obterDataAtual()
        this.getRetroativas()
    },
    methods: {
        async updateLote() {
            const selectedAlimento = this.alimentos.find(alimento => alimento.nome == this.alimento);

            if (selectedAlimento) {
                console.log(selectedAlimento.lote)
                this.lote = selectedAlimento.lote;
            } else {
                this.lote = '';
            }
        },
        formatarData(data) {
            const partes = data.split('/');
            const dia = partes[0];
            const mes = partes[1];
            const ano = partes[2];
            return `${dia}-${mes}-${ano}`;
        },
        cadastrarEntradas() {
            for (let i = 0; i < this.retroativas.length; i++) {
                const entrada = this.entradas[i]
                console.log(entrada)
                if(!entrada){
                    const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return
                }
            }
            for (let i = 0; i < this.retroativas.length; i++) {
                const retroativa = this.retroativas[i];
                const entrada = this.entradas[i];
                const dataFormatada = this.formatarData(retroativa);
                const data = {
                    id_lago: this.$route.params.id,
                    entrada: entrada,
                    alimento: this.alimento,
                    lote: this.lote || null,
                    data_registro: dataFormatada
                };
                const token = localStorage.getItem('token');
                axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAlimento/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                    .then(response => {
                        console.log(response.data);

                        this.alimento = ''
                        this.lote = ''
                        this.isOpen = false
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            const alert = alertController.create({
                                header: this.$t("Success"),
                                message: this.$t("successfully_registered!"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            this.entrada = ''
        },
        setOpen(isOpen) {
            if (!this.alimento) {
                const alert = alertController.create({
                    header: this.$t("Error"),
                    message: this.$t("Fill_in_all_batch_information"),
                    buttons: ['OK']
                });
                alert.then(_alert => {
                    _alert.present();
                });
                return
            }

            // if (!this.lote) {
            //     const alert = alertController.create({
            //         header: this.$t("Error"),
            //         message: this.$t("Fill_in_all_batch_information"),
            //         buttons: ['OK']
            //     });
            //     alert.then(_alert => {
            //         _alert.present();
            //     });
            //     return
            // }
            this.isOpen = isOpen;
        },
        async getRetroativas() {
            const token = localStorage.getItem('token');
            const id = this.$route.params.id
            try {
                const response = await axios.get(`${config.apiUrl}/getRetroativoAlimento/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                console.log(response)
                this.retroativas = response.data
                this.retroativas.pop()
                this.retroativas.reverse();
            } catch (error) {
                console.error(error);
            }
        },
        async getPermissoes() {
            const token = localStorage.getItem('token');
            const groupId = localStorage.getItem('idGrupo');

            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(37) && !funcionalidades.includes(38) && !funcionalidades.includes(39)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message:  this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        obterDataAtual() {
            const data = new Date();
            const ano = data.getFullYear();
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const dia = String(data.getDate()).padStart(2, '0');

            this.dataAtual = `${dia}-${mes}-${ano}`;
        },
        formatKg(value) {
            return value.replace(/[^\d.,]/g, '') + " Kg";
        },
        async fetchAlimentos() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/normalFeeds`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                const items = response.data.items.map((item) => {
                    return {
                        nome: item.nome,
                        id: item.id,
                        lote: item.lote,
                        empresa: item.empresa
                    };
                });
                const idEmpresa = localStorage.getItem('idEmpresa', 10)
                const data = items.filter((alimento) => alimento.empresa == idEmpresa)
                this.alimentos = data;
            } catch (error) {
                console.error("Erro ao buscar nomes ", error);
            }
        },
        async fetchLotes() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/configs/batches`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.lotes = response.data.items;
            } catch (error) {
                console.log(error);
            }
        },
        cadastrarEntrada() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAlimento`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.entrada);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.alimento && this.entrada) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.entrada = ''
                                    this.lote = ''
                                    this.alimento = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    if (!this.alimento) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    // if (!this.lote) {
                                    //     const alert = alertController.create({
                                    //         header: this.$t("Error"),
                                    //         message: this.$t("Fill_in_all_batch_information"),
                                    //         buttons: ['OK']
                                    //     });
                                    //     alert.then(_alert => {
                                    //         _alert.present();
                                    //     });
                                    //     return
                                    // }

                                    const data = {};
                                    if (this.entrada) {
                                        data.id_lago = this.$route.params.id
                                        data.entrada = this.entrada;
                                        data.alimento = this.alimento;
                                        data.lote = this.lote || null;
                                        data.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAlimento/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.entrada = ''
                                            this.lote = ''
                                            this.alimento = ''
                                            const inTutorial = localStorage.getItem('inTutorial')
                                            if (inTutorial) {
                                                window.location.reload()
                                            }
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        if (!this.alimento) {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }

                        // if (!this.lote) {
                        //     const alert = alertController.create({
                        //         header: this.$t("Error"),
                        //         message: this.$t("Fill_in_all_batch_information"),
                        //         buttons: ['OK']
                        //     });
                        //     alert.then(_alert => {
                        //         _alert.present();
                        //     });
                        //     return
                        // }

                        const data = {};
                        if (this.entrada) {
                            data.id_lago = this.$route.params.id
                            data.entrada = this.entrada;
                            data.alimento = this.alimento;
                            data.lote = this.lote || null;
                            data.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAlimento/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.entrada = ''
                                this.lote = ''
                                this.alimento = ''
                                const inTutorial = localStorage.getItem('inTutorial')
                                            if (inTutorial) {
                                                window.location.reload()
                                            }
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });


        },
        cadastrarUsado() {
            const token = localStorage.getItem('token');
            axios.get(`${config.apiUrl}/getAlimento`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    const objetoEncontrado = response.data.items.filter(objeto => objeto.id_lago.toString() == this.$route.params.id.toString() && objeto.data_registro == this.dataAtual && objeto.usado);
                    console.log(response)
                    if (objetoEncontrado.length > 0 && this.alimento && (this.usado || this.jejum)) {
                        const alert = alertController.create({
                            header: this.$t("A_record_already_exists"),
                            message: this.$t("Do_you_want_to_overwrite"),
                            buttons: [{
                                text: this.$t("Cancel"),
                                role: 'cancel',
                                handler: async () => {
                                    this.usado = ''
                                    this.lote = ''
                                    this.alimento = ''
                                    this.jejum = ''
                                    return;
                                }
                            },
                            {
                                text: this.$t("Yes"),
                                role: "destructive",
                                handler: async () => {
                                    if (this.usado && this.jejum) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    if (!this.alimento) {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }

                                    // if (!this.lote) {
                                    //     const alert = alertController.create({
                                    //         header: this.$t("Error"),
                                    //         message: this.$t("Fill_in_all_batch_information"),
                                    //         buttons: ['OK']
                                    //     });
                                    //     alert.then(_alert => {
                                    //         _alert.present();
                                    //     });
                                    //     return
                                    // }

                                    const data2 = {};
                                    if (this.usado || this.jejum) {
                                        data2.id_lago = this.$route.params.id
                                        data2.usado = this.usado;
                                        data2.jejum = this.jejum
                                        data2.alimento = this.alimento;
                                        data2.lote = this.lote || null;
                                        data2.data_registro = this.dataAtual
                                    } else {
                                        const alert = alertController.create({
                                            header: this.$t("Error"),
                                            message: this.$t("Fill_in_all_batch_information"),
                                            buttons: ['OK']
                                        });
                                        alert.then(_alert => {
                                            _alert.present();
                                        });
                                        return
                                    }
                                    console.log(data2)
                                    axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAlimento/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                                        .then(response => {
                                            console.log(response.data);

                                            const alert = alertController.create({
                                                header: this.$t("Success"),
                                                message: this.$t("successfully_registered!"),
                                                buttons: ['OK']
                                            });
                                            alert.then(_alert => {
                                                _alert.present();
                                            });

                                            this.usado = ''
                                            this.lote = ''
                                            this.alimento = ''
                                            this.jejum = ''
                                            this.$refs.modal.$el.dismiss();
                                            return;
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }]
                        });
                        alert.then(_alert => {
                            _alert.present();
                        });
                        return;
                    } else {
                        if (this.usado && this.jejum) {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }

                        if (!this.alimento) {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }

                        // if (!this.lote) {
                        //     const alert = alertController.create({
                        //         header: this.$t("Error"),
                        //         message: this.$t("Fill_in_all_batch_information"),
                        //         buttons: ['OK']
                        //     });
                        //     alert.then(_alert => {
                        //         _alert.present();
                        //     });
                        //     return
                        // }

                        const data2 = {};
                        if (this.usado || this.jejum) {
                            data2.id_lago = this.$route.params.id
                            data2.usado = this.usado;
                            data2.jejum = this.jejum
                            data2.alimento = this.alimento;
                            data2.lote = this.lote || null;
                            data2.data_registro = this.dataAtual
                        } else {
                            const alert = alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("Fill_in_all_batch_information"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            return
                        }
                        console.log(data2)
                        axios.post(`${config.apiUrl}/infrastructures/lago/cadastrarAlimento/create`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                            .then(response => {
                                console.log(response.data);

                                const alert = alertController.create({
                                    header: this.$t("Success"),
                                    message: this.$t("successfully_registered!"),
                                    buttons: ['OK']
                                });
                                alert.then(_alert => {
                                    _alert.present();
                                });

                                this.usado = ''
                                this.lote = ''
                                this.alimento = ''
                                this.jejum = ''
                                this.$refs.modal.$el.dismiss();
                                return;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                })
                .catch(error => {

                    console.log(error);
                });

        },
        dismiss() {
            this.$refs.modal.$el.dismiss();
        },
    }
};
</script>

    
<style scoped>
.main {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.justificativa {
    font-size: 16px;
}

#open-modal2 {
    --background: transparent;
    border: #0049b1 2px solid;
    --color: #0049b1;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
}

ion-modal {
    --height: 35vh;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

#ModalRetroativo {
    --border-radius: 0px;
    --height: 100vh;
}

#retroativoModal {
    min-height: max-content;
}

ion-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal ion-toolbar {
    --background: #0049b1;
    --color: white;
}


ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}</style>