import { createRouter, createWebHistory } from '@ionic/vue-router';
import { authGuard } from '../guards/auth.guards';

import LoginPage from '../views/LoginPage.vue'
import HomePage from "../views/Dashboard/HomePage.vue"
import DailyPage from "../views/Dashboard/DailyPage.vue"
import AccountPage from "../views/AccountPage.vue"
import UsersGroupsPage from "../views/Groups/UsersGroupsPage.vue"
import CreateGroupsPage from "../views/Groups/CreateGroupsPage.vue"
import GroupsEditPage from "../views/Groups/GroupsEditPage.vue"
import UsersPage from "../views/Users/UsersPage.vue"
import UsersCreatePage from "../views/Users/UsersCreatePage.vue"
import CompaniesPage from "../views/Companies/CompaniesPage.vue"
import CompaniesEditPage from "../views/Companies/CompaniesEditPage.vue"
import UsersEditPage from "../views/Users/UsersEditPage.vue"
import UsersCreateCompany from "../views/Users/UsersCreateCompany.vue"
import SpeciesPage from "../views/Species/SpeciesPage.vue"
import BatchesAddEditPage from "../views/Batches/BatchesAddEditPage.vue"
import BatchesPage from "../views/Batches/BatchesPage.vue"
import ClientsAddEditPage from "../views/Clients/ClientsAddEditPage.vue"
import ClientsPage from "../views/Clients/ClientsPage.vue"
import ReasonsForFastingAddEditPage from "../views/ReasonsForFasting/ReasonsForFastingAddEditPage.vue"
import ReasonsForFastingPage from "../views/ReasonsForFasting/ReasonsForFastingPage.vue"
import ProviderAddEditPage from "../views/Providers/ProvidersAddEditPage.vue"
import ProviderPage from "../views/Providers/ProvidersPage.vue"
import CreateFoodsPage from "../views/Foods/CreateFoodsPage.vue"
import FoodsPage from "../views/Foods/FoodsPage.vue"
import FoodsEditPage from "../views/Foods/FoodsEditPage.vue"
import DiseasesPage from "../views/Diseases/DiseasesPage.vue"
import DiseasesEditPage from "../views/Diseases/DiseasesEditPage.vue"
import DiseasesCreatePage from "../views/Diseases/DiseasesCreatePage.vue"
import InfrastructuresPage from "../views/Infrastructures/InfrastructuresPage.vue"
import InfrastructuresEditPage from "../views/Infrastructures/InfrastructuresEditPage.vue"
import InfrastructuresCreatePage from "../views/Infrastructures/InfrastructuresCreatePage.vue"
import ConfigImportExportPage from "../views/Configs/ConfigImportExportPage.vue"
import CreateSpeciesPage from "../views/Species/CreateSpeciesPage.vue"
import EditSpeciesPage from "../views/Species/EditSpeciesPage.vue"
import LicensesPage from "../views/Licenses/LicensesPage.vue"
import ShoppingCreatePage from "../views/Shopping/ShoppingCreatePage.vue"
import ShoppingPage from "../views/Shopping/ShoppingPage.vue"
import ShoppingEditPage from "../views/Shopping/ShoppingEditPage.vue"
import RecordsFinancialCreatePage from "../views/FinancialRecords/RecordsFinancialCreatePage.vue"
import RecordsFinancialPage from "../views/FinancialRecords/RecordsFinancialPage.vue"
import RecordsFinancialEditPage from "../views/FinancialRecords/RecordsFinancialEditPage.vue"
import ItemsFinancialCreatePage from "../views/FinancialItems/ItemsFinancialCreatePage.vue"
import ItemsFinancialPage from "../views/FinancialItems/ItemsFinancialPage.vue"
import ItemsFinancialEditPage from "../views/FinancialItems/ItemsFinancialEditPage.vue"
import SalesFinancialCreatePage from "../views/FinancialSales/SalesFinancialCreatePage.vue"
import SalesFinancialPage from "../views/FinancialSales/SalesFinancialPage.vue"
import SalesFinancialEditPage from "../views/FinancialSales/SalesFinancialEditPage.vue"
import UpdatesFinancialCreatePage from "../views/FinancialUpdates/UpdatesFinancialCreatePage.vue"
import UpdatesFinancialPage from "../views/FinancialUpdates/UpdatesFinancialPage.vue"
import UpdatesFinancialEditPage from "../views/FinancialUpdates/UpdatesFinancialEditPage.vue"
import ProductionCenterPage from "../views/ProductionCenters/ProductionCenterPage.vue"
import ProductionCenterCreatePage from "../views/ProductionCenters/ProductionCenterCreatePage.vue"
import ProductionCenterEditPage from "../views/ProductionCenters/ProductionCenterEditPage.vue"
import InfrastructuresCreateZonePage from "../views/Infrastructures/InfrastructuresCreateZonePage.vue"
import InfrastructuresZoneEditPage from "../views/Infrastructures/InfrastructuresZoneEditPage.vue"
import InfrastructuresCreateLakePage from "../views/Infrastructures/InfrastructuresCreateLakePage.vue"
import InfrastructuresLakeEditPage from "../views/Infrastructures/InfrastructuresLakeEditPage.vue"
import InfrastructuresCreateAutenticationPage from "../views/Infrastructures/InfrastructuresCreateAutenticationPage.vue"
import InfrastructuresLakePage from "../views/Infrastructures/Lake/InfrastructuresLakePage.vue"
import InfrastructuresLakeOxygenPage from "../views/Infrastructures/Lake/InfrastructuresLakeOxygenPage.vue"
import InfrastructuresLakeParametersPage from "../views/Infrastructures/Lake/InfrastructuresLakeParametersPage.vue"
import InfrastructuresLakeElectricityPage from "../views/Infrastructures/Lake/InfrastructuresLakeElectricityPage.vue"
import InfrastructuresLakeFuelPage from "../views/Infrastructures/Lake/InfrastructuresLakeFuelPage.vue"
import InfrastructuresLakeFuelTypePage from "../views/Infrastructures/Lake/InfrastructuresLakeFuelTypePage.vue"
import InfrastructuresLakeFeedPage from "../views/Infrastructures/Lake/InfrastructuresLakeFeedPage.vue"
import InfrastructuresLakeWorkPage from "../views/Infrastructures/Lake/InfrastructuresLakeWorkPage.vue"
import InfrastructuresLakeWaterPage from "../views/Infrastructures/Lake/InfrastructuresLakeWaterPage.vue"
import InfrastructuresLakeProductPage from "../views/Infrastructures/Lake/InfrastructuresLakeProductPage.vue"
import InfrastructuresLakeDiseasePage from "../views/Infrastructures/Lake/InfrastructuresLakeDiseasePage.vue"
import InfrastructuresLakeChemistryPage from "../views/Infrastructures/Lake/InfrastructuresLakeChemistryPage.vue"
import InfrastructuresLakeAgriculturePage from "../views/Infrastructures/Lake/InfrastructuresLakeAgriculturePage.vue"
import InfrastructuresLakeOverviewPage from "../views/Infrastructures/Lake/InfrastructuresLakeOverviewPage.vue"
import WarehouseCreatePage from "../views/Warehouse/WarehouseCreatePage.vue"
import WarehousePage from "../views/Warehouse/WarehousePage.vue"
import WarehouseEditPage from "../views/Warehouse/WarehouseEditPage.vue"

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/',
    name:"HomePage",
    component: HomePage,
    beforeEnter: authGuard
  },
  {
    path: '/daily',
    name:"DailyPage",
    component: DailyPage,
    beforeEnter: authGuard
  },
  {
    path: '/users',
    name: 'UsersPage',
    component: UsersPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/companies',
    name: 'CompaniesPage',
    component: CompaniesPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/companies/:id/edit',
    name: 'CompaniesEditPage',
    component: CompaniesEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/:id/edit',
    name: 'UsersEditPage',
    component: UsersEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/:id/my-account',
    name: 'AccountPage',
    component: AccountPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/users-groups',
    name: 'UsersGroupsPage',
    component: UsersGroupsPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/create-users',
    name: 'UsersCreatePage',
    component: UsersCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path:'/users/create-company',
    name: 'UsersCreateCompany',
    component: UsersCreateCompany,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/users-groups/create-groups',
    name: 'CreateGroupsPage',
    component: CreateGroupsPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/groups/:id/edit',
    name: 'GroupsEditPage',
    component: GroupsEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/production-center/create',
    name: 'ProductionCenterCreatePage',
    component: ProductionCenterCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/production-center',
    name: 'ProductionCenterPage',
    component: ProductionCenterPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/production-center/:id/edit',
    name: 'ProductionCenterEditPage',
    component: ProductionCenterEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/species/create',
    name: 'CreateSpeciesPage',
    component: CreateSpeciesPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/species/:id/edit',
    name: 'EditSpeciesPage',
    component: EditSpeciesPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/species',
    name: 'SpeciesPage',
    component: SpeciesPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/batche/create',
    name: 'BatchesCreatePage',
    component: BatchesAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'create'
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/batche/:id/edit',
    name: 'BatchesEditPage',
    component: BatchesAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'edit'
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/batches',
    name: 'BatchesPage',
    component: BatchesPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/client/create',
    name: 'ClientsCreatePage',
    component: ClientsAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'create'
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/client/:id/edit',
    name: 'ClientsEditPage',
    component: ClientsAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'edit'
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/clients',
    name: 'ClientsPage',
    component: ClientsPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/reasons_for_fasting/create',
    name: 'ReasonsForFastingAddPage',
    component: ReasonsForFastingAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'create'
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/reasons_for_fasting/:id/edit',
    name: 'ReasonsForFastingEditPage',
    component: ReasonsForFastingAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'edit'
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/reasons_for_fasting',
    name: 'ReasonsForFastingPage',
    component: ReasonsForFastingPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/provider/create',
    name: 'ProviderAddPage',
    component: ProviderAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'create'
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/provider/:id/edit',
    name: 'ProviderEditPage',
    component: ProviderAddEditPage,
    meta: {
      requiresAuth: true,
      mode: 'edit'
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/providers',
    name: 'ProviderPage',
    component: ProviderPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/feed/create',
    name: 'CreateFoodsPage',
    component: CreateFoodsPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/feed',
    name: 'FoodsPage',
    component: FoodsPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/feed/:id/edit',
    name: 'FoodsEditPage',
    component: FoodsEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/disease/create',
    name: 'DiseasesCreatePage',
    component: DiseasesCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/disease',
    name: 'DiseasesPage',
    component: DiseasesPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/disease/:id/edit',
    name: 'DiseasesEditPage',
    component: DiseasesEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/users/license',
    name: 'LicensesPage',
    component: LicensesPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/shopping/create',
    name: 'ShoppingCreatePage',
    component: ShoppingCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/shopping',
    name: 'ShoppingPage',
    component: ShoppingPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/shopping/:id/edit',
    name: 'ShoppingEditPage',
    component: ShoppingEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/records/create',
    name: 'RecordsFinancialCreatePage',
    component: RecordsFinancialCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/records',
    name: 'RecordsFinancialPage',
    component: RecordsFinancialPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/records/:id/edit',
    name: 'RecordsFinancialEditPage',
    component: RecordsFinancialEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/warehouse/create',
    name: 'WarehouseCreatePage',
    component: WarehouseCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/warehouse',
    name: 'WarehousePage',
    component: WarehousePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/warehouse/:id/edit',
    name: 'WarehouseEditPage',
    component: WarehouseEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/items/create',
    name: 'ItemsFinancialCreatePage',
    component: ItemsFinancialCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/items',
    name: 'ItemsFinancialPage',
    component: ItemsFinancialPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/items/:id/edit',
    name: 'ItemsFinancialEditPage',
    component: ItemsFinancialEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/sales/create',
    name: 'SalesFinancialCreatePage',
    component: SalesFinancialCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/sales',
    name: 'SalesFinancialPage',
    component: SalesFinancialPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/sales/:id/edit',
    name: 'SalesFinancialEditPage',
    component: SalesFinancialEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/updates/create',
    name: 'UpdatesFinancialCreatePage',
    component: UpdatesFinancialCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/updates',
    name: 'UpdatesFinancialPage',
    component: UpdatesFinancialPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/financial/updates/:id/edit',
    name: 'UpdatesFinancialEditPage',
    component: UpdatesFinancialEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/create',
    name: 'InfrastructuresCreatePage',
    component: InfrastructuresCreatePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure',
    name: 'InfrastructuresPage',
    component: InfrastructuresPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/:id/edit',
    name: 'InfrastructuresEditPage',
    component: InfrastructuresEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/create/zone',
    name: 'InfrastructuresCreateZonaPage',
    component: InfrastructuresCreateZonePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/edit/zone/:id',
    name: 'InfrastructuresZoneEditPage',
    component: InfrastructuresZoneEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/create/lake',
    name: 'InfrastructuresCreateLakePage',
    component: InfrastructuresCreateLakePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/edit/lake/:id',
    name: 'InfrastructuresLakeEditPage',
    component: InfrastructuresLakeEditPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id',
    name: 'InfrastructuresLakePage',
    component: InfrastructuresLakePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/oxygen',
    name: 'InfrastructuresLakeOxygenPage',
    component: InfrastructuresLakeOxygenPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/feed',
    name: 'InfrastructuresLakeFeedPage',
    component: InfrastructuresLakeFeedPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/fuel',
    name: 'InfrastructuresLakeFuelPage',
    component: InfrastructuresLakeFuelPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/fuel/type',
    name: 'InfrastructuresLakeFuelTypePage',
    component: InfrastructuresLakeFuelTypePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/electricity',
    name: 'InfrastructuresLakeElectricityPage',
    component: InfrastructuresLakeElectricityPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/parameters',
    name: 'InfrastructuresLakeParametersPage',
    component: InfrastructuresLakeParametersPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/work',
    name: 'InfrastructuresLakeWorkPage',
    component: InfrastructuresLakeWorkPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/water',
    name: 'InfrastructuresLakeWaterPage',
    component: InfrastructuresLakeWaterPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/product',
    name: 'InfrastructuresLakeProductPage',
    component: InfrastructuresLakeProductPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/disease',
    name: 'InfrastructuresLakeDiseasePage',
    component: InfrastructuresLakeDiseasePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/chemistry',
    name: 'InfrastructuresLakeChemistryPage',
    component: InfrastructuresLakeChemistryPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/agriculture',
    name: 'InfrastructuresLakeAgriculturePage',
    component: InfrastructuresLakeAgriculturePage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/lake/:id/overview',
    name: 'InfrastructuresLakeOverviewPage',
    component: InfrastructuresLakeOverviewPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/config/infrastructure/create/autentication',
    name: 'InfrastructuresCreateAutenticationPage',
    component: InfrastructuresCreateAutenticationPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/cultivation/import-export',
    name: 'ConfigImportExportPage',
    component: ConfigImportExportPage,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
