<template>
    <ion-page>

        <ion-page id="main-content">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/config/infrastructure"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Edit") }} {{ $t("Pond") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding" v-if="empresas">
                <div class="content-items" v-if="lagos">
                    <IonItem fill="outline" v-if="master">
                        <ion-label position="floating">{{ $t("Companies") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select"
                            @ionChange="selecionarEmpresa($event)" v-model="lagos.empresa" 
                            >
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="empresa in empresas" :key="empresa.id">
                                    <ion-select-option :value=empresa.id >{{
                                        empresa.nome }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <div class="contentCommercial" v-if="centros">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Production_centers") }} <span style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select" @ionChange="selecionarCentro($event)"
                                    v-model="lagos.centroProducao"  >
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="centro in centros" :key="centro.id">
                                            <ion-select-option :value="centro.id" >{{ centro.nomeLegal }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <div class="contentCommercial" v-if="zonas">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Zones") }} <span style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select"
                                    v-model="lagos.zona"  @ionChange="selecionarZona($event)"  >
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="(zona, index) in zonas" :key="index">
                                            <ion-select-option :value="zona.id">{{ zona.nome }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <div class="contentCommercial" v-if="secoes">
                        <div class="commercialSizes">
                            <IonItem fill="outline">
                                <ion-label position="floating">{{ $t("Sections") }} <span style="color: red;">*</span></ion-label>
                                <ion-select position="floating" aria-label="Fruit" class="select"
                                v-model="lagos.id_secao" >
                                    <ion-content class="ion-padding" id="content-lista">
                                        <ion-list v-for="(secao, index) in secoes" :key="index">
                                            <ion-select-option :value="secao.id">{{ secao.texto }}</ion-select-option>
                                        </ion-list>
                                    </ion-content>
                                </ion-select>
                            </IonItem>
                        </div>
                    </div>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("Name") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="lagos.nome" ></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">{{ $t("depht") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="lagos.profundidade" ></ion-input>
                    </ion-item>
                    <ion-item fill="outline">
                        <ion-label position="floating">CBM {{ $t("Pond") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="lagos.lagoaCBM"></ion-input>
                    </ion-item>

                    <ion-button size="large" expand="block" @click="cadastrarCompra">{{ $t("Register") }}</ion-button>
                </div>
            </ion-content>
        </ion-page>
    </ion-page>
</template>
    
<script>
import { IonBackButton, IonSelect, IonSelectOption,  IonList, alertController, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ref } from 'vue';

export default {
    components: {
        IonBackButton, IonSelect, IonSelectOption,  IonList, IonButton, IonItem, IonLabel, IonInput, IonPage, IonContent, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle
    },
    data() {
        const lagos = ref(null)
        return {
            lagos,
            centros: "",
            zonas: "",
            secoes: "",
            empresas: '',
            funcionalidades:'',
            master:''
        }
    },
    mounted() {
        this.getPermissoes();
        this.getLago()
        this.fetchUser()
    },
    methods: {
        async fetchUser() {
      const userId = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              this.master = response.data.master
              this.idEmpresa = parseInt(localStorage.getItem('idEmpresa'))
              this.fetchEmpresas()
      } catch (error) {
        console.log(error);
      }
    },
        async getPermissoes(){
      const groupId = localStorage.getItem('idGrupo');
      const token = localStorage.getItem('token');
        await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          
          const funcionalidades = response.data.funcionalidades
          this.funcionalidades = funcionalidades
          if(!funcionalidades.includes(37) && !funcionalidades.includes(38)  && !funcionalidades.includes(39)){
              const alert = await alertController.create({
                header: "Error",
                message:  this.$t("You_haven't_access")
              });
              await alert.present();
              const userId = localStorage.getItem('id');
              window.location.replace(`/users/${userId}/my-account`);
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
        async getLago(){
      const Id = this.$route.params.id
      const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${config.apiUrl}/infrastructures/lago/${Id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
          this.lagos = response.data.lagos;
            
            this.getCompanyName(this.lagos.empresa)

        } catch (error) {
          console.log(error);
        }
    },
    async getCompanyName(empresaId) {
        const token = localStorage.getItem('token');
            try {
                const response = await axios
                .get(`${config.apiUrl}/companies/${empresaId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.nomeEmpresa = response.data.nome
                this.showCentros(empresaId)
            } catch (error) {
                console.error("Erro ao buscar nomes ", error);
            }
            },
        async showCentros() {
            const token = localStorage.getItem('token');
            const data = {
                empresa: this.valueEmpresa ? this.valueEmpresa : this.lagos.empresa
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getProductionCenterbyCompanyName`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
              
                const centros = response.data.items
                
                this.centros = centros;

                this.showZonas(this.lagos.centroProducao)
            } catch (error) {
                console.log(error);
            }
        },
        async showZonas() {
            const token = localStorage.getItem('token');
            const data = {
                idCentro: this.valueCentro ? this.valueCentro : this.lagos.centroProducao
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getZonebyProductionCenter`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
            
                const zonas = response.data.items
                
                this.zonas = zonas;
                this.showSecoes(this.lagos.zona)
            } catch (error) {
                console.log(error);
            }
        },
        async showSecoes() {
            const token = localStorage.getItem('token');
            const data = {
                idZona: this.valueZona ? this.valueZona : this.lagos.zona
            }
            try {
                const response = await axios.put(`${config.apiUrl}/getSecaoByZone`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

                const secoes = response.data.items
                this.secoes = secoes;
            } catch (error) {
                console.log(error);
            }
        },
        selecionarEmpresa($event) {
            this.valueEmpresa = $event.target.value
            this.showCentros(this.valueEmpresa);
            this.lagos.centroProducao = ''
        },
        selecionarCentro($event) {
            this.valueCentro = $event.target.value
            this.showZonas(this.valueCentro);
            this.lagos.zona = ''
        },
        selecionarZona($event) {
            this.valueZona = $event.target.value
            this.showSecoes(this.valueZona);
            this.lagos.id_secao = ''
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                
                const hasPermissoes =  this.master
                
                if (!hasPermissoes) {
                this.empresas = this.empresas.filter(empresa => empresa.id == this.idEmpresa);
                }
            } catch (error) {
                console.log(error);
            }
            },
        async cadastrarCompra() {

            if (!this.lagos.centroProducao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Production_Center"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.zona) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Zone"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.id_secao) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Section"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.nome) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+this.$t("Name"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.profundidade) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+ this.$t("depht"),
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            if (!this.lagos.lagoaCBM) {
                const alert = await alertController.create({
                    header: this.$t("Error"),
            message: this.$t("Fill_in_the_following_field_correctly") +" "+" CBM ",
                    buttons: ['OK']
                });
                await alert.present();
                return;
            }

            const lagos = {
                lagos: {
                    empresa: this.lagos.empresa,
                    centroProducao:this.lagos.centroProducao,
                    zona: this.lagos.zona,
                    nome:this.lagos.nome,
                    profundidade:this.lagos.profundidade,
                    lagoaCBM:this.lagos.lagoaCBM,
                    id_secao: this.lagos.id_secao
                }
            };
            const Id = this.$route.params.id
            const token = localStorage.getItem('token');
            await axios.put(`${config.apiUrl}/infrastructures/lago/${Id}/edit`, lagos, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(response => {
                    console.log(response.data.id);

                    const alert = alertController.create({
                        header: this.$t("successfully_updated!")
                    });
                    alert.then(_alert => {
                        _alert.present();

                        window.location.replace("/config/infrastructure")
                    });
                })
                .catch(error => {
                    console.log(error);
                    const alert = alertController.create({
                        header: this.$t("Error_updating") ,
                        buttons: ['OK']
                    });
                    alert.then(_alert => {
                        _alert.present();
                    });
                });
        }

    }
};
</script>
    
<style scoped>

ion-content #content-lista {
    min-height: 250px;
    max-height: 300px;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}


.content-items {
    max-width: 450px;
    max-height: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

</style>