<template>
    <ion-page>

        <ion-page id="main-content">

            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                    <ion-buttons slot="start">
                        <ion-back-button default-href="/users"></ion-back-button>
                    </ion-buttons>
                    <ion-title>{{ $t("Register") }} {{ $t("User") }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content class="ion-padding">

                <div class="content-items" v-if="!this.master">
                    <ion-item fill="outline" ref="item">
                        <ion-label position="floating">Email <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="emailInserido" @ionInput="validate" @ionBlur="markTouched"
                            ref="email"></ion-input>
                    </ion-item>
                    <ion-item v-if="emailExiste">
                        <ion-text color="danger">{{ $t("This_email_is_already_in_use.") }} {{ $t("Fill_in_the_fields_and_confirm") }}</ion-text>
                    </ion-item>
                    <ion-item fill="outline" v-if="!emailExiste">
                        <ion-label position="floating">{{ $t("Complete_Name") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="nome"></ion-input>
                    </ion-item>
                    <IonItem fill="outline" v-if="items">
                        <ion-label position="floating">{{ $t("Group") }} <span style="color: red;">*</span></ion-label>
                        <ion-select position="floating" aria-label="Fruit" class="select" side="top" v-model="idGrupo"
                            @ionChange="selecionarGrupo($event)">
                            <ion-content class="ion-padding" id="content-lista">
                                <ion-list v-for="item in items" :key="item.id">
                                    <ion-select-option :value="item.id">{{ item.nome
                                    }}</ion-select-option>
                                </ion-list>
                            </ion-content>
                        </ion-select>
                    </IonItem>
                    <ion-item fill="outline" v-if="!emailExiste">
                        <ion-label position="floating">{{ $t("Password") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha" type="password"></ion-input>
                    </ion-item>
                    <ion-item fill="outline" v-if="!emailExiste">
                        <ion-label position="floating">{{ $t("Confirm_Password") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha2" type="password"></ion-input>
                    </ion-item>

                    <ion-button size="large" @click="cadastrarUsuario">{{ $t("Confirm") }}</ion-button>
                </div>
                <div class="content-items" v-else>
                    <ion-item fill="outline" ref="item">
                        <ion-label position="floating">Email <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="emailInserido" @ionInput="validate" @ionBlur="markTouched"
                            ref="email"></ion-input>
                    </ion-item>
                    <ion-item v-if="emailExiste">
                        <ion-text color="danger">{{ $t("This_email_is_already_in_use.") }} {{ $t("Fill_in_the_fields_and_confirm") }}</ion-text>
                    </ion-item>
                    <div class="empresaGru"
                        style="display: flex;flex-direction: row;gap: 10px;width: 100%; align-items: center;"
                        v-for="(empresa, index) in form.empresas" :key="index">
                        <IonItem fill="outline" style="width: 45%;">
                            <ion-label position="floating">{{ $t("Company") }} <span
                                    style="color: red;">*</span></ion-label>
                            <ion-select position="floating" aria-label="Fruit" class="select" v-model="empresa.id">
                                <ion-content class="ion-padding" id="content-lista">
                                    <ion-list v-for="item in empresas" :key="item.id">
                                        <ion-select-option :value="item.id" v-if="!isEmpresaSelecionada(item.id, index)">{{
                                            item.nome }}</ion-select-option>
                                    </ion-list>
                                </ion-content>
                            </ion-select>
                        </IonItem>
                        <IonItem fill="outline" style="width: 45%;">
                            <ion-label position="floating">{{ $t("Group") }} <span style="color: red;">*</span></ion-label>
                            <ion-select position="floating" aria-label="Fruit" class="select" side="top"
                                v-model="empresa.grupo" @ionChange="selecionarGrupo($event)">
                                <ion-content class="ion-padding" id="content-lista">
                                    <ion-list v-for="e in gruposEmpresa(empresa)" :key="e.id">
                                        <ion-select-option :value="e.id">{{ e.nome
                                        }}</ion-select-option>
                                    </ion-list>
                                </ion-content>
                            </ion-select>
                        </IonItem>
                        <div class="iconDelete" style="width: 30px;">
                            <ion-icon @click="removerEmpresa(empresa.id)" :ios="closeOutline" :md="closeSharp" style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: transparent; color: #838383; border-radius: 3px;"></ion-icon>
                        </div>
                            
                    </div>
                    <div class="button" style="display: flex; flex-direction: row; justify-content: flex-end;">
                            <ion-icon @click="adicionarEmpresa(empresa.id)" :ios="addOutline" :md="addSharp" style="font-size: 8px; width: 30px !important; height: 30px !important; background-color: #0049b1; color: white; border-radius: 3px;"></ion-icon>
                    </div>

                    <ion-item fill="outline" v-if="!emailExiste">
                        <ion-label position="floating">{{ $t("Complete_Name") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="nome"></ion-input>
                    </ion-item>

                    <ion-item fill="outline" v-if="!emailExiste">
                        <ion-label position="floating">{{ $t("Password") }} <span style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha" type="password"></ion-input>
                    </ion-item>
                    <ion-item fill="outline" v-if="!emailExiste">
                        <ion-label position="floating">{{ $t("Confirm_Password") }} <span
                                style="color: red;">*</span></ion-label>
                        <ion-input v-model="senha2" type="password"></ion-input>
                    </ion-item>

                    <ion-button size="large" @click="cadastrarUsuario">{{ $t("Confirm") }}</ion-button>
                </div>
            </ion-content>

        </ion-page>
    </ion-page>
</template>
  
<script>
import { IonIcon,IonList, IonBackButton, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, alertController, IonText } from '@ionic/vue';
import axios from 'axios';
import { config } from "../../config"
import { ellipsisVerticalSharp, addOutline, addSharp, closeOutline, closeSharp } from 'ionicons/icons';


export default {
    components: {
        IonList, IonIcon,IonBackButton, IonSelect, IonSelectOption, IonContent, IonPage, IonInput, IonItem, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, IonMenuButton, IonTitle, IonText
    },
    data() {
        return {
            emailInserido: '',
            emails: [],
            emailExiste: false,
            items: '',
            funcionalidades: '',
            empresaSelecionada: '',
            empresas: "",
            master: '',
            form: {
                empresas: [{ id: '', grupo: '' }]
            },
            addOutline,
            addSharp, 
            closeOutline, 
            closeSharp
        }
    },
    mounted() {
        this.getPermissoes();
        this.getEmails()
        this.fetchEmpresas()
    },


    methods: {
        isEmpresaSelecionada(empresaId, currentIndex) {
            for (let i = 0; i < currentIndex; i++) {
                if (this.form.empresas[i].id == empresaId) {
                    
                    return true;
                    
                }
            }
            return false;
        },
        gruposEmpresa(empresa) {
            for (let e of this.empresas) {
                if (e.id == empresa.id) return e.grupos;
                
            }
        },
        async adicionarEmpresa() {

            const ultimaEmpresa = this.form.empresas[this.form.empresas.length - 1];

            if (ultimaEmpresa.id && ultimaEmpresa.grupo) {
                this.form.empresas.push({
                    id: '', grupo: ''
                });
            } else {
                const alert = await alertController.create({
                            header: "Error",
                            message: this.$t("Fill_in_all_company_fields")
                        });
                        await alert.present();
            }
        },
        async removerEmpresa(id) {
            if (this.form.empresas.length > 1) {
                this.form.empresas = this.form.empresas.filter(empresa => empresa.id !== id);
            } else {
                const alert = await alertController.create({
                    header: this.$t("Invalid_action"),
                    message: this.$t("You_can_not_delete_all"),
                    buttons: ['OK']
                });
                await alert.present();
            }
        },
        async fetchEmpresas() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresas = response.data.items;
                this.fetchUser()
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUser() {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('id');
            try {
                const response = await axios.get(`${config.apiUrl}/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.empresa = parseInt(localStorage.getItem('idEmpresa'));
                const empresasFiltradas = this.empresas.filter(empresa => empresa.id == this.empresa);
                this.nomeDaEmpresa = empresasFiltradas[0].nome
                this.master = response.data.master

                this.fetchItems()
            } catch (error) {
                console.log(error);
            }
        },
        async getPermissoes() {
            const groupId = localStorage.getItem('idGrupo');
            const token = localStorage.getItem('token');
            await axios.get(`${config.apiUrl}/users/groups/${groupId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                .then(async response => {

                    const funcionalidades = response.data.funcionalidades
                    this.funcionalidades = funcionalidades
                    if (!funcionalidades.includes(8)) {
                        const alert = await alertController.create({
                            header: "Error",
                            message:  this.$t("You_haven't_access")
                        });
                        await alert.present();
                        const userId = localStorage.getItem('id');
                        window.location.replace(`/users/${userId}/my-account`);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async fetchItems() {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users_groups_normal`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                if (!this.master) {
                    const { nomes, ids, empresas } = response.data.items;
                    const empresaId = parseInt(localStorage.getItem('idEmpresa'));

                    this.items = nomes
                        .map((nome, index) => ({
                            id: ids[index],
                            nome,
                            empresa: empresas[index]
                        }))
                        .filter((item) => {
                            const empresa = parseInt(item.empresa);
                            return empresa == empresaId;
                        });
                    return this.items;
                } else {

                    const { nomes, ids } = response.data.items;
                    this.items = nomes.map((nome, index) => ({
                        id: ids[index],
                        nome
                    }));
                    
                }

            } catch (error) {
                console.log(error);
            }
        },
        selecionarGrupo(event) {
            this.idGrupoSelecionado = event.target.value
        },
        selecionarNivelDeAcesso(event) {
            this.valueNivelacesso = event.target.value
        },
        async getEmails(emailInserido) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiUrl}/users/emails`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                this.emails = response.data.emails;

                return this.emails.includes(emailInserido);
            } catch (error) {
                console.error(error);
            }
        },

        validateEmail(email) {
            const validTLDs = ["com", "net", "org", "io", "br"];
            return email.match(`^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\\.(${validTLDs.join("|")})$`);
        },

        validate(ev) {
            const value = ev.target.value;

            this.$refs.item.$el.classList.remove('ion-valid');
            this.$refs.item.$el.classList.remove('ion-invalid');

            if (value == '') return;

            this.validateEmail(value)
                ? this.$refs.item.$el.classList.add('ion-valid')
                : this.$refs.item.$el.classList.add('ion-invalid');
        },

        markTouched() {
            this.$refs.item.$el.classList.add('ion-touched')
        },

        async cadastrarUsuario() {
            if (!this.emailExiste) {
                if (this.master) {
                    const senhaRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&*]{8,}$/;


                    if (!this.validateEmail(this.emailInserido)) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }


                    if (!this.emailInserido) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    const ultimaEmpresa = this.form.empresas[this.form.empresas.length - 1];

                    if (!ultimaEmpresa.id) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Company"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if (!ultimaEmpresa.grupo) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Group"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if (!this.nome) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;

                    }

                    if (!this.senha) {
                        if (!senhaRegex.test(this.senha)) {
                            const alert = await alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("The_password_must_contain"),
                                buttons: ['OK']
                            });
                            await alert.present();
                            return;
                        }
                    }

                    if (!this.senha2) {
                        if (!senhaRegex.test(this.senha2)) {
                            const alert = await alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("The_password_must_contain"),
                                buttons: ['OK']
                            });
                            await alert.present();
                            return;
                        }
                    }

                    if ((!this.senha) && this.senha2) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Password"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if ((!this.senha2) && this.senha) {
                        const alert = await alertController.create({
                            header: this.$t("Confirm_Password"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }


                    if (this.senha2 !== this.senha) {
                        const alert = await alertController.create({
                            header: this.$t("Passwords_do_not_match"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if (!this.senha) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Password"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                    const token = localStorage.getItem('token');
                    const data = {
                        nome: this.nome,
                        email: this.emailInserido,
                        senha: this.senha,
                        empresas: this.form.empresas,
                    }
                    console.log(data)
                    await axios.post(`${config.apiUrl}/users/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                        .then(response => {
                            console.log(response.data);
                            const alert = alertController.create({
                                header: this.$t("successfully_registered!")
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            window.location.replace('/users')
                        })
                        .catch(error => {
                            console.log(error);
                            const alert = alertController.create({
                                header: this.$t("Error_registering"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                        });
                } else {
                    const senhaRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&*]{8,}$/;

                    if (!this.empresa && this.empresaSelecionada) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;

                    }

                    if (!this.nome) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;

                    }

                    // if ((this.emailExiste == true)) {

                    //     const alert = await alertController.create({
                    //         header: this.$t("Error"),
                    //         message: this.$t("The_following_field_cannot_be_inserted") + " " + " Email ",
                    //         buttons: ['OK']
                    //     });
                    //     await alert.present();
                    //     return;
                    // }

                    if (!this.validateEmail(this.emailInserido)) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }


                    if (!this.emailInserido) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }


                    if (!this.idGrupoSelecionado) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Group"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if (!this.senha) {
                        if (!senhaRegex.test(this.senha)) {
                            const alert = await alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("The_password_must_contain"),
                                buttons: ['OK']
                            });
                            await alert.present();
                            return;
                        }
                    }

                    if (!this.senha2) {
                        if (!senhaRegex.test(this.senha2)) {
                            const alert = await alertController.create({
                                header: this.$t("Error"),
                                message: this.$t("The_password_must_contain"),
                                buttons: ['OK']
                            });
                            await alert.present();
                            return;
                        }
                    }

                    if ((!this.senha) && this.senha2) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Password"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if ((!this.senha2) && this.senha) {
                        const alert = await alertController.create({
                            header: this.$t("Confirm_Password"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }


                    if (this.senha2 !== this.senha) {
                        const alert = await alertController.create({
                            header: this.$t("Passwords_do_not_match"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if (!this.senha) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Password"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }
                    const token = localStorage.getItem('token');
                    const data = {
                        nome: this.nome,
                        email: this.emailInserido,
                        senha: this.senha,
                        idGrupo: this.idGrupoSelecionado,
                        empresa: this.empresaSelecionada ? this.empresaSelecionada : this.empresa,
                        nomeDaEmpresa: this.nomeDaEmpresa
                    }
                    console.log(data)
                    await axios.post(`${config.apiUrl}/users/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                        .then(response => {
                            console.log(response.data);
                            const alert = alertController.create({
                                header: this.$t("successfully_registered!")
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            window.location.replace('/users')
                        })
                        .catch(error => {
                            console.log(error);
                            const alert = alertController.create({
                                header: this.$t("Error_registering"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                        });
                }

            } else {

                if (this.master) {

                    const ultimaEmpresa = this.form.empresas[this.form.empresas.length - 1];

                    if (!ultimaEmpresa.id) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Company"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    if (!ultimaEmpresa.grupo) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Group"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }


                    if (!this.emailInserido) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    const token = localStorage.getItem('token');
                    const data2 = {
                        emailExiste: this.emailInserido,
                    }

                    const response = await axios.put(`${config.apiUrl}/getUserByEmail`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                    const idUsuario = response.data.id

                    const data = {
                        id: idUsuario,
                        empresas: this.form.empresas,
                    }
                    console.log(data)
                    await axios.post(`${config.apiUrl}/users/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                        .then(response => {
                            console.log(response.data);
                            const alert = alertController.create({
                                header: this.$t("successfully_registered!")
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            window.location.replace('/users')
                        })
                        .catch(error => {
                            console.log(error);
                            const alert = alertController.create({
                                header: this.$t("Error_registering"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                        });

                } else {

                    if (!this.empresa && this.empresaSelecionada) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Name"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;

                    }


                    if (!this.emailInserido) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + " Email ",
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }


                    if (!this.idGrupoSelecionado) {
                        const alert = await alertController.create({
                            header: this.$t("Error"),
                            message: this.$t("Fill_in_the_following_field_correctly") + " " + this.$t("Group"),
                            buttons: ['OK']
                        });
                        await alert.present();
                        return;
                    }

                    const token = localStorage.getItem('token');
                    const data2 = {
                        emailExiste: this.emailInserido,
                    }

                    const response = await axios.put(`${config.apiUrl}/getUserByEmail`, data2, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
                    const idUsuario = response.data.id

                    const data = {
                        id: idUsuario,
                        idGrupo: this.idGrupoSelecionado,
                        empresa: this.empresaSelecionada ? this.empresaSelecionada : this.empresa,
                        nomeDaEmpresa: this.nomeDaEmpresa
                    }
                    console.log(data)
                    await axios.post(`${config.apiUrl}/users/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
                        .then(response => {
                            console.log(response.data);
                            const alert = alertController.create({
                                header: this.$t("successfully_registered!")
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                            window.location.replace('/users')
                        })
                        .catch(error => {
                            console.log(error);
                            const alert = alertController.create({
                                header: this.$t("Error_registering"),
                                buttons: ['OK']
                            });
                            alert.then(_alert => {
                                _alert.present();
                            });
                        });
                }

            }
        }

    }
    ,
    watch: {
        emailInserido: async function (newEmail) {

            this.emailExiste = await this.getEmails(newEmail);

        },
        function(newVal) {
            this.email = newVal;
        }
    },

    setup() {

        return { ellipsisVerticalSharp };
    }
}
</script>
  
<style scoped>
ion-input ::placeholder {
    font-size: 12px;
}


ion-avatar {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-button {
    --background: #0049b1;
    margin: 0px;
    --border-radius: 6px;
    --box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.content-items {
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: fit-content;
}


ion-content #content-lista {
    min-height: 240px;
}

.select {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>